.video-popup-container {
  width: 700px;
  height: 500px;
  margin: 5px;
}
.video-popup-container .video-popup-actions-container {
  display: flex;
  justify-content: flex-end;
}
.video-popup-container .video-popup-actions-container a:link,
.video-popup-container .video-popup-actions-container a:visited {
  text-decoration: none;
}
.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  margin-left: -45px !important;
  margin-top: -22px !important;
}

.RichEditor-root {
  padding-bottom: 10px;
  display: inline-block;
}

.RichEditor-message {
  padding-bottom: 10px;
}

.RichEditor-editor {
  cursor: text;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  border: none;
  background-color: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.public-DraftEditorPlaceholder-root {
  overflow: hidden;
  height: 24px;
}

.public-DraftEditorPlaceholder-root {
  position: absolute;
  color: #8f8e8e;
}

.RichEditor-root.block {
  width: 100% !important;
}

.matrixQuestionText {
  color: #000;
  font-size: 14px;
  font-family: 'Open Sans Regular';
  min-height: 28px;
  line-height: 18px;
}

.QuestionThumbnail_meduaUpload__1ydJw {
  position: absolute;
  top: -3px;
  right: 0px;
  width: 30px;
  height: 30px;
  z-index: 15;
}
.ReactModalPortal {
  z-index: 100000;
}
.QuestionThumbnail_meduaUpload__1ydJw .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px !important;
}
.QuestionThumbnail_meduaUpload__1ydJw .video-file-preview {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
}
.QuestionThumbnail_meduaUpload__1ydJw .video-file-preview img {
  left: 9px;
  top: 7px;
  position: absolute;
}
.QuestionThumbnail_meduaUpload__1ydJw .video-file-preview span {
  position: relative;
  display: inline-block;
  top: 6px;
  left: 9px;
}

.SidePropertiesContainer_container__3lm2E {
  position: absolute;
  right: -315px;
  top: 0;
  width: 305px;
  padding-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  font-size: 14px;
  z-index: 20;
  cursor: default;
}
.SidePropertiesContainer_title__3h6TH {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
}
.SidePropertiesContainer_titleLabel__1OKAh {
  display: inline-flex;
  margin-left: 20px;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
}
.SidePropertiesContainer_content__178fL {
  max-height: calc(100vh - 210px);
  overflow-y: visible;
  overflow-x: visible;
  scrollbar-width: thin;
}
.SidePropertiesContainer_settingsIcon__xZqv1 {
  position: absolute;
  top: 25px;
  left: -25px;
}

.UploadMedia_container__15r46 {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 20px;
}

.ToggleSelector_singleProperty__2el8w {
  padding: 10px 0;
  margin: 0 20px 10px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
}
.ToggleSelector_singleProperty__2el8w:last-child {
  border-bottom: 0;
}
.ToggleSelector_switch__mcTSq {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}
.ToggleSelector_switch__mcTSq .slider:before {
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: #fff;
}

.BlockDestinationPosition_container__35Gfp {
  color: #221f1f;
  background-color: #f6f7fb;
  border-bottom: 1px solid #ebecf0;
  padding-top: 10px;
}
.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_dropdownPlaceholder__11U3v {
  display: block;
  position: relative;
  background-color: #ffffff;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  padding-left: 10px;
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 9px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
  margin-bottom: 10px;
}
.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_title__3meuw {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 20px;
  margin-bottom: 10px;
}
.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_additionalPropertiesActions__2fJrZ {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_additionalPropertiesActions__2fJrZ .BlockDestinationPosition_additionalPropertiesCloseButton__1JY6z {
  font-size: 11px;
  color: #221f1f;
  cursor: pointer;
}
.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_additionalPropertiesActions__2fJrZ .BlockDestinationPosition_additionalPropertiesCloseButton__1JY6z img {
  height: 20px;
  width: 20px;
}
.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_additionalPropertiesActions__2fJrZ .BlockDestinationPosition_additionalPropertiesEditButton__1DrmW {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}
.BlockDestinationPosition_container__35Gfp
  .BlockDestinationPosition_additionalPropertiesActions__2fJrZ
  .BlockDestinationPosition_additionalPropertiesEditButton__1DrmW.BlockDestinationPosition_disabled__1I-Sx {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8b8b8d;
  cursor: not-allowed;
}

.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_logic-jump-action-editor-dropdown__1jla4 {
  position: fixed;
  width: 220px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 215px;
  background-color: red;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  scrollbar-width: thin;
  font-size: 14px;
}
.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_logic-jump-action-editor-dropdown__1jla4 .BlockDestinationPosition_logic-jump-dropdown-item__2K3Ci {
  height: 35px;
  line-height: 35px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  cursor: pointer;
}
.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_logic-jump-action-editor-dropdown__1jla4 .BlockDestinationPosition_logic-jump-dropdown-item__2K3Ci.BlockDestinationPosition_active__vk0na {
  background-color: #efefef;
}
.BlockDestinationPosition_container__35Gfp .BlockDestinationPosition_logic-jump-action-editor-dropdown__1jla4 .BlockDestinationPosition_logic-jump-dropdown-item__2K3Ci:hover {
  opacity: 0.7;
}
.BlockDestinationPosition_container__35Gfp
  .BlockDestinationPosition_logic-jump-action-editor-dropdown__1jla4
  .BlockDestinationPosition_logic-jump-dropdown-item__2K3Ci
  .BlockDestinationPosition_option-icon__1b7Li {
  color: #697df0;
  font-size: 12px;
  margin-right: 10px;
}
.BlockDestinationPosition_containerStyle__22gZ4 {
  display: block;
  width: auto !important;
  position: relative;
  background-color: #ffffff;
  font-size: 12px !important;
  height: 32px !important;
  line-height: 32px !important;
  border: 1px solid #dfe0e4 !important;
  border-radius: 3px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  cursor: pointer;
  padding-left: 10px !important;
  background-position: right 7px center !important;
  background-repeat: no-repeat;
  background-size: 9px !important;
  padding-right: 20px !important;
  margin-bottom: 10px !important;
}

.DropdownOptionsPopup_absoluteContainer__9e2N7 {
  position: absolute;
  z-index: 20;
}
.DropdownOptionsPopup_container__3VQty {
  position: static;
  background-color: #ffffff;
  width: 250px;
  max-height: 170px;
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
  overflow: -moz-scrollbars-vertical;
  border: 1px solid #ececec;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.03);
  padding-top: 5px;
  padding-bottom: 5px;
}
.DropdownOptionsPopup_question__10ZWK {
  position: static;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  color: #8b8b8d;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
.DropdownOptionsPopup_question__10ZWK img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.DropdownOptionsPopup_horizontalLine__jiHsN {
  height: 10px;
  border-top: 1px solid #e2e2e2;
  margin: 10px;
}
.DropdownOptionsPopup_questionContainer__3jFa4 {
  flex: 1 1;
}
.DropdownOptionsPopup_questionContainer__3jFa4.DropdownOptionsPopup_questionContainerWithConstraints__259AR {
  opacity: 0.3;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ConstraintTooltip_container__74xwL {
  float: right;
  position: static;
  text-transform: none;
  text-transform: initial;
}
.ConstraintTooltip_symbol__XcqZG {
  background-color: #a97ff8;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  position: static;
}

.ConstraintTooltipContainer_tooltipContainer__2iGDA {
  position: absolute;
  right: -230px;
  top: 0px;
  z-index: 20;
}
.ConstraintTooltipContainer_tooltip__hip7K {
  width: 200px;
  font-size: 12px;
  font-family: 'Open Sans Light';
  background-color: #ebe4fe;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.ConstraintTooltipContainer_tooltip__hip7K:after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
}

.QuestionsListDropdown_dropdownContainer__2kyrX {
  width: 50%;
  max-width: 300px;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;
  padding-right: 30px;
  margin-left: 15px;
  margin-right: 15px;
}
.QuestionsListDropdown_dropdownContainer__2kyrX.QuestionsListDropdown_dropdownVisisble__3-Dj0:before {
  content: '';
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.QuestionsListDropdown_dropdownContainerLabel__3vsdw {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.QuestionsListDropdown_dropdownContainerLabel__3vsdw img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.QuestionsListDropdown_dropdownContainerArrow__1Ut_A {
  width: 20px;
  height: 20px;
}

.QuestionsListDropdown_absoluteContainer__OCnBE {
  position: absolute;
  z-index: 25;
}
.QuestionsListDropdown_container__t9HIn {
  position: static;
  background-color: #ffffff;
  width: 400px;
  max-height: 170px;
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
  overflow: -moz-scrollbars-vertical;
  border: 1px solid #ececec;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.03);
  padding-top: 5px;
  padding-bottom: 5px;
}
.QuestionsListDropdown_question__3UY12 {
  position: static;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  color: #8b8b8d;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
.QuestionsListDropdown_question__3UY12 img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.QuestionsListDropdown_horizontalLine__2I252 {
  height: 10px;
  border-top: 1px solid #e2e2e2;
  margin: 10px;
}
.QuestionsListDropdown_questionContainer__16g16 {
  flex: 1 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.QuestionsListDropdown_globalIndex__14T1w {
  padding-left: 5px;
  padding-right: 2px;
}
.QuestionsListDropdown_questionContainer__16g16.QuestionsListDropdown_questionContainerWithConstraints__xqVE5 {
  opacity: 0.3;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.QuestionsListDropdown_groupIcon__37U9S {
  padding-left: 4px;
  padding-right: 5px;
}
.QuestionsListDropdown_nestingLevel1Padding__3mhJE {
  width: 20px;
  display: inline-block;
}
.QuestionsListDropdown_nestingLevel2Padding__3Zhp8 {
  width: 40px;
  display: inline-block;
}
.QuestionsListDropdown_footer__2C-WC {
  color: #8b8b8d;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  display: flex;
  font-style: italic;
  top: -7px;
  text-align: center;
  display: block;
  width: 100%;
}
.QuestionsListDropdown_distributorIconContainer__2xRbe img {
  width: 12px;
  height: 19px;
  top: 5px;
  position: relative;
}
.QuestionsListDropdown_distributorIconPContainer__3euKn img {
  width: 12px;
  height: 19px;
  top: 5px;
  position: relative;
}

.Action_container__2b0v2 {
  padding: 15px 15px 13px 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  color: #8b8b8d;
  font-family: 'Open Sans SemiBold';
  justify-content: space-between;
}
.Action_notAllowed__35UE8 {
  cursor: default;
  opacity: 0.4;
}
div:last-child > .Action_container__2b0v2 {
  border-bottom: none !important;
  margin-bottom: -20px;
}

.emoji-picker {
  padding: 0 5px 10px 5px;
  position: absolute;
  top: 40px;
  right: -43px;
  width: 208px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
  z-index: 25;
}
.emoji-picker::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.emoji-picker::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.emoji-picker .emoji-category {
  width: 100%;
  margin-left: 5px;
  margin-bottom: 10px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.emoji-picker .emoji-category:first-child {
  margin-top: 10px;
}
.emoji-picker .emoji {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.emoji-picker .emoji:hover {
  opacity: 0.6;
}

.SideProperties_container__2mhX- {
  position: absolute;
  right: -315px;
  top: 0px;
  width: 305px;
  padding-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  font-size: 14px;
}
.SideProperties_title__2Nq0z {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
}
.SideProperties_content__7i2EW {
  max-height: calc(100vh - 210px);
  overflow-y: visible;
  overflow-x: visible;
  scrollbar-width: thin;
}
.SideProperties_titleLabel__1pY9L {
  display: inline-flex;
  margin-left: 20px;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
}
.SideProperties_blockTitle__1uQdv {
  font-size: 11px;
  color: #8b8b8d;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.SideProperties_textContainer__1-NB0 {
  margin-top: 20px;
  margin-left: 10px;
}
.SideProperties_settingsIcon__zGM8B {
  position: absolute;
  top: 25px;
  left: -25px;
}
.SideProperties_icon__343p_ {
  width: 15px;
  height: 12px;
  margin-left: 10px;
  margin-right: 5px;
}

.MCAnswers_blockAdditionalOptions__3jfvJ {
  margin-bottom: 30px;
}
.MCAnswers_descriptionTitle__e5NtU {
  font-size: 14px;
  color: #8b8b8d;
}
.MCAnswers_matrixDescriptionTitle__2ru76 {
  margin-bottom: 5px;
}
.MCAnswers_answerCoverImageContainer__3naAC {
  border: 1px solid #8500f0;
  border-radius: 2px;
  height: 22px;
  display: flex;
  position: absolute;
  left: 0px;
  overflow: hidden;
  cursor: default;
}
.MCAnswers_leftContainer__24fxS {
  width: 22px;
  height: 22px;
}
.MCAnswers_imagePlaceholder__3w0Ch {
  width: 22px;
  height: 22px;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  display: block;
  font-size: 20px;
}
.MCAnswers_answerCoverImageContainer__3naAC .MCAnswers_leftContainer__24fxS .MCAnswers_image-placeholder__3gKyh span {
  font-size: 26px;
  position: absolute;
  left: -2.4px;
  top: -3.5px;
  padding: 0px;
  margin: 0px;
}
.MCAnswers_answerCoverImageContainer__3naAC .MCAnswers_leftContainer__24fxS .MCAnswers_coverImagePreview__2Fi91 {
  width: 22px;
  height: 22px;
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
}
.MCAnswers_rightContainer__243wO {
  background-color: #ffffff;
  width: 22px;
  height: 22px;
}
.MCAnswers_rightContainer__243wO .MCAnswers_actionButton__Xkdgb {
  width: 18px;
  height: 14px;
  top: 3px;
  left: 2px;
  position: relative;
  display: inline-block;
}
.MCAnswers_blockAdditionalOption__15dvB .MCAnswers_answerCoverImageContainer__3naAC .MCAnswers_rightContainer__243wO .MCAnswers_action__3S8Ti {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 22px;
  height: 22px;
  font-size: 20px;
}
.MCAnswers_blockAdditionalOption__15dvB .MCAnswers_answerCoverImageContainer__3naAC .MCAnswers_rightContainer__243wO .MCAnswers_action__3S8Ti span {
  position: absolute;
  left: 2.2px;
  top: 2.25px;
  font-size: 13px;
}
.MCAnswers_answerCoverImageContainer__3naAC .MCAnswers_rightContainer__243wO .MCAnswers_action__3S8Ti .MCAnswers_fileUploadInput__3ldhm {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 22px;
  padding: 0px;
  margin: 0px;
  z-index: 10;
}
.MCAnswers_block-container__1HkzY .MCAnswers_blockAdditionalOptions__3jfvJ .MCAnswers_blockAdditionalOption__15dvB:last-child {
  border-bottom: 0;
}
.MCAnswers_inputAdd__3q8IE {
  margin-bottom: 20px;
  margin-top: 0px;
}
.MCAnswers_inputAddText__1bZig {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 90%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 0px;
  resize: none;
  overflow: hidden;
}
.MCAnswers_coverImagePreview__2Fi91 {
  width: 30px;
  height: 30px;
  background-size: cover;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 0px;
  cursor: pointer;
}
.MCAnswers_addOption__1HhFW {
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  bottom: 10px;
}
.MCAnswers_properties-column-container__32c1J {
  position: fixed;
  top: 90px;
  margin-left: 1044px;
  width: 290px;
  padding-bottom: 20px;
}
.MCAnswers_side-container-title-item__1rEYo {
  display: inline-flex;
  margin-left: 20px;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
}
.MCAnswers_side-container-content__39TcG {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.MCAnswers_side-container-content__39TcG .MCAnswers_emoji-picker__2y8DW {
  padding: 0 5px 10px 5px;
  position: absolute;
  top: 40px;
  right: -43px;
  width: 208px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
}
.MCAnswers_side-container-content__39TcG .MCAnswers_emoji-picker__2y8DW::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.MCAnswers_side-container-content__39TcG .MCAnswers_emoji-picker__2y8DW::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.MCAnswers_side-container-content__39TcG .MCAnswers_emoji-picker__2y8DW .MCAnswers_emoji-category__2Hwia {
  width: 100%;
  margin-left: 5px;
  margin-top: 15px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.MCAnswers_side-container-content__39TcG .MCAnswers_emoji-picker__2y8DW .MCAnswers_emoji-category__2Hwia:first-child {
  margin-top: 10px;
}
.MCAnswers_side-container-content__39TcG .MCAnswers_emoji-picker__2y8DW .MCAnswers_emoji__3ARQc {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.MCAnswers_side-container-content__39TcG .MCAnswers_emoji-picker__2y8DW .MCAnswers_emoji__3ARQc:hover {
  opacity: 0.6;
}
.MCAnswers_side-container-action__12IFw {
  padding: 15px 0 13px 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  color: #8b8b8d;
  font-family: 'Open Sans SemiBold';
}
.MCAnswers_side-container-action__12IFw.MCAnswers_disabled__G2nBW {
  cursor: not-allowed;
  opacity: 0.7;
}
.MCAnswers_side-container-action__12IFw.MCAnswers_disabled__G2nBW .MCAnswers_icon__3nTpJ {
  color: #8b8b8d;
}
.MCAnswers_side-container-action__12IFw .MCAnswers_soon__Z5Vfp {
  color: #ff3018;
  margin-left: 10px;
}
.MCAnswers_side-container-action__12IFw:last-child {
  border-bottom: 0;
}
.MCAnswers_side-container-action__12IFw:hover {
  opacity: 0.7;
}
.MCAnswers_side-container-action__12IFw:last-child {
  border-bottom: 0;
}
.MCAnswers_side-container-action__12IFw .MCAnswers_icon__3nTpJ {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 2px;
  top: 3px;
  font-size: 14px;
  position: relative;
  color: #5200f1;
}
.MCAnswers_edit-block-title__2zzhS {
  font-size: 11px;
  color: #8b8b8d;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.MCAnswers_edit-block-container__1RtV7 {
}
.MCAnswers_text-editor-container__Hs2FK {
  margin-left: 18px;
  margin-right: 18px;
  border-bottom: 1px solid #eeeeee !important;
  height: 50px;
  line-height: 50px;
}
.MCAnswers_text-editor-action__3Pf5j {
  display: inline-block;
  margin-right: 10px;
}
.MCAnswers_text-editor-action-button__1DGqu {
  padding: 0 10px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.4;
  cursor: pointer;
}
.MCAnswers_text-editor-action-button__1DGqu:hover {
  opacity: 0.3;
}
.MCAnswers_text-editor-action__3Pf5j.MCAnswers_true__8xgq- .MCAnswers_text-editor-action-button__1DGqu {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}
.MCAnswers_text-editor-action-button__1DGqu.MCAnswers_picker-active__g5Yi7 {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}
.MCAnswers_side-container-properties__1W8JE {
  width: 255px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.MCAnswers_side-container-properties__1W8JE .MCAnswers_cover-image-preview__2r9Pf {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 60px;
  cursor: pointer;
}
.MCAnswers_side-container-properties__1W8JE .MCAnswers_icon__3nTpJ {
  color: #8500f0;
  font-size: 25px;
  line-height: 20px;
  cursor: pointer;
}
.MCAnswers_side-container-properties__1W8JE .MCAnswers_media-upload__1SmzL a:link,
.MCAnswers_side-container-properties__1W8JE .MCAnswers_media-upload__1SmzL a:visited {
  font-size: 11px;
  text-decoration: none;
  color: #5200f1;
  margin-right: 10px;
}
.MCAnswers_side-container-properties__1W8JE .MCAnswers_action__3S8Ti {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.MCAnswers_side-container-properties__1W8JE .MCAnswers_action__3S8Ti .MCAnswers_file-upload-input__2ORGU {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 48px;
  height: 20px;
  font-size: 0;
}
.MCAnswers_side-container-properties__1W8JE .MCAnswers_action__3S8Ti.MCAnswers_remove__uU6i0 {
  color: red;
}

.MCAnswers_side-container-properties-campaign-web-url-container-wrapper__2YYch {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.MCAnswers_side-container-properties-campaign-web-url-container__2KnEO {
  margin-top: 10px;
  display: flex;
  flex: 1 1;
  width: 100%;
}
.MCAnswers_side-container-properties-campaign-web-url__nhEZc {
  width: 90%;
  padding: 5px;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  font-size: 12px;
}
.MCAnswers_side-container-properties-campaign-web-url-click-link__3fO9Y {
  margin-top: 8px;
  display: inline-block;
  position: relative;
  height: 30px;
  line-height: 30px;
  padding: 1px 6px;
  font-size: 14px;
  font-family: 'Open Sans SemiBold';
  border: none;
  background-color: transparent;
  color: #5200f1;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 4px;
}
.MCAnswers_side-container-properties-campaign-web-url-click-link__3fO9Y:hover {
  background-color: rgba(82, 0, 241, 0.1);
}

/* Toggle switch */
.MCAnswers_switch__1NrlE {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.MCAnswers_switch__1NrlE input {
  opacity: 0;
  width: 0;
  height: 0;
}

.MCAnswers_slider__2UGCS {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Inactive */
  transition: 0.4s;
}

.MCAnswers_slider__2UGCS:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .MCAnswers_slider__2UGCS {
  background-color: #5200f1; /* Active */
}

input:focus + .MCAnswers_slider__2UGCS {
  box-shadow: 0 0 1px #5200f1; /* Active */
}

input:checked + .MCAnswers_slider__2UGCS:before {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.MCAnswers_slider__2UGCS.MCAnswers_round__3xUUD {
  border-radius: 34px;
}

.MCAnswers_slider__2UGCS.MCAnswers_round__3xUUD:before {
  border-radius: 50%;
}

.MCAnswers_main-container__2kZ4o .MCAnswers_loader-container__1cFWw {
  background-color: #e3e3e3;
}
.MCAnswers_main-container__2kZ4o .MCAnswers_loader-container__1cFWw.MCAnswers_button__3GLxd {
  height: 55px;
  width: 150px;
}
.MCAnswers_main-container__2kZ4o .MCAnswers_loader-container__1cFWw.MCAnswers_column__oTMg6 {
  height: 400px;
}
.MCAnswers_main-container__2kZ4o .MCAnswers_loader-container__1cFWw.MCAnswers_item__10DaJ {
  height: 220px;
  margin-bottom: 50px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.MCAnswers_campaign-builder-mini-map-container__1tiCK {
  position: fixed;
  width: 55px;
  top: 67px;
  right: 0px;
  border-left: 1px solid #ebe9ef;
  height: calc(100vh - 67px);
  padding-top: 20px;
}
.MCAnswers_campaign-builder-mini-map-container__1tiCK .MCAnswers_single-question-icon__28j8b {
  text-align: center;
  color: #b4b2b6;
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.MCAnswers_campaign-builder-mini-map-container__1tiCK .MCAnswers_single-question-icon__28j8b.MCAnswers_active__2ZRHu {
  color: #8500f0;
}
.MCAnswers_campaign-builder-mini-map-container__1tiCK .MCAnswers_single-question-icon__28j8b:hover span {
  opacity: 0.7;
}
.MCAnswers_campaign-builder-mini-map-container__1tiCK
  .MCAnswers_single-question-icon__28j8b
  .MCAnswers_single-question-popup__3V-OT {
  display: none;
  position: relative;
}
.MCAnswers_campaign-builder-mini-map-container__1tiCK .MCAnswers_single-question-icon__28j8b .MCAnswers_error-dot__3bxzE {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
}
.MCAnswers_campaign-builder-mini-map-container__1tiCK
  .MCAnswers_single-question-icon__28j8b:hover
  .MCAnswers_single-question-popup__3V-OT {
  position: absolute;
  display: block;
  padding: 10px;
  max-width: 300px;
  min-width: 250px;
  font-size: 13px;
  color: #8b8b8d;
  top: 0px;
  right: 56px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  text-align: left;
  border-radius: 5px;
}

.MCAnswers_campaign-builder-errors-container__36C2I {
}
.MCAnswers_campaign-builder-error__3Omc_ {
  background-color: #f5a0a142;
  color: #b03223;
  font-size: 14px;
  line-height: 35px;
  padding-left: 20px;
}
.MCAnswers_campaign-builder-error__3Omc_:last-child {
  border-bottom: 1px solid #d5aea9;
}

.MCAnswers_blockAdditionalOption__15dvB {
  padding-top: 10px;
  border-bottom: 1px solid #ececec;
  margin-right: 60px;
}

@media only screen and (max-width: 1420px) {
  .MCAnswers_main-container__2kZ4o {
    width: auto;
  }
  .MCAnswers_content-header__1Iu9m {
    width: 97vw;
    padding-left: 10px;
  }
  .MCAnswers_content__38VKJ {
    padding-left: 10px;
  }
  .MCAnswers_blocks-column-container__30LAn {
    width: calc(100vw - 240px - 405px);
  }
  .MCAnswers_properties-column-container__32c1J {
    right: 70px;
  }
  .MCAnswers_block-content__2NfOW .MCAnswers_block-text__3a_0e .MCAnswers_description__kARGQ {
    display: block;
    width: 100%;
  }
  .MCAnswers_block-content__2NfOW .MCAnswers_block-text__3a_0e .MCAnswers_block__qnL-T {
    width: 100% !important;
  }
}

.MCAnswers_flow-chart-node-container__mY-PB {
  max-width: 400px;
  overflow: auto;
  white-space: pre-wrap;
  margin: auto;
  display: inline-block;
  padding: 5px;
}

.MCAnswers_node__1D7O_ rect {
  stroke: #333;
  fill: #fff;
}

.MCAnswers_edgePath__2NuCh path {
  stroke: #333;
  fill: #333;
  stroke-width: 1.5px;
}

.MCAnswers_svg-text-icon__2qpsX {
  width: 13px;
}

.MCAnswers_svg-text-icon-question__2ZfKa,
.MCAnswers_svg-text-icon-bigger__2yANz {
  width: 15px;
}

.MCAnswers_svg-text-minimap-icon__1O2d- {
  width: 15px;
  -webkit-filter: grayscale(1) opacity(0.35);
          filter: grayscale(1) opacity(0.35);
}

.MCAnswers_single-question-icon__28j8b:hover .MCAnswers_svg-text-minimap-icon__1O2d- {
  opacity: 0.7;
}

.MCAnswers_single-question-icon__28j8b.MCAnswers_active__2ZRHu .MCAnswers_svg-text-minimap-icon__1O2d- {
  width: 15px;
  -webkit-filter: grayscale(0) opacity(1);
          filter: grayscale(0) opacity(1);
}
.MCAnswers_randomisedIcon__2fOcN {
  position: absolute;
  left: -48px;
}

.MCAnswers_questionMark__575ta:hover {
  background-color: #5301f2;
}

.AnswerLogicJump_container__1olEL {
  width: calc(100% + 65px);
  max-height: 215px;
  overflow: visible;
  background-color: #f6f2fe;
  scrollbar-width: thin;
  font-size: 14px;
}
.AnswerLogicJump_dropdownRowParent__8IRO9 {
  display: flex;
  flex: 1 1;
  align-items: center;
  min-width: 0;
}
.AnswerLogicJump_dropdownRow__1F43t {
  border-bottom: 1px solid #eeeeee;
  padding: 7px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}
.AnswerLogicJump_dropdownRow__1F43t .AnswerLogicJump_dropdownContainer__3QyHD {
  width: 50%;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 30px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.AnswerLogicJump_dropdownRow__1F43t .AnswerLogicJump_dropdownContainer__3QyHD.AnswerLogicJump_dropdownVisisble__30M1M:before {
  content: '';
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.AnswerLogicJump_dropdownRow__1F43t .AnswerLogicJump_dropdownContainerLabel__4m2Z3 img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.AnswerLogicJump_dropdownRow__1F43t .AnswerLogicJump_dropdownContainerArrow__1dCmI {
  width: 20px;
  height: 20px;
}
.AnswerLogicJump_dropdownRow__1F43t .AnswerLogicJump_close__aDPrg {
  cursor: pointer;
}
.AnswerLogicJump_dropdownRow__1F43t .AnswerLogicJump_close__aDPrg img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.AnswerLogicJump_actionsContainer__3UJRp {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.AnswerLogicJump_actionsContainer__3UJRp .AnswerLogicJump_delete__2fPUZ {
  color: #fc2270;
  cursor: pointer;
}
.AnswerLogicJump_actionsContainer__3UJRp .AnswerLogicJump_delete__2fPUZ img {
  position: relative;
  width: 20px;
  height: 15px;
  margin-right: 5px;
  top: 2px;
}
.AnswerLogicJump_actionsContainer__3UJRp .AnswerLogicJump_confirm__38Iln {
  cursor: pointer;
}
.AnswerLogicJump_actionsContainer__3UJRp .AnswerLogicJump_confirm__38Iln img {
  width: 18px;
  height: 11px;
}
.AnswerLogicJump_ljArrow__3b3bi {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}
.AnswerLogicJump_evenOtherAreSelected__1lyT3 {
  font-style: italic;
  padding-top: 2px;
  padding-bottom: 10px;
  font-size: 12px;
  padding-left: 50px;
}

.AnswerQuotaInput_quotaInput__1AOnr {
  width: 40px;
  margin: 0 8px;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 23px;
  line-height: 23px;
  padding-left: 10px;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 5px;
  outline: none;
  resize: none;
  white-space: nowrap;
  overflow-x: hidden;
}
.AnswerQuotaInput_quotaInput__1AOnr.AnswerQuotaInput_disabled__Rurxj {
  background-color: #ededed;
  cursor: inherit;
}

.AnswerQuota_container__3v8W_ {
  width: calc(100% + 65px);
  margin-top: 5px;
  max-height: 215px;
  overflow: visible;
  background-color: #f6f2fe;
  scrollbar-width: thin;
  font-size: 14px;
}
.AnswerQuota_container__3v8W_.AnswerQuota_error__3D8pL {
  background-color: #fef0f6;
}
.AnswerQuota_dropdownRowParent__JprCo {
  display: flex;
  flex: 1 1;
  align-items: center;
}
.AnswerQuota_dropdownRow__3QA0Z {
  border-bottom: 1px solid #eeeeee;
  padding: 5px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}
.AnswerQuota_dropdownRow__3QA0Z .AnswerQuota_close__21FH3 {
  cursor: pointer;
}
.AnswerQuota_dropdownRow__3QA0Z .AnswerQuota_close__21FH3 img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
.AnswerQuota_dropdownContainer__unRwT {
  width: 20px;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 30px;
  margin-left: 15px;
  z-index: 25;
}
.AnswerQuota_dropdownContainer__unRwT.AnswerQuota_dropdownVisisble__iHg-y:before {
  content: '';
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.AnswerQuota_dropdownContainerArrow__2SOic {
  width: 20px;
  height: 20px;
}
.AnswerQuota_ljArrow__PXrgD {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}
.AnswerQuota_errorIconContainer__3_CTX {
  position: absolute;
  top: 2px;
  right: 0px;
  padding-left: 10px;
  padding-right: 5px;
  border-left: 1px solid #c1c1c1;
  cursor: default;
}
.AnswerQuota_errorIconContainer__3_CTX img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}
.AnswerQuota_errorIconContainer__3_CTX span {
  display: none;
  position: absolute;
  top: -5px;
  left: 80px;
  min-width: 125px;
  max-width: 300px;
  padding: 10px;
  z-index: 100;
  background: #f61553;
  color: #fff;
  -moz-border-radius: 5px; /* this works only in camino/firefox */
  -webkit-border-radius: 5px; /* this is just for Safari */
  white-space: pre-wrap;
}
.AnswerQuota_errorIconContainer__3_CTX span:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #f61553;
  left: -8px;

  top: 11px;
}

.AnswerQuota_errorIconContainer__3_CTX:hover {
  font-size: 99%; /* this is just for IE */
}

.AnswerQuota_errorIconContainer__3_CTX:hover span {
  display: block;
}
.AnswerQuota_constraintTooltip__9KYMP {
  position: absolute;
  top: -4px;
  right: -4px;
  z-index: 25;
}

.AnswerConnectionLine_container__1Nfgd {
  position: absolute;
  top: 0px;
  left: -35px;
  width: 35px;
  height: 100%;
  display: flex;
}
.AnswerConnectionLine_topContainer__N5akl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
}
.AnswerConnectionLine_bottomContainer__1R_XB {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: calc(100% - 20px);
}
.AnswerConnectionLine_leftLine__346Yv {
  border-left: 1px dashed #8500f0;
}
.AnswerConnectionLine_topLine__13aBT {
  border-top: 1px dashed #8500f0;
}
.AnswerConnectionLine_bottomLine__1HlD5 {
  border-bottom: 1px dashed #8500f0;
}

.AnswerJumpMessage_container__3FHSj {
  width: calc(100% + 65px);
  background-color: #f6f2fe;
  margin-top: 4px;
}
.AnswerJumpMessage_dropdownRowParent__30IOb {
  display: flex;
  flex: 1 1;
  align-items: center;
  min-width: 0;
  height: 30px;
}
.AnswerJumpMessage_dropdownRow__158d8 {
  border-bottom: 1px solid #eeeeee;
  padding: 7px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 11px;
  justify-content: space-between;
}
.AnswerJumpMessage_ljArrow__AHBpe {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}

.AnswerText_answerTextarea__33Td3 {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 73%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 60px;
  resize: none;
  overflow: hidden;
}
.AnswerText_answerTextarea__33Td3.AnswerText_draftField__1ECVL {
  min-height: 24px;
  height: auto;
  padding: 2px 2px 6px 2px;
  cursor: text;
}
.AnswerText_answerTextarea__33Td3.AnswerText_textIndent__2p-MI {
  text-indent: 58px;
}
.AnswerText_answerTextarea__33Td3::-webkit-input-placeholder {
  color: #c4c4c4;
}
.AnswerText_answerTextarea__33Td3::-ms-input-placeholder {
  color: #c4c4c4;
}
.AnswerText_answerTextarea__33Td3::placeholder {
  color: #c4c4c4;
}
.AnswerText_answerTextareaSpan__10kXY {
  margin-bottom: 10px;
  line-height: 19px;
  padding-left: 2px;
  padding-top: 2px;
}
.AnswerText_answerTextarea__33Td3.AnswerText_disabled__2JdbN {
  opacity: 0.3;
  cursor: default;
}

.AnswerText_isOtherDebounce__1ZEyj {
  text-indent: 58px;
}
.AnswerText_isOtherDebounce__1ZEyj::first-line {
  line-height: 21px;
}
.AnswerText_draftOverlay__15i5u {
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 25;
}

.Answer_container__2QEdK {
  display: flex;
}
.Answer_blockAdditionalOption__TpAtV {
  padding-top: 10px;
  border-bottom: 1px solid #ececec;
  margin-right: 60px;
}
.Answer_blockAdditionalOption__TpAtV.Answer_matrixBlockAdditionalOption__MTwxc {
  margin-right: 15px;
}
.Answer_close__3M2l- {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 2px;
}
.Answer_close__3M2l- img {
  width: 20px;
  height: 20px;
}
.Answer_close__3M2l-.Answer_disabled__2DWNG {
  opacity: 0.3;
}
.Answer_logicJumpAdmin__3xB7w {
  cursor: pointer;
  position: absolute;
  right: -60px;
}
.Answer_logicJump__3xj1R {
  cursor: pointer;
  position: absolute;
  right: -30px;
}
.Answer_quota__3j8UV {
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -3px;
  font-size: 18px;
}
.Answer_logicJump__3xj1R svg,
.Answer_logicJumpAdmin__3xB7w svg,
.Answer_quota__3j8UV svg {
  width: 17px;
  height: 11px;
}
.Answer_allowOther__1A6_B {
  background-color: #5200f1;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: 0px;
  top: 40%;
  padding: 2px 5px;
  border-radius: 2px;
}
.Answer_answerLabel__2OFNc {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 90%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 62px;
  resize: none;
  overflow: hidden;
  margin-bottom: 15px;
  margin-top: 2px;
  min-height: 19px;
}
.Answer_lock__3NwsL {
  position: absolute;
  right: 30px;
}
.Answer_lockIcon__3NUdH {
  align-self: flex-start;
  padding-top: 4px;
  padding-left: 4px;
}
.Answer_lockTooltip__3Wb-v {
  position: absolute;
  display: none;
  background-color: #a97ff8;
  right: -220px;
  top: -8px;
  font-size: 12px;
  color: #fff;
  width: 200px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 4px;
  border-radius: 8px;
  z-index: 25;
  box-shadow: -5px 5px 10px 5px rgba(0, 0, 0, 0.05);
}
.Answer_lockTooltipArrow__3S19d {
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 10px solid #a97ff8;
  position: absolute;
  left: -7px;
  top: 5px;
}
.Answer_lockIcon__3NUdH:hover + .Answer_lockTooltip__3Wb-v {
  display: block;
}
.Answer_deboundeIcon__3khwf {
  margin-left: 60px;
  padding-bottom: 11px;
}
.Answer_answerOtherNoneLabel__25N_8 {
  color: #5200f1;
  border: 1px solid #5200f1;
  position: absolute;
  left: 60px;
  text-transform: uppercase;
  width: 52px;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  padding: 3px 0;
  border-radius: 5px;
}

.TooltipContainer_container__3b5mE {
  position: absolute;
  left: 39px;
  top: -21px;
  width: 450px;
  background: #a97ff8;
  color: #fff;
  border-radius: 6px;
  cursor: default;
  font-size: 14px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px;
  z-index: 25;
}
.TooltipContainer_arrow__1OnIB {
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-right: 17px solid #a97ff8;
  position: absolute;
  left: -16px;
  top: 12px;
}
.TooltipContainer_header__27Ppe {
  border-bottom: 1px solid rgba(255, 255, 255, 30%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  padding: 15px 25px;
  align-items: center;
}
.TooltipContainer_description__3PNRl {
  padding: 5px 25px 20px 25px;
}
.TooltipContainer_addAnswerButton__33wV2 {
  padding: 5px 10px;
  border: 1px solid #ececec;
  border-radius: 7px;
  font-size: 12px;
  color: #000;
  background: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px 0;
  justify-content: center;
  align-items: center;
  display: flex;
}
.TooltipContainer_addAnswerIcon__U0ILh {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.TooltipContainer_addOpenAnswerIcon__1YJfK {
  width: 15px;
  height: 14px;
  margin-right: 10px;
}
.TooltipContainer_xIcon__3h4NB {
  cursor: pointer;
}

.AddNewAnswer_addAnswerContainer__3EIhf {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.AddNewAnswer_addAnswerContainer__3EIhf.AddNewAnswer_disabled__39yo5 {
  opacity: 0.3;
}
.AddNewAnswer_addAnswerButton__1Fxe5 {
  padding: 5px 9px;
  border: 1px solid #ececec;
  border-radius: 7px;
  font-size: 12px;
  color: #000;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
}
.AddNewAnswer_addAnswerIcon__25epu {
  width: 11px;
  height: 10px;
  margin-right: 10px;
}
.AddNewAnswer_addOpenAnswerIcon__33kC0 {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.AddNewAnswer_questionMark__3J6gu {
  background-color: #a97ff8;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  position: static;
}

.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ {
  font-size: 14px;
  color: #8b8b8d;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ.MultipleAnswersProperties_multipleAnswersNotPossible__1zVCQ {
  padding-bottom: 0;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_logicJumpError__3Tzpk {
  display: flex;
  background-color: #ede5fe;
  padding: 10px;
  margin-top: 5px;
  align-items: center;
  min-height: 50px;
  box-sizing: border-box;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_logicJumpError__3Tzpk .MultipleAnswersProperties_infoIcon__3C3dL {
  width: 20px;
  height: 20px;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_logicJumpError__3Tzpk .MultipleAnswersProperties_logicErrorMessage__3CTn9 {
  font-weight: bold;
  font-size: 11px;
  font-style: italic;
  margin-left: 10px;
  color: #000000;
}

.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_multipleAnswersPossibleControls__Aw2G1 {
  display: flex;
  align-items: center;
  height: 40px;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_multipleAnswersControlsValues__25HGP {
  display: flex;
  align-items: center;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_multiple-answers-controls-values-disabled__1dmus {
  opacity: 0.8;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_multipleAnswersPossibleText__3GBPC {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_inputContainer__ffmBI {
  padding-left: 8px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  border-left: 1px solid #e4e4e4;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_action__3nJGJ {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease;
  border-radius: 4px;
  padding: 5px 6px;
  height: 22px;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_action__3nJGJ.MultipleAnswersProperties_active__1xqHL {
  background-color: rgba(82, 0, 241, 0.1);
  cursor: -webkit-grab;
  cursor: grab;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ
  .MultipleAnswersProperties_multiple-answers-controls-values-disabled__1dmus
  .MultipleAnswersProperties_action__3nJGJ.MultipleAnswersProperties_active__1xqHL {
  background: none;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ
  .MultipleAnswersProperties_multiple-answers-controls-values-disabled__1dmus
  .MultipleAnswersProperties_action__3nJGJ {
  cursor: -webkit-grab;
  cursor: grab;
  color: #8b8b8d;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_inputContainer__ffmBI input {
  padding: 5px;
  margin-left: 8px;
  height: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #8b8b8d;
  max-width: 30px;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_inputContainer__ffmBI input:disabled {
  background-color: #95989a40;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_inputContainer__ffmBI input::-webkit-input-placeholder {
  color: #d5d5d5;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_inputContainer__ffmBI input::-ms-input-placeholder {
  color: #d5d5d5;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_inputContainer__ffmBI input::placeholder {
  color: #d5d5d5;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ
  .MultipleAnswersProperties_multiple-answers-controls-values-disabled__1dmus
  .MultipleAnswersProperties_inputContainer__ffmBI
  input:disabled {
  background-color: #f1f1f1;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_setToMax__1oycx {
  display: inline-flex;
  align-items: center;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_setToMax__1oycx .MultipleAnswersProperties_visibleBox__1ZTb8 {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_setToMax__1oycx .MultipleAnswersProperties_visibleBox__1ZTb8.MultipleAnswersProperties_visibleBoxActive__118hf {
  background-color: #5300f2;
}
.MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_setToMax__1oycx .MultipleAnswersProperties_visibleBox__1ZTb8 .MultipleAnswersProperties_tickIcon__1xHGs {
  width: 10px;
}

@media only screen and (max-width: 975px) {
  .MultipleAnswersProperties_multipleAnswersPossibleContainer__30lyZ .MultipleAnswersProperties_multipleAnswersPossibleControls__Aw2G1 {
    flex-wrap: wrap;
    height: auto;
  }
}

.IncidenceRateSelectors_mcContainer__K1DUa {
  display: flex;
  position: relative;
  bottom: -30px;
  left: -119px;
  margin-right: -157px;
  height: 45px;
  line-height: 45px;
  border-top: 1px solid #dcdcdc;
  font-size: 13px;
}
.IncidenceRateSelectors_sliderContainer__1xFOb {
  display: flex;
  position: relative;
  bottom: 0px;
  left: -119px;
  margin-right: -157px;
  height: 45px;
  line-height: 45px;
  border-top: 1px solid #dcdcdc;
  font-size: 13px;
}
.IncidenceRateSelectors_iconContainer__gu8Ng {
  display: inline-block;
  margin-left: 25px;
  width: 30px;
  font-size: 17px;
  color: #333333;
}
.IncidenceRateSelectors_iconContainer__gu8Ng img {
  height: 16px;
}
.IncidenceRateSelectors_filterDropdownSelectorContainer__2Q0vo {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 70px;
  margin-right: 10px;
  margin-left: 10px;
  top: 6px;
}
.IncidenceRateSelectors_filterDropdownSelectorContainerWide__3qHtz {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 130px;
  margin-right: 10px;
  margin-left: 10px;
  top: 6px;
}

.SearchDropdown_filterItemContainer__1mBXd {
  display: flex;
}
.SearchDropdown_filterItemContainer__1mBXd .SearchDropdown_filterType__L08fl {
  display: flex;
}
.SearchDropdown_filterItemContainer__1mBXd .SearchDropdown_filterType__L08fl .SearchDropdown_closeIcon__20UBg {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.SearchDropdown_filterItemContainer__1mBXd .SearchDropdown_filterType__L08fl {
  display: flex;
}
.SearchDropdown_filterItemContainer__1mBXd .SearchDropdown_filterType__L08fl .SearchDropdown_closeIcon__20UBg {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}

.Loader_loadingAnimation__kJQxn {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 2px;
  opacity: 0.5;
}
.Loader_loadingAnimation__kJQxn div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 2px;
  width: 2px;
  height: 2px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation__1LRsI 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation__1LRsI 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__kJQxn div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.Loader_loadingAnimation__kJQxn div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.Loader_loadingAnimation__kJQxn div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes Loader_loading-animation__1LRsI {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader_loading-animation__1LRsI {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.DropdownSelector_filterDropdownSelectorContainer__1Bp-E {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 290px;
  margin-right: 10px;
}
.DropdownSelector_selectedOptionsLabel__1dWRi {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 30px;
  color: #9e9e9e;
}
.DropdownSelector_selectedOptionsLabel__1dWRi.DropdownSelector_valueSelected__3StFJ {
  color: #000000;
}
.DropdownSelector_filterDropdownPopup__1h75f {
  font-size: 11px;
  background-color: #ffffff;
  position: fixed;
  top: 31px;
  width: 300px;
  border: 1px solid #dfe0e4;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.DropdownSelector_filterDropdownItem__1PewU {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.DropdownSelector_filterDropdownItem__1PewU.DropdownSelector_active__3uHNZ {
  background-color: #fafafa;
}
.DropdownSelector_filterDropdownItemCheckboxSelected__2urom {
  height: 21px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownSelector_filterDropdownItemCheckbox__3Byu1 {
  height: 21px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownSelector_filterDropdownItemLabel__1kddW {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.DropdownSelector_noAvailableClientAccounts__2BFg6 {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__1XbUn {
  display: flex;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__1XbUn input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 40px);
  outline: none;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__1XbUn .DropdownSelector_inputLoaderContainer__2Wiyo > div {
  position: relative;
  top: -10px;
}
.DropdownSelector_filterDropdownPopupItemsContainer__22Zt2 {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.DropdownSelector_filterDropdownPopupItemsContainer__22Zt2::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.DropdownSelector_filterDropdownPopupItemsContainer__22Zt2::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.DropdownSelector_filterDropdownPopupFooterContainer__3fihN {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.DropdownSelector_filterDropdownPopupConfirm__1JtLO {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}
.DropdownSelector_loaderContainer__2985G {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 20px;
}
.DropdownSelector_addItemIcon__KY9vG {
  position: relative;
  top: 0px;
  width: 11px;
  left: 0px;
  cursor: pointer;
}

.InputField_descriptionTitle__3WVE8 {
  font-size: 14px;
  color: #8b8b8d;
}
.InputField_description__1tTQj {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  display: block;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  resize: none;
}
.InputField_sliderDescription__3DNUP {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  display: inline-block;
  background-color: #ffffff;
  overflow: hidden;
  resize: none;
  padding: 5px 15px;
  margin-left: 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #000000;
  max-width: 30px;
}

.LogicField_input__kAFJr {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  display: inline-block;
  background-color: #ffffff;
  overflow: hidden;
  resize: none;
  padding: 5px 15px;
  margin-left: 8px;
  margin-right: 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #000000;
  max-width: 30px;
}

.LogicFields_fieldContainer__2yp4K {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.LogicFields_fieldValues__1Ihh5 {
  display: inline-flex;
  align-items: center;
}
.LogicFields_input__2TLLi {
  display: inline-block;
  background-color: #ffffff;
  overflow: hidden;
  resize: none;
  padding: 5px 15px;
  margin: 0 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #000000;
  max-width: 30px;
}
.LogicFields_logicJumpError__1zDfh {
  display: inline-flex;
  align-items: center;
}
.LogicFields_warningIcon__15RKn {
  width: 20px;
}
.LogicFields_logicErrorMessage__3SVlM {
  font-weight: bold;
  font-size: 11px;
  color: #fc035d;
  margin-left: 8px;
}
.LogicFields_close__14Tv2 {
  cursor: pointer;
}
.LogicFields_close__14Tv2 img {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
}
.LogicFields_label__33GmZ {
  width: 147px;
}

.RangeLogicJump_container__1eSHr {
  max-height: 215px;
  overflow: visible;
  background-color: #f6f2fe;
  scrollbar-width: thin;
  font-size: 14px;
}
.RangeLogicJump_container__1eSHr.RangeLogicJump_error__389Om {
  background-color: #fff2f7;
}
.RangeLogicJump_dropdownRowParent__3xvpI {
  display: flex;
  flex: 1 1;
  align-items: center;
  min-width: 0;
}
.RangeLogicJump_dropdownRow__2PTGg {
  border-bottom: 1px solid #eeeeee;
  padding: 7px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}
.RangeLogicJump_dropdownRow__2PTGg .RangeLogicJump_dropdownContainer__17tO7 {
  width: 50%;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 30px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.RangeLogicJump_dropdownRow__2PTGg .RangeLogicJump_dropdownContainer__17tO7.RangeLogicJump_dropdownVisisble__2MJcF:before {
  content: '';
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.RangeLogicJump_dropdownRow__2PTGg .RangeLogicJump_dropdownContainerLabel__2kSsd img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.RangeLogicJump_dropdownRow__2PTGg .RangeLogicJump_dropdownContainerArrow__3J4qG {
  width: 20px;
  height: 20px;
}
.RangeLogicJump_actionsContainer__1Gk7T {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.RangeLogicJump_actionsContainer__1Gk7T .RangeLogicJump_delete__11DJH {
  color: #fc2270;
  cursor: pointer;
}
.RangeLogicJump_actionsContainer__1Gk7T .RangeLogicJump_delete__11DJH img {
  position: relative;
  width: 20px;
  height: 15px;
  margin-right: 5px;
  top: 2px;
}
.RangeLogicJump_actionsContainer__1Gk7T .RangeLogicJump_confirm__1_hLy {
  cursor: pointer;
}
.RangeLogicJump_actionsContainer__1Gk7T .RangeLogicJump_confirm__1_hLy img {
  width: 18px;
  height: 11px;
}
.RangeLogicJump_ljArrow__2KUXX {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}

.SliderLogicJumps_container__2RS_O {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.SliderLogicJumps_logicContainer__-fEDQ {
  font-size: 14px;
  color: #221f1f;
}
.SliderLogicJumps_addJumpButton__2q3mh {
  cursor: pointer;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-items: center;
}
.SliderLogicJumps_addJumpButton__2q3mh svg {
  width: 17px;
  height: 11px;
  margin-right: 8px;
}

.Slider_blockRow__DuoCP {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  padding-right: 15px;
  display: flex;
}
.Slider_sliderValuesContainer__2j_Bm {
  padding-right: 0;
  margin-right: 15px;
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  display: flex;
  align-items: center;
}
.Slider_fieldContainer__I3qR5 {
  display: flex;
  align-items: center;
}
.Slider_fieldContainer__I3qR5 input {
  margin: 0;
  display: inline-block;
  padding: 5px 15px;
  margin-left: 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  max-width: 30px;
  margin-right: 20px;
}
.Slider_sliderLogicJumpsRow__1yr5q {
  border-top: 1px solid #eeeeee;
  margin-top: 20px;
  padding-top: 20px;
  padding-right: 0;
  margin-right: 15px;
  display: block !important;
}
.Slider_branchIcon__15fNK {
  display: inline-block;
  cursor: pointer;
}

.BranchLogicJump_container__2hBDu {
  width: calc(100% + 65px);
  max-height: 215px;
  overflow: visible;
  background-color: #f6f2fe;
  scrollbar-width: thin;
  font-size: 14px;
  border-top: 1px solid #e6e5ec;
}
.BranchLogicJump_dropdownRowParent__3Lcg4 {
  display: flex;
  flex: 1 1;
  align-items: center;
  min-width: 0;
}
.BranchLogicJump_dropdownRow__2rdWV {
  border-bottom: 1px solid #eeeeee;
  padding: 7px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}
.BranchLogicJump_dropdownRow__2rdWV .BranchLogicJump_dropdownContainer__SDYhz {
  width: 50%;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 30px;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.BranchLogicJump_dropdownRow__2rdWV .BranchLogicJump_dropdownContainer__SDYhz.BranchLogicJump_dropdownVisisble__UvWau:before {
  content: '';
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.BranchLogicJump_dropdownRow__2rdWV .BranchLogicJump_dropdownContainerLabel__lyOlG img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.BranchLogicJump_dropdownRow__2rdWV .BranchLogicJump_dropdownContainerArrow__260XK {
  width: 20px;
  height: 20px;
}
.BranchLogicJump_dropdownRow__2rdWV .BranchLogicJump_close__14Hgi {
  cursor: pointer;
}
.BranchLogicJump_dropdownRow__2rdWV .BranchLogicJump_close__14Hgi img {
  width: 20px;
  height: 20px;
}
.BranchLogicJump_actionsContainer__3hFHS {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.BranchLogicJump_actionsContainer__3hFHS .BranchLogicJump_delete__Uis9b {
  color: #fc2270;
  cursor: pointer;
}
.BranchLogicJump_actionsContainer__3hFHS .BranchLogicJump_delete__Uis9b img {
  position: relative;
  width: 20px;
  height: 15px;
  margin-right: 5px;
  top: 2px;
}
.BranchLogicJump_actionsContainer__3hFHS .BranchLogicJump_confirm__X-5Je {
  cursor: pointer;
}
.BranchLogicJump_actionsContainer__3hFHS .BranchLogicJump_confirm__X-5Je img {
  width: 18px;
  height: 11px;
}
.BranchLogicJump_ljArrow__TU3QX {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}
.BranchLogicJump_evenOtherAreSelected__2AKtm {
  font-style: italic;
  padding-top: 2px;
  padding-bottom: 10px;
  font-size: 12px;
  padding-left: 50px;
}

.ToggleSwitch_container__1Bx26 {
  cursor: pointer;
}
.ToggleSwitch_container__1Bx26 img {
  height: 27px;
  width: 50px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.BranchQuota_container__1uVRQ {
  width: calc(100% + 65px);
  margin-top: 5px;
  max-height: 215px;
  overflow: visible;
  background-color: #f6f2fe;
  scrollbar-width: thin;
  font-size: 14px;
}
.BranchQuota_container__1uVRQ.BranchQuota_error__nGqGE {
  background-color: #fef0f6;
}
.BranchQuota_dropdownRowParent__1oRZU {
  display: flex;
  flex: 1 1;
  align-items: center;
}
.BranchQuota_dropdownRow__38tJa {
  border-bottom: 1px solid #eeeeee;
  padding: 5px 15px 5px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
}
.BranchQuota_dropdownRow__38tJa .BranchQuota_close___wGo3 {
  cursor: pointer;
}
.BranchQuota_dropdownRow__38tJa .BranchQuota_close___wGo3 img {
  width: 20px;
  height: 20px;
}
.BranchQuota_quotaInput__o3E9u {
  width: 55px;
  margin-right: 10px;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 23px;
  line-height: 23px;
  padding-left: 10px;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 5px;
  margin-left: 15px;
  outline: none;
  resize: none;
  white-space: nowrap;
  overflow-x: hidden;
}
.BranchQuota_quotaInput__o3E9u:disabled {
  background-color: #f0f0f0;
}
.BranchQuota_dropdownContainer__1qIwN {
  width: 20px;
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
  padding-right: 30px;
  margin-left: 15px;
  z-index: 25;
}
.BranchQuota_dropdownContainer__1qIwN.BranchQuota_dropdownVisisble__1BLZT:before {
  content: '';
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.BranchQuota_dropdownContainerLabel__jSC8p img {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.BranchQuota_dropdownContainerArrow__1D2Cw {
  width: 20px;
  height: 20px;
}
.BranchQuota_ljArrow__3DYU9 {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}
.BranchQuota_container__1uVRQ.BranchQuota_error__nGqGE .BranchQuota_ljArrow__3DYU9 {
  color: green;
  fill: green;
}
.BranchQuota_errorIconContainer__3Rm_7 {
  position: absolute;
  top: 2px;
  right: 0px;
  padding-left: 10px;
  padding-right: 5px;
  border-left: 1px solid #c1c1c1;
}
.BranchQuota_errorIconContainer__3Rm_7 img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}
.BranchQuota_errorIconContainer__3Rm_7 span {
  display: none;
  position: absolute;
  top: -5px;
  left: 60px;
  min-width: 125px;
  max-width: 300px;
  padding: 10px;
  z-index: 100;
  background: #f61553;
  color: #fff;
  -moz-border-radius: 5px; /* this works only in camino/firefox */
  -webkit-border-radius: 5px; /* this is just for Safari */
}
.BranchQuota_errorIconContainer__3Rm_7 span:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #f61553;
  left: -8px;

  top: 11px;
}

.BranchQuota_errorIconContainer__3Rm_7:hover {
  font-size: 99%; /* this is just for IE */
}

.BranchQuota_errorIconContainer__3Rm_7:hover span {
  display: block;
}

.Branch_container__O19Xk {
  display: flex;
}
.Branch_inputContainer__2vRWv {
  margin-bottom: 5px;
  margin-top: 10px;
  display: flex;
}
.Branch_inputFieldContainer__35YX7 {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  color: #000000;
  width: 100%;
  border: 0;
  outline: none;
}
.Branch_inputFieldContainer__35YX7.Branch_inactive__1tuBP {
  color: #000000;
}
.Branch_inputFieldContainer__35YX7::-webkit-input-placeholder {
  color: #8b8b8d;
}
.Branch_inputFieldContainer__35YX7::-ms-input-placeholder {
  color: #8b8b8d;
}
.Branch_inputFieldContainer__35YX7::placeholder {
  color: #8b8b8d;
}
.Branch_blockAdditionalOption__2ekwb {
  padding-top: 10px;
  border-bottom: 1px solid #ececec;
  margin-right: 60px;
}
.Branch_blockAdditionalOption__2ekwb.Branch_inactive__1tuBP {
  margin-right: 0px;
}
.Branch_answerTextarea__N-Vnn {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 73%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 60px;
  resize: none;
  overflow: hidden;
}
.Branch_answerTextareaSpan__3TGBX {
  margin-bottom: 10px;
  line-height: 19px;
  padding-left: 2px;
  padding-top: 2px;
}
.Branch_close__28KXM {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 2px;
}
.Branch_close__28KXM img {
  width: 20px;
  height: 20px;
}
.Branch_logicJumpAdmin__15rRk {
  cursor: pointer;
  position: absolute;
  right: -60px;
}
.Branch_logicJump__3oVo4 {
  cursor: pointer;
  position: absolute;
  right: -30px;
}
.Branch_quota__1WwMm {
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -3px;
  font-size: 18px;
}
.Branch_logicJump__3oVo4 svg,
.Branch_logicJumpAdmin__15rRk svg,
.Branch_quota__1WwMm svg {
  width: 17px;
  height: 11px;
}
.Branch_allowOther__1oDtD {
  background-color: #5200f1;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: 0px;
  top: 40%;
  padding: 2px 5px;
  border-radius: 2px;
}
.Branch_answerLabel__2RQZ0 {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 90%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 62px;
  resize: none;
  overflow: hidden;
  margin-bottom: 15px;
  margin-top: 2px;
  min-height: 19px;
}
.Branch_lockIcon__31yDl {
  align-self: flex-start;
  padding-top: 4px;
  padding-left: 4px;
}
.Branch_lockTooltip__xLT9b {
  position: absolute;
  display: none;
  background-color: #a97ff8;
  right: -225px;
  top: 0px;
  font-size: 12px;
  color: #fff;
  width: 200px;
  text-align: center;
  padding: 4px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 25;
}
.Branch_lockTooltipArrow__UFBgM {
  width: 0;
  height: 0;
  border-top: 12.5px solid transparent;
  border-bottom: 12.5px solid transparent;
  border-right: 12.5px solid #a97ff8;
  position: absolute;
  left: -12.5px;
  top: 0px;
}
.Branch_lockIcon__31yDl:hover + .Branch_lockTooltip__xLT9b {
  display: block;
}
.Branch_deboundeIcon__2NJm9 {
  margin-left: 60px;
  padding-bottom: 11px;
}
.Branch_isOtherDebounce__18VID {
  width: calc(73% - 26px);
  margin-left: 10px;
}
.Branch_inactiveClose__2qxd1 {
  cursor: pointer;
}
.Branch_inactiveClose__2qxd1 img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.AddPath_container__20R-w {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ececec;
  color: #000000;
  justify-content: space-between;
  padding-bottom: 10px;
  cursor: default;
}
.AddPath_addPathIcon__3rKrS {
  width: 15px;
  height: 14px;
  margin-right: 10px;
  cursor: pointer;
}

.Distributor_blockAdditionalOptions__3tk5V {
  margin-bottom: 30px;
}
.Distributor_descriptionTitle__k7eeS {
  font-size: 14px;
  color: #8b8b8d;
}
.Distributor_answerCoverImageContainer__zyNXp {
  border: 1px solid #8500f0;
  border-radius: 2px;
  height: 22px;
  display: flex;
  position: absolute;
  left: 0px;
  overflow: hidden;
  cursor: default;
}
.Distributor_leftContainer__2waTZ {
  width: 22px;
  height: 22px;
}
.Distributor_imagePlaceholder__3udpt {
  width: 22px;
  height: 22px;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  display: block;
  font-size: 20px;
}
.Distributor_answerCoverImageContainer__zyNXp .Distributor_leftContainer__2waTZ .Distributor_image-placeholder__1QZyM span {
  font-size: 26px;
  position: absolute;
  left: -2.4px;
  top: -3.5px;
  padding: 0px;
  margin: 0px;
}
.Distributor_answerCoverImageContainer__zyNXp .Distributor_leftContainer__2waTZ .Distributor_coverImagePreview__ORdvm {
  width: 22px;
  height: 22px;
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
}
.Distributor_rightContainer__CYYAN {
  background-color: #ffffff;
  width: 22px;
  height: 22px;
}
.Distributor_rightContainer__CYYAN .Distributor_actionButton__2l41d {
  width: 18px;
  height: 14px;
  top: 3px;
  left: 2px;
  position: relative;
  display: inline-block;
}
.Distributor_blockAdditionalOption__6EZf6 .Distributor_answerCoverImageContainer__zyNXp .Distributor_rightContainer__CYYAN .Distributor_action__f2O9c {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 22px;
  height: 22px;
  font-size: 20px;
}
.Distributor_blockAdditionalOption__6EZf6 .Distributor_answerCoverImageContainer__zyNXp .Distributor_rightContainer__CYYAN .Distributor_action__f2O9c span {
  position: absolute;
  left: 2.2px;
  top: 2.25px;
  font-size: 13px;
}
.Distributor_answerCoverImageContainer__zyNXp .Distributor_rightContainer__CYYAN .Distributor_action__f2O9c .Distributor_fileUploadInput__3nNW7 {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 22px;
  padding: 0px;
  margin: 0px;
  z-index: 10;
}
.Distributor_block-container__3Hw5x .Distributor_blockAdditionalOptions__3tk5V .Distributor_blockAdditionalOption__6EZf6:last-child {
  border-bottom: 0;
}
.Distributor_inputAdd__17QXx {
  margin-bottom: 20px;
  margin-top: 0px;
}
.Distributor_inputAddText__eUk0F {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 90%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 0px;
  resize: none;
  overflow: hidden;
}
.Distributor_coverImagePreview__ORdvm {
  width: 30px;
  height: 30px;
  background-size: cover;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 0px;
  cursor: pointer;
}
.Distributor_addOption__2-6Wc {
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  bottom: 10px;
}
.Distributor_properties-column-container__1yXCk {
  position: fixed;
  top: 90px;
  margin-left: 1044px;
  width: 290px;
  padding-bottom: 20px;
}
.Distributor_side-container-title-item__1dc23 {
  display: inline-flex;
  margin-left: 20px;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
}
.Distributor_side-container-content__sN-fc {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.Distributor_side-container-content__sN-fc .Distributor_emoji-picker__3fMLF {
  padding: 0 5px 10px 5px;
  position: absolute;
  top: 40px;
  right: -43px;
  width: 208px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
}
.Distributor_side-container-content__sN-fc .Distributor_emoji-picker__3fMLF::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.Distributor_side-container-content__sN-fc .Distributor_emoji-picker__3fMLF::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.Distributor_side-container-content__sN-fc .Distributor_emoji-picker__3fMLF .Distributor_emoji-category__DhdM2 {
  width: 100%;
  margin-left: 5px;
  margin-top: 15px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.Distributor_side-container-content__sN-fc .Distributor_emoji-picker__3fMLF .Distributor_emoji-category__DhdM2:first-child {
  margin-top: 10px;
}
.Distributor_side-container-content__sN-fc .Distributor_emoji-picker__3fMLF .Distributor_emoji__3TLAh {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.Distributor_side-container-content__sN-fc .Distributor_emoji-picker__3fMLF .Distributor_emoji__3TLAh:hover {
  opacity: 0.6;
}
.Distributor_side-container-action__3rTRf {
  padding: 15px 0 13px 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  color: #8b8b8d;
  font-family: 'Open Sans SemiBold';
}
.Distributor_side-container-action__3rTRf.Distributor_disabled__3RGsD {
  cursor: not-allowed;
  opacity: 0.7;
}
.Distributor_side-container-action__3rTRf.Distributor_disabled__3RGsD .Distributor_icon__1WsOV {
  color: #8b8b8d;
}
.Distributor_side-container-action__3rTRf .Distributor_soon__39hmO {
  color: #ff3018;
  margin-left: 10px;
}
.Distributor_side-container-action__3rTRf:last-child {
  border-bottom: 0;
}
.Distributor_side-container-action__3rTRf:hover {
  opacity: 0.7;
}
.Distributor_side-container-action__3rTRf:last-child {
  border-bottom: 0;
}
.Distributor_side-container-action__3rTRf .Distributor_icon__1WsOV {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 2px;
  top: 3px;
  font-size: 14px;
  position: relative;
  color: #5200f1;
}
.Distributor_edit-block-title__95CQt {
  font-size: 11px;
  color: #8b8b8d;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.Distributor_edit-block-container__uotjv {
}
.Distributor_text-editor-container__1z2rK {
  margin-left: 18px;
  margin-right: 18px;
  border-bottom: 1px solid #eeeeee !important;
  height: 50px;
  line-height: 50px;
}
.Distributor_text-editor-action__afigU {
  display: inline-block;
  margin-right: 10px;
}
.Distributor_text-editor-action-button__2k1g0 {
  padding: 0 10px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.4;
  cursor: pointer;
}
.Distributor_text-editor-action-button__2k1g0:hover {
  opacity: 0.3;
}
.Distributor_text-editor-action__afigU.Distributor_true__2hXLZ .Distributor_text-editor-action-button__2k1g0 {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}
.Distributor_text-editor-action-button__2k1g0.Distributor_picker-active__1C222 {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}
.Distributor_side-container-properties__3MzAW {
  width: 255px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.Distributor_side-container-properties__3MzAW .Distributor_cover-image-preview__1-dje {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 60px;
  cursor: pointer;
}
.Distributor_side-container-properties__3MzAW .Distributor_icon__1WsOV {
  color: #8500f0;
  font-size: 25px;
  line-height: 20px;
  cursor: pointer;
}
.Distributor_side-container-properties__3MzAW .Distributor_media-upload__34x-p a:link,
.Distributor_side-container-properties__3MzAW .Distributor_media-upload__34x-p a:visited {
  font-size: 11px;
  text-decoration: none;
  color: #5200f1;
  margin-right: 10px;
}
.Distributor_side-container-properties__3MzAW .Distributor_action__f2O9c {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.Distributor_side-container-properties__3MzAW .Distributor_action__f2O9c .Distributor_file-upload-input__1hqAM {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
}
.Distributor_side-container-properties__3MzAW .Distributor_action__f2O9c.Distributor_remove__1M0l8 {
  color: red;
}

.Distributor_side-container-properties-campaign-web-url-container-wrapper__3u98d {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Distributor_side-container-properties-campaign-web-url-container__3DiJF {
  margin-top: 10px;
  display: flex;
  flex: 1 1;
  width: 100%;
}
.Distributor_side-container-properties-campaign-web-url__132dK {
  width: 90%;
  padding: 5px;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  font-size: 12px;
}
.Distributor_side-container-properties-campaign-web-url-click-link__Xr3vH {
  margin-top: 8px;
  display: inline-block;
  position: relative;
  height: 30px;
  line-height: 30px;
  padding: 1px 6px;
  font-size: 14px;
  font-family: 'Open Sans SemiBold';
  border: none;
  background-color: transparent;
  color: #5200f1;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 4px;
}
.Distributor_side-container-properties-campaign-web-url-click-link__Xr3vH:hover {
  background-color: rgba(82, 0, 241, 0.1);
}

/* Toggle switch */
.Distributor_switch__3kbC7 {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.Distributor_switch__3kbC7 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Distributor_slider__D1IKF {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Inactive */
  transition: 0.4s;
}

.Distributor_slider__D1IKF:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .Distributor_slider__D1IKF {
  background-color: #5200f1; /* Active */
}

input:focus + .Distributor_slider__D1IKF {
  box-shadow: 0 0 1px #5200f1; /* Active */
}

input:checked + .Distributor_slider__D1IKF:before {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.Distributor_slider__D1IKF.Distributor_round__3LhZr {
  border-radius: 34px;
}

.Distributor_slider__D1IKF.Distributor_round__3LhZr:before {
  border-radius: 50%;
}

.Distributor_main-container__2NNJD .Distributor_loader-container__3bK7x {
  background-color: #e3e3e3;
}
.Distributor_main-container__2NNJD .Distributor_loader-container__3bK7x.Distributor_button__17fGT {
  height: 55px;
  width: 150px;
}
.Distributor_main-container__2NNJD .Distributor_loader-container__3bK7x.Distributor_column__fMvXH {
  height: 400px;
}
.Distributor_main-container__2NNJD .Distributor_loader-container__3bK7x.Distributor_item__3szl7 {
  height: 220px;
  margin-bottom: 50px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.Distributor_campaign-builder-mini-map-container__1VslZ {
  position: fixed;
  width: 55px;
  top: 67px;
  right: 0px;
  border-left: 1px solid #ebe9ef;
  height: calc(100vh - 67px);
  padding-top: 20px;
}
.Distributor_campaign-builder-mini-map-container__1VslZ .Distributor_single-question-icon__1KDMS {
  text-align: center;
  color: #b4b2b6;
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.Distributor_campaign-builder-mini-map-container__1VslZ .Distributor_single-question-icon__1KDMS.Distributor_active__2Hm-Q {
  color: #8500f0;
}
.Distributor_campaign-builder-mini-map-container__1VslZ .Distributor_single-question-icon__1KDMS:hover span {
  opacity: 0.7;
}
.Distributor_campaign-builder-mini-map-container__1VslZ
  .Distributor_single-question-icon__1KDMS
  .Distributor_single-question-popup__3K8wn {
  display: none;
  position: relative;
}
.Distributor_campaign-builder-mini-map-container__1VslZ .Distributor_single-question-icon__1KDMS .Distributor_error-dot__fYtlC {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
}
.Distributor_campaign-builder-mini-map-container__1VslZ
  .Distributor_single-question-icon__1KDMS:hover
  .Distributor_single-question-popup__3K8wn {
  position: absolute;
  display: block;
  padding: 10px;
  max-width: 300px;
  min-width: 250px;
  font-size: 13px;
  color: #8b8b8d;
  top: 0px;
  right: 56px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  text-align: left;
  border-radius: 5px;
}

.Distributor_campaign-builder-errors-container__3qByg {
}
.Distributor_campaign-builder-error__2z3P8 {
  background-color: #f5a0a142;
  color: #b03223;
  font-size: 14px;
  line-height: 35px;
  padding-left: 20px;
}
.Distributor_campaign-builder-error__2z3P8:last-child {
  border-bottom: 1px solid #d5aea9;
}

.Distributor_blockAdditionalOption__6EZf6 {
  padding-top: 10px;
  border-bottom: 1px solid #ececec;
  margin-right: 60px;
}

@media only screen and (max-width: 1420px) {
  .Distributor_main-container__2NNJD {
    width: auto;
  }
  .Distributor_content-header__3dVPH {
    width: 97vw;
    padding-left: 10px;
  }
  .Distributor_content__2tCmZ {
    padding-left: 10px;
  }
  .Distributor_blocks-column-container__DjNeF {
    width: calc(100vw - 240px - 405px);
  }
  .Distributor_properties-column-container__1yXCk {
    right: 70px;
  }
  .Distributor_block-content__3mG3t .Distributor_block-text__3L5Em .Distributor_description__K6Jth {
    display: block;
    width: 100%;
  }
  .Distributor_block-content__3mG3t .Distributor_block-text__3L5Em .Distributor_block__1F0JE {
    width: 100% !important;
  }
}

.Distributor_flow-chart-node-container__3LPQX {
  max-width: 400px;
  overflow: auto;
  white-space: pre-wrap;
  margin: auto;
  display: inline-block;
  padding: 5px;
}

.Distributor_node__N5Zbe rect {
  stroke: #333;
  fill: #fff;
}

.Distributor_edgePath__1XuLO path {
  stroke: #333;
  fill: #333;
  stroke-width: 1.5px;
}

.Distributor_svg-text-icon__LAybS {
  width: 13px;
}

.Distributor_svg-text-icon-question__F7t6I,
.Distributor_svg-text-icon-bigger__17-Ja {
  width: 15px;
}

.Distributor_svg-text-minimap-icon__1Ae3W {
  width: 15px;
  -webkit-filter: grayscale(1) opacity(0.35);
          filter: grayscale(1) opacity(0.35);
}

.Distributor_single-question-icon__1KDMS:hover .Distributor_svg-text-minimap-icon__1Ae3W {
  opacity: 0.7;
}

.Distributor_single-question-icon__1KDMS.Distributor_active__2Hm-Q .Distributor_svg-text-minimap-icon__1Ae3W {
  width: 15px;
  -webkit-filter: grayscale(0) opacity(1);
          filter: grayscale(0) opacity(1);
}
.Distributor_randomisedIcon__26X8S {
  position: absolute;
  left: -48px;
}

.Distributor_questionMark__Z3P5w:hover {
  background-color: #5301f2;
}

.Matrix_container__1IjpX {
  margin-bottom: 10px;
  font-size: 18px;
  color: #221f1f;
  padding-right: 15px;
}
.Matrix_row__2a-BI {
  margin-bottom: 15px;
}
.Matrix_row__2a-BI.Matrix_flexRow__36lNq {
  display: flex;
  justify-content: space-between;
}
.Matrix_flexColumn__3OHvO {
  width: 49.5%;
}
.Matrix_flexGrow__1-tSH {
  flex-grow: 1;
}
.Matrix_label__2KOiC {
  font-size: 14px;
  color: #8b8b8d;
  margin-bottom: 5px;
}
.Matrix_fieldContainer__pIwUq {
  position: relative;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  color: #000;
}
.Matrix_field__2K1fx {
  border-radius: 5px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}
.Matrix_field__2K1fx.Matrix_draftField__sscJP {
  min-height: 30px;
  height: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: text;
}
.Matrix_fieldContainer__pIwUq .Matrix_dropdown__2uuX7 {
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 14px 6px;
  cursor: pointer;
}
.Matrix_field__2K1fx::-webkit-input-placeholder,
.Matrix_fieldContainer__pIwUq .Matrix_placeholder__1jbjB {
  -webkit-user-select: none;
          user-select: none;
  color: #bebebe;
}
.Matrix_field__2K1fx::-ms-input-placeholder,
.Matrix_fieldContainer__pIwUq .Matrix_placeholder__1jbjB {
  -ms-user-select: none;
      user-select: none;
  color: #bebebe;
}
.Matrix_field__2K1fx::placeholder,
.Matrix_fieldContainer__pIwUq .Matrix_placeholder__1jbjB {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #bebebe;
}
.Matrix_fieldContainer__pIwUq .Matrix_popupContainer__2WVqC {
  font-size: 14px;
  background-color: #ffffff;
  position: absolute;
  top: 38px;
  width: 100%;
  border: 1px solid #efefef;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 1;
  box-sizing: border-box;
}
.Matrix_fieldContainer__pIwUq .Matrix_popupContainer__2WVqC .Matrix_popupItemsContainer__5o7aI {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px 0;
  box-sizing: border-box;
}
.Matrix_popupItemsContainer__5o7aI .Matrix_popupItem__3IXY_ {
  line-height: 25px;
  height: 25px;
  cursor: pointer;
  padding-left: 10px;
}

.Question_container__OxuXp {
  display: flex;
}
/*
.container.inGroup {
  width: 606px;
}
*/
.Question_questionParentContainer__2Qp1F {
}
.Question_questionParentRandomizerContainer__19YBP {
  border-radius: 5px;
}
.Question_questionParentRandomizerContainer__19YBP > div > .Question_questionContainer__m5alj.Question_active__3Txrq {
  border-bottom: 1px solid #bf9dff;
}
.Question_questionContainer__m5alj {
  width: calc(100% - 5px);
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  border-left: 4px solid #ffffff;
}
.Question_questionContainer__m5alj.Question_active__3Txrq {
  border-left: 4px solid #8500f0;
  border-top: none;
  opacity: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}
.Question_questionContainer__m5alj.Question_questionInGroup__2YPrL {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0px;
}
div:first-child > div > div > .Question_questionContainer__m5alj.Question_active__3Txrq {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
div:last-child > div > div > .Question_questionContainer__m5alj.Question_active__3Txrq {
  border-bottom-left-radius: 0;
}
div:first-child > div > div > .Question_questionContainer__m5alj {
  border-top: none;
}
div:last-child > div > div > .Question_questionContainer__m5alj {
  border-bottom: none;
}
.Question_blockContainer__1EK0p {
  background-color: #ffffff;
  border-left: 4px solid #ffffff;
  padding-top: 35px;
  margin-left: 20px;
  border-radius: 5px;
}
.Question_blockStructure__1IfFL {
  display: flex;
}
.Question_blockNumber__tZLGf {
  margin-left: 15px;
  width: 35px;
  color: #221f1f;
  font-size: 18px;
}
.Question_blockIcon__2VRas {
  width: 45px;
  color: #8500f0;
  top: 4px;
}
.Question_icon__q1Mrq {
  margin-right: 10px;
  color: #5200f1;
  font-size: 16px;
}
.Question_icon__q1Mrq img {
  width: 18px;
  height: 18px;
}
.Question_blockContent__2aYMI {
  width: calc(90% - 70px);
}
.Question_blockRow__1sf5D {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  padding-right: 15px;
  cursor: text;
}

.Question_groupLine__AaYq1 {
  width: 35px;
  background-color: #e4dafb;
  /* border-bottom: 1px solid #b5b5b8; */
}
div:first-child > div > div > .Question_groupLine__AaYq1 {
  border-top-right-radius: 5px;
}
div:last-child > div:last-child > div > div > .Question_groupLine__AaYq1 {
  border-bottom-right-radius: 5px;
}
.Question_groupLine__AaYq1.Question_groupLineActive__juv7b {
  width: 32px;
  border-left: 3px solid #5300f2;
}
.Question_groupLine__AaYq1 .Question_groupShuffleIcon__AaWW3 {
  width: 20px;
  height: 10px;
  display: block;
  position: absolute;
  top: 15px;
  left: 3px;
}

.Question_questionContainerNumber__3XXaB {
  position: absolute;
  left: 43px;
  top: 35px;
  color: #221f1f;
  font-size: 18px;
  z-index: 5;
}

.Question_noQuestionPlaceholder__2defJ {
  font-style: italic;
  font-size: 16px;
}
.Question_draftOverlay__7Dygi {
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 25;
}

.QuestionContainerActions_addToParentIconContainer__3cekN {
  position: absolute;
  bottom: -14px;
  right: -14px;
  width: 27px;
  height: 27px;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 5;
}
.QuestionContainerActions_addToParentIconGroupedContainer__3Wh7d {
  position: absolute;
  bottom: -14px;
  right: -14px;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border-radius: 50%;
  cursor: pointer;
  background-color: #5200f1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 5;
}
.QuestionContainerActions_addToParentIconGroupedContainer__3Wh7d.QuestionContainerActions_disabled__1CNmo {
  background-color: #ffffff;
}
.QuestionContainerActions_addToParentIconContainer__3cekN:hover,
.QuestionContainerActions_addToParentIconGroupedContainer__3Wh7d:hover {
  z-index: 25;
}
.QuestionContainerActions_addToParentIconContainer__3cekN svg {
  position: absolute;
  width: 13px;
  height: 14px;
  top: 6px;
  left: 7px;
}
.QuestionContainerActions_addToParentIconGroupedContainer__3Wh7d svg {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 5.5px;
  left: 6px;
}
.QuestionContainerActions_groupIcon__1vY5B {
  position: absolute;
  width: 27px;
  height: 27px;
}
.QuestionContainerActions_groupIcon__1vY5B.QuestionContainerActions_disabled__1CNmo {
  opacity: 0.2;
}
.QuestionContainerActions_groupIconTooltipContainer__1Q1mB {
  position: absolute;
  top: -4px;
  left: 20px;
}

.FlowLogicJump_container__PxYA- {
  top: 0px;
  max-height: 215px;
  overflow: visible;
  background-color: #f6f2fe;
  scrollbar-width: thin;
  font-size: 14px;
  display: flex;
  border: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 4px solid #8500f0;
  border-bottom: 1px solid #bf9dff;
}
.FlowLogicJump_container__PxYA-.FlowLogicJump_group__GWSji {
  border: 1px solid #bf9dff;
  border-left: 4px solid #8500f0;
  border-top: 0;
  margin-top: -3px;
}
.FlowLogicJump_container__PxYA-.FlowLogicJump_lastGroupQuestion__3gZ5C {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
}
.FlowLogicJump_containerActiveFlow__3dU9O {
  top: -2px;
}
.FlowLogicJump_container__PxYA-.FlowLogicJump_parentGroupJumpContainer__1YA08 {
  margin-left: -30px;
  width: calc(100% + 60px);
  top: -44px;
  margin-bottom: -44px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.FlowLogicJump_groupBorder__1xfsK {
  width: 30px;
  border-left: 3px solid #5300f2;
  border-bottom-right-radius: 5px;
  background-color: #e4dafb;
}
.FlowLogicJump_title__2g57W {
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #8b8b8d;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
  padding-left: 15px;
  padding-right: 15px;
}
.FlowLogicJump_title__2g57W .FlowLogicJump_branchIcon__1HxUt {
  background-color: #8500f0;
  position: absolute;
  top: 25px;
  left: -25px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FlowLogicJump_title__2g57W .FlowLogicJump_branchIcon__1HxUt svg {
  width: 17px;
  height: 11px;
}
.FlowLogicJump_dropdownRow__2cYZd {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-size: 12px;
  flex: 1 1;
  min-width: 0;
}
.FlowLogicJump_parentGroupJumpContainer__1YA08 .FlowLogicJump_dropdownRow__2cYZd,
.FlowLogicJump_container__PxYA-:last-child .FlowLogicJump_dropdownRow__2cYZd {
  border: none;
}
.FlowLogicJump_actionsContainer__jMzb1 {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.FlowLogicJump_actionsContainer__jMzb1 .FlowLogicJump_delete__N8ugj {
  color: #fc2270;
  cursor: pointer;
}
.FlowLogicJump_actionsContainer__jMzb1 .FlowLogicJump_delete__N8ugj img {
  position: relative;
  width: 20px;
  height: 15px;
  margin-right: 5px;
  top: 2px;
}
.FlowLogicJump_actionsContainer__jMzb1 .FlowLogicJump_confirm__2KGEe {
  cursor: pointer;
}
.FlowLogicJump_actionsContainer__jMzb1 .FlowLogicJump_confirm__2KGEe img {
  width: 18px;
  height: 11px;
}

.FlowLogicJump_additionalJumpInformation__3T9Cg {
  background-color: #e4d9fd;
  padding: 10px;
}
.FlowLogicJump_additionalJumpInformation__3T9Cg .FlowLogicJump_addJumpButton__1nOIt {
  color: #5300f2;
  font-family: 'Open Sans Bold';
  padding-left: 10px;
  text-transform: uppercase;
  font-size: 12px;
}
.FlowLogicJump_ljArrow__zOJDy {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
}
.FlowLogicJump_dummyLeftVerticalLine__14tlG {
  height: 50px;
  width: 1px;
  background-color: #b593f6;
}
.FlowLogicJump_containerRow__3vIf2 {
  width: 100%;
}
.FlowLogicJump_additionalRow__2OtZW {
  border-top: 1px solid #cfcfcf !important;
  padding: 4px 15px;
}
.FlowLogicJump_secondsInput__3NPZ2 {
  background-color: #ffffff;
  font-size: 12px;
  color: #8b8b8d;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  height: 27px;
  line-height: 27px;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 30px;
}

.SideProperties_blockTitle__1idk5 {
  font-size: 11px;
  color: #8b8b8d;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.SideProperties_icon__AKMTq {
  width: 15px;
  height: 12px;
  margin-left: 10px;
  margin-right: 5px;
}
.SideProperties_blockContentWithTooltip__12wqO {
  padding-right: 10px;
  padding-left: 20px;
}

.GroupHeader_questionsContainer__3ntTg {
  border: 1px solid #bf9dff;
  border-radius: 5px;
}

.GroupHeader_groupHeader__3M95N {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
}
.GroupHeader_groupHeaderNoActions__1R6Lx {
  display: flex;
  flex-direction: row;
}

.GroupHeader_groupNameContainer__1egi3 {
  background-color: #5200f1;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-left: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 54px);
}

.GroupHeader_groupIcon__3bhY_ {
  padding: 5px;
  display: flex;
}

.GroupHeader_groupName__1GOBu {
  color: white;
  font-family: 'Open Sans SemiBold';
  padding-left: 5px;
  white-space: nowrap;
  width: auto;
  max-width: calc(100% - 30px);
  text-overflow: ellipsis;
}

.GroupHeader_changeGroupName__FOpUL {
  color: #5200f1;
}

.GroupHeader_blurGroupName__1Mqrv {
  background-color: transparent;
  border: none;
}

.GroupHeader_groupSettingsButton__2UFn5 {
  padding: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.GroupHeader_settingsIcon__1hxAW {
  margin-top: 2px;
  width: 18px;
  height: 18px;
}

.GroupFooter_container__20B5A {
  display: flex;
  flex-direction: row;
  height: 65px;
}

.Group_groupWrapper__1NsLB {
  display: flex;
  border-top-left-radius: 6px;
  border-left: 3px solid #ffffff00;
}
.Group_groupWrapper__1NsLB.Group_active__3ixR0 {
  border-left: 4px solid #8500f0;
}
.Group_verticalGroupLine__2h-is {
  width: 33px;
  background-color: #e4dafb;
  border-top: 1px solid #bf9dff;
  border-right: 1px solid #bf9dff;
  border-bottom: 1px solid #bf9dff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.Group_verticalGroupLine__2h-is.Group_active__3ixR0 {
  border-left: 3px solid #e4dafb;
}
.Group_groupParentTopContainer__2avG9 {
  padding: 0px 25px;
  background-color: rgba(191, 157, 255, 0.2);
  border: 1px solid #bf9dff;
}
.Group_groupContainer__1ruzG {
  width: 100%;
  border-radius: 5px;
  flex: 1 1;
}
.Group_active__3ixR0 {
  margin-bottom: 0;
}
.Group_firstChildQuestion__3CasQ {
  padding-top: 25px;
}
.Group_lastChildQuestion__2s5rD {
  padding-bottom: 25px;
}

.QuestionColumn_container__3oyTP {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 644px;
  top: -30px;
}
.QuestionColumn_nestedContainer__3QJzl {
  margin-top: 40px;
  margin-bottom: 40px;
  top: -30px;
}

.QuestionColumn_container__3oyTP:last-child {
  margin-bottom: 110px;
}
.QuestionColumn_titleContainer__54aEN {
  display: flex;
  height: 30px;
}
.QuestionColumn_titleContainer__54aEN .QuestionColumn_title__s2Q7I {
  font-family: 'Open Sans SemiBold';
  background-color: #bf9dff;
  height: 30px;
  line-height: 30px;
  left: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #ffffff;
}
.QuestionColumn_draggableBackgroundDragging__2cWzp {
  background-color: #f2ebff;
}

.QuestionColumn_wrapper__fQtjh {
  padding: 30px;
  padding-bottom: 0;
  border: 1px solid #bf9dff;
  border-radius: 5px;
  background-color: rgba(191, 157, 255, 0.2);
}

.QuestionColumn_wrapper__fQtjh *:last-child {
  margin-bottom: 0;
}

.QuestionColumn_dropZoneContainer__2dtp7 {
  position: absolute;
  width: 100%;
  height: 50px;
  transition: margin-bottom 0.3s ease;
  overflow: visible;
}

.QuestionColumn_wrapper__fQtjh .QuestionColumn_dropZoneTop__2qDJm {
  width: calc(100% - 60px);
}

.QuestionColumn_dropZoneBottom__35UNT {
  margin-top: 4px;
}

.QuestionColumn_dropZoneTop__2qDJm {
  top: 4px;
}

.QuestionColumn_dropZonePlaceholder__2YqxJ {
  transition: height 0.3s ease;
  background-color: #f2ebff;
}
.QuestionColumn_wrapper__fQtjh .QuestionColumn_dropZonePlaceholder__2YqxJ {
  background-color: #dfd4f5;
}

.QuestionColumn_dropZonePlaceholderActive__PJCMU {
  height: 50px;
}
.QuestionColumn_dropZoneBottom__35UNT .QuestionColumn_dropZonePlaceholderActive__PJCMU {
  margin-top: 25px;
}

.QuestionColumn_dropdownPlaceholderContainer__2Z0s7 {
  width: 644px;
  padding-bottom: 40px;
  padding-top: 70px;
}
.QuestionColumn_dropdownPlaceholder__1oVOX {
  background-color: #f9f5ff;
  color: #ceb7fc;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.QuestionColumn_questionSet__1Ah9I {
  background-color: #f1e9ff;
  border: 1px solid #bf9dff;
  border-radius: 5px;
}
.QuestionColumn_questionSet__1Ah9I.QuestionColumn_setInGroup__1vOan {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.QuestionColumn_questionSet__1Ah9I.QuestionColumn_dragging__1ZhYX {
  background-color: #f1e9ff;
}

@media only screen and (max-width: 1035px) {
  .QuestionColumn_container__3oyTP {
    width: calc(100vw - 377px);
  }
  .QuestionColumn_dropdownPlaceholderContainer__2Z0s7 {
    width: calc(100vw - 377px);
  }
}

:root {
  --slider-circle-color: #24b2e8;
  --mc-question-answer-background-color: #e9f7fc;
  --mc-question-answer-background-color-active: #a7e0f5;
  --mc-question-image-answer-background-color-hover: #a7e0f5;
  --mc-question-image-answer-background-color-active: #24b2e8;
}

@font-face {
  font-family: 'Open Sans Regular';
  src: local('Open Sans Regular'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/OpenSans-Regular.d7d5d458.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: local('Open Sans Bold'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/OpenSans-SemiBold.e1c83f94.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Bold';
  src: local('Open Sans Bold'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/OpenSans-Bold.1025a6e0.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans Light';
  src: local('Open Sans Light'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/OpenSans-Light.2d0bdc8d.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans LightItalic';
  src: local('Open Sans LightItalic'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/OpenSans-LightItalic.c147d130.ttf) format('truetype');
}

@font-face {
  font-family: 'BufflClientIcons';
  src: url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/bufflclienticons.bb66df68.eot);
  src: url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/bufflclienticons.861a240c.ttf) format('truetype'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/bufflclienticons.bb66df68.eot?#iefix) format('embedded-opentype'); /* IE6-IE8 */
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto Medium'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/Roboto-Medium.d0884059.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Bold';
  src: local('Roboto Bold'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/Roboto-Bold.ee7b96fa.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/Roboto-Light.88823c20.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Black';
  src: local('Roboto Black'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/Roboto-Black.5ebb24ee.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Regular';
  src: local('Roboto Regular'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/Roboto-Regular.f36638c2.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat Bold';
  src: local('Montserrat Bold'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/Montserrat-Bold.ed86af2e.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: local('Montserrat SemiBold'),
    url(https://bufflprodstorage.blob.core.windows.net/build-production/static/media/Montserrat-SemiBold.cc10461c.ttf) format('truetype');
}

html,
body {
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  font-family: 'Open Sans Regular';
  height: 100%;
}
div {
  margin: 0px;
  padding: 0px;
  position: relative;
}
.tooltip-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid #efefef;
  padding: 10px;
  border-radius: 5px;
  min-width: 150px;
  display: none;
}

@keyframes loaderContainerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes loaderContainerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.loader-container {
  -webkit-animation: loaderContainerAnimation 2s infinite;
  animation: loaderContainerAnimation 2s infinite;
}
.no-chart-data {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #8b8b8d;
}

.DayPicker-NavButton {
  z-index: 999999999;
}
.roboto-medium-font {
  font-family: 'Roboto Medium';
}
.roboto-bold-font {
  font-family: 'Roboto Bold';
}
.roboto-light-font {
  font-family: 'Roboto Light';
}

.loader-fixed-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  opacity: 0.7;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-fixed-container .loading-animation {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
  .loader-fixed-container .loading-animation div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    -webkit-animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #000000 transparent transparent transparent;
  }
  .loader-fixed-container .loading-animation div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .loader-fixed-container .loading-animation div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .loader-fixed-container .loading-animation div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes loading-animation {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes loading-animation {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

.right-container {
  width: 100%;
  height: 100vh;
  display: flex;
}
.right-container .welcome-container {
  width: 50%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-container .welcome-container .welcome-container-content {
  width: 450px;
  max-height: 700px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right-container
  .welcome-container
  .welcome-container-content
  .welcome-message {
  font-family: 'Roboto Medium';
  font-size: 60px;
  margin-bottom: 30px;
  color: #ffffff;
}
.right-container
  .welcome-container
  .welcome-container-content
  .welcome-message-description {
  margin-bottom: 20px;
  color: #ffffff;
}
.right-container
  .welcome-container
  .welcome-container-content
  .mobile-apps-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.right-container
  .welcome-container
  .welcome-container-content
  .mobile-apps-container
  a {
}
.right-container
  .welcome-container
  .welcome-container-content
  .mobile-apps-container
  img {
  width: 200px;
  height: 60px;
}
.right-container .form-container {
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-container .form-container .form-container-title {
  font-family: 'Roboto Bold';
  font-size: 30px;
  margin-bottom: 10px;
  text-align: center;
}
.right-container .form-container .buffl-logo {
  position: relative;
  width: 200px;
  left: 50%;
  margin-bottom: 40px;
  margin-left: -100px;
  display: block;
}
.right-container .form-container form {
  position: relative;
  width: 560px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.right-container .form-container .form-text {
  margin: 30px 0;
  font-size: 14px;
}
.right-container .form-container input,
.right-container .form-container select {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  display: block;
  width: 500px;
  height: 90px;
  line-height: 90px;
  color: #221f1f;
  font-size: 18px;
  padding-left: 30px;
  padding-right: 30px;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: content-box;
}
.right-container .form-container input:focus {
  box-shadow: 0 0 5px #5200f1;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  outline: none;
}

.right-container .form-container .error-message {
  background: #f5a0a1;
  color: #b03223;
  border: 1px solid #b03223;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
}
.right-container .form-container .success-message {
  background: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
}

.right-container .form-container .button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  min-width: 80px;
}
.right-container .form-container .button-loading {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
}
.right-container .form-container .button-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: button-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: button-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.right-container .form-container .button-loading div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.button-loading div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.button-loading div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes button-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes button-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.right-container .form-container .link a:link,
.right-container .form-container .link a:visited {
  text-align: center;
  color: #5200f1;
  text-decoration: none;
}
.right-container .form-container .link a:hover {
  text-decoration: underline;
}
.right-container .form-container .link.forgot-password {
  margin-top: 10px;
  font-family: 'Open Sans SemiBold';
}

.user-app-button-loading {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
}
.user-app-button-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: user-app-button-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: user-app-button-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.user-app-button-loading.user-app-button-loading-grey div {
  border-color: #7b7b7b transparent transparent transparent;
}
.user-app-button-loading div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.user-app-button-loading div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.user-app-button-loading div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

.right-container.registration-page {
  height: auto;
}

.right-container .form-container.registration-page {
  height: auto;
  display: block;
}

.right-container .form-container.registration-page .buffl-logo {
  margin-top: 30px;
}

.right-container .welcome-container.registration-page {
  height: auto;
}

.client-registration-howDidYouLearnAboutBuffl-input {
  height: 100px !important;
}

@-webkit-keyframes user-app-button-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes user-app-button-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1200px) {
  .right-container .form-container input,
  .right-container .form-container select {
    width: 30vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 768px) {
  .right-container .welcome-container {
    display: none;
  }
  .right-container .form-container {
    width: 100%;
  }
  .right-container .form-container form {
    width: 100%;
  }
  .right-container .form-container input,
  .right-container .form-container select {
    width: 60vw;
  }
}

.button {
  height: 50px;
  line-height: 45px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
.button-icon {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.primary-active {
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
}
.primary-active:hover {
  background-color: #4e00e5;
}
.primary-disabled {
  border: 0;
  background-color: #b5b5b8;
  color: #ffffff;
  cursor: not-allowed;
}
.secondary-active {
  background-color: #ffffff;
  border: 1px solid #5200f1;
  color: #5200f1;
  cursor: pointer;
}
.secondary-disabled {
  background-color: #ffffff;
  border: 1px solid #b5b5b8;
  color: #b5b5b8;
  cursor: not-allowed;
}
.black-active {
  border: 0;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #ffffff;
}
.black-active:hover {
  background-color: #000000;
}
.white-active {
  border: 1px solid #ececec;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
}
.white-disabled {
  border: 0;
  background-color: #ffffff;
  color: #000000;
  opacity: 0.5;
  cursor: not-allowed;
}
.white-active:hover {
  background-color: #ffffff;
}

.confirmation-dialog-container {
  width: 400px;
  margin: 5px;
}
.confirmation-dialog-container .confirmation-dialog-message {
  margin-bottom: 50px;
  text-transform: none;
  font-size: 15px;
  color: #000000;
}
.confirmation-dialog-container .confirmation-dialog-actions-container {
  display: flex;
  justify-content: space-between;
}

.popup {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  z-index: 9999;
  color: #8b8b8d;
  font-size: 11px;
  text-transform: uppercase;
  text-align: left;
  scrollbar-width: thin;
}
.actionDropdownPopup {
  z-index: 99999;
}
.popup::-webkit-scrollbar {
  -webkit-appearance: none;
}
.popup::-webkit-scrollbar:vertical {
  width: 11px;
}
.popup::-webkit-scrollbar:horizontal {
  height: 11px;
}
.popup::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.option,
.option:link,
a.option:visited {
  display: block;
  height: 40px;
  line-height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  color: #8b8b8d;
  text-decoration: none;
  cursor: pointer;
}
.option:hover {
  opacity: 0.7;
}
.separator {
  background-color: #ebeaef;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.icon {
  margin-right: 10px;
  color: #5200f1;
  font-size: 12px;
}
.option.action-dropdown-withdraw-request-option {
  line-height: normal;
  height: auto;
  display: flex;
}
.action-dropdown-publish-option.action-dropdown-publish-option-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.TableColumnHeaderItem_column__1vXeR {
  cursor: pointer;
  line-height: normal;
  line-height: initial;
}
.TableColumnHeaderItem_column__1vXeR:hover {
  opacity: 0.8;
}
.TableColumnHeaderItem_activeColumn__2kUoR {
  color: #4a25e8;
}
.TableColumnHeaderItem_arrow__1dzwP {
  width: 8px;
  height: 8px;
  margin-right: 5px;
}
.TableColumnHeaderItem_arrowTop__o5otG {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.list-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.list-item-header-container {
  display: inline-flex;
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
}

.list-item-header-section-title {
  font-family: 'Roboto Bold';
  font-size: 23px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}

.list-item-container {
  display: inline-flex;
  font-size: 14px;
  cursor: pointer;
}
.list-item-container:hover {
  background-color: #ffffff;
}

.list-item {
  height: 77px;
  line-height: 77px;
  border-top: 1px solid #ebeaef;
}
.list-item .list-item-campaign-name {
  height: 22px;
  line-height: 22px;
  margin-top: 16px;
  font-family: 'Open Sans SemiBold';
  color: #8500f0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}
.list-item .list-item-campaign-name .tooltip {
  display: none;
  position: fixed;
  z-index: 9999999999999999;
  font-family: 'Open Sans SemiBold';
  font-size: 12px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid #efefef;
  padding: 8px 10px;
  border-radius: 5px;
  color: #000;
  max-width: 500px;
  height: auto;
  text-wrap: wrap;
}
.list-item .list-item-client-email .only-web-version-icon {
  height: 10px;
  margin-left: 10px;
}
.list-item .list-item-client-email {
  height: 22px;
  line-height: 22px;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.list-item.show-actions {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
}
.list-item .list-item-campaign-star {
  width: 13px;
  height: 13px;
  cursor: pointer;
}
.list-item .list-item-campaign-star:hover {
  opacity: 0.5;
}

.column-1 {
  width: 50px;
}

.column-2 {
  width: 80px;
}

.column-3 {
  width: 190px;
}

.column-05 {
  width: 30px;
}

.column-max-width {
  width: 250px;
}

.column-4 {
  width: 300px;
  padding-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.column-5 {
  width: 150px;
}

.column-6 {
  width: 150px;
  padding-left: 40px;
  display: inline-flex;
}
.column-6 .percentage {
  width: 30%;
}
.column-6 .bar-container {
  width: 50%;
  height: 5px;
  background-color: #dfe0e4;
  top: 36px;
  border-radius: 5px;
}
.column-6 .bar-container .bar {
  height: 5px;
  background-color: #5200f1;
  border-radius: 5px;
}

.column-7 {
  width: 100px;
}
.column-7.campaign-list-created-at {
  width: 90px;
}

.campaign-img {
  width: 30px;
  height: 30px;
  background-color: #dfe0e4;
  border-radius: 50%;
  display: inline-block;
  background-size: cover;
  top: 10px;
}

.completion-column {
  padding-left: 15px;
}

.column-account {
  padding: 0 10px;
  width: 160px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.align-right {
  text-align: right;
}

.list-container .loader-container {
  width: 1200px;
  background-color: #f6f7fb;
}
.list-container .loader-container .item {
  background-color: #e3e3e3;
  height: 75px;
  margin-bottom: 10px;
}

.list-item.campaign-imag-container .flags-container {
  position: absolute;
  bottom: 12px;
  left: 0px;
  display: flex;
  width: 30px;
  justify-content: space-evenly;
}
.list-item.campaign-imag-container .flags-container img {
  width: 10px;
  height: 6px;
}

@media only screen and (max-width: 768px) {
  .campaigns-table-main-container {
    padding: 10px;
    margin-top: 70px !important;
    padding-bottom: 20px !important;
  }
  .column-2,
  .column-5,
  .column-6,
  .column-7,
  .column-account {
    display: none;
  }
  .campaigns-table-main-container .campaigns-table-header {
    display: block !important;
    height: auto !important;
  }
  .campaign-list-name {
    width: calc(100vw - 100px) !important;
  }
  .list-item-header-container {
    width: 95%;
  }
  .list-item-container {
    display: flex;
    width: 100%;
  }
  .list-item .campaign-imag-container .flags-container {
    display: none;
  }
}

.FilterDropdownSelector_filterDropdownSelectorContainer__3Gxt0 {
  position: relative;
  width: calc(100% - 30px);
  height: 20px;
  line-height: 20px;
}
.FilterDropdownSelector_selectedClientsLabel__2ejo4 {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  background-color: #ffffff;
  height: 20px;
  line-height: 20px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
}
.FilterDropdownSelector_filterDropdownPopup__3NGAE {
  font-size: 11px;
  background-color: #ffffff;
  position: absolute;
  top: 20px;
  left: 10px;
  width: 250px;
  border: 1px solid #dfe0e4;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.FilterDropdownSelector_filterDropdownItem__1vpiJ {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
}
.FilterDropdownSelector_filterDropdownItemCheckboxSelected__9DXAH {
  height: 21px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.FilterDropdownSelector_filterDropdownItemCheckbox__2usZP {
  height: 21px;
  margin-left: 10px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.FilterDropdownSelector_filterDropdownItemLabel__99vvQ {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.FilterDropdownSelector_noAvailableClientAccounts__2y-Ul {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.FilterDropdownSelector_filterDropdownPopupSearchInputContainer__y0YRD input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 20px);
  outline: none;
}
.FilterDropdownSelector_filterDropdownPopupItemsContainer__3Yu5V {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.FilterDropdownSelector_filterDropdownPopupItemsContainer__3Yu5V::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.FilterDropdownSelector_filterDropdownPopupItemsContainer__3Yu5V::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.FilterDropdownSelector_filterDropdownPopupFooterContainer__31CtD {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.FilterDropdownSelector_filterDropdownPopupConfirm__2xXG1 {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}

.ActiveFilters_activeFiltersContainer__2aHLL {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.ActiveFilters_activeFilterItem__7y719 {
  display: flex;
  background-color: #5200f1;
  color: #ffffff;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  min-width: 100px;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 8px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 8px;
}
.ActiveFilters_activeFilterItem__7y719 .ActiveFilters_removeIcon__1iVOy {
  font-size: 10px;
  cursor: pointer;
  margin-left: 10px;
}
.ActiveFilters_clearAllFilters__5PEyH {
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
}

.CampaignFilters_campaignsFilterContainer__-naEv {
  margin-bottom: 20px;
}
.CampaignFilters_header__2PSuq {
  display: flex;
}
.CampaignFilters_header__2PSuq .CampaignFilters_campaignsCount__19P-m {
  font-size: 12px;
  margin-left: 15px;
  top: 2px;
  display: flex;
}
.CampaignFilters_header__2PSuq .CampaignFilters_campaignsCount__19P-m .CampaignFilters_campaignsLabel__1ODMp {
  margin-left: 5px;
}
.CampaignFilters_header__2PSuq .CampaignFilters_filtersButton__22JPT {
  color: #4a25e8;
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  top: -4px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}
.CampaignFilters_header__2PSuq .CampaignFilters_filtersButton__22JPT img {
  width: 9px;
  margin-right: 5px;
}
.CampaignFilters_header__2PSuq .CampaignFilters_filtersButton__22JPT.CampaignFilters_filtersButtonActive__2Bx8A {
  padding-bottom: 10px;
  border-radius: 0px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.CampaignFilters_filtersContainer__1y9Zz {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  top: -10px;
  display: flex;
  padding: 15px;
}
.CampaignFilters_filtersContainerOverlay__JM_dA {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  opacity: 0.7;
}
.CampaignFilters_filtersContainerNarrowSection__qI2Xq {
  width: 15%;
  border-right: 1px solid #d5d5d5;
}
.CampaignFilters_filtersContainerNormalSection__31oKX {
  width: 20%;
  border-right: 1px solid #d5d5d5;
}
.CampaignFilters_filtersContainerNormalSection__31oKX:last-of-type {
  border-right: none;
}
.CampaignFilters_filtersContainerWideSection__2_lnn {
  flex-grow: 1;
  border-right: 1px solid #d5d5d5;
}

.CampaignFilters_sectionTitle__saPS9 {
  color: #5200f1;
  font-size: 12px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.CampaignFilters_filterInput__2fP7U,
.CampaignFilters_filterDropdown__1LeH4 {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  outline: none;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 11px;
}
.CampaignFilters_filterInput__2fP7U {
  width: calc(100% - 30px);
  height: 18px;
  line-height: 18px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.CampaignFilters_filterDropdown__1LeH4 {
  width: calc(100% - 20px);
  height: 22px;
  line-height: 22px;
  margin-left: 10px;
  -webkit-appearance: none;
  padding-left: 5px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
}
.CampaignFilters_languageItem__12v5C {
  cursor: pointer;
}
.CampaignFilters_bottomDescription__nkPaL {
  font-size: 11px;
  margin-left: 10px;
  color: #8f8e8e;
  margin-bottom: 10px;
}
.CampaignFilters_subsectionItemCheckbox__2ODjy {
  font-size: 11px;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  position: relative;
  color: #b5b5b8;
  cursor: pointer;
}
.CampaignFilters_subsectionItemCheckboxSelected__11mKW {
  font-size: 11px;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  position: relative;
  color: #5200f1;
  cursor: pointer;
}
.CampaignFilters_subsectionItemLabel__xMFE0 {
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
  width: 50px;
}
.CampaignFilters_subsectionItemLabel__xMFE0.CampaignFilters_subsectionItemLabelLanguage__1R62r {
  width: auto;
}
.CampaignFilters_dateItemContainer__28cpn {
  min-height: 100px;
}
.CampaignFilters_subSectionItemContainer__PrrB1 {
  margin-bottom: 5px;
}
.CampaignFilters_subsectionItemValue__3I8xk {
  width: calc(100% - 240px);
}
.CampaignFilters_subsectionItemValue__3I8xk input {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  outline: none;
  background-color: #ffffff;
  height: 18px;
  line-height: 18px;
  margin-left: 10px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  width: 62%;
}
.CampaignFilters_communityContainer__1ytI6 {
  min-height: 70px;
}
.CampaignFilters_typeContainer__1iFBh {
  min-height: 70px;
}
.CampaignFilters_clientContainer__2NMpV {
  min-height: 70px;
}
.CampaignFilters_sectionBottomDescription__YTXW1 {
  font-size: 10px;
  color: #8f8e8e;
  margin-left: 10px;
}

.SearchInputDropdown_searchInputDropdownPlaceholder__3KahC {
  display: flex;
  background-color: #5200f1;
  color: #ffffff;
  font-size: 11px;
  height: 25px;
  line-height: 25px;
  min-width: 167px;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 8px;
  position: relative;
}
.SearchInputDropdown_dropdownIcon__2mMSE {
  width: 8px;
  height: 8px;
  margin-left: 15px;
  display: block;
  position: relative;
  top: 9px;
}
.SearchInputDropdown_searchInputDropdown__FPswj {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 25px;
  width: 100%;
  border-radius: 5px;
}
.SearchInputDropdown_dropdownItem__3vqLt {
  padding-left: 8px;
  color: #000000;
}

.CampaignNameInput_headerInputContainer__33oBM {
  display: flex;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  top: 8px;
  width: 40%;
  flex-grow: 1;
}
.CampaignNameInput_headerInputContainer__33oBM input {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  border: 0;
  font-size: 15px;
  width: 190px;
  padding-right: 30px;
  outline: none;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  flex-grow: 1;
}
.CampaignNameInput_headerInputContainer__33oBM .CampaignNameInput_close__39KTO {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #777777;
}

ul.pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  margin-top: 20px;
  margin-bottom: 20px;
}
ul.pagination li {
  list-style: none;
  color: #8b8b8d;
  height: 21px;
  width: 21px;
  line-height: 21px;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}
ul.pagination li a {
  display: inline-block;
  cursor: pointer;
  height: 21px;
  width: 21px;
  border-radius: 50%;
}
ul.pagination li.active {
}
ul.pagination li.active a {
  background-color: #5200f1;
  color: #f6f7fb;
}
ul.pagination li.previous,
ul.pagination li.next {
  color: #5200f1;
  font-size: 16px;
  font-weight: 1000;
}

.header-icon {
  cursor: pointer;
  margin-right: 15px;
  font-size: 14px;
  color: #b5b5b8;
}
.header-icon.active {
  color: #000000;
}
.header-icon.search {
  margin-left: 30px;
}
.campaigns-table-main-container {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.campaigns-table-main-container .campaigns-table-header {
  display: flex;
  justify-content: space-between;
  height: 55px;
  line-height: 55px;
  margin-bottom: 10px;
}
.campaigns-table-main-container .campaigns-table-header .left-header-content {
  display: inline-flex;
}
.campaigns-table-main-container .campaigns-table-header .right-header-content {
  display: inline-flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.campaigns-table-main-container
  .campaigns-table-header
  .left-header-content
  .header1 {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.campaigns-table-main-container
  .campaigns-table-header
  .left-header-content
  .header2 {
  font-size: 21px;
  color: #8b8b8d;
  margin-right: 50px;
}
.campaigns-table-nocampaigns {
  text-align: center;
  margin-top: 30px;
  font-size: 13px;
  color: #8b8b8d;
}

.campaigns-table-results-per-page-container {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #8f8e8e;
}
.campaigns-table-results-per-page-container
  .campaigns-table-results-per-page-container-single-option {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.campaigns-table-results-per-page-container
  .campaigns-table-results-per-page-container-single-option.active-results-option {
  color: #5200f1;
}
.campaigns-table-publish-error-container {
  width: 300px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #ffffff;
  font-size: 14px;
}
.campaigns-table-publish-error-container div {
  display: flex;
  justify-content: center;
}
.campaigns-table-publish-error-container img {
  display: inline-block;
  margin-bottom: 20px;
  height: 30px;
  width: 30px;
}
.campaign-list-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (max-width: 1230px) {
  .campaigns-table-main-container {
    width: auto;
  }
  .campaign-list-name {
    width: calc(100vw - 880px);
  }
  .campaign-list-target {
    width: 70px;
  }
  .campaign-list-created-at {
    width: 100px;
  }
}

.CombineSurveys_combineSurveysContainer__urg_1 {
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 20px;
  font-size: 12px;
  width: 600px;
}
.CombineSurveys_combineSurveysExplanation__1M2X7 {
  font-size: 12px;
  margin-bottom: 10px;
  font-style: italic;
  color: #919191;
}
.CombineSurveys_surveysListElement__273XD {
  list-style-type: decimal;
  font-size: 12px;
  margin-bottom: 4px;
}
.CombineSurveys_combineButton__2QcWi,
.CombineSurveys_combineButtonDisabled__uW2tg {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  display: inline-block;
}
.CombineSurveys_combineButton__2QcWi {
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
}
.CombineSurveys_combineButtonDisabled__uW2tg {
  background-color: #bfbfbf;
  color: #ffffff;
}
.CombineSurveys_combineButton__2QcWi:hover {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
}
.CombineSurveys_combineResult__1LH3M {
  font-size: 12px;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
}
.CombineSurveys_combineResultError__edO_c,
.CombineSurveys_combineResultSuccess__1VXtL {
  font-size: 12px;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
}
.CombineSurveys_combineResultError__edO_c {
  color: #fc035d;
}
.CombineSurveys_combineResultSuccess__1VXtL {
  color: #23cc95;
}
.CombineSurveys_combineLoading__3rpgL {
  font-size: 12px;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  display: flex;
  vertical-align: top;
}
.CombineSurveys_campaignList__3S6Tc {
  max-height: 300px;
  overflow-y: scroll;
  font-size: 12px;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #efefef;
  border-radius: 5px 0 0 5px;
  border-right: none;
  padding: 5px;
}
.CombineSurveys_campaignList__3S6Tc.CombineSurveys_disabled__38ydb {
  opacity: 0.6;
}
.CombineSurveys_campaignListItem__y5sNP {
  display: flex;
}
.CombineSurveys_campaignListItemName__ruyE2 {
  margin-left: 5px;
}
.CombineSurveys_searchInput__1iGhq {
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 40%;
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  font-size: 12px;
  outline: none;
}
.CombineSurveys_combineSurveysTitle__29qZP {
  font-family: 'Roboto Medium';
  font-size: 18px;
  margin-bottom: 20px;
}
.CombineSurveys_selectSurveys__eSOgN {
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
}
.CombineSurveys_noCampaigns__1lbbr {
  color: #919191;
  margin: 15px 0;
}
.CombineSurveys_selectedSurveys__NdiZm {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
}
.CombineSurveys_loadMore__3JEas {
  color: #5200f1;
  cursor: pointer;
  margin: 3px 3px 3px 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.AppBar_appBarContainer__38vaU {
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 66px;
  top: 0px;
  left: 0px;
  right: 0px;
  line-height: 70px;
  background-color: #221f1f;
  padding-left: 25px;
  padding-right: 25px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}
.AppBar_appBarAbsolute__svkZl {
  position: absolute;
}
.AppBar_appBarBuilder__lIfOK .AppBar_appBarLeft__1FAtQ {
  max-width: calc(100% - 730px);
}
.AppBar_appBarRight__3tL1E {
  display: inline-flex;
  color: #ffffff;
}
.AppBar_appBarRight__3tL1E .AppBar_appBarButtonsContainer__1SeLC {
  display: flex;
  align-items: center;
  justify-content: center;
}
.AppBar_appBarRight__3tL1E .AppBar_appBarButtonsContainer__1SeLC .AppBar_button__39rWs {
  margin-right: 20px;
  height: 40px;
  line-height: 37px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  white-space: nowrap;
}
.AppBar_appBarRight__3tL1E .AppBar_authContainer__36ZDv {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}
.AppBar_appBarRight__3tL1E .AppBar_authContainer__36ZDv img {
  margin-left: 2px;
  margin-right: 2px;
}
.AppBar_appBarRight__3tL1E .AppBar_authContainer__36ZDv .AppBar_dropdownContent__32hwl {
  display: none;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  cursor: default;
  position: absolute;
  right: 0px;
  top: 55px;
  color: #000000;
}
.AppBar_appBarRight__3tL1E .AppBar_authContainer__36ZDv:hover .AppBar_dropdownContent__32hwl {
  display: block;
  font-size: 16px;
}
.AppBar_appBarRight__3tL1E .AppBar_authContainer__36ZDv .AppBar_dropdownContent__32hwl .AppBar_clientEmail__1Sodc {
  height: 35px;
  line-height: 35px;
  font-family: 'Open Sans SemiBold';
  margin-bottom: 20px;
}
.AppBar_appBarRight__3tL1E .AppBar_authContainer__36ZDv .AppBar_dropdownContent__32hwl .AppBar_clientLogout__mFwWO {
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  white-space: nowrap;
}
.AppBar_bufflLogoWhiteSmall__1bNE5 {
  height: 18px;
  cursor: pointer;
}
.AppBar_bufflLogoWhiteShort__1atJm {
  width: 18px;
  height: 18px;
}

.AppBar_bufflVersion__1ClRe {
  color: #fff;
  font-size: 0.8em;
}
.AppBar_clientNameContainer__1_-l7 {
  padding-right: 25px;
  border-right: 1px solid #3b3838;
}
.AppBar_clientName__29cFd {
}
.AppBar_logoutAction__1F3ok {
  padding-left: 25px;
  font-family: 'Open Sans SemiBold';
  cursor: pointer;
  background: none;
  border: none;
  color: #fff;
}
.AppBar_appBarShortLogoContainer__2WYm3 {
  margin-right: 25px;
  height: 66px;
  line-height: 66px;
  color: #ffffff;
  font-family: 'Open Sans SemiBold';
  display: flex;
}
.AppBar_appBarShortLogoContainer__2WYm3 .AppBar_logoPart__3Q4Vk {
  display: inline-block;
  min-width: 70px;
}
.AppBar_appBarShortLogoContainer__2WYm3 .AppBar_logoPart__3Q4Vk.AppBar_backend__26BOg {
  min-width: 130px;
}
.AppBar_appBarShortLogoContainer__2WYm3 img {
  margin-right: 8px;
}
.AppBar_appBarShortLogoContainer__2WYm3 .AppBar_myCampaignsAction__2gE1S {
  font-family: 'Open Sans Regular';
  border-left: 1px solid #3b3838;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  background: none;
  border: none;
  display: inline-block;
  min-width: 130px;
}
.AppBar_appBarShortLogoContainer__2WYm3 .AppBar_routeSubtitleName__2qsz9 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.AppBar_appBarCenter__1Xfv6 {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
}
.AppBar_appBarCenter__1Xfv6 .AppBar_pathOption__2V6_X {
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #8f8e8e;
  margin-left: 35px;
  cursor: pointer;
}
.AppBar_appBarCenter__1Xfv6 .AppBar_pathOption__2V6_X:first-child {
  margin-left: 0px;
}
.AppBar_appBarCenter__1Xfv6 .AppBar_pathOption__2V6_X.AppBar_active__3zvS_ {
  color: #ffffff;
  border-bottom: 3px solid #ffffff;
  cursor: default;
}
.AppBar_appBarCenter__1Xfv6 .AppBar_pathOption__2V6_X .AppBar_count__1GeSd {
  background-color: #937ff0;
  color: #ffffff;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  border-radius: 5px;
}

@media only screen and (max-width: 990px) {
  .AppBar_appBarShortLogoContainer__2WYm3 .AppBar_myCampaignsAction__2gE1S {
    display: none;
  }
  .AppBar_appBarShortLogoContainer__2WYm3 .AppBar_routeSubtitleName__2qsz9 {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .AppBar_bufflVersion__1ClRe {
    display: none;
  }
}

@media print {
  .AppBar_appBarContainer__38vaU {
    position: absolute;
  }
}

.AccountManagementRecordSelect_container__20ADY {
  display: flex;
  align-items: center;
  margin-right: 25px;
}
.AccountManagementRecordSelect_label__1LWqh {
  font-size: 13px;
  margin-right: 10px;
}
.AccountManagementRecordSelect_disabled__3kbb4 {
  opacity: 0.5;
}

.DropdownSelector_filterDropdownSelectorContainer__2VyeC {
  position: relative;
  height: 30px;
  line-height: 30px;
  max-width: 290px;
  margin-right: 10px;
}
.DropdownSelector_selectedOptionsLabel__10a5F {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 30px;
  color: #9e9e9e;
}
.DropdownSelector_selectedOptionsLabel__10a5F.DropdownSelector_valueSelected__33IB2 {
  color: #000000;
}
.DropdownSelector_filterDropdownPopup__3IPV8 {
  font-size: 11px;
  background-color: #ffffff;
  position: fixed;
  top: 31px;
  width: 300px;
  border: 1px solid #dfe0e4;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.DropdownSelector_filterDropdownItem__eC2Sq {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.DropdownSelector_allOption__Bxw3i {
  border-bottom: 1px solid #ececec;
}
.DropdownSelector_filterDropdownItem__eC2Sq:hover {
  background-color: #f5f5f5;
}
.DropdownSelector_filterDropdownItem__eC2Sq.DropdownSelector_active__2oUPM {
  background-color: #fafafa;
}
.DropdownSelector_filterDropdownItem__eC2Sq.DropdownSelector_selfRecord__1bpZI {
  border-top: 1px solid #ececec;
}
.DropdownSelector_filterDropdownItem__eC2Sq.DropdownSelector_notSelectable__7o9w5 {
  cursor: not-allowed;
}
.DropdownSelector_filterDropdownItem__eC2Sq.DropdownSelector_notSelectable__7o9w5:hover {
  background-color: #ffffff;
}
.DropdownSelector_filterDropdownItemCheckboxSelected__3ot3_ {
  height: 21px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownSelector_filterDropdownItemCheckbox__1PvRA {
  height: 21px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownSelector_filterDropdownItemLabel__1e2gE {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.DropdownSelector_filterDropdownItemLabel__1e2gE .DropdownSelector_level2Padding__20qP3 {
  display: inline-block;
  width: 20px;
  height: 5px;
}
.DropdownSelector_noAvailableClientAccounts__3Lfvd {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__3Jo-C {
  display: flex;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__3Jo-C input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 40px);
  outline: none;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__3Jo-C .DropdownSelector_inputLoaderContainer__2IsIs > div {
  position: relative;
  top: -10px;
}
.DropdownSelector_filterDropdownPopupItemsContainer__2D0Cm {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  color: #000000;
}
.DropdownSelector_filterDropdownPopupItemsContainer__2D0Cm::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.DropdownSelector_filterDropdownPopupItemsContainer__2D0Cm::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.DropdownSelector_filterDropdownPopupFooterContainer__3EnxO {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.DropdownSelector_filterDropdownPopupConfirm__3jttz {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}
.DropdownSelector_loaderContainer__2PPSY {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 20px;
}
.DropdownSelector_addItemIcon__1xrx2 {
  position: relative;
  top: 0px;
  width: 11px;
  left: 0px;
  cursor: pointer;
}
.DropdownSelector_dropdownPicker__PD-Yx {
  font-family: 'Open Sans Regular';
  height: 40px;
  line-height: 42px;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  color: #535353;
  cursor: pointer;
  position: relative;
  bottom: 5px;
  display: flex;
  align-items: center;
}
.DropdownSelector_dropdownPicker__PD-Yx.DropdownSelector_disabled__2tMWr {
  background-color: #ebebeb;
  border-color: #dcdcdc !important;
}
.DropdownSelector_dropdownPickerLabel__ZF55j {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 230px;
}
.DropdownSelector_arrowDown__17SeP {
  display: inline-block;
  position: relative;
  margin-left: 10px;
  bottom: 0px;
}

.main-container {
  width: 1390px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 66px;
}
.main-container .warning-message {
  background: rgba(200, 0, 0, 0.2);
  border: rgba(200, 0, 0, 0.8);
  border-radius: 8px;
  padding: 8px;
  color: white;
  margin: 16px 0;
}
.content-header {
  position: fixed;
  width: 1393px;
  background-color: #f6f7fb;
  display: flex;
  justify-content: space-between;
  height: 55px;
  top: 66px;
  line-height: 55px;
  padding-top: 24px;
  padding-bottom: 30px;
  z-index: 99;
}
.left-header-content {
}
.left-header-content .header1 {
  font-family: 'Roboto Bold';
  font-size: 23px;
  color: #221f1f;
  height: 30px;
  line-height: 30px;
  max-width: 1010px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.left-header-content .header2 {
  font-size: 14px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.right-header-content {
  display: inline-flex;
}
.right-header-content .button {
  margin-left: 15px;
}
.right-header-content .error-message {
  font-family: 'Open Sans SemiBold';
  color: #f10054;
  font-size: 13px;
  margin-right: 10px;
}
.content {
}
.container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}
.container.question {
  margin-top: 60px;
  margin-bottom: 120px;
}
.container-title {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
}
.action-column-container {
  position: fixed;
  top: 90px;
  width: 220px;
  z-index: 10;
}
.action-column-actions-container {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
}
.blocks-column-container {
  top: 25px;
  width: 785px;
  margin-left: 240px;
}
.blocks-column-container .container {
  /* margin-bottom: 55px; */
}
.blocks-column-container .container .container-title {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 30px;
}
.blocks-column-container .block-container {
  background-color: #ffffff;
  border-left: 4px solid #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  padding-top: 35px;
}
.blocks-column-container .block-container.focused {
  border-left: 4px solid #8500f0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}
.blocks-column-container .block-container.out-of-focus {
  opacity: 0.2;
}
.blocks-column-container .block-container .block-dropdown {
  text-align: center;
  font-size: 14px;
  color: #8b8b8d;
  border: 1px dashed #d1d1d1;
  border-style: dashed;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
  border-radius: 5px;
  margin-bottom: 35px;
}
.block-container .block-structure {
  display: inline-flex;
}
.block-container .block-structure .media-upload {
  position: absolute;
  top: -3px;
  right: 0px;
  width: 30px;
  height: 30px;
  z-index: 10;
}
.block-container .block-structure .media-upload .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
}
.block-container .block-structure .media-upload .video-file-preview {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
}
.block-container .block-structure .media-upload .video-file-preview span {
  position: relative;
  display: inline-block;
  top: 6px;
  left: 9px;
}
.block-container .block-structure .block-number {
  margin-left: 15px;
  width: 35px;
  color: #221f1f;
  font-size: 18px;
}

.block-container .block-structure .mandatory {
  color: #8500f0;
}
.block-container .block-structure .block-icon {
  width: 45px;
  color: #8500f0;
  top: 4px;
}
.block-container .block-structure .block-icon .icon {
  font-size: 16px;
}
.block-container .block-structure .block-content {
  width: 650px;
}
.block-content .block-text {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  padding-right: 15px;
}
.block-content .block-text .block {
  font-family: 'Open Sans Regular';
  font-size: 18px;
  border: 0;
  outline: none;
  width: 600px;
  background-color: #ffffff;
  color: #000000;
  margin-bottom: 10px;

  resize: none;
  overflow: hidden;
}
.block-content .block-text .block:invalid {
  border-left: 3px solid red;
}
.block-content .block-text .description-title {
  font-size: 14px;
  color: #8b8b8d;
}
.block-content .block-text .description {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  display: block;
  width: 650px;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  resize: none;
}
.block-content .block-text .description.notes-field {
  padding-left: 10px;
  padding-top: 10px;
  font-size: 12px;
  background-color: #f6f7fb;
}
.block-content .block-text .description-notes-content {
  position: absolute;
  top: -20px;
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.block-content .block-text .description-notes-title {
  font-size: 14px;
}
.block-structure ::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8e8e;
}
.block-structure ::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8e8e;
}
.block-structure ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8f8e8e;
}
.block-structure :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8f8e8e;
}
.block-structure ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8f8e8e;
}
.block-content .block-website {
}
.block-container .block-additional-options {
}
.block-container .block-additional-options .description-title {
  font-size: 14px;
  color: #8b8b8d;
  padding-left: 95px;
}
.block-container .block-additional-options .block-additional-option {
  margin-left: 95px;
  padding-top: 10px;
  border-bottom: 1px solid #ececec;
  margin-right: 60px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .answer-cover-image-container {
  border: 1px solid #8500f0;
  border-radius: 2px;
  height: 22px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 0px;
  overflow: hidden;
  cursor: default;
}
.block-additional-option .answer-cover-image-container .left-container {
  width: 22px;
  height: 22px;
}
.answer-cover-image-container .left-container .image-placeholder {
  width: 22px;
  height: 22px;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  display: block;
}
.answer-cover-image-container .left-container .image-placeholder span {
  font-size: 26px;
  position: absolute;
  left: -2.4px;
  top: -3.5px;
  padding: 0px;
  margin: 0px;
}
.answer-cover-image-container .left-container .cover-image-preview {
  width: 22px;
  height: 22px;
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
}
.block-additional-option .answer-cover-image-container .right-container {
  background-color: #ffffff;
  width: 22px;
  height: 22px;
}
.block-additional-option
  .answer-cover-image-container
  .right-container
  .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 22px;
  height: 22px;
}
.block-additional-option
  .answer-cover-image-container
  .right-container
  .action
  span {
  position: absolute;
  left: 2.2px;
  top: 2.25px;
  font-size: 13px;
  z-index: 10;
}
.answer-cover-image-container .right-container .action .file-upload-input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 22px;
  padding: 0px;
  margin: 0px;
  z-index: 11;
}
.block-container .block-additional-options .block-additional-option:last-child {
  border-bottom: 0;
}
.block-container .block-additional-options .block-additional-option textarea {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  width: 90%;
  background-color: #ffffff;
  color: #000000;
  margin-left: 60px;
  resize: none;
  overflow: hidden;
}
.block-container .block-additional-options .block-additional-option.input-add {
  margin-bottom: 20px;
  margin-top: 0px;
}
.block-container
  .block-additional-options
  .block-additional-option.input-add
  textarea {
  margin-left: 0px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .media-upload {
  position: absolute;
  right: 5px;
  top: 10px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .media-upload
  .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 90px;
  cursor: pointer;
}
.block-container
  .block-additional-options
  .block-additional-option
  .media-upload
  .action {
  font-family: 'Open Sans SemiBold';
  font-size: 12px;
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.block-container .block-additional-options .block-additional-option .close {
  color: #8b8b8d;
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: 50%;
}
.block-container
  .block-additional-options
  .block-additional-option
  .allow-other {
  background-color: #5200f1;
  color: #fff;
  font-size: 11px;
  position: absolute;
  right: 0px;
  top: 40%;
  padding: 2px 5px;
  border-radius: 2px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .add-option {
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  right: -30px;
  bottom: 10px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .jump-container {
  position: absolute;
  right: -40px;
  bottom: 10px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .jump-to-block {
  position: relative;
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  left: 20px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .jump-end-survey {
  position: relative;
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  left: 5px;
}
.block-container
  .block-additional-options
  .block-additional-option
  .jump-end-survey.active,
.block-container
  .block-additional-options
  .block-additional-option
  .jump-to-block.active {
  color: #a87ff8;
}
.block-container .blocks-jump {
  font-size: 14px;
  color: #221f1f;
  background-color: #f6f7fb;
  line-height: 60px;
  border-bottom: 1px solid #ebecf0;
  padding-left: 95px;
}
.block-container .blocks-jump .block-jump-icon {
  position: absolute;
  left: 50px;
}
.block-container .blocks-jump .description {
  width: 580px;
  display: inline-block;
  line-height: 22px;
  margin-top: 20px;
}
.block-container .blocks-jump .edit-button {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  cursor: pointer;
  position: absolute;
  top: -1px;
  right: 63px;
  color: #8500f0;
  cursor: pointer;
}
.block-container .blocks-jump .close {
  font-size: 11px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 30px;
}
.properties-column-container {
  position: fixed;
  top: 90px;
  margin-left: 1044px;
  width: 290px;
  padding-bottom: 20px;
  z-index: 9;
}
.side-container-title-item {
  display: inline-flex;
  margin-left: 20px;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
}
.side-container-content {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
.side-container-content .emoji-picker {
  padding: 0 5px 10px 5px;
  position: absolute;
  top: 40px;
  right: -43px;
  width: 208px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
}
.side-container-content .emoji-picker::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.side-container-content .emoji-picker::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.side-container-content .emoji-picker .emoji-category {
  width: 100%;
  margin-left: 5px;
  margin-top: 15px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.side-container-content .emoji-picker .emoji-category:first-child {
  margin-top: 10px;
}
.side-container-content .emoji-picker .emoji {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.side-container-content .emoji-picker .emoji:hover {
  opacity: 0.6;
}
.side-container-action {
  padding: 15px 0 13px 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  color: #8b8b8d;
  font-family: 'Open Sans SemiBold';
}
.side-container-action.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
.side-container-action.disabled .icon {
  color: #8b8b8d;
}
.side-container-action .soon {
  color: #ff3018;
  margin-left: 10px;
}
.side-container-action:last-child {
  border-bottom: 0;
}
.side-container-action:hover {
  opacity: 0.7;
}
.side-container-action:last-child {
  border-bottom: 0;
}
.side-container-action .icon {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 2px;
  top: 3px;
  font-size: 14px;
  position: relative;
  color: #5200f1;
}
.edit-block-title {
  font-size: 11px;
  color: #8b8b8d;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.edit-block-container {
}
.text-editor-container {
  margin-left: 18px;
  margin-right: 18px;
  border-bottom: 1px solid #eeeeee !important;
  height: 50px;
  line-height: 50px;
}
.text-editor-action {
  display: inline-block;
  margin-right: 10px;
}
.text-editor-action-button {
  padding: 0 10px;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.4;
  cursor: pointer;
}
.text-editor-action-button:hover {
  opacity: 0.3;
}
.text-editor-action.true .text-editor-action-button {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}
.text-editor-action-button.picker-active {
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  opacity: 1;
}
.side-container-properties {
  width: 255px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.side-container-properties .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -3px;
  right: 60px;
  cursor: pointer;
}
.side-container-properties .icon {
  color: #8500f0;
  font-size: 25px;
  line-height: 20px;
  cursor: pointer;
}
.side-container-properties .media-upload a:link,
.side-container-properties .media-upload a:visited {
  font-size: 11px;
  text-decoration: none;
  color: #5200f1;
  margin-right: 10px;
}
.side-container-properties .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.side-container-properties .action .file-upload-input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
}
.side-container-properties .action.remove {
  color: red;
}

.side-container-properties-campaign-web-url-container-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.side-container-properties-campaign-web-url-container {
  margin-top: 10px;
  display: flex;
  flex: 1 1;
  width: 100%;
}
.side-container-properties-campaign-web-url {
  width: 90%;
  padding: 5px;
  border: 1px solid #cdcdcd;
  border-radius: 2px;
  font-size: 12px;
}
.side-container-properties-campaign-web-url-click-link {
  margin-top: 8px;
  display: inline-block;
  position: relative;
  height: 30px;
  line-height: 30px;
  padding: 1px 6px;

  font-size: 14px;
  font-family: 'Open Sans SemiBold';
  border: none;
  background-color: transparent;
  color: #5200f1;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 4px;
}
.side-container-properties-campaign-web-url-click-link:hover {
  background-color: rgba(82, 0, 241, 0.1);
}

/* Toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Inactive */
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5200f1; /* Active */
}

input:focus + .slider {
  box-shadow: 0 0 1px #5200f1; /* Active */
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.main-container .loader-container {
  background-color: #e3e3e3;
}
.main-container .loader-container.button {
  height: 55px;
  width: 150px;
}
.main-container .loader-container.column {
  height: 400px;
}
.main-container .loader-container.item {
  height: 220px;
  margin-bottom: 50px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.campaign-builder-mini-map-container {
  position: fixed;
  width: 55px;
  top: 67px;
  right: 0px;
  border-left: 1px solid #ebe9ef;
  height: calc(100vh - 67px);
  padding-top: 20px;
  z-index: 99;
}
.campaign-builder-mini-map-container .single-question-icon {
  text-align: center;
  color: #b4b2b6;
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.campaign-builder-mini-map-container .single-question-icon.active {
  color: #8500f0;
}
.campaign-builder-mini-map-container .single-question-icon:hover span {
  opacity: 0.7;
}
.campaign-builder-mini-map-container
  .single-question-icon
  .single-question-popup {
  display: none;
  position: relative;
}
.campaign-builder-mini-map-container .single-question-icon .error-dot {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
}
.campaign-builder-mini-map-container
  .single-question-icon:hover
  .single-question-popup {
  position: absolute;
  display: block;
  padding: 10px;
  max-width: 300px;
  min-width: 250px;
  font-size: 13px;
  color: #8b8b8d;
  top: 0px;
  right: 56px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  text-align: left;
  border-radius: 5px;
}

.campaign-builder-errors-container {
}
.campaign-builder-error {
  background-color: #f5a0a142;
  color: #b03223;
  font-size: 14px;
  line-height: 35px;
  padding-left: 20px;
}
.campaign-builder-error:last-child {
  border-bottom: 1px solid #d5aea9;
}

@media only screen and (max-width: 1420px) {
  .main-container {
    width: auto;
  }
  .content-header {
    width: 97vw;
    padding-left: 10px;
  }
  .content {
    padding-left: 10px;
  }
  .blocks-column-container {
    width: calc(100vw - 240px - 405px);
  }
  .properties-column-container {
    right: 70px;
  }
  .block-container .block-structure {
    width: 100%;
  }
  .block-container .block-structure .media-upload {
    right: 30px;
  }
  .block-container .block-structure .block-content {
    width: calc(90% - 100px);
  }
  .block-container .block-additional-options .block-additional-option input {
    width: calc(90% - 30px);
  }
  .block-content .block-text .description {
    display: block;
    width: 100%;
  }
  .block-content .block-text .block {
    width: 100% !important;
  }
  .block-container .block-structure .jump-container {
    right: 30px;
  }
  .block-container .blocks-jump .description {
    width: 70%;
  }
}

.flow-chart-node-container {
  max-width: 400px;
  overflow: auto;
  white-space: pre-wrap;
  margin: auto;
  display: inline-block;
  padding: 5px;
}

.node rect {
  stroke: #333;
  fill: #fff;
}

.edgePath path {
  stroke: #333;
  fill: #333;
  stroke-width: 1.5px;
}

.svg-text-icon {
  width: 13px;
}

.svg-text-icon-question,
.svg-text-icon-bigger {
  width: 15px;
}

.svg-text-minimap-icon {
  width: 15px;
  -webkit-filter: grayscale(1) opacity(0.35);
          filter: grayscale(1) opacity(0.35);
}

.single-question-icon:hover .svg-text-minimap-icon {
  opacity: 0.7;
}

.single-question-icon.active .svg-text-minimap-icon {
  width: 15px;
  -webkit-filter: grayscale(0) opacity(1);
          filter: grayscale(0) opacity(1);
}
.multiple-answers-possible-container {
  font-size: 14px;
  color: #8b8b8d;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 95px;
  margin-bottom: 20px;
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}
.multiple-answers-possible-container .logic-jump-error {
  display: inline-flex;
  height: 20px;
  line-height: 20px;
  margin-bottom: 10px;
}
.multiple-answers-possible-container .logic-jump-error .warning-icon {
  width: 20px;
}
.multiple-answers-possible-container .logic-jump-error .logic-error-message {
  font-weight: bold;
  font-size: 11px;
  color: #fc035d;
  margin-left: 8px;
}
.multiple-answers-possible-container .logic-jump-error .close {
  color: #8b8b8d;
  margin-left: 8px;
  cursor: pointer;
  font-weight: normal;
}
.multiple-answers-possible-container .logic-jump-error .close span {
  vertical-align: bottom;
}

.multiple-answers-possible-container .multiple-answers-possible-controls {
  display: flex;
  align-items: center;
  height: 40px;
}
.multiple-answers-possible-container .multiple-answers-controls-values {
  display: flex;
  align-items: center;
}
.multiple-answers-possible-container
  .multiple-answers-controls-values-disabled {
  opacity: 0.8;
}
.multiple-answers-possible-container .multiple-answers-possible-text {
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  align-items: center;
}
.multiple-answers-possible-container .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease;
  border-radius: 4px;
  padding: 5px 6px;
  height: 22px;
}
.multiple-answers-possible-container .action.active {
  background-color: rgba(82, 0, 241, 0.1);
  cursor: -webkit-grab;
  cursor: grab;
}
.multiple-answers-possible-container
  .multiple-answers-controls-values-disabled
  .action.active {
  background: none;
}
.multiple-answers-possible-container
  .multiple-answers-controls-values-disabled
  .action {
  cursor: -webkit-grab;
  cursor: grab;
  color: #8b8b8d;
}
.multiple-answers-possible-container .multiple-answers-possible-text input {
  padding: 5px 15px;
  margin-left: 8px;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  text-align: right;
  outline-color: #e5e5e5;
  color: #8b8b8d;
  max-width: 30px;
}
.multiple-answers-possible-container
  .multiple-answers-possible-text
  input::-webkit-input-placeholder {
  color: #d5d5d5;
}
.multiple-answers-possible-container
  .multiple-answers-possible-text
  input::-ms-input-placeholder {
  color: #d5d5d5;
}
.multiple-answers-possible-container
  .multiple-answers-possible-text
  input::placeholder {
  color: #d5d5d5;
}
.multiple-answers-possible-container
  .multiple-answers-controls-values-disabled
  .multiple-answers-possible-text
  input:disabled {
  background-color: #f1f1f1;
}

.flow-bar-root-container {
  padding-top: 16px;
  background-color: #ffffff;
  position: fixed;
  margin-top: 0px;
  z-index: 9999;
  width: 1028px;
}
.flow-bar-container {
  margin: 32px;
  margin-top: 0px;
  display: flex;
  padding: 10px 0;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  margin-bottom: 0px;
  background-color: #fff;
  z-index: 1000;
}
.flow-bar-tab-wrapper {
  flex-grow: 1;
}
.flow-bar-tab-wrapper:hover .flow-bar-tab {
  color: rgba(149, 152, 154, 0.7);
}
.flow-bar-tab {
  height: 50px;
  line-height: 50px;
  text-align: center;
  text-transform: uppercase;
  color: #95989a;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
}
.flow-bar-tab-wrapper .flow-bar-tab-active,
.flow-bar-tab-wrapper:hover .flow-bar-tab-active {
  color: #5300f2;
}
.flow-bar-tab-wrapper:last-child .flow-bar-tab {
  border: none;
}

@media only screen and (max-width: 1035px) {
  .flow-bar-root-container {
    width: 100vw;
  }
}

@media only screen and (max-width: 750px) {
  .flow-bar-tab {
    font-size: 2vw;
  }
}

.Loader_loaderFixedContainer__3VQn3 {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
.Loader_loaderFillContainer__1bplK {
  position: absolute;
  top: -20px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.Loader_loadingAnimation__1-4Fe {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.Loader_loadingAnimation__1-4Fe div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation__nUIGN 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation__nUIGN 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__1-4Fe div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.Loader_loadingAnimation__1-4Fe div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.Loader_loadingAnimation__1-4Fe div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes Loader_loading-animation__nUIGN {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader_loading-animation__nUIGN {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ResultPopup_container__1MGLA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ResultPopup_title__2TQua {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  font-size: 15px;
  margin-bottom: 10px;
}
.ResultPopup_description__1Wuj2 {
  color: #9c9696;
  font-size: 11px;
  width: 210px;
  text-align: center;
  margin-bottom: 10px;
}
.ResultPopup_checkmark__1Q3DM {
  width: 60px;
  height: 60px;
  margin-top: 20px;
  -webkit-animation: ResultPopup_transformer__tDoL5 1s ease-in-out 0s 1;
          animation: ResultPopup_transformer__tDoL5 1s ease-in-out 0s 1;
}

@-webkit-keyframes ResultPopup_transformer__tDoL5 {
  from {
    width: 0px;
  }
  to {
    width: 60px;
  }
}

@keyframes ResultPopup_transformer__tDoL5 {
  from {
    width: 0px;
  }
  to {
    width: 60px;
  }
}

.TimePicker_pickerContainer__1Mcit {
  display: flex;
  position: absolute;
  width: 100%;
  height: 200px;
  overflow: hidden;
  z-index: 1;
  left: 2px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  background-color: white;
}

.TimePicker_valueContainer__mZqDu {
  flex: 1 1;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
}

.TimePicker_value__3qaQn {
  display: flex;
  list-style: none;
  padding: 5px 0px 5px 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.TimePicker_active__10VhL {
  background-color: aliceblue;
}

.TimePicker_wrapper__3KLpf {
  position: relative;
}

.SurveyScheduler_container__2L_Ey {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.SurveyScheduler_scheduleButton__1unEI {
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45px;
}
.SurveyScheduler_scheduleButtonAdditionalText__bhR3A {
  color: #5200f1;
  font-size: 10px;
}
.SurveyScheduler_schedulerHeader__WVxPh {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
}

.SurveyScheduler_schedulerHeaderTitle__cKvDG {
  font-size: 16px;
  font-weight: bold;
}

.SurveyScheduler_schedulerHeaderExtraTitle__1KTBZ {
  font-size: 12px;
  font-weight: bold;
  color: #5200f1;
}

.SurveyScheduler_schedulerActionsContainer__2Jd0D {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.SurveyScheduler_checkboxLabel__1441N {
  font-style: italic;
  font-size: 13px;
  margin-left: 10px;
}

.SurveyScheduler_checkboxLabel__1441N .SurveyScheduler_actionLabel__3R1P8 {
  color: #5200f1;
  text-transform: capitalize;
  font-weight: bold;
}

.SurveyScheduler_actionOption__19nox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.SurveyScheduler_middle__3BCI5 {
  width: 1px;
  background-color: #e6e6e6;
}

.SurveyScheduler_schedulerFooter__3pgCw {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SurveyScheduler_schedulerFooter__3pgCw .SurveyScheduler_scheduleButton__1unEI {
  font-size: 16px;
  border-radius: 8px;
  font-family: 'Open Sans Regular';
  border: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding: 5px 30px 5px 30px;
  line-height: 45px;
  color: #ffffff;
  background-color: #5200f1;
  width: 140px;
}

.SurveyScheduler_switcherContainer__19QNw {
  display: flex;
  flex-direction: row;
}

.SurveyScheduler_dateTimeContainer__2rwob {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin-top: 25px;
}

.SurveyScheduler_dateContainer__3D32g {
  display: flex;
  width: 40%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.SurveyScheduler_disabled__KmgyN * {
  color: #f2f2f2 !important;
  pointer-events: none;
}

.SurveyScheduler_disabled__KmgyN * > .SurveyScheduler_timeInput__38wDK {
  border: 2px solid #f2f2f2;
}

.SurveyScheduler_disabled__KmgyN * > .SurveyScheduler_dateInput__2gUoD input {
  border: 2px solid #f2f2f2;
}

.SurveyScheduler_disabled__KmgyN * ::-webkit-input-placeholder {
  color: #f2f2f2;
}

.SurveyScheduler_disabled__KmgyN * ::-ms-input-placeholder {
  color: #f2f2f2;
}

.SurveyScheduler_disabled__KmgyN * ::placeholder {
  color: #f2f2f2;
}

.SurveyScheduler_dateTimeInputContainer__1qkOr {
  display: flex;
  width: 35%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.SurveyScheduler_timeInput__38wDK {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  padding: 10px 0px 10px 0px;
  width: 100%;
  font-size: 15px;
  color: #000000;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

.SurveyScheduler_dateInput__2gUoD input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  padding: 10px 0px 10px 0px;
  width: 100%;
  font-size: 15px;
  color: #000000;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

.SurveyScheduler_dateInput__2gUoD::-webkit-input-placeholder {
  color: #000000;
}

.SurveyScheduler_dateInput__2gUoD::-ms-input-placeholder {
  color: #000000;
}

.SurveyScheduler_dateInput__2gUoD::placeholder {
  color: #000000;
}

.SurveyScheduler_datePickerContainer__1x_Wm {
  position: absolute;
}

.SurveyScheduler_datePicker__MVXFO {
  display: block;
}

.SurveyScheduler_errorMessage__2Obdb {
  color: red;
  font-size: 12px;
  font-family: 'Open Sans Bold';
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0px;
}

.SurveyScheduler_extendedErrorMessage__1DQze {
  width: 40vh;
}

.SurveyScheduler_errorMessageBorder__7_xlR input {
  border: 2px solid red;
}

.SurveyScheduler_scheduleButtonDisabled__2zT2B {
  background: #cccccc !important;
  pointer-events: none;
}

.SurveyScheduler_orPublishNowButton__Wc6L3 {
  font-family: 'Open Sans Bold';
  font-size: 12px;
  color: #5200f1;
  margin-top: 15px;
  cursor: pointer;
}

.SchedulePopup_container__1YvoW {
  display: flex;
  flex-direction: column;
  z-index: 0;
}
.SchedulePopup_schedulerContainer__IPZr3 {
  display: flex;
  width: 45vh;
  height: 18vh;
}
.SchedulePopup_buttonsContainer__bSceP {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 20px);
  left: -10px;
  border-top: 1px solid #e6e6e6;
  z-index: -1;
}
.SchedulePopup_button__36gX3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 40px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  line-height: 35px;
  border: 0;
  margin: 15px 15px 5px 15px;
  cursor: pointer;
}
.SchedulePopup_buttonDisabled__1mddC {
  background: #cccccc !important;
  pointer-events: none;
}
.SchedulePopup_leftButton__3VWXH {
  color: #fc025d;
}
.SchedulePopup_rightButton__2whuF {
  color: #ffffff;
  border-radius: 5px;
  background-color: #4926e9;
}

.Step_container__1Sea_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  margin-left: 50px;
  margin-right: 50px;
  min-width: 150px;
}
.Step_stepContainer__PQWIX {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Step_infoContainer__Q0gAM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25px;
}
.Step_headerText__1MDSv {
  font-style: italic;
  text-align: center;
  font-weight: bold;
  height: 35px;
}
.Step_stepProgressline__3xqbF {
  position: absolute;
  width: 250px;
  top: 48%;
  right: 50%;
  z-index: 1;
  /* border-top: 2px dashed #4926e9; */
}
.Step_stepOuterCircle__1PpJw {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: #ffffff;
  margin-top: 30px;
  margin-bottom: 30px;
  z-index: 2;
}
.Step_stepInnerCircle__2rTyh {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 81%;
  /* border: 9px solid #a184f1;
  background-color: #4926e9; */
  opacity: 1;
  z-index: 1;
}
.Step_footerText__1BFdB {
  font-style: italic;
  font-size: 10px;
  text-align: center;
}
.Step_author__1u6GG {
  color: #9c9696;
  font-size: 10px;
  margin-top: 5px;
}
.Step_button__3JE-s {
  width: 71%;
  height: 35px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  line-height: 35px;
  margin-top: 25px;
  border: 0;
}
.Step_enabledButton__2vZNi {
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.Step_disabledButton__2ybSR {
  cursor: default;
  color: rgb(149, 151, 153);
}
.Step_invisibleButton__o1DCq {
  pointer-events: none;
}
.Step_cancelButton__1NTuA {
  background-color: #fc025d;
}
.Step_scheduleButton__3ITeX {
  background-color: #4926e9;
}
.Step_popupContainer__1tfxl {
  display: flex;
  flex-direction: column;
}
.Step_schedulerContainer__1SKUU {
  display: flex;
  width: 45vh;
  height: 18vh;
}
.Step_popupButtonsContainer__3gq2i {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 20px);
  left: -10px;
  border-top: 1px solid #e6e6e6;
}
.Step_popupButton__3jpzj {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 40px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  line-height: 35px;
  border: 0;
  margin: 15px 15px 5px 15px;
  cursor: pointer;
}
.Step_popupLeftButton__TiUbm {
  color: #fc025d;
}
.Step_popupRightButton__2OMrN {
  color: #ffffff;
  border-radius: 5px;
  background-color: #4926e9;
}
.Step_buttonsContainer__21vEG {
  display: flex;
}

.step-progressline-PAST {
  border-top: 2px solid rgb(181, 157, 247);
}
.step-progressline-NOW {
  border-top: 2px solid rgb(112, 65, 241);
}
.step-progressline-FUTURE {
  border-top: 2px dashed rgb(188, 190, 191);
}
.step-progressline-POTENTIAL {
  border-top: 2px dashed rgb(188, 190, 191);
}

.step-inner-circle-PAST {
  border: 9px solid rgb(205, 190, 250);
  background-color: rgb(157, 126, 244);
}
.step-inner-circle-NOW {
  border: 9px solid rgba(157, 126, 244);
  background-color: rgb(83, 0, 242);
}
.step-inner-circle-FUTURE {
  border: 9px solid rgb(201, 202, 203);
  background-color: rgb(149, 151, 153);
}
.step-inner-circle-POTENTIAL {
  border: 9px solid rgb(201, 202, 203);
  background-color: rgb(149, 151, 153);
}

.step-header-text-PAST {
  color: rgb(157, 126, 244);
  display: flex;
  align-items: center;
}
.step-header-text-NOW {
  color: #4926e9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115%;
}
.step-header-text-FUTURE {
  color: rgb(149, 151, 153);
  display: flex;
  align-items: center;
}
.step-header-text-POTENTIAL {
  color: rgb(149, 151, 153);
  display: flex;
  align-items: center;
}

.step-footer-text-PAST {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.step-footer-text-NOW {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.step-footer-text-FUTURE {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.step-footer-text-POTENTIAL {
  width: 100%;
}

.SurveyTimeline_titleContainer__2Ugea {
  border-bottom: 1px solid #e6e6e6;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 20px;
  padding-top: 30px;
  color: #9c9696;
  font-size: 12px;
}
.SurveyTimeline_timelineContainer__2aHF0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 50px 0 50px 0;
}
.SurveyTimeline_arrowContainer__2kI1k {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SurveyTimeline_arrowControllerContainer__2g5Q4 {
  position: absolute;
}
.SurveyTimeline_arrowController__jMhQz {
  width: 18px;
  cursor: pointer;
  position: absolute;
  top: -53px;
}
.SurveyTimeline_scheduleListContainer__4nPwr {
  display: flex;
  flex: 10 1;
  overflow: hidden;
  scroll-behavior: smooth;
}
.SurveyTimeline_centeredScheduleListContainer__1YNCc {
  justify-content: center;
  align-items: flex-start;
}
.SurveyTimeline_gradient__j1C7l {
  position: absolute;
  top: 0;
  height: 250px;
  width: 160px;
  pointer-events: none;
  z-index: 3;
}
.SurveyTimeline_leftGradient__1c_eq {
  left: 70px;
  background: linear-gradient(90deg, #ffffff, #fff0);
}
.SurveyTimeline_rightGradient__3VkV0 {
  right: 70px;
  background: linear-gradient(90deg, #fff0, #ffffff);
}

.PublishSurveyJumbotron_container__3eMoq {
  border: 1px solid #ceced4;
  border-radius: 5px;
  box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  margin: 0 35px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-left: 5px solid #5200f1;
}
.PublishSurveyJumbotron_iconContainer__3ja6n {
  height: 48px;
  width: 80px;
  text-align: center;
}
.PublishSurveyJumbotron_description__3gnEb {
  height: 48px;
  line-height: 48px;
  flex: 1 1;
  color: #6d6d6d;
}
.PublishSurveyJumbotron_buttonContainer__3z2q2 {
  width: 140px;
  height: 48px;
}
.PublishSurveyJumbotron_buttonContainer__3z2q2 .PublishSurveyJumbotron_button__3oaAB {
  height: 48px;
  line-height: 45px;
  font-size: 14px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #fc025d;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  width: 140px;
}

.PublishSurvey_surveyInformationContainer__19Ziv {
  display: flex;
  padding-left: 32px;
  padding-right: 38px;
  justify-content: space-between;
  margin-bottom: 40px;
}
.PublishSurvey_surveyInformationContainer__19Ziv .PublishSurvey_surveyInformation__2Af0U {
  width: 49.3%;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
}
.PublishSurvey_surveyInformationContainer__19Ziv .PublishSurvey_surveyInformation__2Af0U .PublishSurvey_description__3KkKE {
  text-align: center;
  color: #95989a;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.PublishSurvey_titleContainer__3CRaf {
  border-bottom: 1px solid #e4e4e4;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PublishSurvey_titleContainer__3CRaf .PublishSurvey_title__2uHa4 {
  color: #95989a;
}
.PublishSurvey_titleContainer__3CRaf .PublishSurvey_errorMessage__3wx0N {
  font-family: 'Open Sans Bold';
  color: #fc035d;
}
.PublishSurvey_errorsContainer__3xmJa {
  padding: 30px 0;
}
.PublishSurvey_errorsContainer__3xmJa .PublishSurvey_errorContainer__1pgM_ {
  display: flex;
  padding-bottom: 10px;
  border-right: 1px solid #e4e4e4;
}
.PublishSurvey_errorsContainer__3xmJa .PublishSurvey_errorContainer__1pgM_ .PublishSurvey_title__2uHa4 {
  width: 35%;
  border-right: 1px solid #d6d6d6;
  text-align: right;
  color: #888888;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 13px;
}
.PublishSurvey_errorsContainer__3xmJa .PublishSurvey_errorContainer__1pgM_ .PublishSurvey_errors__3y83o {
  width: 65%;
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 12px;
}
.PublishSurvey_errorsContainer__3xmJa .PublishSurvey_errorContainer__1pgM_ .PublishSurvey_errors__3y83o .PublishSurvey_message__1fQoG {
  margin-bottom: 5px;
}
.PublishSurvey_errorsContainer__3xmJa .PublishSurvey_errorContainer__1pgM_ .PublishSurvey_errors__3y83o .PublishSurvey_message__1fQoG span {
  padding: 3px 8px;
  border: 1px solid #fc035d;
  color: #fc035d;
  text-transform: uppercase;
  border-radius: 5px;
  display: inline-block;
}

.PublishSurvey_disabledPublishContainer__9V3Yl {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #dcd3d3;
  padding-top: 40px;
  padding-bottom: 40px;
}
.PublishSurvey_disabledPublishContainer__9V3Yl .PublishSurvey_description__3KkKE {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 30px;
  opacity: 0.5;
}
.PublishSurvey_disabledPublishContainer__9V3Yl .PublishSurvey_buttonContainer__1oCLG {
  opacity: 0.2;
}
.PublishSurvey_disabledPublishContainer__9V3Yl .PublishSurvey_buttonContainer__1oCLG .PublishSurvey_button__2MrZj {
  cursor: no-drop;
}

.PublishSurvey_testingContainer__33kGJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #dcd3d3;
  padding-top: 40px;
  padding-bottom: 40px;
}
.PublishSurvey_testingContainer__33kGJ .PublishSurvey_description__3KkKE {
  font-size: 13px;
  font-style: italic;
  margin-bottom: 30px;
  text-align: center;
  opacity: 0.5;
}
.PublishSurvey_buttonContainer__1oCLG .PublishSurvey_button__2MrZj {
  width: 170px;
  font-size: 14px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  padding: 5px 30px 5px 30px;
}

.PublishSurvey_publishButton__2wQ8f {
  line-height: 45px;
  color: #ffffff;
  background-color: #5200f1;
  margin-bottom: 20px;
}
.PublishSurvey_publishButtonDisabled__2JakH {
  line-height: 45px;
  color: #ffffff;
  background-color: #a488f3;
  margin-bottom: 20px;
  cursor: no-drop !important;
}
.PublishSurvey_scheduleButton__3NAzk {
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45px;
}
.PublishSurvey_scheduleButtonAdditionalText__2n1WD {
  color: #5200f1;
  font-size: 10px;
}
.PublishSurvey_licenceDropdownContainer__20tUg {
  width: 230px;
  margin-bottom: 20px;
}

.Loader_loadingAnimation__LSt-j.Loader_normal__3_EL2 {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.Loader_loadingAnimation__LSt-j.Loader_normal__3_EL2 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation__22KrX 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation__22KrX 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__LSt-j.Loader_small__2LEeU {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  width: 30px;
  height: 20px;
  opacity: 0.5;
}
.Loader_loadingAnimation__LSt-j.Loader_small__2LEeU div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation__22KrX 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation__22KrX 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__LSt-j div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.Loader_loadingAnimation__LSt-j div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.Loader_loadingAnimation__LSt-j div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes Loader_loading-animation__22KrX {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader_loading-animation__22KrX {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.SurveyDetails_container__3BKXA {
  color: #a8a8a8;
  padding: 30px;
  font-size: 13px;
}
.SurveyDetails_container__3BKXA .SurveyDetails_row__O68o5 {
  display: flex;
  justify-content: space-between;
  line-height: 35px;
}
.SurveyDetails_container__3BKXA .SurveyDetails_row__O68o5 .SurveyDetails_value__2-bdU {
  font-family: 'Open Sans Light';
  white-space: pre-line;
  text-align: right;
}

.SurveyDetails_confirmContainer__4lktV {
  display: flex;
  margin-top: 30px;
  color: #000;
}
.SurveyDetails_confirmContainer__4lktV .SurveyDetails_checkboxContainer__3nNU5 {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.SurveyDetails_confirmContainer__4lktV.SurveyDetails_confirmContainerDisabled__ddcEr .SurveyDetails_checkboxContainer__3nNU5 {
  opacity: 0.2;
  cursor: not-allowed;
}
.SurveyDetails_confirmContainer__4lktV.SurveyDetails_confirmContainerDisabled__ddcEr .SurveyDetails_confirmLabel__OUYaJ {
  opacity: 0.2;
}
.SurveyDetails_confirmContainer__4lktV .SurveyDetails_checkboxContainer__3nNU5 img {
  position: relative;
  top: 1px;
}

.Welcome_container__crcQr {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.Welcome_titleContainer__9jwfJ {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.Welcome_titleContainer__9jwfJ .Welcome_title__3sCiv {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.Welcome_detailContainer__161Re {
  font-size: 18px;
  color: #221f1f;
  padding: 10px 20px 0 20px;
}
.Welcome_detailContainer__161Re.Welcome_welcomeAccountManagmenetRecordSelectContainer__1OYDA
  div:nth-child(2) {
  margin-right: 0;
}
.Welcome_detailTitleContainer__2Lzpi {
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.Welcome_detailTitleContainer__2Lzpi .Welcome_title__3sCiv {
  font-size: 14px;
}
.Welcome_surveyName__1k745 {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 31px;
}
.Welcome_description__6loSI {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  display: block;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  height: 25px;
  line-height: 25px;
  margin-bottom: 5px;
  overflow: hidden;
  resize: none;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}
.Welcome_description__6loSI.Welcome_disabled__3iAIE {
  opacity: 0.5;
  background-color: #ebebeb;
  border-color: #dcdcdc;
}
.Welcome_description__6loSI.Welcome_disabled__3iAIE::-webkit-input-placeholder {
  color: #8b8b8d;
}
.Welcome_description__6loSI.Welcome_disabled__3iAIE::-ms-input-placeholder {
  color: #8b8b8d;
}
.Welcome_description__6loSI.Welcome_disabled__3iAIE::placeholder {
  color: #8b8b8d;
}
.Welcome_description__6loSI.Welcome_fakeTextarea__SCwnF {
  height: auto;
}
.Welcome_numberInput__BQaaL {
  width: 50px;
  font-family: 'Open Sans Regular';
  font-size: 13px;
  outline: none;
  background-color: #ffffff;
  color: #000000;
  height: 25px;
  line-height: 25px;
  margin-bottom: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: right;
}
.Welcome_notesField__2UkbC {
}
.Welcome_notesTitleContainer__1KltC {
  position: absolute;
  top: -20px;
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.Welcome_notesTitleContainer__1KltC .Welcome_title__3sCiv {
  font-size: 14px;
}
.Welcome_arrowExpanded__28v4S {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.Welcome_arrowCollapsed__3zItH {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.Welcome_imageContainer__3TbPs {
  display: inline-block;
  margin-bottom: 20px;
}
.Welcome_imageContainer__3TbPs .Welcome_removeImageContainer__3cHbv {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  background-color: #5200f2;
  border-radius: 50%;
  cursor: pointer;
}
.Welcome_imageContainer__3TbPs .Welcome_removeImageContainer__3cHbv img {
  width: 26px;
  height: 15px;
  position: absolute;
  left: -5px;
  top: 0px;
}
.Welcome_imageContainer__3TbPs .Welcome_coverImage__gGG9- {
  max-height: 100px;
  max-width: 300px;
}
.Welcome_dropContainerTitle__2Oiwp {
  font-size: 12px;
  margin-bottom: 15px;
}
.Welcome_dropContainer__19tdA {
  height: 115px;
  border: 1px dashed #e8e8e8;
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 20px;
}
.Welcome_dropContainer__19tdA .Welcome_button__14NxQ {
  width: 180px;
  text-align: center;
  border: 1px solid #e8e8e8;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.Welcome_dropContainer__19tdA .Welcome_dropDescription__yCuPf {
  text-align: center;
}
.Welcome_wordCount__3Mi1Z {
  font-size: 12px;
  font-family: 'Open Sans Light';
  text-transform: uppercase;
  color: #bbb;
  padding: 4px 20px 10px 20px;
}
.Welcome_translationOfOtherSurvey__2Yd1A {
  font-size: 11px;
  text-align: right;
  margin-bottom: 10px;
}
textarea::-webkit-input-placeholder {
  color: #bebebe;
}
textarea::-ms-input-placeholder {
  color: #bebebe;
}
textarea::placeholder {
  color: #bebebe;
}
.Welcome_rowText__176ja {
  margin-bottom: 10px;
  font-size: 12px;
  color: #8b8b8d;
}
.Welcome_requiredAsterisk__C8rGF {
  margin-left: 0;
  color: #be0000;
}
.Welcome_welcomeFilterDropdownStyle__2dFCC:has(div) {
  border: 0;
  width: 100%;
  height: 34px;
  line-height: 34px;
}
.Welcome_welcomeFilterDropdownStyle__2dFCC {
  position: relative;
  height: 30px;
  line-height: 30px;
  margin-right: 0;
  max-width: 100%;
}
.Welcome_welcomeFilterDropdownStyle__2dFCC > div:first-child {
  border: 1px solid #efefef;
  font-size: 12px;
  color: #000000;
  height: 34px;
  line-height: 34px;
}
.Welcome_welcomeFilterDropdownStyle__2dFCC img {
  position: absolute;
  right: 17px;
  top: 15px;
  opacity: 0.5;
  width: 12px;
  height: 7px;
}
.Welcome_moreInfoContainer__1ylYM {
  display: inline-block;
  margin-left: 5px;
}
.Welcome_moreInfoContainer__1ylYM .Welcome_questionMark__1pand {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.Welcome_moreInfoContainer__1ylYM .Welcome_tooltip__1JNFr {
  display: none;
  position: absolute;
  top: -20px;
  left: 25px;
  z-index: 100;
  font-size: 12px;
  width: 640px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px 20px;
  color: #000000;
  border-radius: 5px;
  line-height: 18px;
  white-space: pre-wrap;
  border: 1px solid #e3e3e4;
}
.Welcome_moreInfoContainer__1ylYM:hover .Welcome_tooltip__1JNFr {
  display: block;
}
.Welcome_moreInfoContainer__1ylYM .Welcome_tooltip__1JNFr ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.Welcome_checkboxContainer__ZU6VP {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Welcome_checkboxContainer__ZU6VP img {
  position: relative;
  top: 1px;
}
.Welcome_checkboxContainer__ZU6VP .Welcome_checkboxText__2cQil {
  display: inline-block;
  margin-left: 8px;
  font-size: 12px;
  color: #8b8b8d;
  vertical-align: top;
}

.Properties_container__2ynBJ {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.Properties_titleContainer__3S4Pd {
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.Properties_titleContainer__3S4Pd .Properties_title__O_bEd {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.Properties_propertiesContentContainer__2mJj6 {
  border-top: 1px solid #eeeeee;
}
.Properties_singleProperty__37Wkw {
  padding: 10px 0;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.Properties_singleProperty__37Wkw.Properties_askForEmailAddressInWebSurveyProperty__pPQrQ {
  border-bottom: none;
}
.Properties_singleProperty__37Wkw .Properties_propertyTitle__5emCX {
  margin-right: 15px;
}
.Properties_singleProperty__37Wkw:last-child {
  border-bottom: 0;
}
.Properties_switch__xd0nd {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  width: 42px;
  height: 24px;
  vertical-align: middle;
}
.Properties_switch__xd0nd .slider:before {
  height: 13px;
  width: 13px;
  height: 18px;
  width: 18px;
}
.Properties_arrowExpanded__la1Bw {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.Properties_arrowCollapsed__1RhXn {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.Properties_languageSelector__3KRSt {
  display: inline-block;
  color: #000;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 12px;
  margin-right: 5px;
  cursor: pointer;
}
.Properties_languageSelectorActive__2cS1k {
  color: #fff;
  background-color: #5200f1;
}
.Properties_colorCodesContainer__1eS_o {
  display: flex;
  margin: 0 20px 0 20px;
  padding: 10px 0 10px 0;
  flex: 1 1;
  border-bottom: 1px solid #eeeeee;
}
.Properties_customImageUploadContainer__2lyCz {
  padding: 10px 20px 0 20px;
}
.Properties_customImageUploadContainer__2lyCz .Properties_imageContainer__2x6Sp {
  display: inline-block;
  margin-bottom: 20px;
}
.Properties_customImageUploadContainer__2lyCz .Properties_imageContainer__2x6Sp .Properties_removeImage__3e0uq {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  background-color: #5200f2;
  border-radius: 50%;
  cursor: pointer;
}
.Properties_customImageUploadContainer__2lyCz .Properties_imageContainer__2x6Sp .Properties_removeImage__3e0uq img {
  width: 26px;
  height: 15px;
  position: absolute;
  left: -5px;
  top: 0px;
}
.Properties_customImageUploadContainer__2lyCz .Properties_imageContainer__2x6Sp .Properties_selectedImage__3KHBz {
  max-height: 120px;
  max-width: 240px;
  padding: 15px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
}
.Properties_dropContainer__1C2og {
  height: 115px;
  border: 1px dashed #e8e8e8;
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 20px;
}
.Properties_dropContainer__1C2og .Properties_dropButton__NML3u {
  width: 180px;
  text-align: center;
  border: 1px solid #e8e8e8;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.Properties_dropContainer__1C2og .Properties_dropDescription__37Bmj {
  text-align: center;
}
.Properties_moreInfoContainer__1OE7p {
  display: inline-block;
  margin-left: 5px;
}
.Properties_moreInfoContainer__1OE7p .Properties_questionMark__1_X5B {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.Properties_moreInfoContainer__1OE7p .Properties_tooltip__3sPah {
  display: none;
  position: absolute;
  top: -20px;
  left: 25px;
  z-index: 100;
  font-size: 12px;
  width: 640px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px 20px;
  color: #000000;
  border-radius: 5px;
  line-height: 18px;
  white-space: pre-wrap;
  border: 1px solid #e3e3e4;
}
.Properties_moreInfoContainer__1OE7p:hover .Properties_tooltip__3sPah {
  display: block;
}
.Properties_moreInfoContainer__1OE7p .Properties_tooltip__3sPah ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Dropdown_container__GEFzJ {
  position: relative;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  color: #000;
}
.Dropdown_container__GEFzJ .Dropdown_selectedValue__3UyrA {
  border-radius: 5px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  height: 34px;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 14px 6px;
  cursor: pointer;
  font-size: 12px;
  box-sizing: border-box;
}
.Dropdown_container__GEFzJ .Dropdown_selectedValue__3UyrA.Dropdown_disabled__LSwIX {
  background-color: #ebebeb;
  border-color: #dcdcdc;
  opacity: 0.7;
  cursor: default;
}
.Dropdown_container__GEFzJ .Dropdown_selectedValue__3UyrA .Dropdown_placeholder__2FWMK {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #bebebe;
}
.Dropdown_container__GEFzJ .Dropdown_selectedValue__3UyrA .Dropdown_selectedValueLoader__36bRs {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.Dropdown_container__GEFzJ .Dropdown_popupContainer__1LxuH {
  font-size: 12px;
  background-color: #ffffff;
  position: absolute;
  top: 38px;
  width: 100%;
  border: 1px solid #efefef;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
  box-sizing: border-box;
}
.Dropdown_container__GEFzJ .Dropdown_popupContainer__1LxuH .Dropdown_searchInput__7KMI8 {
  border-bottom: 1px solid #efefef;
  display: flex;
}
.Dropdown_container__GEFzJ .Dropdown_popupContainer__1LxuH .Dropdown_searchInput__7KMI8 input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 20px);
  outline: none;
  color: #000;
  border-radius: 5px;
}
.Dropdown_container__GEFzJ .Dropdown_popupContainer__1LxuH .Dropdown_searchInput__7KMI8 input::-webkit-input-placeholder {
  color: #bebebe;
}
.Dropdown_container__GEFzJ .Dropdown_popupContainer__1LxuH .Dropdown_searchInput__7KMI8 input::-ms-input-placeholder {
  color: #bebebe;
}
.Dropdown_container__GEFzJ .Dropdown_popupContainer__1LxuH .Dropdown_searchInput__7KMI8 input::placeholder {
  color: #bebebe;
}
.Dropdown_container__GEFzJ .Dropdown_popupContainer__1LxuH .Dropdown_searchInput__7KMI8 .Dropdown_inputLoaderContainer__3UQph {
  position: relative;
  top: -10px;
}
.Dropdown_container__GEFzJ .Dropdown_popupContainer__1LxuH .Dropdown_searchInput__7KMI8 .Dropdown_addItemIcon__VxnOP {
  position: relative;
  top: 0px;
  width: 11px;
  right: 10px;
  cursor: pointer;
}
.Dropdown_container__GEFzJ .Dropdown_popupContainer__1LxuH .Dropdown_popupItemsContainer__1YZmj {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px 0;
  box-sizing: border-box;
}
.Dropdown_popupItemsContainer__1YZmj .Dropdown_popupItem__1y3n- {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding-left: 10px;
}
.Dropdown_popupItemsContainer__1YZmj .Dropdown_popupItem__1y3n- .Dropdown_itemLabel__1_Eqi {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.Dropdown_popupItemsContainer__1YZmj .Dropdown_popupItem__1y3n- .Dropdown_checkboxSelected__2gcI7,
.Dropdown_popupItemsContainer__1YZmj .Dropdown_popupItem__1y3n- .Dropdown_checkbox__2Tk_x {
  width: 13px;
  height: 21px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
}
.Dropdown_popupItemsContainer__1YZmj .Dropdown_popupItem__1y3n- .Dropdown_checkboxSelected__2gcI7 {
  color: #5200f1;
}
.Dropdown_popupItemsContainer__1YZmj .Dropdown_popupItem__1y3n- .Dropdown_checkbox__2Tk_x {
  color: #b5b5b8;
}
.Dropdown_popupItemsContainer__1YZmj .Dropdown_noItems__ZRbEp {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  height: 34px;
  line-height: 34px;
  font-size: 12px;
  color: #8b8b8d;
}
.Dropdown_popupItemsContainer__1YZmj::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.Dropdown_popupItemsContainer__1YZmj::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.Dropdown_popupItemsContainer__1YZmj .Dropdown_loaderContainer__145yo {
  position: relative;
  top: -5px;
  left: 10px;
  display: inline-block;
}

.Dropdown_container__2lrtp {
  position: relative;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  color: #000;
}
.Dropdown_container__2lrtp .Dropdown_selectedValue__32LiD {
  border-radius: 5px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  height: 34px;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 14px 6px;
  cursor: pointer;
  font-size: 12px;
  box-sizing: border-box;
}
.Dropdown_container__2lrtp .Dropdown_selectedValue__32LiD.Dropdown_disabled__tLlWx {
  background-color: #ebebeb;
  border-color: #dcdcdc;
  opacity: 0.7;
  cursor: default;
}
.Dropdown_container__2lrtp .Dropdown_selectedValue__32LiD .Dropdown_placeholder__3Wlbp {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #bebebe;
}
.Dropdown_container__2lrtp .Dropdown_popupContainer__2CjWc {
  font-size: 12px;
  background-color: #ffffff;
  position: absolute;
  top: 38px;
  width: 100%;
  border: 1px solid #efefef;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
  box-sizing: border-box;
}
.Dropdown_container__2lrtp .Dropdown_popupContainer__2CjWc .Dropdown_searchInput__3t5V1 {
  border-bottom: 1px solid #efefef;
  display: flex;
}
.Dropdown_container__2lrtp .Dropdown_popupContainer__2CjWc .Dropdown_searchInput__3t5V1 input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 20px);
  outline: none;
  color: #000;
  border-radius: 5px;
}
.Dropdown_container__2lrtp .Dropdown_popupContainer__2CjWc .Dropdown_searchInput__3t5V1 input::-webkit-input-placeholder {
  color: #bebebe;
}
.Dropdown_container__2lrtp .Dropdown_popupContainer__2CjWc .Dropdown_searchInput__3t5V1 input::-ms-input-placeholder {
  color: #bebebe;
}
.Dropdown_container__2lrtp .Dropdown_popupContainer__2CjWc .Dropdown_searchInput__3t5V1 input::placeholder {
  color: #bebebe;
}
.Dropdown_container__2lrtp .Dropdown_inputLoaderContainer__3ZcA2 {
  position: relative;
  top: -10px;
}
.Dropdown_container__2lrtp .Dropdown_popupContainer__2CjWc .Dropdown_searchInput__3t5V1 .Dropdown_addItemIcon__3XxIc {
  position: relative;
  top: 0px;
  width: 11px;
  right: 10px;
  cursor: pointer;
}
.Dropdown_container__2lrtp .Dropdown_popupContainer__2CjWc .Dropdown_popupItemsContainer__2TgUs {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px 0;
  box-sizing: border-box;
}
.Dropdown_popupItemsContainer__2TgUs .Dropdown_popupItem__1XaB- {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding-left: 10px;
}
.Dropdown_popupItemsContainer__2TgUs .Dropdown_popupItem__1XaB- .Dropdown_itemLabel__ie5wS {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.Dropdown_popupItemsContainer__2TgUs .Dropdown_popupItem__1XaB- .Dropdown_checkboxSelected__1GQT9,
.Dropdown_popupItemsContainer__2TgUs .Dropdown_popupItem__1XaB- .Dropdown_checkbox__2Bi2s {
  width: 13px;
  height: 21px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
}
.Dropdown_popupItemsContainer__2TgUs .Dropdown_popupItem__1XaB- .Dropdown_checkboxSelected__1GQT9 {
  color: #5200f1;
}
.Dropdown_popupItemsContainer__2TgUs .Dropdown_popupItem__1XaB- .Dropdown_checkbox__2Bi2s {
  color: #b5b5b8;
}
.Dropdown_popupItemsContainer__2TgUs .Dropdown_noItems__Eigi9 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  height: 34px;
  line-height: 34px;
  font-size: 12px;
  color: #8b8b8d;
}
.Dropdown_popupItemsContainer__2TgUs::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.Dropdown_popupItemsContainer__2TgUs::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.Dropdown_popupItemsContainer__2TgUs .Dropdown_loaderContainer__EKlQi {
  position: relative;
  top: -5px;
  left: 10px;
  display: inline-block;
}

.WebSurveyRedirections_targetAudienceTabContainer__1QIew {
  margin-bottom: 10px;
}
.WebSurveyRedirections_targetAudienceTabContainer__1QIew .WebSurveyRedirections_container__bAvfL {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}
.WebSurveyRedirections_targetAudienceTabContainer__1QIew .WebSurveyRedirections_container__bAvfL .WebSurveyRedirections_containerTitle__3WnRJ {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.WebSurveyRedirections_targetAudienceTabContainer__1QIew .WebSurveyRedirections_container__bAvfL .WebSurveyRedirections_blockContainer__YRevR {
  padding: 15px 20px 15px 20px;
  font-size: 14px;
}
.WebSurveyRedirections_redirectionTypeRowContainer__1Qzd9 {
  display: flex;
}
.WebSurveyRedirections_redirectionTypeRowContainer__1Qzd9 .WebSurveyRedirections_label__1JZ71 {
  width: 30%;
}
.WebSurveyRedirections_redirectionTypeRowContainer__1Qzd9 .WebSurveyRedirections_inputContainer__1sczv {
  width: 70%;
}
.WebSurveyRedirections_redirectionTypeRowContainer__1Qzd9 .WebSurveyRedirections_inputContainer__1sczv input {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  outline: none;
  display: block;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-top: 8px;
  margin-bottom: 6px;
  overflow: hidden;
  resize: none;
}

input::-webkit-input-placeholder {
  color: #bebebe;
}

input::-ms-input-placeholder {
  color: #bebebe;
}

input::placeholder {
  color: #bebebe;
}
.WebSurveyRedirections_inputContainer__1sczv {
  display: flex;
  border: 1px solid #dfe0e4;
  border-radius: 7px;
  height: 30px;
  margin-bottom: 10px;
}
.WebSurveyRedirections_inputContainer__1sczv .WebSurveyRedirections_link__2_B8H {
  padding-left: 10px;
  height: 30px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 30px;
  font-size: 12px;
  color: #8b8b8d;
  display: flex;
  flex-direction: row;
}
.WebSurveyRedirections_inputContainer__1sczv .WebSurveyRedirections_link__2_B8H .WebSurveyRedirections_websiteIcon__29wqW {
  height: 12px;
  margin-right: 10px;
  margin-top: 8px;
}
.WebSurveyRedirections_inputContainer__1sczv .WebSurveyRedirections_urlInput__1BbPo {
  border: 0;
  flex: 1 1;
  padding-right: 20px;
  outline: none;
  font-size: inherit;
}
.WebSurveyRedirections_inputContainer__1sczv .WebSurveyRedirections_copyLinkButton__2xpFt {
  background-color: #5200f2;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  width: 10%;
  border-radius: 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 32%;
  background-position: center center;
  margin: 3px;
  font-size: 12px;
  text-align: center;
}
.WebSurveyRedirections_inputContainer__1sczv .WebSurveyRedirections_copyLinkButton__2xpFt.WebSurveyRedirections_copied__3IaM8 {
  background-color: #95989a;
}
.WebSurveyRedirections_arrowExpanded__1Fw-V {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.WebSurveyRedirections_arrowCollapsed__zX5RP {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  cursor: pointer;
}

.CustomCopyField_description__1Vfj8 {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  border: 0;
  outline: none;
  display: block;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  height: 25px;
  line-height: 25px;
  margin-bottom: 5px;
  overflow: hidden;
  resize: none;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}

.WebSurveyCustomCopy_container__1VFBX {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.WebSurveyCustomCopy_titleContainer__VdmSG {
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.WebSurveyCustomCopy_titleContainer__VdmSG .WebSurveyCustomCopy_title__3ThNN {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.WebSurveyCustomCopy_propertiesContentContainer__r1HoA {
  border-top: 1px solid #eeeeee;
}
.WebSurveyCustomCopy_blockPropertyContainer__16eaS {
  border-bottom: 1px solid #eeeeee;
}
.WebSurveyCustomCopy_singleProperty__2YwOX {
  padding: 10px 0;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.WebSurveyCustomCopy_singleProperty__2YwOX .WebSurveyCustomCopy_propertyTitle__27rFc {
  margin-right: 15px;
}
.WebSurveyCustomCopy_singleProperty__2YwOX:last-child {
  border-bottom: 0;
}
.WebSurveyCustomCopy_switch__2Xsgs {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  width: 42px;
  height: 24px;
  vertical-align: middle;
}
.WebSurveyCustomCopy_switch__2Xsgs .slider:before {
  height: 13px;
  width: 13px;
  height: 18px;
  width: 18px;
}
.WebSurveyCustomCopy_arrowExpanded__HvyaJ {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.WebSurveyCustomCopy_arrowCollapsed__2aA-P {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.WebSurveyCustomCopy_propertyFields__UOvJx {
  padding-bottom: 10px;
  margin: 0 20px;
  font-size: 12px;
  color: #8b8b8d;
  line-height: 30px;
}
.WebSurveyCustomCopy_propertyFields__UOvJx .WebSurveyCustomCopy_propertyInputField__1DjDa {
  height: 30px;
  font-family: 'Open Sans Regular';
  font-size: 12px;
  border: 0;
  outline: none;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  margin-bottom: 5px;
}

.Conversion_container__yeLml {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.Conversion_titleContainer__1MMtb {
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.Conversion_titleContainer__1MMtb .Conversion_title__3rYTE {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.Conversion_propertiesContentContainer__1yaCf {
  border-top: 1px solid #eeeeee;
}
.Conversion_singleProperty__1iSLU {
  padding: 10px 0;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.Conversion_singleProperty__1iSLU.Conversion_askForEmailAddressInWebSurveyProperty__2xZfA {
  border-bottom: none;
}
.Conversion_singleProperty__1iSLU .Conversion_propertyTitle__I2_cE {
  margin-right: 15px;
}
.Conversion_singleProperty__1iSLU:last-child {
  border-bottom: 0;
}
.Conversion_switch__2aG55 {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  width: 42px;
  height: 24px;
  vertical-align: middle;
}
.Conversion_switch__2aG55 .slider:before {
  height: 13px;
  width: 13px;
  height: 18px;
  width: 18px;
}
.Conversion_arrowExpanded__2G1-_ {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.Conversion_arrowCollapsed__eEeJA {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.Conversion_languageSelector__1_WMF {
  display: inline-block;
  color: #000;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0 12px;
  margin-right: 5px;
  cursor: pointer;
}
.Conversion_languageSelectorActive__3WTAK {
  color: #fff;
  background-color: #5200f1;
}
.Conversion_colorCodesContainer__2HmXL {
  display: flex;
  margin: 0 20px 0 20px;
  padding: 10px 0 10px 0;
  flex: 1 1;
  border-bottom: 1px solid #eeeeee;
}
.Conversion_customImageUploadContainer__1ggFd {
  padding: 10px 20px 0 20px;
}
.Conversion_customImageUploadContainer__1ggFd .Conversion_imageContainer__3OMdU {
  display: inline-block;
  margin-bottom: 20px;
}
.Conversion_customImageUploadContainer__1ggFd .Conversion_imageContainer__3OMdU .Conversion_removeImage__2BMXj {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  background-color: #5200f2;
  border-radius: 50%;
  cursor: pointer;
}
.Conversion_customImageUploadContainer__1ggFd .Conversion_imageContainer__3OMdU .Conversion_removeImage__2BMXj img {
  width: 26px;
  height: 15px;
  position: absolute;
  left: -5px;
  top: 0px;
}
.Conversion_customImageUploadContainer__1ggFd .Conversion_imageContainer__3OMdU .Conversion_selectedImage__3hhti {
  max-height: 120px;
  max-width: 240px;
  padding: 15px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
}
.Conversion_dropContainer__2e0vt {
  height: 115px;
  border: 1px dashed #e8e8e8;
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 20px;
}
.Conversion_dropContainer__2e0vt .Conversion_dropButton__1Eckn {
  width: 180px;
  text-align: center;
  border: 1px solid #e8e8e8;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.Conversion_dropContainer__2e0vt .Conversion_dropDescription__2nMOq {
  text-align: center;
}

.Conversion_content__2viRs {
  padding: 10px 20px;
  border-top: 1px solid #eeeeee;
  font-size: 12px;
  color: #8b8b8d;
}
.Conversion_linkContainer__3_H6I {
  display: flex;
  justify-content: space-between;
  border: 1px solid #eeeeee;
  border-radius: 7px;
}
.Conversion_linkContainer__3_H6I .Conversion_link__2H77J {
  display: flex;
  flex-direction: row;
  height: 30px;
  line-height: 30px;
  width: 90%;
  padding-left: 10px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
}
.Conversion_linkContainer__3_H6I .Conversion_url__7hxrn {
  border: 0;
  flex: 1 1;
  padding-right: 20px;
  outline: none;
}
.Conversion_linkContainer__3_H6I .Conversion_copyLinkButton__1jdtI {
  background-color: #5200f2;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  width: 10%;
  border-radius: 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 32%;
  background-position: center center;
  margin: 3px;
  font-size: 12px;
  text-align: center;
}
.Conversion_linkContainer__3_H6I .Conversion_copyLinkButton__1jdtI.Conversion_copied__3R7n7 {
  background-color: #95989a;
}

.Conversion_row__2fh7p {
  margin: 10px 0;
}
.Conversion_row__2fh7p.Conversion_topBorder__9buNx {
  border-top: 1px solid #e7e7e7;
  margin-top: 15px;
  padding-top: 15px;
  position: relative;
}
.Conversion_row__2fh7p.Conversion_flexRow__3rqSJ {
  display: flex;
}
.Conversion_flexRow__3rqSJ .Conversion_flexColumn__2FUgR {
  width: 50%;
  margin-right: 5px;
}
.Conversion_row__2fh7p .Conversion_rowText__UoLC9 {
  margin-bottom: 10px;
}
.Conversion_thankYouScreenConversionMessageError__6S1A0 {
  color: #ef6c6c;
}

.ColorPicker_container__4Mx-h {
  width: 110px;
  padding: 2px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  margin-right: 6px;
  display: flex;
  cursor: pointer;
}
.ColorPicker_colorRectangle__1ExcU {
  width: 30px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.ColorPicker_colorCode___Mosy {
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.ColorPicker_pickerContainer__S9QFs {
  position: absolute;
  z-index: 99999;
}
.ColorPicker_fixedPickerContainer__L_aTL {
  position: fixed;
  z-index: 99999;
}
.ColorPicker_transparentPageConver__1LLxr {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99998;
  width: 100%;
  height: 100%;
}

.SurveySelector_container__3Kwpi {
  margin-top: 15px;
}
.SurveySelector_description__26jhJ {
  margin-bottom: 5px;
}
.SurveySelector_warningText__1occe {
  font-style: italic;
  color: #f04d53;
  margin-top: 5px;
  font-size: 12px;
}

.ConfirmationPopup_container__oTblK {
  padding: 15px;
  width: 400px;
  font-size: 13px;
}
.ConfirmationPopup_title__2SqHy {
  font-family: Open Sans SemiBold;
  color: #5200f1;
}
.ConfirmationPopup_description__2REtp {
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: break-spaces;
}
.ConfirmationPopup_warning__34c8v {
  padding-top: 15px;
  padding-bottom: 15px;
  font-style: italic;
  color: #f04d53;
}
.ConfirmationPopup_questionsContainer__2vWHd {
  padding-top: 25px;
  padding-bottom: 5px;
  max-height: 200px;
  overflow: auto;
  border-bottom: 1px solid #e6e6e6;
}
.ConfirmationPopup_question__3IO0D {
  display: flex;
  margin-bottom: 15px;
}
.ConfirmationPopup_question__3IO0D .ConfirmationPopup_selectorContainer__1hVPC {
  width: 5%;
  text-align: center;
  color: #5200f1;
  font-size: 12px;
  position: relative;
  top: 2px;
}
.ConfirmationPopup_question__3IO0D .ConfirmationPopup_selectorContainer__1hVPC .ConfirmationPopup_selectorIcon__A-jaO {
  cursor: pointer;
}
.ConfirmationPopup_question__3IO0D .ConfirmationPopup_number__2uxyJ {
  width: 5%;
  text-align: center;
}
.ConfirmationPopup_question__3IO0D .ConfirmationPopup_iconContainer__ORd89 {
  width: 5%;
  text-align: center;
}
.ConfirmationPopup_question__3IO0D .ConfirmationPopup_iconContainer__ORd89 img {
  height: 12px;
}
.ConfirmationPopup_question__3IO0D .ConfirmationPopup_questionTextContainer__78i55 {
  width: 85%;
}
.ConfirmationPopup_footer__yNAfe {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.ConfirmationPopup_footer__yNAfe .ConfirmationPopup_details__ppUmF {
  color: #5200f1;
}
.ConfirmationPopup_actions__LQKDZ .ConfirmationPopup_confirm__3p3I4 {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.ConfirmationPopup_actions__LQKDZ .ConfirmationPopup_close__vdu_- {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.ConfirmationPopup_selectAllContainerWrapper__3PcwT {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
}
.ConfirmationPopup_selectAllContainer__Dans3 {
  display: flex;
}
.ConfirmationPopup_selectAllContainer__Dans3 .ConfirmationPopup_selectorContainer__1hVPC {
  width: 5%;
  text-align: center;
  font-size: 12px;
  position: relative;
  top: 2px;
  margin-right: 10px;
}
.ConfirmationPopup_selectAllContainer__Dans3 .ConfirmationPopup_selectorContainer__1hVPC span {
  cursor: pointer;
}
.ConfirmationPopup_deletePopulation__2hEgl {
  color: #fc035d;
  cursor: pointer;
}
.ConfirmationPopup_deletePopulation__2hEgl .ConfirmationPopup_deletePopulationIcon__2x0wL {
  height: 12px;
  margin-right: 7px;
}

.DropdownSelector_filterDropdownSelectorContainer__y98cs {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 290px;
  margin-right: 10px;
}
.DropdownSelector_selectedOptionsLabel__2Hd4C {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 30px;
  color: #9e9e9e;
}
.DropdownSelector_selectedOptionsLabel__2Hd4C.DropdownSelector_valueSelected__uEz8i {
  color: #000000;
}
.DropdownSelector_dropdownDisabled__215P7 {
  background-color: #f9f9f9 !important;
  color: #8b8b8d !important;
}
.DropdownSelector_filterDropdownPopup__1cQA3 {
  font-size: 11px;
  background-color: #ffffff;
  position: fixed;
  top: 31px;
  width: 300px;
  border: 1px solid #dfe0e4;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.DropdownSelector_filterDropdownItem__2wwx8 {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.DropdownSelector_filterDropdownItem__2wwx8.DropdownSelector_active__3HH0H {
  background-color: #fafafa;
}
.DropdownSelector_filterDropdownItemCheckboxSelected__28TDt {
  height: 21px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownSelector_filterDropdownItemCheckbox__1EnyL {
  height: 21px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownSelector_filterDropdownItemLabel__1zajQ {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.DropdownSelector_noAvailableClientAccounts__2jgai {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__34XoL {
  display: flex;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__34XoL input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 40px);
  outline: none;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__34XoL .DropdownSelector_inputLoaderContainer__1G2uP > div {
  position: relative;
  top: -10px;
}
.DropdownSelector_filterDropdownPopupItemsContainer__1lx2t {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.DropdownSelector_filterDropdownPopupItemsContainer__1lx2t::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.DropdownSelector_filterDropdownPopupItemsContainer__1lx2t::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.DropdownSelector_filterDropdownPopupFooterContainer__2Oz6O {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.DropdownSelector_filterDropdownPopupConfirm__1Q_9- {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}
.DropdownSelector_loaderContainer__2oCPM {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 20px;
}
.DropdownSelector_addItemIcon__1ZOCh {
  position: relative;
  top: 0px;
  width: 11px;
  left: 0px;
  cursor: pointer;
}

.SearchDropdown_filterItemContainer__3efz6 {
  display: flex;
}
.SearchDropdown_filterItemContainer__3efz6 .SearchDropdown_filterType__2tvoY {
  display: flex;
}
.SearchDropdown_filterItemContainer__3efz6 .SearchDropdown_filterType__2tvoY .SearchDropdown_closeIcon__3YIkk {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.SearchDropdown_filterItemContainer__3efz6 .SearchDropdown_filterType__2tvoY {
  display: flex;
  width: 220px;
  margin-right: 5px;
}
.SearchDropdown_filterItemContainer__3efz6 .SearchDropdown_filterType__2tvoY .SearchDropdown_closeIcon__3YIkk {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}

.QuestionChoiceTagging_container__gGZPV {
  border-top: 1px solid #f3f3f3;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 10px;
  padding-bottom: 15px;
}
.QuestionChoiceTagging_selectorsContainer__3LKfI {
  display: flex;
}
.QuestionChoiceTagging_answer__LWuH5 {
  margin-bottom: 10px;
}

.QuestionChoicesTagging_container__3ZrAE {
  width: 700px;
  font-size: 13px;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.QuestionChoicesTagging_top__3jU17 {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.QuestionChoicesTagging_question__1ZNWG {
  font-size: 15px;
  margin-bottom: 5px;
  white-space: pre-line;
}
.QuestionChoicesTagging_questionDescription__3-FS1 {
  font-style: italic;
  margin-bottom: 10px;
  padding-left: 15px;
  color: #bebebe;
}
.QuestionChoicesTagging_footer__1qk2G {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-right: 10px;
}
.QuestionChoicesTagging_footer__1qk2G .QuestionChoicesTagging_details__20ff2 {
  color: #5200f1;
}
.QuestionChoicesTagging_actions__3Ve-Q .QuestionChoicesTagging_confirm__orlkH {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.QuestionChoicesTagging_actions__3Ve-Q .QuestionChoicesTagging_close__2tIo7 {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.ResearchCostIncreased_container__3Q22T {
  padding: 15px;
  width: 400px;
  font-size: 13px;
}
.ResearchCostIncreased_title__1erCz {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
}
.ResearchCostIncreased_description__2oZrK {
  padding-top: 15px;
  padding-bottom: 15px;
}
.ResearchCostIncreased_footer__31cPD {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid #e6e6e6;
}
.ResearchCostIncreased_actions__3T42g .ResearchCostIncreased_confirm__2cnWA {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.ResearchCostIncreased_actions__3T42g .ResearchCostIncreased_close__gH9Uy {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.ExtraQuestions_container__GDjwc {
  padding: 15px;
  padding-bottom: 10px;
  width: 400px;
  font-size: 13px;
}
.ExtraQuestions_description__3Tikr {
  padding-bottom: 15px;
}
.ExtraQuestions_footer__29jmZ {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}
.ExtraQuestions_actions__1f7Kv .ExtraQuestions_confirm__15UIl {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.ExtraQuestions_actions__1f7Kv .ExtraQuestions_close__35my1 {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.MatrixQuestionEditScale_container__1FZc1 {
  width: 700px;
  font-size: 13px;
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.MatrixQuestionEditScale_top__2ABps {
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.MatrixQuestionEditScale_title__33qjf {
  font-size: 15px;
  margin-bottom: 5px;
  white-space: pre-line;
  font-family: 'Open Sans SemiBold';
  color: #5200f5;
  padding-bottom: 10px;
}
.MatrixQuestionEditScale_axisScaleFieldContainers__3_gP0 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.MatrixQuestionEditScale_footer__2XANp {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-right: 10px;
}
.MatrixQuestionEditScale_footer__2XANp .MatrixQuestionEditScale_details__1E37J {
  color: #5200f1;
}
.MatrixQuestionEditScale_actions__D4cf9 .MatrixQuestionEditScale_confirm__SMYhV {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.MatrixQuestionEditScale_actions__D4cf9 .MatrixQuestionEditScale_close__3J6Ir {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.AxisScaleFields_container__14bTr {
  width: 320px;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 10px;
  padding-bottom: 15px;
}
.AxisScaleFields_title__1GPXe {
  margin-bottom: 10px;
}
.AxisScaleFields_fieldRow__2I1er {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.AxisScaleFields_fieldRowNumber__2CtDW {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  top: 5px;
}
.AxisScaleFields_field__2let6 {
  border-radius: 5px;
  border: 1px solid #efefef;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
}

.AddQuestionList_container__3sEhv {
  position: fixed;
  top: 170px;
  left: 50%;
  margin-left: 545px;
  z-index: 9;
  width: calc(50vw - 560px);
}
.AddQuestionList_container__3sEhv.AddQuestionList_expanded__cVf5k {
  width: 240px;
  height: calc(100vh - 66px);
  top: 66px;
  background-color: #ffffff;
  position: fixed;
  right: 15px;
  left: auto;
  margin-left: 0;
  padding-left: 30px;
  padding-top: 105px;
  padding-bottom: 5px;
  box-shadow: -5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  z-index: 10000;
}
.AddQuestionList_draggableQuestionsContainer__Rtq_- {
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 175px);
}
.AddQuestionList_draggableQuestionPlaceholder__eQrvG {
  width: 200px;
  height: 50px;
  margin-bottom: 7px;
}
.AddQuestionList_question__tIYDu {
  width: 200px;
  height: 50px;
  line-height: 50px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 0 15px;
  font-family: 'Open Sans Regular';
  font-size: 11px;
  text-transform: uppercase;
  color: #8b8b8d;
  cursor: pointer;
}
.AddQuestionList_icon__2oyVH {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 15px;
}
.AddQuestionList_expandButton__pDbYL {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -15px;
  left: -44px;
  cursor: pointer;
  z-index: 9;
}
.AddQuestionList_expandButton__pDbYL .AddQuestionList_icon__2oyVH {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 5px;
  margin-right: 0;
}
.AddQuestionList_expandButton__pDbYL .AddQuestionList_label__14UWi {
  font-family: 'Open Sans SemiBold';
  color: #909090;
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 12px;
  padding: 5px;
  background-color: #ffffff;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.AddQuestionList_container__3sEhv.AddQuestionList_expanded__cVf5k .AddQuestionList_expandButton__pDbYL {
  top: 80px;
  left: -15px;
}
.AddQuestionList_container__3sEhv.AddQuestionList_expanded__cVf5k .AddQuestionList_expandButton__pDbYL .AddQuestionList_icon__2oyVH {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.AddQuestionList_sectionTitle__1v2Ow {
  width: 230px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #909090;
  height: 20px;
  top: 5px;
}
.AddQuestionList_sectionTitle__1v2Ow span {
  display: inline-block;
  padding: 10px;
  background-color: #ffffff;
  font-family: 'Open Sans Regular';
  color: #909090;
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
  top: -20px;
}
.AddQuestionList_sectionTitle__1v2Ow.AddQuestionList_sectionTitleMargin__1mi7E {
  margin-top: 25px;
}
.AddQuestionList_distributorIcon__21If8 {
  width: 13px;
}

.AddQuestionList_expandButtonResolutionCheck__2mpKi {
  display: none;
}

@media only screen and (max-width: 1450px) {
  .AddQuestionList_expandButtonResolutionCheck__2mpKi {
    display: flex;
  }
}

@media only screen and (max-width: 1070px) {
  .AddQuestionList_container__3sEhv {
    left: auto;
    margin-left: 0;
    right: 0;
  }
  .AddQuestionList_expandButton__pDbYL {
    left: -30px;
  }
}

@media only screen and (max-height: 770px) {
  .AddQuestionList_draggableQuestionsContainer__Rtq_- {
    height: calc(100vh - 205px);
  }
}

.FlowNavigation_container__1TiKD {
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  width: calc(50vw - 540px);
  height: calc(100vh - 66px);
  top: 66px;
  z-index: 10000;
}
.FlowNavigation_container__1TiKD.FlowNavigation_expanded__3eAZr {
  width: 60vw;
  box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.FlowNavigation_expandButton__2rYXw {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 150px;
  cursor: pointer;
  z-index: 9;
  left: calc(50vw - 530px);
}
.FlowNavigation_expandButton__2rYXw .FlowNavigation_icon__3YTY- {
  width: 15px;
  height: 15px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-top: 5px;
}
.FlowNavigation_expandButton__2rYXw .FlowNavigation_label__2WS6U {
  font-family: 'Open Sans SemiBold';
  color: #909090;
  -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 12px;
  padding: 5px;
  background-color: #ffffff;
}
.FlowNavigation_container__1TiKD.FlowNavigation_expanded__3eAZr .FlowNavigation_expandButton__2rYXw {
  right: 0px;
  position: absolute;
  left: auto;
  top: 75px;
}
.FlowNavigation_container__1TiKD.FlowNavigation_expanded__3eAZr .FlowNavigation_expandButton__2rYXw .FlowNavigation_icon__3YTY- {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.FlowNavigation_dagContainer__F8OIx {
  position: relative;
  width: 100%;
  height: 100%;
}
.FlowNavigation_dagContainer__F8OIx .edgePath path {
  fill: none;
}
.FlowNavigation_dagContainer__F8OIx .flow-chart-question-type {
  cursor: pointer;
}
.FlowNavigation_dagContainer__F8OIx .flow-chart-zoom-control-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 9;
  display: flex;
}
.FlowNavigation_dagContainer__F8OIx .flow-chart-zoom-control {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  font-size: 14px;
  border-radius: 2px;
}
.FlowNavigation_emptyStateDescriptionContainer__2e5Dv {
  color: #95989a;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.FlowNavigation_emptyStateDescriptionContainer__2e5Dv div {
  padding-left: 18px;
}

@media only screen and (max-width: 1070px) {
  .FlowNavigation_expandButton__2rYXw {
    left: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .FlowNavigation_container__1TiKD:not(.FlowNavigation_expanded__3eAZr) .FlowNavigation_emptyStateDescriptionContainer__2e5Dv {
    display: none;
  }
}

.SurveysPlaceholder_emptyStatePlaceholder__2gRhz {
  height: calc(100vh - 250px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SurveysPlaceholder_emptySurveyPlaceholderCover__QjZHx {
  height: 50%;
  max-height: 500px;
  min-height: 290px;
  margin-bottom: 50px;
}
.SurveysPlaceholder_emptyStateDescription__3-Srb {
  color: #95989a;
  text-align: center;
}
.SurveysPlaceholder_loadingPlaceholdersContainer__mrCqI div {
  background-color: #f2f2f2;
}
.SurveysPlaceholder_loadingPlaceholderFirst__p6fEx {
  height: 200px;
  margin-bottom: 30px;
}
.SurveysPlaceholder_loadingPlaceholderFirst__p6fEx {
  width: 640px;
  height: 90px;
  margin-bottom: 1px;
}
.SurveysPlaceholder_loadingPlaceholderSecond__3_H3l {
  width: 640px;
  height: 250px;
}
.SurveysPlaceholder_loadingPlaceholderSide__c5yuv {
  position: absolute;
  width: 305px;
  height: 400px;
  top: 0px;
  right: 10px;
}

.ErrorOccuredPopup_container__2In11 {
  width: 400px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ErrorOccuredPopup_title__GgUfW {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  font-size: 13px;
  margin-bottom: 10px;
}
.ErrorOccuredPopup_description__24iYU {
  color: #9c9696;
  font-size: 11px;
  width: 210px;
  text-align: center;
  margin-bottom: 10px;
}
.ErrorOccuredPopup_checkmark__2f0wg {
  width: 60px;
  height: 60px;
}

.Footer_container__2pHfJ {
  display: flex;
  font-family: 'Open Sans Regular';
  justify-content: space-between;
}
.Footer_left__erWrF {
  background-repeat: no-repeat;
  cursor: pointer;
}
.Footer_left__erWrF img {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-right: 10px;
}
.Footer_right__2X7SN {
  background-repeat: no-repeat;
  cursor: pointer;
}
.Footer_right__2X7SN img {
  margin-left: 10px;
}
.Footer_left__erWrF.Footer_disabled__ZNXNI,
.Footer_right__2X7SN.Footer_disabled__ZNXNI {
  opacity: 0.2;
}

.SelectStart_container__2EvAw {
  width: 800px;
}
.SelectStart_title__1C-2O {
  font-family: 'Open Sans Regular';
  font-size: 16px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
.SelectStart_optionsContainer__5oGbF {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.SelectStart_option__ST-jg {
  width: 180px;
  height: 180px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.SelectStart_option__ST-jg:last-child {
  margin-right: 0px;
}
.SelectStart_option__ST-jg img {
  margin-bottom: 20px;
}
.SelectStart_option__ST-jg .SelectStart_optionLabel__-kQyc {
  font-size: 12px;
}
.SelectStart_selectedBar__-wVZr {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #ebe6fc;
  left: 0px;
  bottom: 0px;
}
.SelectStart_footerContainer__3oo_B {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 770px) {
  .SelectStart_container__2EvAw {
    width: 85vw;
  }
}

.SelectLanguage_container__3YNC- {
  width: 800px;
}
.SelectLanguage_title__1aS40 {
  font-family: 'Open Sans Regular';
  font-size: 16px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
.SelectLanguage_title__1aS40 .SelectLanguage_purple__2wXAE {
  color: #4a25e8;
}
.SelectLanguage_optionsContainer__2eXSt {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.SelectLanguage_option__3NBZh {
  width: 180px;
  height: 180px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.SelectLanguage_option__3NBZh:last-child {
  margin-right: 0px;
}
.SelectLanguage_option__3NBZh img {
  margin-bottom: 20px;
  width: 100px;
  height: 40px;
}
.SelectLanguage_option__3NBZh .SelectLanguage_optionLabel__2XA35 {
  font-size: 12px;
}
.SelectLanguage_selectedBar__2g0qU {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #ebe6fc;
  left: 0px;
  bottom: 0px;
}
.SelectLanguage_info__-pzJC {
  margin-bottom: 40px;
  background-color: #f7f4fd;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-style: italic;
  font-size: 14px;
}
.SelectLanguage_footerContainer__GGksr {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 770px) {
  .SelectLanguage_container__3YNC- {
    width: 85vw;
  }
}

.InfoPopup_container__60wPq {
  width: 800px;
  height: 500px;
}
.InfoPopup_textContainer__lsbSE {
  overflow-y: auto;
  height: 450px;
  margin: 50px;
  margin-right: 0;
  padding-right: 40px;
}

/* Works on Firefox */
.InfoPopup_textContainer__lsbSE {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.InfoPopup_textContainer__lsbSE::-webkit-scrollbar {
  width: 7px;
}

.InfoPopup_textContainer__lsbSE::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.InfoPopup_textContainer__lsbSE::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.InfoPopup_closePopupButton__3iALb {
  position: absolute;
  width: 22px;
  height: 22px;
  top: -45px;
  right: 0px;
  cursor: pointer;
}
.InfoPopup_subtitle__1GTDF {
  color: #4a25e8;
  margin-bottom: 15px;
}
.InfoPopup_text__2T6VJ {
  margin-bottom: 15px;
}
.InfoPopup_text__2T6VJ .InfoPopup_bold__COf9p {
  font-family: 'Open Sans SemiBold';
}
.InfoPopup_text__2T6VJ a:link,
.InfoPopup_text__2T6VJ a:visited,
.InfoPopup_text__2T6VJ .InfoPopup_purple__3zmZB {
  color: #4a25e8;
  cursor: pointer;
  text-decoration: none;
}
.InfoPopup_canvasImage__LGAJL {
  width: 250px;
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.SelectTemplateType_container__1cy7L {
  width: 800px;
}
.SelectTemplateType_title__3AUul {
  font-family: 'Open Sans Regular';
  font-size: 16px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
.SelectTemplateType_title__3AUul .SelectTemplateType_purple__GHlfE {
  color: #4a25e8;
}
.SelectTemplateType_optionsContainer__asyAO {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.SelectTemplateType_optionWrapper__38S0t {
  margin-right: 20px;
}
.SelectTemplateType_optionWrapper__38S0t:last-child {
  margin-right: 0px;
}
.SelectTemplateType_option__2Y1nD {
  width: 150px;
  height: 150px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 15px;
}
.SelectTemplateType_option__2Y1nD img {
  margin-bottom: 20px;
  width: 100px;
  height: 40px;
}
.SelectTemplateType_option__2Y1nD .SelectTemplateType_optionLabel__BWQsP {
  font-size: 12px;
}
.SelectTemplateType_optionInfo__1VG-o {
  font-style: italic;
  text-decoration: underline;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.SelectTemplateType_selectedBar__3u9R6 {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #ebe6fc;
  left: 0px;
  bottom: 0px;
}
.SelectTemplateType_info__szyq_ {
  margin-bottom: 40px;
  background-color: #f7f4fd;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-style: italic;
  font-size: 14px;
}
.SelectTemplateType_info__szyq_ a:link,
.SelectTemplateType_info__szyq_ a:visited {
  color: #4a25e8;
  cursor: pointer;
  text-decoration: none;
}
.SelectTemplateType_footerContainer__3ZzuP {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 770px) {
  .SelectTemplateType_container__1cy7L {
    width: 85vw;
  }
}

.SelectTestsNumber_container__uSUNy {
  width: 800px;
}
.SelectTestsNumber_title__2eGP4 {
  font-family: 'Open Sans Regular';
  font-size: 16px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
.SelectTestsNumber_title__2eGP4 .SelectTestsNumber_purple__XVodA {
  color: #4a25e8;
}
.SelectTestsNumber_optionsWrapper__1VSgo {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}
.SelectTestsNumber_optionsContainer__1epTB {
  display: flex;
  justify-content: center;
}
.SelectTestsNumber_option__367kt {
  width: 100px;
  height: 100px;
  border: 1px solid #e1e1e1;
  margin-right: 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.SelectTestsNumber_option__367kt:last-child {
  margin-right: 0px;
}
.SelectTestsNumber_option__367kt img {
  margin-bottom: 20px;
  width: 100px;
  height: 40px;
}
.SelectTestsNumber_option__367kt .SelectTestsNumber_optionLabel__Da6vO {
  font-size: 12px;
}
.SelectTestsNumber_arrowLeftContainer__3-pyi {
  display: flex;
  align-items: center;
  margin-right: 20px;
  width: 10px;
}
.SelectTestsNumber_arrowLeftContainer__3-pyi img {
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.SelectTestsNumber_arrowRightContainer__2MJ1W {
  display: flex;
  align-items: center;
  margin-left: 20px;
  width: 10px;
}
.SelectTestsNumber_arrowRightContainer__2MJ1W img {
  cursor: pointer;
}
.SelectTestsNumber_selectedBar__3odZl {
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: #ebe6fc;
  left: 0px;
  bottom: 0px;
}
.SelectTestsNumber_info__2Aihg {
  background-color: #f7f4fd;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-style: italic;
  font-size: 14px;
}
.SelectTestsNumber_info__2Aihg a:link,
.SelectTestsNumber_info__2Aihg a:visited {
  color: #4a25e8;
  cursor: pointer;
  text-decoration: none;
}
.SelectTestsNumber_footerContainer__32a9c {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  padding-top: 40px;
}

@media only screen and (max-width: 770px) {
  .SelectTestsNumber_container__uSUNy {
    width: 85vw;
  }
}

.DefinePoints_container__11M6M {
  width: 795px;
  margin-right: 5px;
}
.DefinePoints_textContainer__w_za8 {
  overflow-y: auto;
  max-height: 480px;
  margin: 30px;
  margin-right: 0;
  padding-right: 30px;
}

/* Works on Firefox */
.DefinePoints_textContainer__w_za8 {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.DefinePoints_textContainer__w_za8::-webkit-scrollbar {
  width: 7px;
}

.DefinePoints_textContainer__w_za8::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.DefinePoints_textContainer__w_za8::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.DefinePoints_title__31JQY {
  font-size: 16px;
  margin-bottom: 20px;
}
.DefinePoints_text__3T2Ep {
  margin-bottom: 15px;
}
.DefinePoints_text__3T2Ep a:link,
.DefinePoints_text__3T2Ep a:visited {
  color: #4a25e8;
  text-decoration: none;
}
.DefinePoints_text__3T2Ep.DefinePoints_purple__UmnMY {
  color: #4a25e8;
}
.DefinePoints_inputContainer__3IiVc {
  margin-bottom: 10px;
}
.DefinePoints_inputContainer__3IiVc .DefinePoints_label__2AfiZ {
  margin-bottom: 10px;
}
.DefinePoints_inputContainer__3IiVc textarea {
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  width: calc(100% - 40px);
  height: 130px;
  border-radius: 5px;
  font-family: 'Open Sans Regular';
  resize: none;
}

@media only screen and (max-width: 770px) {
  .DefinePoints_container__11M6M {
    width: 85vw;
  }
}

.RangeSelector_container__1n3C7 .input-range__slider {
  background-color: #4a25e8;
  border: 0;
  width: 12px;
  height: 12px;
  margin-top: -8px;
}
.RangeSelector_container__1n3C7 .input-range__track--active {
  background-color: #4a25e8;
}
.RangeSelector_container__1n3C7 .input-range__track {
  height: 3px;
}
.RangeSelector_container__1n3C7 .input-range__label--min,
.RangeSelector_container__1n3C7 .input-range__label--max {
  display: none;
}

.MCSelector_container__25EDr {
  display: flex;
  justify-content: center;
}
.MCSelector_option__3_vRg {
  background-color: #ffffff;
  border: 1px solid #dfdddd;
  width: 220px;
  min-height: 120px;
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 3px;
  margin-right: 15px;
  cursor: pointer;
}
.MCSelector_option__3_vRg:last-child {
  margin-right: 0;
}
.MCSelector_option__3_vRg.MCSelector_selected__3rkGZ {
  background-color: #ebe6fc;
}
.MCSelector_answer__1CBxK {
}

.SetScale_container___c_jo {
  width: 795px;
  height: 500px;
  margin-right: 5px;
}
.SetScale_textContainer__12pNk {
  overflow-y: auto;
  height: 480px;
  margin: 30px;
  margin-left: 10px;
  margin-right: 0;
  padding-right: 20px;
}

/* Works on Firefox */
.SetScale_textContainer__12pNk {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.SetScale_textContainer__12pNk::-webkit-scrollbar {
  width: 7px;
}

.SetScale_textContainer__12pNk::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.SetScale_textContainer__12pNk::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.SetScale_title__1Enc_ {
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 20px;
}
.SetScale_text__1y1cM {
  margin-bottom: 15px;
  margin-left: 20px;
}
.SetScale_text__1y1cM .SetScale_purple__2hgDu {
  color: #4a25e8;
}
.SetScale_scalePickersContainer__1tiJp {
  display: flex;
  padding-left: 20px;
  margin-bottom: 25px;
}
.SetScale_scalePicker__1mOJc {
  margin-right: 20px;
  cursor: pointer;
}
.SetScale_scalePicker__1mOJc.SetScale_selected__tVDEa {
  color: #4a25e8;
}
.SetScale_scalePicker__1mOJc img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.SetScale_scaleDetailsWrapper__d-9y1 {
  background-color: #f7f4fd;
  border-left: 3px solid #4a25e8;
  padding: 25px 17px 1px 17px;
  margin-bottom: 20px;
}
.SetScale_scaleContainer__2kyjd {
}
.SetScale_scaleContainer__2kyjd .SetScale_rangeContainer__Mk0oY {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 50px;
  padding-right: 50px;
}
.SetScale_scaleContainer__2kyjd .SetScale_mcContainer__apCis {
  margin-top: 40px;
  margin-bottom: 40px;
}
.SetScale_scaleTitle1__2wYma {
  font-family: 'Open Sans SemiBold';
}
.SetScale_scaleTitle2__YE3cE {
}
.SetScale_pointContainer__2RVyX {
  padding: 10px 0px 1px 0px;
  background-color: #fafafa;
  margin-bottom: 10px;
}
.SetScale_disclaimer__1O7DG {
  margin-left: 20px;
  margin-bottom: 20px;
  color: #7e7e7e;
  padding-right: 40px;
}
.SetScale_footerContainer__1IXxq {
  padding-left: 20px;
  padding-right: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 770px) {
  .SetScale_container___c_jo {
    width: 85vw;
  }
}

.SetSuccessCriteria_container__3ZuXW {
  width: 795px;
  margin-right: 5px;
}
.SetSuccessCriteria_textContainer__3R3vd {
  overflow-y: auto;
  max-height: 480px;
  margin: 30px;
  margin-left: 10px;
  margin-right: 0;
  padding-right: 20px;
}

/* Works on Firefox */
.SetSuccessCriteria_textContainer__3R3vd {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.SetSuccessCriteria_textContainer__3R3vd::-webkit-scrollbar {
  width: 7px;
}

.SetSuccessCriteria_textContainer__3R3vd::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.SetSuccessCriteria_textContainer__3R3vd::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.SetSuccessCriteria_title__2jk-n {
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 20px;
}
.SetSuccessCriteria_text__2PHnF {
  margin-bottom: 15px;
  margin-left: 20px;
}
.SetSuccessCriteria_label__39Gvh {
  margin-bottom: 15px;
  margin-left: 20px;
}
.SetSuccessCriteria_label__39Gvh strong {
  font-family: 'Open Sans SemiBold';
}
.SetSuccessCriteria_assumptionsContainer__1X_r7 {
  background-color: #f7f4fd;
  border-left: 3px solid #4a25e8;
  padding: 15px 17px 10px 17px;
  margin-bottom: 20px;
}
.SetSuccessCriteria_assumptionLine__2pw-9 {
  margin-bottom: 7px;
}
.SetSuccessCriteria_assumptionLine__2pw-9 .SetSuccessCriteria_bold__hgob4 {
  font-family: 'Open Sans SemiBold';
}
.SetSuccessCriteria_assumptionNumberInput__3h6R4 {
  background-color: #ffffff;
  border: 1px solid #dfdddd;
  border-radius: 3px;
  width: 30px;
  margin-right: 5px;
  font-size: 12px;
  text-align: center;
  font-family: 'Open Sans Regular';
}
.SetSuccessCriteria_assumptionDropdown__1iu3L {
  background-color: #ffffff;
  border: 1px solid #dfdddd;
  border-radius: 3px;
  width: 185px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
  font-family: 'Open Sans Regular';
}
.SetSuccessCriteria_disclaimer__kULdQ {
  margin-left: 20px;
  margin-bottom: 20px;
  color: #7e7e7e;
  padding-right: 40px;
}
.SetSuccessCriteria_footerContainer__1kcN1 {
  padding-left: 20px;
  padding-right: 5px;
  padding-bottom: 5px;
}

@media only screen and (max-width: 770px) {
  .SetSuccessCriteria_container__3ZuXW {
    width: 85vw;
  }
}

.LogicMap_container__2FbL3 {
  width: 795px;
  margin-right: 5px;
}
.LogicMap_title__3E2Df {
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-top: 30px;
}
.LogicMap_chartContainer__3qrZR {
  height: 400px;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
}
.LogicMap_footerContainer__14vfb {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

.LogicMap_container__2FbL3 .edgePath path {
  fill: none;
}
.LogicMap_container__2FbL3 .flow-chart-question-type {
  cursor: pointer;
}
.LogicMap_container__2FbL3 .flow-chart-zoom-control-container {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 9;
  display: flex;
}
.LogicMap_container__2FbL3 .flow-chart-zoom-control {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  font-size: 14px;
  border-radius: 2px;
}

@media only screen and (max-width: 770px) {
  .LogicMap_container__2FbL3 {
    width: 85vw;
  }
}

.SetContext_container__1MQSn {
  width: 795px;
}
.SetContext_textContainer__2CoXR {
  padding: 20px;
}

/* Works on Firefox */
.SetContext_textContainer__2CoXR {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}

/* Works on Chrome, Edge, and Safari */
.SetContext_textContainer__2CoXR::-webkit-scrollbar {
  width: 7px;
}

.SetContext_textContainer__2CoXR::-webkit-scrollbar-track {
  background-color: #cccccc;
}

.SetContext_textContainer__2CoXR::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}

.SetContext_title__2ZpJ_ {
  font-size: 16px;
  margin-bottom: 20px;
}
.SetContext_text__2fiUJ {
  margin-bottom: 15px;
}
.SetContext_text__2fiUJ .SetContext_bold__TFcZO {
  font-family: 'Open Sans SemiBold';
}
.SetContext_text__2fiUJ a:link,
.SetContext_text__2fiUJ a:visited {
  color: #4a25e8;
  text-decoration: none;
}
.SetContext_inputWrapper__dduoj {
  margin-bottom: 10px;
}
.SetContext_inputWrapper__dduoj textarea {
  border: 1px solid #d9d9d9;
  padding: 10px 20px;
  width: calc(100% - 40px);
  height: 130px;
  border-radius: 5px;
  font-family: 'Open Sans Regular';
  resize: none;
}
.SetContext_footerContainer__Pa1f- {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 770px) {
  .SetContext_container__1MQSn {
    width: 85vw;
  }
}

.TemplatesWizard_closePopupButton__3-aUv {
  position: absolute;
  width: 22px;
  height: 22px;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.TemplatesWizard_popupWrapper__1Me-t > div > div {
  margin-left: -20px;
}

.SurveyBuilder_container__2jsrK {
  box-sizing: border-box;
  width: 965px;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  position: relative;
}

@media only screen and (max-width: 1035px) {
  .SurveyBuilder_container__2jsrK {
    width: calc(100vw - 146px);
    margin-left: 30px;
  }
}

.TargetAudience_topContainer__e5H0K {
  margin: 50px 33px 32px 33px;
}
.TargetAudience_container__1S1nQ {
  background-color: #fff;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 4px;
}
.TargetAudience_containerTitle__2Dqeb {
  display: flex;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 30px;
  padding-right: 30px;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
}

.TargetAudience_containerTitle__2Dqeb .TargetAudience_advancedSettingsButton__1CzRW {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  top: -5px;
}
.TargetAudience_containerTitle__2Dqeb .TargetAudience_advancedSettingsDropdown__1fCIp {
  top: 40px;
  right: 0px;
  width: auto;
  position: absolute;
  background-color: #fff;
  border: 1px solid #e7e4e4;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  z-index: 9;
  color: #8b8b8d;
  font-size: 11px;
  text-transform: uppercase;
  text-align: left;
  white-space: nowrap;
}
.TargetAudience_containerTitle__2Dqeb .TargetAudience_advancedSettingsDropdownOption__3B2Ic {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}
.TargetAudience_containerTitle__2Dqeb .TargetAudience_advancedSettingsDropdownOption__3B2Ic:hover {
  opacity: 0.6;
}

.TargetAudience_blockContainer__2lsaZ {
  padding: 15px 30px 15px 0;
  font-size: 14px;
  background-color: #fff;
  border-left: 4px solid #fff;
  border-bottom: 1px solid #eee !important;
  margin-left: 20px;
}
.TargetAudience_singleBlock__3eHr9 {
  display: flex;
  justify-content: space-between;
}
.TargetAudience_singleBlock__3eHr9 .TargetAudience_label__FXboz {
  width: 350px;
  padding-left: 8px;
}
.TargetAudience_singleBlock__3eHr9 .TargetAudience_label__FXboz.TargetAudience_descriptionCorrected__3Awjr {
  top: 15px;
}
.TargetAudience_singleBlock__3eHr9 .TargetAudience_description__NkZv- {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  outline: none;
  display: block;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-top: 8px;
  margin-bottom: 6px;
  overflow: hidden;
  resize: none;
  width: 125px;
}

.TargetAudience_rowContainer__3Dvl3 {
  display: flex;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #ececec;
}
.TargetAudience_rowContainer__3Dvl3:first-child {
  margin-top: 20px;
}
.TargetAudience_rowContainer__3Dvl3:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 20;
}
.TargetAudience_scalesContainer__3xHpi {
  width: 100%;
  padding-right: 20px;
  margin-left: 15px;
}
.TargetAudience_additionalConfigurationContainer__1z227 {
  display: flex;
  width: 560px;
  flex-direction: row;
  justify-content: right;
}
.TargetAudience_deleteFilterRowIcon__2JGmW {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

/* Age scales */
.TargetAudience_scalesContainer__3xHpi .input-range {
  position: relative;
  top: 5px;
}
.TargetAudience_scalesContainer__3xHpi
  .input-range
  .input-range__slider-container:first-of-type
  .input-range__slider {
  background: #8500f0 !important;
}
.TargetAudience_scalesContainer__3xHpi
  .input-range
  .input-range__slider-container:last-of-type
  .input-range__slider {
  background: #8500f0 !important;
}
.TargetAudience_scalesContainer__3xHpi .input-range__label--value {
  top: -33px !important;
}
.TargetAudience_scalesContainer__3xHpi .input-range__label {
  color: #8500f0 !important;
  font-family: 'Open Sans Regular';
}
.TargetAudience_scalesContainer__3xHpi .input-range__slider {
  background: #8500f0 !important;
  border: 1px solid #8500f0 !important;
  z-index: 2;
}
.TargetAudience_scalesContainer__3xHpi .input-range__track--active {
  background: #937ff0;
  z-index: 2;
}
.TargetAudience_scalesContainer__3xHpi .input-range__label {
  font-family: 'Open Sans SemiBold';
}
.TargetAudience_scalesContainer__3xHpi .input-range__label--min {
  display: none;
}
.TargetAudience_scalesContainer__3xHpi .input-range__label--max {
  display: none;
}

/* Gender pickers */
.TargetAudience_scalesContainer__3xHpi .TargetAudience_pickers__K3Gkd {
  top: -20px;
}
.TargetAudience_scalesContainer__3xHpi .TargetAudience_valueOption__3MqVn {
  display: inline-block;
  background-color: #ffffff;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  cursor: pointer;
  top: 8px;
  position: relative;
}
.TargetAudience_scalesContainer__3xHpi .TargetAudience_valueOption__3MqVn.TargetAudience_active__Kh-l5 {
  background-color: #8500f0;
  color: #ffffff;
}
.TargetAudience_scalesContainer__3xHpi .TargetAudience_valueOption__3MqVn.TargetAudience_disabled__2aOtx {
  cursor: default;
}

.TargetAudience_scalesContainer__3xHpi .TargetAudience_targetValuesTrack__1x7Ki {
  position: absolute;
  top: 5px;
  height: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.TargetAudience_scalesContainer__3xHpi .TargetAudience_targetValues__okuLB {
  height: 4.8px;
  position: relative;
  background-color: #95989a;
  display: inline-block;
  z-index: 1;
  width: calc(100% - 20px);
}
.TargetAudience_scalesContainer__3xHpi .TargetAudience_targetLeftValue__2wXG9,
.TargetAudience_scalesContainer__3xHpi .TargetAudience_targetRightValue__3h_vs {
  position: absolute;
  display: inline-block;
  height: 15px;
  width: 4px;
  border-radius: 4px;
  background-color: #95989a;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-weight: bold;
  font-size: 11px;
}
.TargetAudience_scalesContainer__3xHpi .TargetAudience_targetValues__okuLB .TargetAudience_targetLeftValue__2wXG9 span,
.TargetAudience_scalesContainer__3xHpi .TargetAudience_targetValues__okuLB .TargetAudience_targetRightValue__3h_vs span {
  left: -5px;
  top: 18px;
  position: absolute;
  color: #aaaaaa;
}
.TargetAudience_scalesContainer__3xHpi .TargetAudience_targetValues__okuLB .TargetAudience_targetLeftValue__2wXG9 {
  left: -1.5px;
}
.TargetAudience_scalesContainer__3xHpi .TargetAudience_targetValues__okuLB .TargetAudience_targetRightValue__3h_vs {
  right: -3.15px;
}

.TargetAudience_additionalConfigurationContainer__1z227 input {
  margin-left: 8px;
  margin-right: 8px;
  width: 50px;
  border: 1px solid #bebebe;
}
.TargetAudience_additionalConfigurationContainer__1z227 select {
  border: 1px solid #bebebe;
  width: 35px;
  background-color: #ffffff;
}

.TargetAudience_warningTitle__36fIE {
  min-height: 45px;
  height: auto;
}

.TargetAudience_dynamicFilterWarnings__2DVpL .TargetAudience_dynamicFilterWarningMessageBlock__1_xcO {
  padding-left: 15px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.TargetAudience_dynamicFilterWarnings__2DVpL .TargetAudience_dynamicFilterWarningMessageBlock__1_xcO .TargetAudience_warningMessage__3e2iN {
  font-weight: 700;
  color: #fc035d;
  text-transform: none;
  margin-left: 20px;
  display: flex;
}
.TargetAudience_dynamicFilterWarnings__2DVpL .TargetAudience_dynamicFilterWarningMessageBlock__1_xcO .TargetAudience_warningIcon__3Bn7m {
  width: 15px;
  height: 15px;
  position: relative;
  display: inline-block;
  top: 2px;
  margin-right: 10px;
}

.TargetAudience_noFiltersAvailable__5LZVP {
  text-align: center;
  color: #8b8b8d;
}

.AdvancedFilterSetting_container__QOf-L {
  color: #999696;
  padding-bottom: 7px;
  margin-bottom: 15px;
  padding-left: 3px;
}
.AdvancedFilterSetting_container__QOf-L:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.AdvancedFilterSetting_title__p7GTR {
  display: flex;
  margin-bottom: 20px;
}
.AdvancedFilterSetting_title__p7GTR span {
  text-transform: uppercase;
  margin-right: 15px;
}
.AdvancedFilterSetting_optionContainer__2CjCZ {
  margin-right: 10px;
}
.AdvancedFilterSetting_optionContainer__2CjCZ input {
  margin-right: 5px;
}
.AdvancedFilterSetting_description__2WDkn {
  white-space: break-spaces;
}

.AdvancedFilterSettings_container__3hjWM {
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 700px;
  padding-top: 20px;
}
.AdvancedFilterSettings_blockContainer__3JF2- {
  padding: 15px 30px 15px 0;
  font-size: 14px;
  background-color: #fff;
  border-left: 4px solid #fff;
  margin-left: 20px;
}
.AdvancedFilterSettings_footer__2jbaR {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-right: 20px;
  border-top: 1px solid #dedada;
  left: -10px;
  width: 100%;
  margin-top: 20px;
}
.AdvancedFilterSettings_footer__2jbaR .AdvancedFilterSettings_details__2PnCE {
  color: #5200f1;
}
.AdvancedFilterSettings_actions__qFOJ9 .AdvancedFilterSettings_confirm__2kq7P {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.AdvancedFilterSettings_actions__qFOJ9 .AdvancedFilterSettings_close__2lX-6 {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.AdvancedFilterSettings_loadingAnimation__3ThyG {
  display: block;
  position: relative;
  margin: 0;
  width: 28px;
  height: 28px;
  opacity: 0.5;
}
.AdvancedFilterSettings_loadingAnimation__3ThyG div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: AdvancedFilterSettings_loading-animation__3Y-sn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: AdvancedFilterSettings_loading-animation__3Y-sn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.AdvancedFilterSettings_loadingAnimation__3ThyG div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.AdvancedFilterSettings_loadingAnimation__3ThyG div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.AdvancedFilterSettings_loadingAnimation__3ThyG div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes AdvancedFilterSettings_loading-animation__3Y-sn {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes AdvancedFilterSettings_loading-animation__3Y-sn {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.TargetAudienceHeader_outerContainer__1ldiZ {
  position: fixed;
  top: 154px;
  left: 50%;
  margin-left: -514px;
  padding-left: 26px;
  padding-right: 26px;
  z-index: 9999;
  width: 964px;
  background-color: #ffffff;
}
.TargetAudienceHeader_container__1G7ug {
  height: 60px;
  background-color: #fff;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border: 1px solid #efefef;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  padding: 10px 0;
  box-sizing: border-box;
}
.TargetAudienceHeader_back__1Fhgd {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TargetAudienceHeader_title__2L_3K {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans Regular';
  font-size: 13px;
  color: #8b8b8d;
  text-transform: uppercase;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
}
.TargetAudienceHeader_title__2L_3K:last-child {
  border-right: none;
}
.TargetAudienceHeader_title__2L_3K:hover {
  color: rgba(149, 152, 154, 0.7);
}
.TargetAudienceHeader_title__2L_3K.TargetAudienceHeader_titleActive__ACgPL {
  color: #5300f2;
}
.TargetAudienceHeader_next__3jaYx {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TargetAudienceHeader_arrow__3mH0V {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.TargetAudienceHeader_arrow__3mH0V.TargetAudienceHeader_left__2WRta {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.ContactInnovationExpertPopup_container__1gRdT {
  width: 500px;
}
.ContactInnovationExpertPopup_container__1gRdT.ContactInnovationExpertPopup_containerSuccess__1iHlZ {
  text-align: center;
  padding: 20px 0 25px 0;
  width: 360px;
}
.ContactInnovationExpertPopup_content__6JM6K {
  padding: 25px 20px 0 20px;
}
.ContactInnovationExpertPopup_title__1dupu {
  font-family: 'Open Sans Bold';
  font-size: 13px;
  color: #5300f2;
}
.ContactInnovationExpertPopup_successMessage__3d9g2 {
  font-size: 11px;
  color: #9c9696;
  margin: 8px auto 10px auto;
  max-width: 250px;
}
.ContactInnovationExpertPopup_checkmark__2gdNS {
  width: 60px;
  height: 60px;
}
.ContactInnovationExpertPopup_form__rnwf3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0;
}
.ContactInnovationExpertPopup_field__2hfWH {
  background-color: #ffffff;
  border: 1px solid #efefef;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  padding: 0 10px;
  width: 48%;
  outline: none;
  border-radius: 4px;
  margin-bottom: 8px;
  box-sizing: border-box;
}
.ContactInnovationExpertPopup_field__2hfWH::-webkit-input-placeholder {
  color: #95989a;
}
.ContactInnovationExpertPopup_field__2hfWH::-ms-input-placeholder {
  color: #95989a;
}
.ContactInnovationExpertPopup_field__2hfWH::placeholder {
  color: #95989a;
}
.ContactInnovationExpertPopup_field__2hfWH.ContactInnovationExpertPopup_textarea__SsGqG {
  height: 75px;
  line-height: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: 'Open Sans Regular';
  width: 100%;
  resize: none;
}
.ContactInnovationExpertPopup_footer__3lIqf {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 2px solid #efefef;
}
.ContactInnovationExpertPopup_footer__3lIqf.ContactInnovationExpertPopup_error__1M6UU {
  background-color: #fff2f7;
}
.ContactInnovationExpertPopup_footer__3lIqf .ContactInnovationExpertPopup_errorMessage__sGJvM {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  margin-left: 25px;
}
.ContactInnovationExpertPopup_footer__3lIqf .ContactInnovationExpertPopup_errorMessage__sGJvM .ContactInnovationExpertPopup_warningIcon__pDoP6 {
  height: 20px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.ContactInnovationExpertPopup_actions__1WeEL {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ContactInnovationExpertPopup_confirmIcon__PAY7M {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.ContactInnovationExpertPopup_confirmIconDisabled__3_F-Z {
  -webkit-filter: grayscale(0.8) contrast(0.1);
          filter: grayscale(0.8) contrast(0.1);
  cursor: not-allowed;
}
.ContactInnovationExpertPopup_closeIcon__2opMu {
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.SearchDropdown_filterItemContainer__3sF2B {
  display: flex;
}
.SearchDropdown_filterItemContainer__3sF2B .SearchDropdown_filterType__1Skti {
  display: flex;
}
.SearchDropdown_filterItemContainer__3sF2B .SearchDropdown_filterType__1Skti .SearchDropdown_closeIcon__1fpNH {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.SearchDropdown_filterItemContainer__3sF2B .SearchDropdown_filterType__1Skti {
  display: flex;
}
.SearchDropdown_filterItemContainer__3sF2B .SearchDropdown_filterType__1Skti .SearchDropdown_closeIcon__1fpNH {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}

.ProfilingQuestionDetails_container__X0bar {
  margin-bottom: 10px;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
}
.ProfilingQuestionDetails_container__X0bar.ProfilingQuestionDetails_notActiveQuestion__xtdyB {
  opacity: 0.4;
}
.ProfilingQuestionDetails_question__ODQO6 {
  display: flex;
}
.ProfilingQuestionDetails_question__ODQO6.ProfilingQuestionDetails_expanded__1QTfm {
  border-bottom: 1px solid #d8d8d8;
}
.ProfilingQuestionDetails_checkboxContainer__2B3aT {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProfilingQuestionDetails_checkboxContainer__2B3aT input {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.ProfilingQuestionDetails_questionText__2CMtn {
  flex: 1 1;
  color: #000000;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.ProfilingQuestionDetails_expandIconContainer__1EsOS {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProfilingQuestionDetails_expandIconContainer__1EsOS .ProfilingQuestionDetails_arrowDownPurple__1IJ0u {
  padding: 5px;
  cursor: pointer;
}
.ProfilingQuestionDetails_expandIconContainer__1EsOS .ProfilingQuestionDetails_arrowDownPurple__1IJ0u img {
  width: 10px;
  height: 10px;
}
.ProfilingQuestionDetails_answersContainer__1Uoob {
  padding: 13px;
}
.ProfilingQuestionDetails_answer__3dkzt {
  font-style: italic;
  font-size: 13px;
  color: #333333;
  min-height: 27px;
  line-height: 27px;
}
.ProfilingQuestionDetails_answer__3dkzt input {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  padding: 0;
  margin: 0;
  margin-right: 10px;
  cursor: pointer;
}
.ProfilingQuestionDetails_bottomInfo__3swh- {
  background-color: #f6f2fe;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.ProfilingQuestionDetails_ljArrow__1r_ZS {
  margin-left: 10px;
  margin-right: 15px;
  height: 15px;
  margin-right: 10px;
}
.ProfilingQuestionDetails_whyFilteredOutExplanation__BbrvQ {
  width: calc(100% - 20px);
  border: 0;
  padding: 10px;
  border-radius: 5px;
  font-family: 'Open Sans Regular';
  resize: none;
  border: 1px solid #e7e7e7;
  outline: none;
}

.ProfilingQuestionGroup_container__24Lyr {
  background-color: #fff;
  box-shadow: 0 0 15px 5px rgb(0 0 0 / 2%);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.ProfilingQuestionGroup_searchContainer__3gpKR {
  display: flex;
  background-color: #f6f2fe;
  padding: 15px 20px;
  justify-content: space-between;
  align-items: center;
}
.ProfilingQuestionGroup_removeQuestion__3VV8e {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.ProfilingQuestionGroup_mainContent__2UNT7 {
  padding: 0 20px;
  color: #777777;
  font-size: 13px;
}
.ProfilingQuestionGroup_mainContent__2UNT7.ProfilingQuestionGroup_isNotEligiblePage__2_-Ed {
  color: #000000;
  font-size: 13px;
}
.ProfilingQuestionGroup_title__2MsTJ {
  font-size: 12px;
  color: #000000;
  margin-bottom: 10px;
  font-family: 'Open Sans SemiBold';
  padding-left: 15px;
  margin-top: 20px;
}
.ProfilingQuestionGroup_questionContainer__3U4Bc.ProfilingQuestionGroup_limitHeight__2RgDx {
  max-height: 220px;
  overflow-y: auto;
}

.ProfilingQuestionGroup_loaderFillContainer__R1a0n {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.ProfilingQuestionGroup_loadingAnimation__1HIB5 {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 30px;
  opacity: 0.5;
}
.ProfilingQuestionGroup_loadingAnimation__1HIB5 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: ProfilingQuestionGroup_loading-animation__2WUKw 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: ProfilingQuestionGroup_loading-animation__2WUKw 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.ProfilingQuestionGroup_loadingAnimation__1HIB5 div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.ProfilingQuestionGroup_loadingAnimation__1HIB5 div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.ProfilingQuestionGroup_loadingAnimation__1HIB5 div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
.ProfilingQuestionGroup_getInTouchContainer__1pIPf {
  left: -32px;
  font-size: 12px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  width: calc(100% + 70px);
  margin-top: 30px;
}
.ProfilingQuestionGroup_getInTouchContainer__1pIPf span {
  color: #5300f2;
  cursor: pointer;
}

@-webkit-keyframes ProfilingQuestionGroup_loading-animation__2WUKw {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes ProfilingQuestionGroup_loading-animation__2WUKw {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ProfilingQuestions_container__3Cxp8 {
  margin: 10px 4px 10px 0;
}
.ProfilingQuestions_addProfilingQuestion__1SGzB {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #5300f2;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}

.PreviousSurveysList_listContainer__2ASur {
  margin-top: 15px;
}
.PreviousSurveysList_listTitle__1rDq1 {
  font-family: 'Open Sans Semibold';
}
.PreviousSurveysList_row__1BHY6 {
  display: flex;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-top: 1px solid #ebeaef;
}
.PreviousSurveysList_row__1BHY6 {
  display: flex;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border-top: 1px solid #ebeaef;
}
.PreviousSurveysList_row__1BHY6 .PreviousSurveysList_checkboxContainer__1Xt_n {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.PreviousSurveysList_row__1BHY6 .PreviousSurveysList_checkboxContainer__1Xt_n img {
  position: relative;
  top: 3px;
}
.PreviousSurveysList_row__1BHY6 .PreviousSurveysList_surveyProjectName__s0Gei {
  max-width: calc(100% - 175px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PreviousSurveysList_row__1BHY6 .PreviousSurveysList_surveyPublishDate__q9hQn {
  margin-left: auto;
  display: block;
  width: 150px;
}
.PreviousSurveysList_checkedRow__38BMw:last-of-type {
  border-bottom: 1px solid #ebeaef;
}
.PreviousSurveysList_headerRow__37hqV {
  border: none;
  color: #8b8b8d;
  text-transform: uppercase;
  font-size: 12px;
}
.PreviousSurveysList_loadingRow__3OPgS {
  height: 36px;
  background-color: #ebebeb;
  margin-bottom: 5px;
}

.PreviousSurveyParticipantConfiguration_container__1UI73 {
  margin: 10px 4px 10px 0;
  width: calc(100% - 5px);
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 25px 40px;
  box-sizing: border-box;
  font-size: 13px;
}
.PreviousSurveyParticipantConfiguration_topDescription__2KY7d {
  margin-bottom: 10px;
}
.PreviousSurveyParticipantConfiguration_loaderContainerWrapper__c-SEv {
  margin-top: 40px;
}

.PreviousSurveyParticipantConfiguration_loadingItem__3k_i5 {
  height: 35px;
  background-color: #e3e3e3;
  margin-bottom: 5px;
}
.PreviousSurveyParticipantConfiguration_surveysContainer__2TMHe {
  margin-top: 25px;
}

.WarningPopup_container__1O7Rn {
  padding: 15px;
  width: 400px;
  font-size: 13px;
}
.WarningPopup_title__2_psp {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
}
.WarningPopup_description__3fOux {
  padding-top: 15px;
  padding-bottom: 15px;
}
.WarningPopup_footer__2iKI9 {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid #e6e6e6;
}
.WarningPopup_actions__1f_mt .WarningPopup_confirm__3AUje {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.WarningPopup_actions__1f_mt .WarningPopup_close__1PQ4V {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.TestSurvey_container__BK8sB {
  padding: 0 35px;
}
.TestSurvey_sections__1_eFd {
  height: 50px;
  line-height: 50px;
  margin-top: 0px;
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  margin-bottom: 0px;
  z-index: 1000;
  padding: 0;
}
.TestSurvey_sections__1_eFd .TestSurvey_sectionName__29nf1 {
  font-family: 'Open Sans Bold';
  width: 50%;
  color: #95989a;
  text-align: center;
  cursor: pointer;
}
.TestSurvey_sections__1_eFd .TestSurvey_sectionName__29nf1.TestSurvey_active__s8YZU {
  color: #000000;
}

.TestSurvey_subsectionContainer__aMNki {
  border-top: 1px solid #e4e4e4;
  margin-top: 40px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}
.TestSurvey_subsectionContainer__aMNki .TestSurvey_subsectionTitle__3BSdD {
  padding: 5px 25px;
  background-color: #ffffff;
  top: -15px;
  color: #8b8b8d;
  text-transform: uppercase;
  font-size: 12px;
}

.TestSurvey_webContainer__3ceFA,
.TestSurvey_mobileContainer__7xvRj {
  text-align: center;
}

.TestSurvey_smallTitle__2o5C2 {
  color: #5200f1;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Open Sans Bold';
  font-size: 12px;
  margin-bottom: 10px;
}
.TestSurvey_description__WWIzR {
  text-align: center;
  color: #8b8b8d;
  width: 550px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.TestSurvey_inputContainer__3GnNE {
  display: flex;
  border: 1px solid #dfe0e4;
  border-radius: 7px;
  height: 30px;
}
.TestSurvey_inputContainer__3GnNE .TestSurvey_link__1RpRf {
  padding-left: 10px;
  height: 30px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 30px;
  font-size: 13px;
  color: #8b8b8d;
}
.TestSurvey_inputContainer__3GnNE .TestSurvey_link__1RpRf .TestSurvey_websiteIcon__1Xh3l {
  height: 12px;
  margin-right: 10px;
}
.TestSurvey_inputContainer__3GnNE .TestSurvey_copyLinkButton__3yVcE {
  background-color: #5200f2;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  width: 10%;
  border-radius: 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 32%;
  background-position: center center;
  margin: 3px;
  font-size: 12px;
}
.TestSurvey_inputContainer__3GnNE .TestSurvey_copyLinkButton__3yVcE.TestSurvey_copied__WIYhM {
  background-color: #95989a;
  cursor: default;
}
.TestSurvey_bigButton__9rC2S {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  padding-left: 80px;
  padding-right: 80px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.SurveyUpdatedPopup_container__mvSI1 {
  width: 700px;
  max-height: 500px;
}
.SurveyUpdatedPopup_title__2lJjI {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  padding-left: 15px;
  height: 30px;
  line-height: 24px;
  border-bottom: 1px solid #eeeeee;
}
.SurveyUpdatedPopup_message__3zKDP {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #8a898c;
}
.SurveyUpdatedPopup_button__1PEOG {
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
}

body {
  overflow-y: scroll;
}
.SurveyBuilderPage_BUFFLLoaderContainer__S_jfE {
  text-align: center;
}
.SurveyBuilderPage_BUFFLLoader__32gnZ {
  width: 64px;
  height: 64px;
  opacity: 0.7;
  margin-top: 100px;
}
.SurveyBuilderPage_flowContainer__9ZG9y {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: 100vh;
}
.SurveyBuilderPage_flowContainer__9ZG9y::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.1);
}
.SurveyBuilderPage_flowContainer__9ZG9y::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.4);
}
.SurveyBuilderPage_flow__qQFTf {
  box-sizing: border-box;
  width: 1035px;
  top: 66px;
  margin: 0 auto;
  padding-bottom: 50px;
  font-size: 14px;
  border-left: 1px solid #e3e3e4;
  border-right: 1px solid #e3e3e4;
  min-height: calc(100vh - 66px) !important;
  position: relative;
}
.SurveyBuilderPage_flow__qQFTf .flow-container {
  margin: 32px;
  margin-top: 0px;
}
.SurveyBuilderPage_flow__qQFTf .container-title {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.SurveyBuilderPage_flow__qQFTf .block-container {
  background-color: #ffffff;
  border-left: 4px solid #ffffff;
  border-bottom: 1px solid #eeeeee !important;
  padding-top: 35px;
  margin-left: 20px;
}
.SurveyBuilderPage_flow__qQFTf .block-container.focused {
  border-left: 4px solid #8500f0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}
.SurveyBuilderPage_flow__qQFTf .block-container .block-structure {
  display: flex;
}

.SurveyBuilderPage_flow__qQFTf .properties-column-container {
  position: relative;
  margin-left: 0;
}

.SurveyBuilderPage_flow__qQFTf .welcome {
  width: 636px;
  display: inline-block;
}
.SurveyBuilderPage_flow__qQFTf .properties-column-container {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}
.SurveyBuilderPage_flow__qQFTf .block-content .block-text .description,
.SurveyBuilderPage_flow__qQFTf .block-content .block-text .block {
  width: 100%;
}

.SurveyBuilderPage_flow__qQFTf .side-container-properties {
  width: auto;
}

.SurveyBuilderPage_flow__qQFTf .action .file-upload-input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
}

.SurveyBuilderPage_flow__qQFTf .media-upload a:link,
.SurveyBuilderPage_flow__qQFTf .media-upload a:visited {
  font-size: 11px;
  text-decoration: none;
  color: #5200f1;
  margin-right: 10px;
}
.SurveyBuilderPage_flow__qQFTf .action {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.SurveyBuilderPage_flow__qQFTf .action.remove {
  color: red;
}
.SurveyBuilderPage_flow__qQFTf ::-webkit-input-placeholder {
  color: '#aaa';
}
.SurveyBuilderPage_flow__qQFTf ::-ms-input-placeholder {
  color: '#aaa';
}
.SurveyBuilderPage_flow__qQFTf ::placeholder {
  color: '#aaa';
}

@media only screen and (max-width: 1035px) {
  .SurveyBuilderPage_flow__qQFTf {
    width: 100%;
  }
}

.demographic-range-selector-container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
}
.demographic-range-selector-container .input-range__slider {
  background: #5200f1;
  border: 1px solid #5200f1;
  width: 10px;
  height: 10px;
  top: 2px;
}
.demographic-range-selector-container .input-range__label {
  font-size: 12px;
}
.demographic-range-selector-activate-button {
  font-family: 'Open Sans SemiBold';
  text-align: center;
  font-size: 13px;
  color: #5300f2;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
.demographic-range-selector-container .input-range {
  position: absolute;
}
.demographic-range-selector-container .input-range__track--active {
  z-index: 1;
  background: #5300f2;
}
.demographic-range-selector-container .input-range__slider-container {
  z-index: 1;
}
.demographic-range-selector-container .input-range__label--min {
  display: none;
}
.demographic-range-selector-container .input-range__label--max {
  display: none;
}
.demographic-range-selector-container .demographic-range-selector-min {
  position: absolute;
  left: -7px;
  top: 20px;
}
.demographic-range-selector-container .demographic-range-selector-max {
  position: absolute;
  right: -7px;
  top: 20px;
}

.Slider_container__8ptcz {
  margin-top: 30px;
  margin-left: 20px;
}
.Slider_additionalDetailsContentContainer__YGCuD {
  display: inline-block;
}
.Slider_additionalDetailsContainer__2kdzC {
  padding: 10px 30px 20px 30px;
  font-size: 13px;
  display: flex;
  justify-content: center;
}
.Slider_averageRatingCount__2NKb3 {
  font-family: 'Roboto Bold';
}
.Slider_switchContainer__holZq {
  text-align: right;
  margin-right: 45px;
  margin-bottom: 10px;
}
.Slider_ratingSwitch__3sKq3 {
  position: relative;
  display: inline-block;
  width: 74px;
  height: 33px;
}
.Slider_ratingSlider__2SWXB {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
input:checked + .Slider_ratingSlider__2SWXB:before {
  -webkit-transform: translateX(0px) translateY(-50%);
          transform: translateX(0px) translateY(-50%);
}
.Slider_ratingSlider__2SWXB:before {
  position: absolute;
  content: '';
  height: 27px;
  width: 38px;
  left: 3px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  -webkit-transform: translateX(30px) translateY(-50%);
          transform: translateX(30px) translateY(-50%);
}
.Slider_ratingPercentageOn__2b8xS,
.Slider_ratingPercentageOff__1RKh-,
.Slider_ratingNumberOn__2g4uU,
.Slider_ratingNumberOff__3wTx4 {
  position: absolute;
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  font-weight: bold;
}
.Slider_ratingPercentageOn__2b8xS,
.Slider_ratingPercentageOff__1RKh- {
  left: 15px;
}
.Slider_ratingNumberOn__2g4uU,
.Slider_ratingNumberOff__3wTx4 {
  right: 17px;
}
.Slider_ratingPercentageOff__1RKh-,
.Slider_ratingNumberOff__3wTx4 {
  color: #fff;
}
.Slider_ratingPercentageOn__2b8xS,
.Slider_ratingNumberOn__2g4uU {
  color: rgb(82, 0, 241);
  opacity: 0.7;
}
.Slider_input__2YMSZ {
  display: none;
}
.Slider_disclaimer__2KYBX {
  font-size: 12px;
  color: #8b8b8d;
  padding: 0 30px 20px 30px;
}
.Slider_bullet__20XvF {
  display: inline-block;
  border-radius: 100%;
  width: 5px;
  height: 5px;
  margin-right: 6px;
  margin-bottom: 2px;
}

.RankingQuestion_chartContainer__1wdR6 {
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 40px;
}
.RankingQuestion_switchParentContainer__3oq1m {
  display: flex;
  justify-content: flex-end;
}
.RankingQuestion_switchContainer__2L6_y {
  text-align: right;
  margin-left: 20px;
  display: inline-block;
  top: -60px;
}
.RankingQuestion_ratingSwitch__1vN8a {
  position: relative;
  display: inline-block;
  width: 74px;
  height: 33px;
}
.RankingQuestion_ratingSlider__1Z7K9 {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
input:checked + .RankingQuestion_ratingSlider__1Z7K9:before {
  -webkit-transform: translateX(0px) translateY(-50%);
          transform: translateX(0px) translateY(-50%);
}
.RankingQuestion_ratingSlider__1Z7K9:before {
  position: absolute;
  content: '';
  height: 27px;
  width: 38px;
  left: 3px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  -webkit-transform: translateX(30px) translateY(-50%);
          transform: translateX(30px) translateY(-50%);
}
.RankingQuestion_ratingPercentageOn__1R5sS,
.RankingQuestion_ratingPercentageOff__3-k8W,
.RankingQuestion_ratingNumberOn__tJrmQ,
.RankingQuestion_ratingNumberOff__KdKpz {
  position: absolute;
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  font-weight: bold;
}
.RankingQuestion_ratingPercentageOn__1R5sS,
.RankingQuestion_ratingPercentageOff__3-k8W {
  left: 15px;
}
.RankingQuestion_ratingNumberOn__tJrmQ,
.RankingQuestion_ratingNumberOff__KdKpz {
  right: 17px;
}
.RankingQuestion_ratingPercentageOff__3-k8W,
.RankingQuestion_ratingNumberOff__KdKpz {
  color: #fff;
}
.RankingQuestion_ratingPercentageOn__1R5sS,
.RankingQuestion_ratingNumberOn__tJrmQ {
  color: rgb(82, 0, 241);
  opacity: 0.7;
}
.RankingQuestion_input__gDNXS {
  display: none;
}
.RankingQuestion_loadingAnimation__Dva8s {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.RankingQuestion_loadingAnimation__Dva8s div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: RankingQuestion_loading-animation__3Cid6 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: RankingQuestion_loading-animation__3Cid6 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.RankingQuestion_loadingAnimation__Dva8s div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.RankingQuestion_loadingAnimation__Dva8s div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.RankingQuestion_loadingAnimation__Dva8s div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes RankingQuestion_loading-animation__3Cid6 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes RankingQuestion_loading-animation__3Cid6 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Bars_container__2Wr-S {
}
.Bars_container__2Wr-S .tick line {
  visibility: hidden;
}
.Bars_container__2Wr-S .tick text {
  font-family: 'Open Sans Regular';
}
.Bars_container__2Wr-S path.domain {
  stroke: #b5b5b8;
}
.Bars_allOptionsHidden__1mlay {
  color: #95989a;
  position: absolute;
  -webkit-transform: translate(-50%, -55%);
          transform: translate(-50%, -55%);
  left: 50%;
  top: 45%;
  font-size: 14px;
}

.AnswersList_container__idIzI {
  margin-left: 40px;
  margin-right: 40px;
}
.AnswersList_answersList__3ggJK {
  margin-right: 40px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-left: 10px;
  border-top: 1px solid #e6e6e6;
}
.AnswersList_answer__ssCrS {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.AnswersList_answerColor__1M7Ol {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 25px;
}
.AnswersList_answerString__3U3C4 {
  display: inline-flex;
  flex-grow: 1;
  overflow: hidden;
}
.AnswersList_answerStringText__3H1f- {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
}
.AnswersList_answerOtherLabel__36f8f {
  margin-left: 10px;
  border-width: 1px;
  border-style: solid;
  font-size: 11px;
  padding: 2px 5px;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
}
.AnswersList_answerToggle__2tWe8 {
  text-align: right;
  margin-left: 20px;
  display: inline-block;
}
.AnswersList_answerSwitch__2UXzp {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}
.AnswersList_answerSlider__3-Fdt {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
input:checked + .AnswersList_answerSlider__3-Fdt:before {
  -webkit-transform: translateX(20px) translateY(-50%);
          transform: translateX(20px) translateY(-50%);
}
.AnswersList_answerSlider__3-Fdt:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 15px;
  left: -15px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  -webkit-transform: translateX(30px) translateY(-50%);
          transform: translateX(30px) translateY(-50%);
}
.AnswersList_input__2ge54 {
  display: none;
}
.AnswersList_otherAnswerResults__2mX8W {
  width: calc(100% - 90px);
  margin-left: 35px;
}
.AnswersList_otherAnswerResults__2mX8W.AnswersList_opened__ru7HX {
  margin-bottom: 12px;
}
.AnswersList_otherAnswerButton__2-4Vb {
  font-family: 'Open Sans SemiBold';
  font-size: 12px;
  margin-bottom: 12px;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.AnswersList_otherAnswerString__3mixC {
  display: block;
  padding: 10px 0 10px 10px;
  color: #8b8b8d;
  font-size: 12px;
}
.AnswersList_otherAnswersList__3WYCD {
  margin-left: 4px;
  border-left: 1px dotted #8b8b8d;
}
.AnswersList_textOverflowInfo__3kpQp {
  position: fixed;
  line-height: 13px;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 999999;
  color: #000;
  font-size: 11px;
  width: 400px;
}

.ResultActionPopup_popupContainer__2Q1eW {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ResultActionPopup_popup__2N16o {
  background-color: #fff;
  border-radius: 5px;
  z-index: 99999999;
  max-height: 90vh;
  font-size: 12px;
  width: 330px;
}
.ResultActionPopup_mainTitle__1LGFP {
  font-family: 'Open Sans Bold', Arial;
  font-size: 13px;
  color: #5300f2;
  padding: 25px 20px 10px 20px;
}
.ResultActionPopup_mainText__1v8MR {
  padding: 0 20px 25px 20px;
}
.ResultActionPopup_text__2IJi1 {
  display: inline-block;
  padding-left: 8px;
}
.ResultActionPopup_actionButton__3npTE {
  min-height: 30px;
  margin: 0 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ResultActionPopup_actionButton__3npTE:hover * {
  opacity: 0.7;
}
.ResultActionPopup_actionButton__3npTE.ResultActionPopup_disabledButton__10kwZ {
  cursor: default;
}
.ResultActionPopup_actionButton__3npTE.ResultActionPopup_disabledButton__10kwZ *,
.ResultActionPopup_actionButton__3npTE.ResultActionPopup_disabledButton__10kwZ:hover * {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}
.ResultActionPopup_answerList__2-730 {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: scroll;
}
.ResultActionPopup_answerList__2-730::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.ResultActionPopup_answerList__2-730::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.ResultActionPopup_answerList__2-730 .ResultActionPopup_answerListItem__3OLmj {
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.ResultActionPopup_answerList__2-730 .ResultActionPopup_visibleBox__xztfa {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 10px;
}
.ResultActionPopup_answerList__2-730 .ResultActionPopup_visibleBox__xztfa.ResultActionPopup_active__1FiL0 {
  background-color: #5300f2;
}
.ResultActionPopup_answerList__2-730 .ResultActionPopup_visibleBox__xztfa.ResultActionPopup_disabled__1bdSZ {
  background-color: gray;
  border-color: gray;
  opacity: 0.5;
  cursor: default;
}
.ResultActionPopup_answerList__2-730 .ResultActionPopup_visibleBox__xztfa .ResultActionPopup_visibleTick__1gZ6U {
  width: 10px;
}
.ResultActionPopup_answerList__2-730 .ResultActionPopup_answerListItemText__j6ydn {
  max-width: calc(100% - 50px);
  overflow: hidden;
  max-height: 100%;
  word-break: break-word;
}
.ResultActionPopup_actions__1Iqyh {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-top: 2px solid #efefef;
}
.ResultActionPopup_confirmIcon__2Zt1b {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.ResultActionPopup_confirmIconDisabled__By-mz {
  -webkit-filter: grayscale(0.8) contrast(0.1);
          filter: grayscale(0.8) contrast(0.1);
  cursor: not-allowed;
}
.ResultActionPopup_closeIcon__24oUQ {
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.ResultActionPopup_loadingContainer__1jxiI {
  padding-bottom: 5px;
}
.ResultActionPopup_description__3wAap {
  white-space: pre-line;
}

.StackedBars_stackedBarContainer__1Lj-R {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.StackedBars_stackedBarContainer__1Lj-R.StackedBars_additionalMarginBottom__Jhwdu {
  margin-bottom: 80px;
}
.StackedBars_stackedBarContainer__1Lj-R.StackedBars_noMarginBottom__1-Tw- {
  margin-bottom: 0;
}
.StackedBars_left__SO21E {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: right;
  font-size: 14px;
  border-right: 1px solid #fff;
  min-height: 80px;
}
.StackedBars_left__SO21E.StackedBars_leftIsFiltered__CrcY8 {
  border-color: #000;
  margin-bottom: 0;
}
.StackedBars_right__uGYua {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
}
.StackedBars_right__uGYua.StackedBars_rightIsFiltered__3V1YO {
  padding-top: 20px;
}
.StackedBars_right__uGYua.StackedBars_rightNoAverageScores__26ARr {
  padding-top: 4px;
}
.StackedBars_average__kIuBq {
  text-align: center;
  font-size: 12px;
}
.StackedBars_legend__1zxwD {
  text-align: center;
  margin-left: auto;
}
.StackedBars_row__3Jwxs {
  color: #000;
  margin-right: 20px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
}
.StackedBars_row__3Jwxs:last-child {
  margin-right: 0;
}
.StackedBars_row__3Jwxs .StackedBars_rectangle__1abuz {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
}
.StackedBars_bold__XYC8s {
  font-family: 'Open Sans SemiBold';
}

.McStackedBars_wrapper__ipSnr {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 85px;
  padding-right: 120px;
}

.DraftEditor-root {
  cursor: inherit !important;
}

.AnswersDistribution_resultAnswersDistributionContainer__1zGAk {
  max-height: 600px !important;
}
.AnswersDistribution_disclaimer__1MjzZ {
  font-size: 12px;
  color: #8b8b8d;
  margin-top: 20px;
}
.AnswersDistribution_actionContainer__oVoBa {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 13px;
}
.AnswersDistribution_searchAnswerInputContainer__2HrLW {
  display: inline-block;
  margin-right: 4px;
}
.AnswersDistribution_searchAnswerInputContainer__2HrLW .AnswersDistribution_searchAnswerInput__18fRX {
  min-height: 30px;
  width: 230px;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  outline: none;
  font-size: 13px;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
}
.AnswersDistribution_searchAnswerInputContainer__2HrLW .AnswersDistribution_searchAnswerInput__18fRX::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.AnswersDistribution_searchAnswerInputContainer__2HrLW .AnswersDistribution_searchAnswerInput__18fRX::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.AnswersDistribution_searchAnswerInputContainer__2HrLW .AnswersDistribution_searchAnswerInput__18fRX::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.AnswersDistribution_searchAnswerInputContainer__2HrLW .AnswersDistribution_closeIcon__3ntPd {
  position: absolute;
  right: 5px;
  line-height: 30px;
  font-size: 12px;
  color: #777777;
  cursor: pointer;
  font-family: BufflClientIcons;
  text-transform: lowercase;
}
.AnswersDistribution_actionButton__2MxQn {
  min-height: 30px;
  margin-right: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.AnswersDistribution_actionButton__2MxQn * {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.AnswersDistribution_actionButton__2MxQn:hover * {
  opacity: 0.7;
}
.AnswersDistribution_actionButton__2MxQn.AnswersDistribution_disabledButton__3k95m {
  cursor: not-allowed;
}
.AnswersDistribution_actionButton__2MxQn.AnswersDistribution_disabledButton__3k95m *,
.AnswersDistribution_actionButton__2MxQn.AnswersDistribution_disabledButton__3k95m:hover * {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}
.AnswersDistribution_actionButton__2MxQn .AnswersDistribution_buttonText__35M6l {
  display: inline-block;
  padding-left: 8px;
}
.AnswersDistribution_actionButton__2MxQn .AnswersDistribution_flagIcon__1jQUM {
  width: 14px;
  height: 14px;
}
.AnswersDistribution_actionButton__2MxQn .AnswersDistribution_trashIcon__1SPe3 {
  height: 12px;
}
.AnswersDistribution_visibleBox__Qbg7E {
  margin-right: 10px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.AnswersDistribution_visibleBox__Qbg7E.AnswersDistribution_visibleBoxActive__2VG6O {
  background-color: #5300f2;
}
.AnswersDistribution_visibleBox__Qbg7E .AnswersDistribution_visibleTick__2yNIV {
  width: 10px;
  visibility: hidden;
}
.AnswersDistribution_visibleBox__Qbg7E.AnswersDistribution_visibleBoxActive__2VG6O .AnswersDistribution_visibleTick__2yNIV {
  width: 10px;
  visibility: visible;
}
.AnswersDistribution_tickIcon__3Pl7T {
  width: 14px;
  margin-right: 6px;
}

.OpenQuestion_openQuestionContainer__Mr6sY {
  display: flex;
  flex-flow: column;
}
.OpenQuestion_openQuestionCategoriesContainer__zvhA9 {
  min-height: 30px;
}
.OpenQuestion_openQuestionDistributionContainer___LACk {
  max-height: 315px;
  flex: 2 1;
  overflow-y: scroll;
  overflow-x: visible;
  padding-left: 100px;
  padding-bottom: 10px;
  padding-right: 10px;
  border-bottom: 1px solid #dfe0e4;
}
.OpenQuestion_openQuestionAnswersContainer__4Ttcp {
  flex: 2 1;
  overflow-y: scroll;
  overflow-x: visible;
  padding-left: 100px;
  padding-bottom: 10px;
  padding-right: 10px;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb {
  border-bottom: 1px solid #dfe0e4;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #8b8b8d;
  font-size: 13px;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb:last-child {
  border-bottom: 0;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb .OpenQuestion_answerText__3cf3Q {
  width: 85%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 25px;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb .OpenQuestion_answerText__3cf3Q .OpenQuestion_categoryNameAnswerString__2Ka29 {
  margin-right: 4px;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb .OpenQuestion_answerText__3cf3Q .OpenQuestion_categoryName__1zm5t {
  display: inline-block;
  padding: 4px 8px 4px 8px;
  font-size: 11px;
  color: #000;
  margin-right: 4px;
  border-radius: 3px;
  background-color: rgba(149, 152, 154, 0.05);
  transition: background-color 0.2s ease-in-out;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb .OpenQuestion_answerText__3cf3Q .OpenQuestion_categoryName__1zm5t:hover {
  background-color: rgba(83, 0, 242, 0.05);
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb .OpenQuestion_answerText__3cf3Q .OpenQuestion_categoryName__1zm5t .OpenQuestion_categoryNameLabel__GKRQW {
  display: inline-flex;
  align-items: center;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb
  .OpenQuestion_answerText__3cf3Q
  .OpenQuestion_categoryName__1zm5t
  .OpenQuestion_categoryNameLabel__GKRQW
  .OpenQuestion_categoryText__2cUx6 {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 4px;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb
  .OpenQuestion_answerText__3cf3Q
  .OpenQuestion_categoryName__1zm5t
  .OpenQuestion_categoryNameLabel__GKRQW
  .OpenQuestion_categoryCount__OoSyn {
  color: #707070;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb .OpenQuestion_answerText__3cf3Q .OpenQuestion_categorySingleAnswerRemove__cGu53 {
  color: white;
  margin-left: 8px;
  font-size: 8px;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb
  .OpenQuestion_answerText__3cf3Q
  .OpenQuestion_categoryName__1zm5t:hover
  .OpenQuestion_categorySingleAnswerRemove__cGu53 {
  visibility: visible;
  opacity: 1;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb .OpenQuestion_answerTimeTaken__3hWg3 {
  width: 15%;
  text-align: right;
  padding-right: 5px;
  box-sizing: border-box;
}
.OpenQuestion_openQuestionAnswerContainer__3WGxb .OpenQuestion_selectIconContainer__1Dg7d {
  width: 25px;
  height: 25px;
  color: #5200f1;
  font-size: 12px;
  position: absolute;
  cursor: pointer;
  top: 12px;
  z-index: 1;
}
.OpenQuestion_categoryNameSingleLabelContainer__2rNMZ {
  display: inline-block;
}
.OpenQuestion_visibleBox__3LwYa {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.OpenQuestion_visibleBox__3LwYa.OpenQuestion_active__2t4yZ {
  background-color: #5300f2;
}
.OpenQuestion_visibleBox__3LwYa .OpenQuestion_visibleTick__2RTvM {
  width: 10px;
}
.OpenQuestion_categoryOverflowInfo__3M1y4 {
  position: fixed;
  line-height: 13px;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 999999;
  color: #000;
  font-size: 9px;
}
.OpenQuestion_heartIcon__osCsc {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: 2px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.5;
}

.CategoryList_filtersBarContainer__3LtZT {
  z-index: 99;
  justify-content: left;
  padding-top: 10px;
  padding-left: 100px;
  border-bottom: 1px solid #efefef;
  min-height: 40px;
}
.CategoryList_filtersBarContainerNoCategories__1mm2x {
  color: #8b8b8d;
  font-size: 12px;
  position: relative;
  display: inline-block;
  padding-top: 6px;
}
.CategoryList_singleActiveFilter__1JxCW {
  display: inline-block;
  font-size: 12px;
  margin-bottom: 10px;
  height: 32px;
  line-height: 32px;
  margin-right: 4px;
  border-radius: 3px;
  background-color: rgba(149, 152, 154, 0.05);
  transition: background-color 0.2s ease-in-out;
}
.CategoryList_singleActiveFilter__1JxCW:hover,
.CategoryList_singleActiveFilter__1JxCW:focus,
.CategoryList_singleActiveFilter__1JxCW:active,
.CategoryList_singleActiveFilter__1JxCW::-moz-selection {
  background-color: rgba(83, 0, 242, 0.05);
}
.CategoryList_singleActiveFilter__1JxCW:hover,
.CategoryList_singleActiveFilter__1JxCW:focus,
.CategoryList_singleActiveFilter__1JxCW:active,
.CategoryList_singleActiveFilter__1JxCW::selection {
  background-color: rgba(83, 0, 242, 0.05);
}
.CategoryList_singleActiveFilterLabel__27-LR {
  display: inline-flex;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 8px;
  box-sizing: border-box;
}
.CategoryList_singleActiveFilterLabelText__gcwQa {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 4px;
}
.CategoryList_singleActiveFilterLabelCount__3Fxj8 {
  color: #707070;
}
.CategoryList_removeSingleActiveFilter__2gmGQ {
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 9px;
  height: 30px !important;
  line-height: 30px !important;
  padding-right: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.CategoryList_singleActiveFilter__1JxCW:hover .CategoryList_removeSingleActiveFilter__2gmGQ {
  visibility: visible;
  opacity: 1;
}
.CategoryList_filtersBarDropdownButton__2JW1T {
  background-color: #5200f1;
  color: #ffffff;
  font-size: 11px;
  position: absolute;
  left: 60px;
  top: 16px;
  padding: 2px 3px 0px 3px;
  border-radius: 5px;
  cursor: pointer;
}
.CategoryList_addCategoryRowContainer__2rRyD {
  flex: 2 1;
  border-bottom: 1px solid #efefef;
  padding-left: 100px;
  padding-right: 10px;
}
.CategoryList_addCategoryRow__f99qu {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  align-items: flex-start;
}
.CategoryList_addCategoryRow__f99qu .CategoryList_selectAllContainer__3HcbC {
  display: inline-flex;
  align-items: center;
  height: 21px;
}
.CategoryList_visibleBox__OoW77 {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.CategoryList_visibleBox__OoW77.CategoryList_active__1SROM {
  background-color: #5300f2;
}
.CategoryList_visibleBox__OoW77 .CategoryList_visibleTick__1niIy {
  width: 10px;
}
.CategoryList_addCategoryRow__f99qu .CategoryList_singleActiveFilter__1JxCW {
  margin: 0px;
  border-radius: 5px;
  margin: 5px;
}
.CategoryList_addIcon__uHpR0 {
  width: 10;
  height: 10px;
}
.CategoryList_addCategoryRow__f99qu .CategoryList_selectAllText__2odEf {
  padding-left: 10px;
}

.CategoryList_searchCategoryContainer__1sLQc {
  display: flex;
  padding: 20px 10px 0px 100px;
  font-size: 13px;
  align-items: flex-start;
  flex-wrap: wrap;
}
.CategoryList_searchAnswerInputContainer__304Iu {
  display: inline-block;
  margin-right: 4px;
}
.CategoryList_searchAnswerInputContainer__304Iu .CategoryList_searchAnswerInput__MN_A- {
  min-height: 30px;
  width: 230px;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  outline: none;
  font-size: 13px;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
}
.CategoryList_searchAnswerInputContainer__304Iu .CategoryList_closeIcon__AGhlX {
  position: absolute;
  right: 5px;
  line-height: 32px;
  font-size: 12px;
  color: #777777;
  cursor: pointer;
  font-family: BufflClientIcons;
  text-transform: lowercase;
}
.CategoryList_categoryInput__8S0EW {
  padding: 5px 0px 5px 0px;
  outline: none;
  border: none;
  color: #8b8b8d;
  font-size: inherit;
  width: inherit;
  background-color: inherit;
  font-family: inherit;
}
.CategoryList_doubleClickHint__3XotP {
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  position: absolute;
  padding: 0px 5px;
  top: 39px;
  left: 0px;
  z-index: -1;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: #000000;
  visibility: hidden;
}
.CategoryList_singleActiveFilter__1JxCW:hover .CategoryList_doubleClickHint__3XotP {
  z-index: 1;
  visibility: visible;
}

.CategoryList_actionButton__1bb1w {
  min-height: 30px;
  margin-right: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.CategoryList_actionButton__1bb1w * {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.CategoryList_actionButton__1bb1w:hover * {
  opacity: 0.7;
}
.CategoryList_actionButton__1bb1w.CategoryList_disabledButton__mGAVK {
  cursor: not-allowed;
}
.CategoryList_actionButton__1bb1w.CategoryList_disabledButton__mGAVK *,
.CategoryList_actionButton__1bb1w.CategoryList_disabledButton__mGAVK:hover * {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}
.CategoryList_actionButton__1bb1w .CategoryList_buttonText__1xjep {
  display: inline-block;
  padding-left: 8px;
}
.CategoryList_actionButton__1bb1w .CategoryList_flagIcon__1_742 {
  width: 14px;
  height: 14px;
}
.CategoryList_actionButton__1bb1w .CategoryList_trashIcon__1Cp7D {
  height: 12px;
}
.CategoryList_categoryOverflowInfo__2T83O {
  position: fixed;
  line-height: 13px;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 999999;
  color: #000;
  font-size: 10px;
}

.CategoryPopup_popupContainer__3_isb {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CategoryPopup_popup__3AKxF {
  background-color: #fff;
  border-radius: 5px;
  z-index: 99999999;
  max-height: 90vh;
  font-size: 12px;
  width: 500px;
}
.CategoryPopup_mainTitle__aoGl2 {
  font-family: 'Open Sans Bold', Arial;
  font-size: 13px;
  color: #5300f2;
  padding: 25px 20px 10px 20px;
}
.CategoryPopup_mainText__2eAs5 {
  padding: 0 20px 25px 20px;
}
.CategoryPopup_text__16X6c {
  display: inline-block;
  padding-left: 8px;
}
.CategoryPopup_categoryActions__QHZf4 {
  display: flex;
  margin-bottom: 10px;
}
.CategoryPopup_searchAnswerInputContainer__pXwHM {
  flex-grow: 1;
}
.CategoryPopup_searchAnswerInputContainer__pXwHM .CategoryPopup_searchAnswerInput__LcjIz {
  min-height: 30px;
  width: 100%;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  outline: none;
  font-size: 13px;
  border-radius: 3px;
  box-sizing: border-box;
}
.CategoryPopup_searchAnswerInputContainer__pXwHM .CategoryPopup_closeIcon__1IIH_ {
  position: absolute;
  right: 0;
  line-height: 32px;
  font-size: 12px;
  color: #777777;
  cursor: pointer;
  font-family: BufflClientIcons;
  text-transform: lowercase;
}
.CategoryPopup_actionButton__YzVru {
  min-height: 30px;
  margin-left: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
}
.CategoryPopup_actionButton__YzVru:hover * {
  opacity: 0.7;
}
.CategoryPopup_actionButton__YzVru.CategoryPopup_disabledButton__3qGhy:hover * {
  opacity: 0.5;
}
.CategoryPopup_disabledButton__3qGhy {
  cursor: default;
}
.CategoryPopup_disabledButton__3qGhy * {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}
.CategoryPopup_actionButton__YzVru .CategoryPopup_addIcon__XQ2JK {
  height: 10px;
  width: 10px;
}
.CategoryPopup_disabled__1YKi6 {
  opacity: 0.5;
}
.CategoryPopup_categoryList__3MUZE {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: scroll;
}
.CategoryPopup_categoryList__3MUZE::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.CategoryPopup_categoryList__3MUZE::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.CategoryPopup_categoryList__3MUZE .CategoryPopup_categoryListItem__3v3ST {
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.CategoryPopup_categoryList__3MUZE .CategoryPopup_visibleBox__1XTZ8 {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 10px;
}
.CategoryPopup_categoryList__3MUZE .CategoryPopup_visibleBox__1XTZ8.CategoryPopup_active__1cusg {
  background-color: #5300f2;
}
.CategoryPopup_categoryList__3MUZE .CategoryPopup_visibleBox__1XTZ8.CategoryPopup_disabled__1YKi6 {
  background-color: gray;
  border-color: gray;
  opacity: 0.5;
  cursor: default;
}
.CategoryPopup_categoryList__3MUZE .CategoryPopup_visibleBox__1XTZ8 .CategoryPopup_visibleTick__1CmgU {
  width: 10px;
}
.CategoryPopup_categoryList__3MUZE .CategoryPopup_categoryListItemText__2g-Wp {
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.CategoryPopup_actions___I_Vv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-top: 2px solid #efefef;
}
.CategoryPopup_confirmIcon__1c4Ls {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.CategoryPopup_confirmIconDisabled__JtSOq {
  -webkit-filter: grayscale(0.8) contrast(0.1);
          filter: grayscale(0.8) contrast(0.1);
  cursor: not-allowed;
}
.CategoryPopup_closeIcon__1IIH_ {
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.CategoriesDistribution_barContainer__2UPI9 {
  font-size: 14px;
  color: #221f1f;
  margin-top: 10px;
  margin-bottom: 20px;
}
.CategoriesDistribution_barLabel__ZsIqH {
  width: 90%;
  padding-right: 20px;
  margin-bottom: 3px;
  z-index: 9;
}

.CategoriesDistribution_barRectangleContainer__1hg8e {
  display: flex;
}
.CategoriesDistribution_barSingleRectangleContainer__18xSq {
  width: 75%;
  height: 11px;
  background-color: #ebecf0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 3px;
  cursor: pointer;
}
.CategoriesDistribution_unfilteredBarContainer__2IE3C {
  height: 15px;
}
.CategoriesDistribution_barSingleRectangle__3rnNT {
  height: 11px;
  background-color: #5200f1;
  cursor: pointer;
}
.CategoriesDistribution_unfiltered__1AG_X {
  font-size: 10px;
}
.CategoriesDistribution_barUnfilteredSingleRectangle__p6pnF {
  height: 5px;
  background-color: #8b8b8d;
  cursor: default;
}

.CategoriesDistribution_percentage__2modT {
  font-family: 'Roboto Bold';
  width: 10%;
  padding-left: 20px;
}
.CategoriesDistribution_answers__2oUEN {
  font-family: 'Roboto Bold';
  width: 15%;
  text-align: right;
  padding-right: 5px;
  box-sizing: border-box;
}

.AnswerProbablityBars_resultsAnswerPercentageRange__3GI5S {
  color: #ad8dea;
  font-family: 'Open Sans Regular';
  font-size: 12px;
  cursor: default;
  position: absolute;
  width: 200px;
}
.AnswerProbablityBars_resultsAnswerBarContainer__35n1p {
  overflow: visible !important;
  cursor: default !important;
}
.AnswerProbablityBars_resultsAnswerCentralValueBar__1GAyB {
  height: 17px;
  background-color: #5200f1;
  border-radius: 10px;
  cursor: default;
  position: absolute;
  z-index: 99;
  top: -2px;
}
.AnswerProbablityBars_resultsAnswerRangeBar__69vAe {
  height: 11px;
  background-color: #d2c4ef;
  border-radius: 10px;
  cursor: default;
}
.AnswerProbablityBars_loadingAnimation__3UZ5d {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.AnswerProbablityBars_loadingAnimation__3UZ5d div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: AnswerProbablityBars_loading-animation__2gtCN 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: AnswerProbablityBars_loading-animation__2gtCN 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.AnswerProbablityBars_loadingAnimation__3UZ5d div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.AnswerProbablityBars_loadingAnimation__3UZ5d div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.AnswerProbablityBars_loadingAnimation__3UZ5d div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes AnswerProbablityBars_loading-animation__2gtCN {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes AnswerProbablityBars_loading-animation__2gtCN {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.WordCloud_wordCloudContainer__TAdwo {
  font-size: 12px;
}

.WordCloud_actionsBar__2B9_7 {
  border-bottom: 1px solid #efefef;
  display: flex;
}
.WordCloud_actionsBarLeft__3H8il {
  display: inline-flex;
  align-items: center;
  width: 28%;
  box-sizing: border-box;
  padding: 15px 20px 15px 30px;
}
.WordCloud_actionsBarRight__3cQ6L {
  display: inline-flex;
  width: 72%;
  box-sizing: border-box;
  padding: 15px 0 15px 15px;
  border-left: 1px solid #efefef;
}
.WordCloud_searchAnswerInputContainer__2DFma {
  width: 100%;
}
.WordCloud_searchAnswerInputContainer__2DFma .WordCloud_searchAnswerInput__Xf7Su {
  height: 30px;
  width: 100%;
  line-height: 30px;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  outline: none;
  font-size: 13px;
  border-radius: 3px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
}
.WordCloud_searchAnswerInputContainer__2DFma .WordCloud_searchAnswerInput__Xf7Su::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.WordCloud_searchAnswerInputContainer__2DFma .WordCloud_searchAnswerInput__Xf7Su::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.WordCloud_searchAnswerInputContainer__2DFma .WordCloud_searchAnswerInput__Xf7Su::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.WordCloud_searchAnswerInputContainer__2DFma .WordCloud_closeIcon__1yoVz {
  position: absolute;
  right: 5px;
  line-height: 30px;
  font-size: 12px;
  color: #777777;
  cursor: pointer;
  font-family: BufflClientIcons;
  text-transform: lowercase;
}
.WordCloud_hideAll__25-5Z {
  display: flex;
  align-items: center;
  padding-left: 30px;
  box-sizing: border-box;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #efefef;
}
.WordCloud_hideAll__25-5Z .WordCloud_visibleBox__2URsj {
  margin-left: 0;
}
.WordCloud_hideAll__25-5Z .WordCloud_visibleBox__2URsj .WordCloud_hideAllLine__1k5Ba {
  position: absolute;
  top: 4px;
  left: 1px;
  width: 8px;
  height: 1px;
  background-color: #fff;
}
.WordCloud_actionButton__3hcEX {
  min-height: 30px;
  margin: 0 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  padding: 0 8px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
}
.WordCloud_actionButton__3hcEX:hover * {
  opacity: 0.7;
}
.WordCloud_actionButton__3hcEX.WordCloud_disabledButton__2Qyun:hover * {
  opacity: 0.5;
}
.WordCloud_disabledButton__2Qyun {
  cursor: default;
}
.WordCloud_disabledButton__2Qyun * {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}
.WordCloud_mergeIcon__TFz59 {
  width: 13px;
  height: 13px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.WordCloud_listMergeIcon__3xidt {
  width: 15px;
  height: 15px;
  margin-left: 12px;
  cursor: pointer;
}
.WordCloud_hideIcon__1c7Hj {
  width: 12px;
  height: 12px;
}
.WordCloud_actionButton__3hcEX .WordCloud_addIcon__1gcA0 {
  height: 10px;
  width: 10px;
}

.WordCloud_sidebar__2q25m {
  display: inline-block;
  width: 28%;
}
.WordCloud_inActive__3-40m {
  color: #8b8b8d;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.WordCloud_active__3tR9Z {
  color: #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.WordCloud_filterArrowDown__3aOOj {
  height: 14px;
  vertical-align: middle;
  margin-left: 2px;
}
.WordCloud_filterArrowUp__2n8IC {
  height: 14px;
  vertical-align: middle;
  margin-left: 2px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.WordCloud_sideBarList__1vX2o {
  box-sizing: border-box;
  max-height: 500px;
  height: 500px;
  overflow-y: scroll;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.WordCloud_sideBarList__1vX2o .WordCloud_oddWord__3BXhY {
  background-color: rgba(83, 0, 242, 0.03);
}
.WordCloud_sideBarList__1vX2o .WordCloud_listItem__3I_Fv {
  padding: 15px 0;
  display: flex;
  height: 50px;
  box-sizing: border-box;
  background-color: rgba(83, 0, 242, 0.01);
  align-items: center;
}
.WordCloud_text__35H93 {
  display: inline-block;
  padding-left: 8px;
}
.WordCloud_wordListText__Y06r1 {
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.WordCloud_visibleBox__2URsj {
  margin-left: 30px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.WordCloud_visibleBox__2URsj.WordCloud_active__3tR9Z {
  background-color: #5300f2;
}
.WordCloud_visibleTick__2kj7G {
  width: 10px;
}

.WordCloud_mainContent__13BI_ {
  display: inline-block;
  width: 72%;
}
.WordCloud_cloudVersionsContainer__3lvoF {
  height: 60px;
  line-height: 60px;
  border-left: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsTabs__1shvh {
  display: inline-block;
  margin-left: 15px;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsTabs__1shvh .WordCloud_cloudTabNameInput__3foiI {
  color: #000;
  font-family: 'Roboto Bold';
  font-size: 1rem;
  margin: 19px 13px;
  outline: 1px solid #cacaca;
  border: none;
  float: left;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsTabs__1shvh .WordCloud_cloudTabContainer__2zk-X {
  display: inline-block;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsTabs__1shvh .WordCloud_cloudTab__CLwbJ {
  font-family: 'Roboto Bold';
  margin: 0 15px;
  color: #8b8b8d;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  max-width: 130px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  display: inline;
  float: left;
  z-index: 1;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsTabs__1shvh .WordCloud_cloudTab__CLwbJ.WordCloud_cloudTabActive__sSkfH {
  color: #000;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsTabs__1shvh .WordCloud_nameOverflowInfo__1r3VH {
  position: absolute;
  top: 10px;
  left: 145px;
  line-height: 13px;
  width: 115px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 2;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsActions__2gC6x {
  display: inline-block;
  margin-right: 15px;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsActions__2gC6x .WordCloud_actionIconContainer__3J1yt {
  display: inline-block;
  position: relative;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsActions__2gC6x .WordCloud_actionIconContainer__3J1yt .WordCloud_addIcon__1gcA0 {
  height: 11px;
}
.WordCloud_cloudVersionsContainer__3lvoF
  .WordCloud_cloudVersionsActions__2gC6x
  .WordCloud_actionIconContainer__3J1yt
  .WordCloud_addDisabledInfo__-869a,
.WordCloud_cloudVersionsContainer__3lvoF
  .WordCloud_cloudVersionsActions__2gC6x
  .WordCloud_actionIconContainer__3J1yt
  .WordCloud_trashDisabledInfo__2VWSR {
  position: absolute;
  top: 10px;
  right: 15px;
  line-height: 13px;
  width: 135px;
  padding: 5px 10px;
  border-radius: 4px;
  display: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
}
.WordCloud_cloudVersionsContainer__3lvoF
  .WordCloud_cloudVersionsActions__2gC6x
  .WordCloud_actionIconContainer__3J1yt
  .WordCloud_addIcon__1gcA0:hover
  + .WordCloud_addDisabledInfo__-869a,
.WordCloud_cloudVersionsContainer__3lvoF
  .WordCloud_cloudVersionsActions__2gC6x
  .WordCloud_actionIconContainer__3J1yt
  .WordCloud_trashIcon__137zr:hover
  + .WordCloud_trashDisabledInfo__2VWSR {
  display: inline-block !important;
}
.WordCloud_cloudVersionsContainer__3lvoF
  .WordCloud_cloudVersionsActions__2gC6x
  .WordCloud_actionIconContainer__3J1yt
  .WordCloud_addDisabledInfo__-869a:hover,
.WordCloud_cloudVersionsContainer__3lvoF
  .WordCloud_cloudVersionsActions__2gC6x
  .WordCloud_actionIconContainer__3J1yt
  .WordCloud_trashDisabledInfo__2VWSR:hover {
  display: inline-block;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsActions__2gC6x .WordCloud_actionIconContainer__3J1yt .WordCloud_trashIcon__137zr {
  height: 12px;
  margin-left: 12px;
}
.WordCloud_cloudVersionsContainer__3lvoF
  .WordCloud_cloudVersionsActions__2gC6x
  .WordCloud_addIcon__1gcA0.WordCloud_versionActionActive__3FDac:hover,
.WordCloud_cloudVersionsContainer__3lvoF
  .WordCloud_cloudVersionsActions__2gC6x
  .WordCloud_trashIcon__137zr.WordCloud_versionActionActive__3FDac:hover {
  opacity: 0.7;
  cursor: pointer;
}
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsActions__2gC6x .WordCloud_addIcon__1gcA0.WordCloud_versionActionDisabled__18qFb,
.WordCloud_cloudVersionsContainer__3lvoF .WordCloud_cloudVersionsActions__2gC6x .WordCloud_trashIcon__137zr.WordCloud_versionActionDisabled__18qFb {
  -webkit-filter: grayscale(1) opacity(0.4);
          filter: grayscale(1) opacity(0.4);
}

.WordCloud_cloudContainer__2DEL0 {
  border-left: 1px solid #efefef;
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.WordCloud_noWords__27fG0 {
  color: #95989a;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  font-size: 14px;
}
.WordCloud_dropDownContainer__1U-II {
  min-height: 30px;
  line-height: 30px;
  padding: 0 23px 0 8px;
  border: 1px solid #d4d4d4;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-repeat: no-repeat;
  background-position: 95% 12px;
  background-size: 10px 5px;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 4px;
}
.WordCloud_filterDropDown__1WDWG {
  min-width: 125px;
  width: 150px;
}
.WordCloud_propertyDropDown__1L68P {
  min-width: 110px;
  width: 110px;
}
.WordCloud_dropDownArrow__1X7dr {
  width: 10px;
  height: 5px;
  position: absolute;
  top: 12px;
  right: 8px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.WordCloud_dropDownArrowActive__3pNaP {
  -webkit-transform: rotate(180deg) scaleX(-1);
          transform: rotate(180deg) scaleX(-1);
}
.WordCloud_dropDown__3vm67 {
  width: 100%;
  position: absolute;
  top: 35px;
  left: -1px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  text-align: left;
  z-index: 100;
  background-color: #fff;
}
.WordCloud_dropDownOption__1ZAUJ {
  cursor: pointer;
  width: 100%;
  padding-left: 8px;
  box-sizing: border-box;
}
.WordCloud_dropDownOption__1ZAUJ:hover {
  background-color: #edeef1;
}
.WordCloud_wordOverflowInfo__2o8X7 {
  position: fixed;
  line-height: 13px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dfe0e4;
  z-index: 999999;
}

.MergePopup_popupContainer__3GYJy {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MergePopup_popup__2aLcX {
  background-color: #fff;
  border-radius: 5px;
  z-index: 99999999;
  max-height: 90vh;
  font-size: 12px;
  width: 320px;
}
.MergePopup_mainTitle__2nN5P {
  font-family: 'Open Sans Bold';
  font-size: 13px;
  color: #5300f2;
  padding: 25px 20px 10px 20px;
}
.MergePopup_wordsToMerge__2vE46 {
  padding: 0 20px;
}
.MergePopup_wordsToMerge__2vE46 .MergePopup_wordsToMergeList__1RLdw {
  margin: 10px 0;
  max-height: 200px;
  overflow-y: scroll;
}
.MergePopup_wordsToMerge__2vE46 .MergePopup_wordsToMergeList__1RLdw::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.MergePopup_wordsToMerge__2vE46 .MergePopup_wordsToMergeList__1RLdw::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.MergePopup_wordsToMerge__2vE46 .MergePopup_wordsToMergeList__1RLdw .MergePopup_wordsToMergeListItem__2ptbW {
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.MergePopup_wordsToMerge__2vE46 .MergePopup_wordsToMergeList__1RLdw .MergePopup_visibleBox__3GH4E {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 10px;
}
.MergePopup_wordsToMerge__2vE46 .MergePopup_wordsToMergeList__1RLdw .MergePopup_visibleBox__3GH4E.MergePopup_active__1H6fO {
  background-color: #5300f2;
}
.MergePopup_wordsToMerge__2vE46 .MergePopup_wordsToMergeList__1RLdw .MergePopup_visibleBox__3GH4E.MergePopup_disabled__1B3P9 {
  background-color: gray;
  border-color: gray;
  opacity: 0.5;
  cursor: default;
}
.MergePopup_wordsToMerge__2vE46 .MergePopup_wordsToMergeList__1RLdw .MergePopup_visibleBox__3GH4E .MergePopup_visibleTick__2Dsa5 {
  width: 10px;
}
.MergePopup_wordsToMerge__2vE46 .MergePopup_wordsToMergeList__1RLdw .MergePopup_wordsToMergeListItemText__3f0gq {
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}
.MergePopup_popupTitle__3o9Lr {
  padding: 5px 20px 10px 20px;
}
.MergePopup_inputContainer__Uta-m {
  padding: 0 20px 15px 20px;
}
.MergePopup_inputContainer__Uta-m .MergePopup_inputContainerInput__XBkd1 {
  background-color: #ffffff;
  border: 1px solid #efefef;
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  padding: 0 10px;
  width: 250px;
  outline: none;
  border-radius: 2px;
}
.MergePopup_errorContainer__14N-g {
  color: #fc035d;
  background-color: #fff2f7;
  padding: 10px 20px;
  max-width: 320px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.MergePopup_errorContainer__14N-g .MergePopup_errorIcon__2LeUZ {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  float: left;
}
.MergePopup_actions__S0i8g {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-top: 2px solid #efefef;
}
.MergePopup_confirmIcon__AZOf_ {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.MergePopup_confirmIconDisabled__-BaMH {
  -webkit-filter: grayscale(0.8) contrast(0.1);
          filter: grayscale(0.8) contrast(0.1);
  cursor: not-allowed;
}
.MergePopup_closeIcon__3A33R {
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.HidePopup_popupContainer__2WYMe {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HidePopup_popup__18xKP {
  background-color: #fff;
  border-radius: 5px;
  z-index: 99999999;
  max-height: 90vh;
  font-size: 12px;
  width: 320px;
}
.HidePopup_mainTitle__3TZi7 {
  font-family: 'Open Sans Bold', Arial;
  font-size: 13px;
  color: #5300f2;
  padding: 25px 20px 10px 20px;
}
.HidePopup_mainText__3xEbw {
  padding: 0 20px 25px 20px;
}
.HidePopup_wordsToHideList__12FVk {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: scroll;
}
.HidePopup_wordsToHideList__12FVk::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.HidePopup_wordsToHideList__12FVk::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.HidePopup_wordsToHideList__12FVk .HidePopup_wordsToHideListItem__LfXvh {
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.HidePopup_wordsToHideList__12FVk .HidePopup_visibleBox__1oT48 {
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 10px;
}
.HidePopup_wordsToHideList__12FVk .HidePopup_visibleBox__1oT48.HidePopup_active__26U1k {
  background-color: #5300f2;
}
.HidePopup_wordsToHideList__12FVk .HidePopup_visibleBox__1oT48.HidePopup_disabled__6YfZH {
  background-color: gray;
  border-color: gray;
  opacity: 0.5;
  cursor: default;
}
.HidePopup_wordsToHideList__12FVk .HidePopup_visibleBox__1oT48 .HidePopup_visibleTick__2TFbc {
  width: 10px;
}
.HidePopup_wordsToHideList__12FVk .HidePopup_wordsToHideListItemText__1L_AI {
  max-width: calc(100% - 50px);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}
.HidePopup_actions__BibgA {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  border-top: 2px solid #efefef;
}
.HidePopup_confirmIcon__wFfad {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.HidePopup_confirmIconDisabled__BjfGt {
  -webkit-filter: grayscale(0.8) contrast(0.1);
          filter: grayscale(0.8) contrast(0.1);
  cursor: not-allowed;
}
.HidePopup_closeIcon__2kpKQ {
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.Bars_container__2MCKU {
}
.Bars_container__2MCKU .tick line {
  visibility: hidden;
}
.Bars_container__2MCKU .tick text {
  font-family: 'Open Sans Regular';
}
.Bars_container__2MCKU path.domain {
  stroke: #b5b5b8;
}

.QuestionsList_container__2RxM2 {
}
.QuestionsList_questionsList__38Fcd {
  margin-left: 125px;
  margin-right: 40px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-left: 10px;
  border-top: 1px solid #e6e6e6;
}
.QuestionsList_question__WWEOb {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.QuestionsList_questionColor__1xdIF {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 20px;
}
.QuestionsList_questionNumber__wcJrC {
}
.QuestionsList_questionString__3I_sH {
  margin-left: 50px;
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.QuestionsList_questionString__3I_sH.QuestionsList_matrixQuestionString__1HFc3 {
  margin-left: 0;
  white-space: pre-line;
}
.QuestionsList_questionToggle__27Ipv {
  text-align: right;
  margin-left: 20px;
  display: inline-block;
}
.QuestionsList_questionSwitch__8AABi {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}
.QuestionsList_questionSlider__rnX1e {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
input:checked + .QuestionsList_questionSlider__rnX1e:before {
  -webkit-transform: translateX(20px) translateY(-50%);
          transform: translateX(20px) translateY(-50%);
}
.QuestionsList_questionSlider__rnX1e:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 15px;
  left: -15px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  -webkit-transform: translateX(30px) translateY(-50%);
          transform: translateX(30px) translateY(-50%);
}
.QuestionsList_input__16orS {
  display: none;
}

.MatrixStackedBars_wrapper__Gu8gI {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 85px;
  padding-right: 120px;
}
.MatrixStackedBars_border__qUwbB {
  margin-top: 60px;
  margin-bottom: 40px;
  padding-left: 85px;
  padding-right: 120px;

  border-bottom: 1px solid #efefef;
}

.MergedGraph_container__2jVLl {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 15px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
.MergedGraph_blockTitle__3YsKA {
  display: flex;
  margin-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.MergedGraph_blockIcon__2HI00 {
  margin-left: 56px;
  width: 35px;
  color: #8500f0;
  margin-top: 4px;
}
.MergedGraph_blockIcon__2HI00 img {
  width: 16px;
  height: 16px;
}
.MergedGraph_chartContainer__1XtLb {
  margin-bottom: 15px;
  margin-left: 95px;
  margin-right: 40px;
}
.MergedGraph_opportunityChartContainer__xWx6k {
  margin-bottom: 15px;
  margin-left: 15x;
  margin-right: 15px;
}
.MergedGraph_removeMergedGraph__3-r-0 {
  position: absolute;
  top: 0;
  right: 40px;
  font-size: 12px;
  color: #707070;
}
.MergedGraph_editMergedGraph__1nEoI {
  position: absolute;
  top: 13px;
  right: 65px;
  font-size: 12px;
  color: #707070;
  cursor: pointer;
}
.MergedGraph_blockContent__3-UXy {
  width: 100%;
}
.MergedGraph_opportunityGraphContent__1iFMH {
  display: flex;
}
.MergedGraph_blockText__2i6ef {
  margin-left: 6px;
  font-size: 18px;
  color: #221f1f;
}
.MergedGraph_loadingAnimation__100UW {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.MergedGraph_loadingAnimation__100UW div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: MergedGraph_loading-animation__2TuMo 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: MergedGraph_loading-animation__2TuMo 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.MergedGraph_loadingAnimation__100UW div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.MergedGraph_loadingAnimation__100UW div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.MergedGraph_loadingAnimation__100UW div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes MergedGraph_loading-animation__2TuMo {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes MergedGraph_loading-animation__2TuMo {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.MergedGraph_noData__1gNkt {
  width: 100%;
}
.MergedGraph_graphInfoContainer__1FAlg {
  flex-grow: 1;
  min-width: 0;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
}
.MergedGraph_disclaimer__1cOdS {
  font-size: 12px;
  color: #8b8b8d;
  padding: 0 30px 20px 95px;
}

.Toolbar_container__Acp3O {
  background-color: #fff;
  padding: 0 30px 0 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 0;
  height: 0;
  max-width: 100%;
  line-height: 30px;
  visibility: hidden;
  opacity: 0;
  transition: min-height 0.25s ease-in-out, opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out, padding 0.2s ease-in-out;
  border-bottom: 1px solid #efefef;
}
.Toolbar_container__Acp3O.Toolbar_containerActive__1uGLi {
  opacity: 1;
  min-height: 30px;
  visibility: visible;
  padding: 8px 0;
  padding: 8px 25px 8px 0;
  height: auto;
}
.Toolbar_subContainer__2RIQw {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.Toolbar_toolbarItem__24SYx {
  border-right: 1px solid #e9e5f0;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  margin: 2px 0;
}
.Toolbar_greyTextToolbarItem__rZN7G {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  color: #95989a;
  font-style: italic;
}
.Toolbar_toolbarItem__24SYx.Toolbar_clickable__m4a_l:hover {
  cursor: pointer;
  opacity: 0.7;
}
.Toolbar_toolbarItem__24SYx.Toolbar_verticalAlign__4ch0S {
  display: inline-flex;
  align-items: center;
}
.Toolbar_toolbarItem__24SYx:first-of-type {
  padding-left: 0;
}
.Toolbar_toolbarItem__24SYx:last-of-type {
  padding-right: 0;
  border: none;
}
.Toolbar_dropdownContainer__2qMOT {
  display: inline-block;
  min-width: 150px;
  line-height: 30px;
  padding: 0 23px 0 8px;
  border: 1px solid #d4d4d4;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: left;
  color: #221f1f;
  background-color: #fff;
}
.Toolbar_dropdownArrowActive__NMzam {
  -webkit-transform: rotate(180deg) scaleX(-1);
          transform: rotate(180deg) scaleX(-1);
}
.Toolbar_dropdownActive__3MdG4 {
  border-radius: 3px 3px 0 0;
}
.Toolbar_dropdownArrow__3AdXC {
  width: 10px;
  height: 5px;
  position: absolute;
  top: 12px;
  right: 8px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.Toolbar_dropdownList__3DQDZ {
  width: 100%;
  position: absolute;
  top: 35px;
  left: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 3px;
  text-align: left;
  z-index: 100;
  background-color: #fff;
}
.Toolbar_dropdownOption__2IVGq {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  padding-left: 8px;
  box-sizing: border-box;
  color: #221f1f;
}
.Toolbar_dropdownOption__2IVGq:hover {
  background-color: #edeef1;
}
.Toolbar_toolbarItemRandom__39awC {
  padding-left: 4px;
}
.Toolbar_randomDisabled__1ieha {
  color: #95989a;
  padding-left: 4px;
}
.Toolbar_visibleBox__SlRzg {
  margin-right: 10px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Toolbar_visibleBox__SlRzg.Toolbar_visibleBoxActive__2B0Uh {
  background-color: #5300f2;
}
.Toolbar_visibleBox__SlRzg .Toolbar_visibleTick__Pa9ke {
  width: 10px;
  visibility: hidden;
}
.Toolbar_visibleBox__SlRzg.Toolbar_visibleBoxActive__2B0Uh .Toolbar_visibleTick__Pa9ke {
  width: 10px;
  visibility: visible;
}
.Toolbar_tickIcon__2nNLj {
  width: 14px;
  margin-right: 6px;
}
.Toolbar_crossIcon__3KvMv {
  height: 19px;
  width: 19px;
  vertical-align: text-bottom;
  margin-right: 2px;
}
.Toolbar_dropDownToolbarIcon__5VjM6 {
  position: absolute;
  top: 10px;
  right: 0;
  width: 14px;
  height: 9px;
  margin: 9px 12px 0 auto;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.Toolbar_dropDownToolbarIcon__5VjM6.Toolbar_dropDownToolbarIconInactive__3PhXT {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.AddInsight_addInsightButton__3aCpc {
  display: inline-block;
  line-height: 30px;
  padding: 0 10px 0 8px;
  margin-left: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  border: 1px solid #d4d4d4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: left;
  color: #221f1f;
  background-color: #fff;
}
.AddInsight_plusIcon__3wdA0 {
  color: #5300f2;
}
.AddInsight_backgroundContainer__1QN-1 {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AddInsight_popupContainer__2XLOr {
  background-color: #fff;
  padding: 30px 0 15px 0;
  border: 1px solid #707070;
  border-radius: 8px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
}
.AddInsight_popupContent__MZeLf {
  width: 600px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.AddInsight_title__4qiGf {
  font-family: 'Open Sans Bold';
  color: #5200f5;
  padding-bottom: 20px;
}

.AddInsight_footer__1rOwC {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
}
.AddInsight_footer__1rOwC .AddInsight_details__15iW9 {
  color: #5200f1;
}
.AddInsight_footer__1rOwC .AddInsight_footerErrorBackground__2LuVQ {
  position: absolute;
  width: calc(100% + 50px);
  height: calc(100% + 25px);
  left: -25px;
  top: 0;
  background-color: #fff2f7;
}
.AddInsight_footer__1rOwC .AddInsight_error__NFwxG {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.AddInsight_footer__1rOwC .AddInsight_error__NFwxG .AddInsight_warningIcon__3MCpx {
  height: 22px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.AddInsight_actions__2ZFDp .AddInsight_confirm__1UITi {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.AddInsight_actions__2ZFDp .AddInsight_close__KaSTh {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.AddInsight_inputElement__1ecZM {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  font-size: 14px;
  width: calc(100% - 12px);
}
.AddInsight_textareaElement__3sQWS {
  font-family: 'Open Sans Regular';
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  font-size: 14px;
  resize: none;
  width: calc(100% - 12px);
}

.RespondentsLegend_sample__6wdF9 {
  display: inline-block;
  margin-right: 10px;
}
.RespondentsLegend_bullet__3OrNo {
  display: inline-block;
  border-radius: 100%;
  width: 4px;
  height: 4px;
  margin-right: 4px;
  margin-bottom: 2px;
}

.filters-bar-container {
  width: 1010px;
  z-index: 100;
  justify-content: left;
  padding: 5px 0 10px 20px;
  display: flex;
  flex-wrap: wrap;
}
.filters-bar-container .active-filters-title {
  color: #221f1f;
  font-family: 'Open Sans Bold';
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  margin-right: 15px;
}
.filters-bar-container .reset-all-filters {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
}
.filters-bar-container .reset-all-filters:hover {
  opacity: 0.6;
}
.filters-bar-container .active-filter-row {
  display: flex;
  flex-wrap: wrap;
}
.filters-bar-container .single-active-filter {
  display: inline-block;
  background-color: #ffffff;
  border-radius: 3px;
  font-size: 12px;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 30px;
  overflow: hidden;
  background-color: rgba(149, 152, 154, 0.05);
  transition: background-color 0.2s ease-in-out;
}
.filters-bar-container .single-active-filter.nested-active-filter {
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  height: auto;
}
.filters-bar-container [class~='single-active-filter']:last-of-type {
  margin-right: 15px;
}
.filters-bar-container .single-active-filter:hover {
  background-color: rgba(83, 0, 242, 0.05);
}
.filters-bar-container .single-active-filter.single-active-filter-warning {
  background-color: rgba(252, 3, 93, 0.05);
}
.filters-bar-container .single-active-filter .single-active-filter-label {
  display: inline-flex;
  max-width: 380px;
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.filters-bar-container
  .single-active-filter
  .single-active-filter-label
  .single-active-extreme-user-filter-name {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 5px;
}
.filters-bar-container
  .single-active-filter
  .single-active-filter-label
  .single-active-filter-label-parentheses {
  color: #707070;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filters-bar-container
  .single-active-filter
  .single-active-filter-label
  .single-active-filter-overflow {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  height: auto;
}
.filters-bar-container .single-active-filter-relations {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #707070;
  border: 1px solid #d3d3d3;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 70px;
  box-sizing: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.filters-bar-container
  .single-active-filter-relations.single-active-filter-relations-active {
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}
.filters-bar-container
  .single-active-filter-relations
  .single-active-filter-relations-dropdown {
  width: 70px;
  position: absolute;
  top: 29px;
  left: -1px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 0 0 5px 5px;
  text-align: left;
  z-index: 100;
  background-color: #fff;
  padding-left: 10px;
  box-sizing: border-box;
  height: 20px;
  line-height: 20px;
}
.filters-bar-container
  .single-active-filter-relations
  .single-active-filter-relations-dropdown-icon {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  -webkit-transform: translateY(5px);
          transform: translateY(5px);
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}
.filters-bar-container
  .single-active-filter
  .nested-active-filter-filters-relation {
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #707070;
}
.filters-bar-container .single-active-filter .remove-single-active-filter {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 9px;
  height: 30px !important;
  line-height: 30px !important;
  padding-left: 10px;
  padding-right: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.filters-bar-container
  .single-active-filter:hover
  .remove-single-active-filter {
  visibility: visible;
  opacity: 1;
}
.filters-bar-container .mutually-exclusive-filters-warning {
  color: #fc035d;
  font-family: 'Open Sans SemiBold';
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-left: 15px;
  border-left: 1px solid #d3d3d3;
  height: 30px;
  max-width: 150px;
}
.filters-bar-container
  .mutually-exclusive-filters-warning
  .filters-warning-icon {
  height: 20px;
  width: 20px;
  margin-right: 15px;
  float: left;
}

@media only screen and (max-width: 1395px) {
  .filters-bar-container {
    width: calc(100vw - 417px);
  }
}

@media only screen and (max-width: 768px) {
  .filters-bar-container {
    width: 100%;
  }
  .blocks-results-column-container .blocks-results-header-content {
    position: relative;
    width: 100%;
    top: 0px;
    height: auto;
    display: block;
  }
}

.video-popup-container {
  width: 700px;
  height: 500px;
  margin: 5px;
}
.video-popup-container .video-popup-actions-container {
  display: flex;
  justify-content: flex-end;
}
.video-popup-container .video-popup-actions-container a:link,
.video-popup-container .video-popup-actions-container a:visited {
  text-decoration: none;
}
.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  margin-left: -45px !important;
  margin-top: -22px !important;
}

.QuestionsList_container__1Utmc {
}
.QuestionsList_questionsList__2WT-T {
  margin-left: 125px;
  margin-right: 40px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-left: 10px;
  border-top: 1px solid #e6e6e6;
}
.QuestionsList_question__1IaZc {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}
.QuestionsList_questionColor__2bETH {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 20px;
}
.QuestionsList_questionNumber__3dCfk {
}
.QuestionsList_questionString__2WeZh {
  margin-left: 20px;
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.QuestionsList_questionString__2WeZh.QuestionsList_matrixQuestionString__2oDNL {
  margin-left: 0;
  white-space: pre-line;
}
.QuestionsList_questionToggle__R_MVb {
  text-align: right;
  margin-left: 10px;
  display: inline-block;
}
.QuestionsList_questionSwitch__2Q_9b {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}
.QuestionsList_questionSlider__1uGjq {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
input:checked + .QuestionsList_questionSlider__1uGjq:before {
  -webkit-transform: translateX(20px) translateY(-50%);
          transform: translateX(20px) translateY(-50%);
}
.QuestionsList_questionSlider__1uGjq:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 15px;
  left: -15px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  -webkit-transform: translateX(30px) translateY(-50%);
          transform: translateX(30px) translateY(-50%);
}
.QuestionsList_input__S_8WP {
  display: none;
}
.QuestionsList_editIcon__3_sda {
  cursor: pointer;
  height: 14px;
  width: 14px;
  opacity: 0.3;
  margin-left: 10px;
}

.EditQuestionPopup_container__2KhAh {
  width: 650px;
  font-size: 13px;
  max-height: 1000px;
}
.EditQuestionPopup_container__2KhAh::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.EditQuestionPopup_container__2KhAh::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.EditQuestionPopup_templateTitle__1vvWg {
  padding: 15px;
  font-size: 14px;
  font-family: Open Sans SemiBold;
}
.EditQuestionPopup_bottomLine__2ReJK {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #e6e6e6;
}
.EditQuestionPopup_templateTitle__1vvWg .EditQuestionPopup_bottomLine__2ReJK {
  width: calc(100% - 30px);
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
}
.EditQuestionPopup_templateDescription__1uNM6 {
  padding-top: 15px;
}
.EditQuestionPopup_footer__26yTc {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}
.EditQuestionPopup_footer__26yTc .EditQuestionPopup_details__1g4oX {
  color: #5200f1;
}
.EditQuestionPopup_footer__26yTc .EditQuestionPopup_footerErrorBackground__1dgtZ {
  position: absolute;
  width: calc(100%);
  height: calc(100%);
  left: 0;
  top: 0;
  background-color: #fff2f7;
  border-radius: 0 0 10px 10px;
}
.EditQuestionPopup_footer__26yTc .EditQuestionPopup_error__3eck_ {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.EditQuestionPopup_footer__26yTc .EditQuestionPopup_error__3eck_ .EditQuestionPopup_warningIcon__3t2IR {
  height: 22px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.EditQuestionPopup_actions__3H0Ec .EditQuestionPopup_confirm__2Z_Hs {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.EditQuestionPopup_actions__3H0Ec .EditQuestionPopup_close__100GA {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.EditQuestionPopup_feature__2RumL {
  display: flex;
  margin-bottom: 10px;
  padding: 10px 15px;
  justify-content: space-between;
}
.EditQuestionPopup_background__1KBJz {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ebe5fc;
}
.EditQuestionPopup_description__3vo9H {
  width: calc(100% - 5px);
  background-color: #ffffff;
  font-size: 13px;
  height: 32px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  outline: none;
}
.EditQuestionPopup_featureFields__1Kn1m {
  display: inline-flex;
  width: 95%;
}
.EditQuestionPopup_featureFieldsDropdowns__2aggk {
  display: flex;
}
.EditQuestionPopup_close__100GA {
  position: relative;
  top: 2px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.EditQuestionPopup_colorPickersContainer__1ZNip {
  display: flex;
  flex-wrap: wrap;
}
.EditQuestionPopup_colorPickersContainer__1ZNip .EditQuestionPopup_colorPicker__1FEzQ {
  margin-bottom: 5px;
  margin-right: 6px;
  background-color: #fff;
  border-radius: 5px;
}

.Bar_opportunityGraphAxis__3nzuT line {
  color: #fff;
}
.Bar_opportunityGraphAxis__3nzuT text {
  color: #000;
}
.Bar_opportunityGraphBottomAxis__2FptO {
  text-transform: uppercase;
}
.Bar_label__1UxpR {
  cursor: pointer;
}
.Bar_labelInput__3iZHI {
  color: #000;
  font-size: 13px;
  font-family: 'Open Sans Regular';
  margin: 19px 13px;
  outline: 1px solid #cacaca;
  border: none;
  float: left;
  width: 100px;
}
.Bar_xAxisLabel__1N8By {
  position: absolute;
  left: 0px;
  bottom: 38px;
  padding-left: 80px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #000;
  font-size: 13px;
}
.Bar_xAxisLabel__1N8By.Bar_axisLabelSet__4Lcl9,
.Bar_yAxisLabel__1zmGD.Bar_axisLabelSet__4Lcl9 {
  color: #000;
}
.Bar_yAxisLabel__1zmGD {
  -webkit-transform: rotate(-90deg) translateX(50%);
          transform: rotate(-90deg) translateX(50%);
  position: absolute;
  left: calc(-50% + 80px);
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 13px;
}
.Bar_yLabelContainer__2HTpE {
  -webkit-transform: rotate(-90deg) translateX(calc(50% - 68px));
          transform: rotate(-90deg) translateX(calc(50% - 68px)); /* input width + margins = 68px */
  position: absolute;
  left: calc(-50% + 20px);
  bottom: calc(50% - 10px);
  width: 100%;
  text-align: center;
}

.Toggle_container__1gUQF {
  display: inline-block;
  margin-right: 10px;
  float: right;
  margin-left: auto;
}
.Toggle_switch__1I1re {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;

  width: 28px;
  height: 16px;
}
.Toggle_switch__1I1re input {
  opacity: 0;
  width: 0;
  height: 0;
}
.Toggle_slider__2-AWQ {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dbdbdb;
  transition: 0.4s;
  display: inline-flex;
  align-items: center;
}
.Toggle_slider__2-AWQ:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 4px;

  height: 11px;
  width: 11px;
  left: 3px;

  background-color: white;
  transition: 0.4s;
}
.Toggle_container__1gUQF input:checked + .Toggle_slider__2-AWQ {
  background-color: #5200f1;
}
.Toggle_container__1gUQF input:focus + .Toggle_slider__2-AWQ {
  box-shadow: 0 0 1px #2196f3;
}
.Toggle_container__1gUQF input:checked + .Toggle_slider__2-AWQ:before {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.Toggle_slider__2-AWQ.Toggle_round__32pHK {
  border-radius: 34px;
}
.Toggle_slider__2-AWQ.Toggle_round__32pHK:before {
  border-radius: 50%;
}

.DropdownPopup_container__3uXVl {
  position: fixed;
  width: 590px;
  margin-left: 285px;
  background-color: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 3%);
  box-sizing: border-box;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 20;
}
.DropdownPopup_dropdownItem__58RjK {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 10px 0px 10px;
  margin-bottom: 5px;
}
.DropdownPopup_dropdownItem__58RjK img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
.DropdownPopup_dropdownItemLabel__1aqC1 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
}
.DropdownPopup_noAvailableOptions__2e7it {
  text-align: center;
  min-height: 30px;
  font-size: 13px;
  color: #8b8b8d;
  white-space: pre-wrap;
}
.DropdownPopup_dropdownPopupItemsContainer__1fx3f {
  padding: 5px 0;
  overflow-y: auto;
  max-height: 100px;
}
.DropdownPopup_dropdownItemNumber__ttgz- {
  padding-right: 2px;
}

.Dropdown_dropdownContainer__1C6ex {
  width: 50%;
}
.Dropdown_placeholderContainer__1yxuL {
  width: 100%;
  max-width: calc(100% - 5px);
  position: relative;
  background-color: #ffffff;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  cursor: pointer;
  padding-left: 10px;
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 9px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
  box-sizing: border-box;
}
.Dropdown_placeholderContainer__1yxuL img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}

.Feature_noFeatures__1ODC3 {
  color: #8b8b8d;
  position: absolute;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.Feature_feature__2iwWM {
  display: flex;
  margin-bottom: 10px;
  padding: 10px 15px;
  justify-content: space-between;
}
.Feature_background__2buOF {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ebe5fc;
}
.Feature_description__1mV__ {
  width: calc(100% - 5px);
  background-color: #ffffff;
  font-size: 13px;
  height: 32px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  outline: none;
}
.Feature_featureFields__Rf_f_ {
  display: inline-flex;
  flex-direction: column;
  width: 95%;
}
.Feature_featureFieldsDropdowns__3605t {
  display: flex;
}
.Feature_close__3i3T9 {
  position: relative;
  top: 2px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.Feature_colorPickersContainer__c31RI {
  display: flex;
  flex-wrap: wrap;
}
.Feature_colorPickersContainer__c31RI .Feature_colorPicker__339n- {
  margin-bottom: 5px;
  margin-right: 6px;
  background-color: #fff;
  border-radius: 5px;
}

.EditFeaturePopup_container__2v1co {
  width: 650px;
  font-size: 13px;
  max-height: 1000px;
}
.EditFeaturePopup_container__2v1co::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.EditFeaturePopup_container__2v1co::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.EditFeaturePopup_templateTitle__3hPih {
  padding: 15px;
  font-size: 14px;
  font-family: Open Sans SemiBold;
}
.EditFeaturePopup_bottomLine__3eqHC {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #e6e6e6;
}
.EditFeaturePopup_templateTitle__3hPih .EditFeaturePopup_bottomLine__3eqHC {
  width: calc(100% - 30px);
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
}
.EditFeaturePopup_description__1m005 {
  padding: 10px 15px 15px 15px;
  font-style: italic;
}
.EditFeaturePopup_templateDescription__oMl-G {
  padding-top: 15px;
}
.EditFeaturePopup_footer__2nagI {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.EditFeaturePopup_footer__2nagI .EditFeaturePopup_details__oFJQm {
  color: #5200f1;
}
.EditFeaturePopup_footer__2nagI .EditFeaturePopup_footerErrorBackground__1V_rv {
  position: absolute;
  width: calc(100%);
  height: calc(100%);
  left: 0;
  top: 0;
  background-color: #fff2f7;
  border-radius: 0 0 10px 10px;
}
.EditFeaturePopup_footer__2nagI .EditFeaturePopup_error__Iqqpk {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.EditFeaturePopup_footer__2nagI .EditFeaturePopup_error__Iqqpk .EditFeaturePopup_warningIcon__1fheQ {
  height: 22px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.EditFeaturePopup_actions__28OaH .EditFeaturePopup_confirm__1cnVL {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.EditFeaturePopup_actions__28OaH .EditFeaturePopup_close__2xCFi {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.FeaturesList_tooltipContainer__1mBHe {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 5px 10px 3px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  font-style: italic;
  font-size: 12px;
  background-color: #fff;
  width: 400px;
  position: fixed;
  display: block;
  right: 105px;
}
.FeaturesList_tooltipContainer__1mBHe .FeaturesList_questionTitle__1_Wo6 {
  font-family: 'Open Sans SemiBold';
  margin-bottom: 5px;
}
.FeaturesList_tooltipContainer__1mBHe .FeaturesList_questionText__1UO8b {
  margin-bottom: 15px;
}
.FeaturesList_featuresContainer__18aap {
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 350px;
  overflow: auto;
}
/* Works on Firefox */
.FeaturesList_featuresContainer__18aap {
  scrollbar-width: thin;
  scrollbar-color: #999999 #cccccc;
}
/* Works on Chrome, Edge, and Safari */
.FeaturesList_featuresContainer__18aap::-webkit-scrollbar {
  width: 7px;
}
.FeaturesList_featuresContainer__18aap::-webkit-scrollbar-track {
  background-color: #cccccc;
}
.FeaturesList_featuresContainer__18aap::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 20px;
  border: 0;
}
.FeaturesList_featuresContainer__18aap.FeaturesList_noScrolling__3MR7q {
  max-height: none;
}

.FeaturesList_group__1Kyf0 {
  margin-bottom: 25px;
  padding-right: 10px;
}
.FeaturesList_branchLabelContainer__24bqX {
  font-size: 12px;
  padding-bottom: 10px;
  border-bottom: solid 2px #e7e7e7;
}
.FeaturesList_branchLabelContainer__24bqX .FeaturesList_branchLabel__1Vsql {
  font-size: 13px;
  margin-right: 5px;
}
.FeaturesList_feature__17F9v {
  font-size: 12px;
  margin-top: 10px;
  padding-bottom: 5px;
  display: flex;
}
.FeaturesList_circle__2aFrS {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: 3px;
}
.FeaturesList_sampleSize__10Lcw {
  margin-top: 5px;
  color: #aaaaaa;
}
.FeaturesList_loversHatersTooltipContainer__2W0V5 {
  display: inline-block;
  margin-left: 5px;
}
.FeaturesList_loversHatersTooltipContainer__2W0V5 .FeaturesList_loversHatersTooltipIcon__2XD3x {
  display: inline-block;
  height: 13px;
  position: relative;
  top: 2px;
}
.FeaturesList_loversHatersTooltipPopup__2f45s {
  position: absolute;
  left: 20px;
  top: -25px;
  padding: 4px 8px;
  width: 240px;
  background-color: #ffffff;
  display: none;
  border: 1px solid #dddddd;
  color: #333333;
  text-transform: none;
  border-radius: 5px;
  font-size: 12px;
}
.FeaturesList_loversHatersTooltipIcon__2XD3x:hover + .FeaturesList_loversHatersTooltipPopup__2f45s {
  display: block;
}
.FeaturesList_right__DH2tV {
  float: right;
  display: inline-flex;
  align-items: flex-start;
  margin-left: auto;
  margin-top: 3px;
}
.FeaturesList_editIcon__3U58L {
  cursor: pointer;
  height: 14px;
  width: 14px;
  opacity: 0.3;
  margin-right: 8px;
}

.Legend_container__1vOLd {
  margin-top: 15px;
}
.Legend_row__3zBAQ {
  color: #000;
  margin-bottom: 15px;
  margin-right: 10px;
  font-size: 12px;
  display: flex;
}
.Legend_rowContainer__2IUWY {
  display: flex;
  flex-direction: column;
}
.Legend_row__3zBAQ .Legend_rectangle__OJYnX {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 4px;
  flex-shrink: 0;
}
.Legend_row__3zBAQ .Legend_dots__4AWXA {
  position: absolute;
  width: 10px;
  left: 0;
  bottom: -1px;
  font-size: 18px;
  color: #8b8b8d;
  letter-spacing: -1px;
}
.Legend_bold__1Zhpd {
  font-family: 'Open Sans Bold';
}

.GraphTitle_container__1C24w {
  margin-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.GraphTitle_container__1C24w.GraphTitle_containerWithWarning__2oOBq {
  padding-bottom: 20px;
}
.GraphTitle_titleContainer__19V-0 {
  display: flex;
}
.GraphTitle_icon__1P92U {
  margin-left: 56px;
  width: 35px;
  color: #8500f0;
  margin-top: 4px;
}
.GraphTitle_icon__1P92U img {
  width: 16px;
  height: 16px;
}
.GraphTitle_graphName__3DV2C {
  margin-left: 6px;
  font-size: 18px;
  color: #221f1f;
}
.GraphTitle_sampleErrorMessage__1pmrw {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff2f7;
  color: #fc035d;
  font-family: Open Sans SemiBold;
  font-size: 13px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding-left: 50px;
}
.GraphTitle_sampleErrorMessage__1pmrw .GraphTitle_warningIcon__2HTM8 {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  position: relative;
}
.GraphTitle_close__nEUBF {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.GraphInfoTabs_container__2cT6w {
  display: flex;
  border-bottom: 1px solid #efefef;
  padding-left: 95px;
  height: 45px;
  line-height: 45px;
}
.GraphInfoTabs_tab__jL73h {
  font-family: 'Open Sans Bold';
  margin-right: 25px;
  color: #8b8b8d;
  cursor: pointer;
  font-size: 13px;
  transition: all 0.15s ease-in-out;
}
.GraphInfoTabs_tab__jL73h.GraphInfoTabs_active__3rhWa {
  color: #000;
}

.Disclaimer_disclaimer__3LrqL {
  margin: 15px 0;
  font-size: 12px;
}
.Disclaimer_contactInnovationExpertButton__3PXMU {
  display: inline-block;
  padding: 6px 25px;
  color: #fff;
  background-color: #5200f1;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
}

.DropdownPopup_container__2JYje {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 3%);
  box-sizing: border-box;
  z-index: 20;
  margin-top: 2px;
}
.DropdownPopup_dropdownItem__2r3aD {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 10px 0px 10px;
  margin-bottom: 5px;
}
.DropdownPopup_noAvailableOptions__1xvgL {
  text-align: center;
  min-height: 30px;
  font-size: 13px;
  color: #8b8b8d;
  white-space: pre-wrap;
}
.DropdownPopup_dropdownPopupItemsContainer__1hDo3 {
  padding: 5px 0;
  overflow-y: auto;
  max-height: 150px;
}
.DropdownPopup_circle__2XRwy {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 10px;
}

.Dropdown_dropdownContainer__2jabU {
  width: 100%;
  margin-bottom: 5px;
}
.Dropdown_placeholderContainer__2bpMc {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  cursor: pointer;
  padding-left: 10px;
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 9px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
  box-sizing: border-box;
}
.Dropdown_placeholderContainer__2bpMc img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
.Dropdown_circle__3srI2 {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 100%;
  margin-right: 10px;
}

.LoversHaters_container__2bWVk {
  padding-top: 18px;
}
.LoversHaters_explanation__2CIid {
  margin: 15px 0;
  font-size: 12px;
}
.LoversHaters_subtitle__2Z8Zb {
  font-size: 12px;
  margin-bottom: 5px;
}
.LoversHaters_dropdownWrapper__1sW_B {
  margin-bottom: 10px;
}
.LoversHaters_touchpointsTooltipContainer__2-Y9D {
  display: inline-block;
  margin-left: 5px;
}
.LoversHaters_touchpointsTooltipIcon__1MCQU {
  display: inline-block;
  cursor: pointer;
  height: 13px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.LoversHaters_touchpointsTooltipPopup__16scU {
  position: absolute;
  left: -30px;
  bottom: 20px;
  padding: 0px 30px;
  width: 250px;
  background-color: #ffffff;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  text-align: center;
  color: #333333;
  text-transform: none;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px 0px;
  font-style: italic;
}
.LoversHaters_touchpointsTooltipIcon__1MCQU:hover + .LoversHaters_touchpointsTooltipPopup__16scU {
  display: block;
}

.Branding_explanation__2psm3 {
  margin: 15px 0 10px 0;
  font-size: 12px;
}
.Branding_colorPickersContainer__O8KoE {
  display: flex;
  flex-wrap: wrap;
}
.Branding_colorPickersContainer__O8KoE .Branding_colorPicker__2ObsJ {
  margin-bottom: 5px;
}

.Bar_opportunityGraphAxis__fc2CS line {
  color: #fff;
}
.Bar_opportunityGraphAxis__fc2CS text {
  color: #000;
}
.Bar_opportunityGraphBottomAxis__JCPuq {
  text-transform: uppercase;
}
.Bar_label__HpelL {
  cursor: pointer;
}
.Bar_labelInput__1mpmZ {
  color: #000;
  font-size: 13px;
  font-family: 'Open Sans Regular';
  margin: 19px 13px;
  outline: 1px solid #cacaca;
  border: none;
  float: left;
  width: 100px;
}
.Bar_xAxisLabel__Vcqt5 {
  position: absolute;
  left: 0px;
  bottom: 38px;
  padding-left: 80px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #000;
  font-size: 13px;
}
.Bar_xAxisLabel__Vcqt5.Bar_axisLabelSet__23W1d,
.Bar_yAxisLabel__16FYj.Bar_axisLabelSet__23W1d {
  color: #000;
}
.Bar_yAxisLabel__16FYj {
  -webkit-transform: rotate(-90deg) translateX(50%);
          transform: rotate(-90deg) translateX(50%);
  position: absolute;
  left: calc(-50% + 80px);
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 13px;
}
.Bar_yLabelContainer__1WGxb {
  -webkit-transform: rotate(-90deg) translateX(calc(50% - 68px));
          transform: rotate(-90deg) translateX(calc(50% - 68px)); /* input width + margins = 68px */
  position: absolute;
  left: calc(-50% + 20px);
  bottom: calc(50% - 10px);
  width: 100%;
  text-align: center;
}

.Bar_opportunityGraphAxis__2dxP- line {
  color: #fff;
}
.Bar_opportunityGraphAxis__2dxP- text {
  color: #000;
}
.Bar_opportunityGraphBottomAxis__KiA2Q {
  text-transform: uppercase;
}
.Bar_label__1m-cm {
  cursor: pointer;
}
.Bar_labelInput__2Khkx {
  color: #000;
  font-size: 13px;
  font-family: 'Open Sans Regular';
  margin: 19px 13px;
  outline: 1px solid #cacaca;
  border: none;
  float: left;
  width: 100px;
}
.Bar_xAxisLabel__3OBpT {
  position: absolute;
  left: 0px;
  bottom: 38px;
  padding-left: 80px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #000;
  font-size: 13px;
}
.Bar_xAxisLabel__3OBpT.Bar_axisLabelSet___iZwI,
.Bar_yAxisLabel__2gHBY.Bar_axisLabelSet___iZwI {
  color: #000;
}
.Bar_yAxisLabel__2gHBY {
  -webkit-transform: rotate(-90deg) translateX(50%);
          transform: rotate(-90deg) translateX(50%);
  position: absolute;
  left: calc(-50% + 80px);
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 13px;
}
.Bar_yLabelContainer__1k4_x {
  -webkit-transform: rotate(-90deg) translateX(calc(50% - 68px));
          transform: rotate(-90deg) translateX(calc(50% - 68px)); /* input width + margins = 68px */
  position: absolute;
  left: calc(-50% + 20px);
  bottom: calc(50% - 10px);
  width: 100%;
  text-align: center;
}

.Bar_opportunityGraphAxis__3Sv7z line {
  color: #fff;
}
.Bar_opportunityGraphAxis__3Sv7z text {
  color: #000;
}
.Bar_opportunityGraphBottomAxis__OqGRu {
  text-transform: uppercase;
}
.Bar_label__ebwb0 {
  cursor: pointer;
}
.Bar_labelInput__2Pwmc {
  color: #000;
  font-size: 13px;
  font-family: 'Open Sans Regular';
  margin: 19px 13px;
  outline: 1px solid #cacaca;
  border: none;
  float: left;
  width: 100px;
}
.Bar_xAxisLabel__CjsDy {
  position: absolute;
  left: 0px;
  bottom: 38px;
  padding-left: 80px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #000;
  font-size: 13px;
}
.Bar_xAxisLabel__CjsDy.Bar_axisLabelSet__2yxxM,
.Bar_yAxisLabel__2YSdx.Bar_axisLabelSet__2yxxM {
  color: #000;
}
.Bar_yAxisLabel__2YSdx {
  -webkit-transform: rotate(-90deg) translateX(50%);
          transform: rotate(-90deg) translateX(50%);
  position: absolute;
  left: calc(-50% + 80px);
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 13px;
}
.Bar_yLabelContainer__1BThS {
  -webkit-transform: rotate(-90deg) translateX(calc(50% - 68px));
          transform: rotate(-90deg) translateX(calc(50% - 68px)); /* input width + margins = 68px */
  position: absolute;
  left: calc(-50% + 20px);
  bottom: calc(50% - 10px);
  width: 100%;
  text-align: center;
}

.Bar_customGraphAxis__27XqR line {
  color: #e3e3e3;
}
.Bar_customGraphAxis__27XqR text {
  color: #000;
}
.Bar_opportunityGraphBottomAxis__3f6a9 {
  text-transform: uppercase;
}
.Bar_label__9xb3Z {
  cursor: pointer;
}
.Bar_labelInput__1AcTO {
  color: #000;
  font-size: 13px;
  font-family: 'Open Sans Regular';
  margin: 19px 13px;
  outline: 1px solid #cacaca;
  border: none;
  float: left;
  width: 100px;
}
.Bar_xAxisLabel__3RnlE {
  position: absolute;
  left: 0px;
  bottom: 4px;
  padding-left: 135px;
  padding-right: 50px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #000;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Bar_xAxisLabel__3RnlE.Bar_axisLabelSet__3FBoU,
.Bar_yAxisLabel__3TCIt.Bar_axisLabelSet__3FBoU {
  color: #000;
}
.Bar_xAxisLabelRating__2i3kM {
  bottom: 8px;
  padding-left: 60px;
  padding-right: 40px;
}
.Bar_yAxisLabel__3TCIt {
  -webkit-transform: rotate(-90deg) translateX(50%);
          transform: rotate(-90deg) translateX(50%);
  position: absolute;
  left: calc(-50% + 80px);
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 13px;
  padding: 0 100px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Bar_yAxisLabelRating__1D_Gb {
  left: calc(-50% + 20px);
}
.Bar_yLabelContainer__2TDpe {
  -webkit-transform: rotate(-90deg) translateX(calc(50% - 68px));
          transform: rotate(-90deg) translateX(calc(50% - 68px)); /* input width + margins = 68px */
  position: absolute;
  left: calc(-50% + 20px);
  bottom: calc(50% - 10px);
  width: 100%;
  text-align: center;
}

.EditBackgroundPopup_container__P1uFx {
  width: 650px;
  font-size: 13px;
  max-height: 1000px;
}
.EditBackgroundPopup_container__P1uFx::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.EditBackgroundPopup_container__P1uFx::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.EditBackgroundPopup_editContainer__1cHTB {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}
.EditBackgroundPopup_bottomLine__yhtJV {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #e6e6e6;
}
.EditBackgroundPopup_templateTitle__9780S {
  padding: 15px;
  font-size: 14px;
  font-family: Open Sans SemiBold;
}
.EditBackgroundPopup_templateTitle__9780S .EditBackgroundPopup_bottomLine__yhtJV {
  width: calc(100% - 30px);
  -webkit-transform: translateX(15px);
          transform: translateX(15px);
}
.EditBackgroundPopup_colorPickersContainer__22G-v {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  margin-left: 10px;
  margin-bottom: 35px;
}
.EditBackgroundPopup_colorPickersContainer__22G-v .EditBackgroundPopup_colorPicker__3Y7ne {
  margin-bottom: 5px;
  background-color: #fff;
}
.EditBackgroundPopup_footer__2LRnC {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.EditBackgroundPopup_footer__2LRnC .EditBackgroundPopup_error__26isG {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.EditBackgroundPopup_actions__1e3CF .EditBackgroundPopup_confirm__xwXJs {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.EditBackgroundPopup_actions__1e3CF .EditBackgroundPopup_close__egWLE {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.Legend_container__2EIe9 {
  margin-top: 15px;
}
.Legend_row__dm_Mw {
  color: #000;
  margin-bottom: 15px;
  margin-right: 10px;
  font-size: 12px;
  display: flex;
}
.Legend_rowContainer__1nXMw {
  display: flex;
  flex-direction: column;
}
.Legend_row__dm_Mw .Legend_rectangle__3zKd8 {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 4px;
  flex-shrink: 0;
}
.Legend_row__dm_Mw .Legend_dots__1Kt0s {
  position: absolute;
  width: 10px;
  left: 0;
  bottom: -1px;
  font-size: 18px;
  color: #8b8b8d;
  letter-spacing: -1px;
}
.Legend_bold__3LV2H {
  font-family: 'Open Sans Bold';
}

.Legend_container__1x7WY {
  margin-top: 15px;
}
.Legend_row__1hdoB {
  color: #000;
  margin-bottom: 15px;
  margin-right: 10px;
  font-size: 12px;
  display: flex;
}
.Legend_rowContainer__2kT5A {
  display: flex;
  flex-direction: column;
}
.Legend_row__1hdoB .Legend_rectangle__2aKjt {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 4px;
  flex-shrink: 0;
}
.Legend_row__1hdoB .Legend_dots__3HGWW {
  position: absolute;
  width: 10px;
  left: 0;
  bottom: -1px;
  font-size: 18px;
  color: #8b8b8d;
  letter-spacing: -1px;
}
.Legend_bold__TwBTc {
  font-family: 'Open Sans Bold';
}

.Legend_container__30R6A {
  margin-top: 15px;
}
.Legend_row__1spBz {
  color: #000;
  margin-bottom: 15px;
  margin-right: 10px;
  font-size: 12px;
  display: flex;
}
.Legend_rowContainer__3SLd9 {
  display: flex;
  flex-direction: column;
}
.Legend_row__1spBz .Legend_rectangle__3Sz3_ {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
  margin-top: 4px;
  flex-shrink: 0;
}
.Legend_row__1spBz .Legend_dots__1FD7B {
  position: absolute;
  width: 10px;
  left: 0;
  bottom: -1px;
  font-size: 18px;
  color: #8b8b8d;
  letter-spacing: -1px;
}
.Legend_bold__2Yjz0 {
  font-family: 'Open Sans Bold';
}

.Loader_loadingAnimation__3xyfJ.Loader_normal__1QB0E {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.Loader_loadingAnimation__3xyfJ.Loader_normal__1QB0E div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation__NhT-b 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation__NhT-b 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__3xyfJ.Loader_small__1IoLj {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  width: 30px;
  height: 20px;
  opacity: 0.5;
}
.Loader_loadingAnimation__3xyfJ.Loader_small__1IoLj div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 6px;
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation__NhT-b 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation__NhT-b 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__3xyfJ div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.Loader_loadingAnimation__3xyfJ div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.Loader_loadingAnimation__3xyfJ div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes Loader_loading-animation__NhT-b {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader_loading-animation__NhT-b {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ManageWorkspace_container__3SQez {
  padding: 15px;
  width: 650px;
  font-size: 13px;
}
.ManageWorkspace_title__390RX {
  font-family: Open Sans SemiBold;
  color: #5200f1;
}
.ManageWorkspace_description__WLa9Q {
  padding-top: 15px;
  padding-bottom: 15px;
  font-style: italic;
}
.ManageWorkspace_questionsContainer__3OHtz {
  padding-top: 25px;
  padding-bottom: 5px;
  max-height: 200px;
  overflow: auto;
  border-bottom: 1px solid #e6e6e6;
}
.ManageWorkspace_question__Q-O94 {
  display: flex;
  margin-bottom: 15px;
}
.ManageWorkspace_question__Q-O94 .ManageWorkspace_selectorContainer__16DNu {
  width: 5%;
  text-align: center;
  color: #5200f1;
  font-size: 12px;
  position: relative;
  top: 2px;
}
.ManageWorkspace_question__Q-O94 .ManageWorkspace_selectorContainer__16DNu .ManageWorkspace_selectorIcon__XPXYp {
  cursor: pointer;
}
.ManageWorkspace_question__Q-O94 .ManageWorkspace_number__2SJw0 {
  width: 5%;
  text-align: center;
}
.ManageWorkspace_question__Q-O94 .ManageWorkspace_iconContainer__2GjL9 {
  width: 5%;
  text-align: center;
}
.ManageWorkspace_question__Q-O94 .ManageWorkspace_iconContainer__2GjL9 img {
  height: 12px;
}
.ManageWorkspace_question__Q-O94 .ManageWorkspace_questionTextContainer__19kSG {
  width: 85%;
}
.ManageWorkspace_footer__2RR2R {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.ManageWorkspace_footer__2RR2R .ManageWorkspace_details__2dtz0 {
  color: #5200f1;
}
.ManageWorkspace_actions__8PNxr .ManageWorkspace_confirm__19ELm {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.ManageWorkspace_actions__8PNxr .ManageWorkspace_close__2aXXt {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.ManageWorkspace_selectAllContainer__1IhZw {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
}
.ManageWorkspace_selectAllContainer__1IhZw .ManageWorkspace_selectorContainer__16DNu {
  width: 5%;
  text-align: center;
  color: #5200f1;
  font-size: 12px;
  position: relative;
  top: 2px;
}
.ManageWorkspace_selectAllContainer__1IhZw .ManageWorkspace_selectorContainer__16DNu span {
  cursor: pointer;
}
.ManageWorkspace_deletePopulation__21D6W {
  color: #fc035d;
  cursor: pointer;
}
.ManageWorkspace_deletePopulation__21D6W .ManageWorkspace_deletePopulationIcon__1JEdP {
  height: 12px;
  margin-right: 7px;
}

.NoWorkspaces_container__1f7sM {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 180px);
}
.NoWorkspaces_authorizedContainer__1r0pG {
  height: calc(100vh - 480px);
  margin-left: 360px;
}
.NoWorkspaces_heardy__1RyDI {
  margin-bottom: 30px;
  height: 200px;
}
.NoWorkspaces_title__2s1Ax,
.NoWorkspaces_text__1INpS {
  font-size: 12px;
  margin-bottom: 25px;
  max-width: 300px;
  text-align: center;
}
.NoWorkspaces_title__2s1Ax {
  color: #5200f1;
  font-family: 'Open Sans Bold';
}
.NoWorkspaces_text__1INpS {
  color: #8b8b8d;
}
.NoWorkspaces_newWorkspaceButton__sQiPu {
  display: inline-block;
  color: #5200f1;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 5px;
  font-family: Open Sans SemiBold;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
  border: 1px solid #c3c3c3;
}
.NoWorkspaces_editIcon__3QOc7 {
  height: 12px;
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .NoWorkspaces_container__1f7sM {
    height: auto;
  }
}

.Population_container__3le9x {
  border: 1px solid #efefef;
  border-radius: 10px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  box-shadow: 0px 10px 5px -8px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  margin-bottom: 10px;
}
.Population_titleContainer__2wpJM {
  font-size: 11px;
  display: flex;
  height: 50px;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.Population_titleContainer__2wpJM .Population_titleTextContainer__3lxJC {
  display: flex;
  align-items: center;
}
.Population_titleContainer__2wpJM .Population_colorBullet__3HKai {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 15px;
}
.Population_titleContainer__2wpJM .Population_populationLabel__NyUtj {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  color: #707070;
}
.Population_titleContainer__2wpJM .Population_filteredResultsCount__25yu4 {
  text-transform: lowercase;
}
.Population_content__1-mG8 {
  border-top: 1px solid #e6e6e6;
}
.Population_content__1-mG8 .Population_title__2YCGl {
  display: flex;
  height: 50px;
  align-items: center;
  text-transform: uppercase;
  text-transform: uppercase;
  font-size: 11px;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
}
.Population_content__1-mG8 .Population_title__2YCGl .Population_details__3xICv .Population_name__3s0lE {
  color: #5200f1;
}
.Population_content__1-mG8 .Population_title__2YCGl .Population_arrow__XHAwc {
  width: 8px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.Population_content__1-mG8 .Population_title__2YCGl .Population_arrow__XHAwc.Population_expanded__2CFKU {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.Population_expandedContent__ima5b .Population_filterRow__iXyoe {
  font-size: 11px;
  display: flex;
  height: 35px;
  align-items: center;
  text-transform: uppercase;
  margin-left: 20px;
}
.Population_expandedContent__ima5b .Population_filterRow__iXyoe .Population_name__3s0lE {
  margin-left: 10px;
  margin-right: 15px;
}
.Population_expandedContent__ima5b .Population_filterRow__iXyoe .Population_value__12BJe {
  color: #5200f1;
}
.Population_expandedContent__ima5b .Population_footer__2KprN {
  font-size: 11px;
  display: flex;
  height: 50px;
  align-items: center;
  text-transform: uppercase;
  margin-left: 20px;
}
.Population_smallPopulation__2-4dy {
  color: #fc035d;
  background-color: #fff2f7;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 20px 0 10px 0;
  top: -20px;
  z-index: -1;
}
.Population_smallPopulation__2-4dy img {
  height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  position: relative;
  margin-top: 5px;
}
.Population_populationActionsDropdown__QeI5O {
  position: absolute;
  z-index: 100;
  top: 30px;
  left: -182px;
  box-sizing: border-box;
  width: 200px;
  font-size: 11px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  margin-bottom: 50px;
}
.Population_dropdownAction__hjeP- {
  height: 40px;
  text-transform: none;
  font-family: Open Sans SemiBold;
  color: #5200f1;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.Population_dropdownAction__hjeP-:hover .Population_dropdownActionText__1bjh7 {
  opacity: 0.7;
}
.Population_inverseAction__1gTdf:hover .Population_inverseActionText__hGLrz {
  opacity: 1;
}
.Population_inverseAction__1gTdf:hover .Population_inverseActionText__hGLrz .Population_inverseActionTextHover__1QEBj,
.Population_inverseAction__1gTdf:hover .Population_dropdownActionIcon__1O8mg {
  opacity: 0.7;
}
.Population_dropdownActionIconWrapper__rFgR6 {
  width: 20px;
  text-align: center;
}
.Population_dropdownActionIcon__1O8mg {
  width: 12px;
  color: #5200f1;
  font-size: 12px;
}
.Population_dropdownActionText__1bjh7 {
  margin-left: 15px;
}
.Population_dropdownActionTextRed__2w3CP {
  color: #fc035d;
}
.Population_inverseAction__1gTdf .Population_dropdownActionIcon__1O8mg {
  width: 20px;
  min-width: 20px;
}
.Population_dots__OB5jO,
.Population_dotsActive__-FfRv {
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 0px;
}
.Population_dots__OB5jO {
  color: #bfbfbf;
}
.Population_dotsActive__-FfRv {
  color: #5200f1;
}
.Population_inverseExplanationIcon__3cmgM {
  width: 12px;
  position: absolute;
  bottom: 7px;
  margin-left: 5px;
}
.Population_inverseExplanationIcon__3cmgM:hover + .Population_inverseExplanation__1UoWl {
  visibility: visible;
  opacity: 1;
  background-color: #ede2fb;
  color: #000000e6;
  top: 40px;
}
.Population_inverseExplanation__1UoWl {
  position: absolute;
  width: 260px;
  right: 0;
  top: 60px;
  background-color: #fff;
  border-radius: 5px;
  visibility: hidden;
  z-index: 99;
  padding: 10px 15px;
  color: #fff;
  font-style: italic;
  transition: all 0.2s ease-out;
  opacity: 0;
  display: flex;
  align-items: flex-start;
}
.Population_inverseExplanationText__3XQTs {
  margin-left: 10px;
}

.Toggle_switch__3rNe8 {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
}
.Toggle_switch__3rNe8 input {
  opacity: 0;
  width: 0;
  height: 0;
}
.Toggle_slider__1uefF {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9e9e9e;
  transition: 0.4s;
}
.Toggle_slider__1uefF:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
.Toggle_container__1rYLj input:checked + .Toggle_slider__1uefF {
  background-color: #5200f1;
}
.Toggle_container__1rYLj input:focus + .Toggle_slider__1uefF {
  box-shadow: 0 0 1px #2196f3;
}
.Toggle_container__1rYLj input:checked + .Toggle_slider__1uefF:before {
  -webkit-transform: translateX(11px);
  transform: translateX(11px);
}
.Toggle_slider__1uefF.Toggle_round__1VFrn {
  border-radius: 34px;
}
.Toggle_slider__1uefF.Toggle_round__1VFrn:before {
  border-radius: 50%;
}

.DropdownPopup_container__14GK7 {
  left: 10px;
  top: 35px;
  width: 340px;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 100px;
  background-color: red;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  z-index: 20;
  overflow: auto;
}
.DropdownPopup_dropdownItem__1IVEI {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  font-size: 11px;
}
.DropdownPopup_dropdownItemCheckboxSelected__1WwX8 {
  height: 21px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownPopup_dropdownItemCheckbox__sXuVy {
  height: 21px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownPopup_dropdownItemLabel__35y8v {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.DropdownPopup_noAvailableOptions__38V5P {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.DropdownPopup_dropdownPopupSearchInputContainer__OhgGK input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 20px);
  outline: none;
}
.DropdownPopup_dropdownPopupItemsContainer__xzSzO {
  max-height: 75px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.DropdownPopup_dropdownPopupItemsContainer__xzSzO::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.DropdownPopup_dropdownPopupItemsContainer__xzSzO::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.DropdownPopup_dropdownPopupFooterContainer__3_SoJ {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}
.DropdownPopup_dropdownPopupConfirm__PF6PZ {
  font-family: 'Open Sans SemiBold';
  font-size: 10px;
  color: #8500f0;
  cursor: pointer;
}

.Dropdown_placeholderContainer__2LPKu {
  width: 100%;
  display: block;
  position: relative;
  background-color: #ffffff;
  font-size: 11px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  margin-right: 10px;
  cursor: pointer;
  padding-left: 10px;
  background-position: right 7px center;
  background-repeat: no-repeat;
  background-size: 9px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.PopulationRow_populationRow__LgPyb {
  font-size: 11px;
  display: flex;
  height: 45px;
  align-items: center;
  text-transform: uppercase;
  background-color: #ebe5fc;
  margin-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;
  overflow: visible;
  font-size: 9px;
}
.PopulationRow_populationRow__LgPyb:last-child {
  margin-bottom: 0px;
}
.PopulationRow_populationRow__LgPyb .PopulationRow_toggleContainer__1R63K {
  width: 10%;
}
.PopulationRow_populationRow__LgPyb .PopulationRow_typeContainer__7V-nH {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PopulationRow_populationRow__LgPyb .PopulationRow_valueContainer__14RjZ {
  width: 55%;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.PopulationRow_populationRow__LgPyb .PopulationRow_deleteContainer__1mvKi {
  width: 5%;
  text-align: right;
}
.PopulationRow_populationRow__LgPyb .PopulationRow_deleteContainer__1mvKi img {
  cursor: pointer;
  height: 20px;
}
.PopulationRow_textInput__361Zd {
  width: 50px;
  display: block;
  position: relative;
  background-color: #ffffff;
  font-size: 11px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 20px;
  cursor: pointer;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
}
.PopulationRow_populationRowExtended__21dnM {
  font-size: 11px;
  align-items: center;
  text-transform: uppercase;
  background-color: #ebe5fc;
  top: -5px;
  margin-bottom: 5px;
  padding-left: 88px;
  padding-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: visible;
  border-top: 1px solid #d8d8d8;
}
.PopulationRow_populationRowExtended__21dnM .PopulationRow_extendedRow__339tu {
  height: 38px;
  padding-right: 70px;
}

.ManagePopulation_container__2Ayno {
  padding: 15px;
  width: 650px;
  font-size: 13px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
}
.ManagePopulation_container__2Ayno::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.ManagePopulation_container__2Ayno::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.ManagePopulation_title__2IFI9 {
  font-family: Open Sans SemiBold;
  color: #5200f1;
  margin-bottom: 10px;
}
.ManagePopulation_description__-hdyO {
  padding-top: 15px;
  padding-bottom: 15px;
  font-style: italic;
  padding-bottom: 15px;
}
.ManagePopulation_labelsContainer__uzlYk {
  padding-top: 25px;
  /*max-height: calc(100vh - 400px);*/
  overflow: visible;
  left: -25px;
  width: 700px;
}
.ManagePopulation_addLabelContainer__2cLdY {
  font-size: 13px;
  display: flex;
  height: 50px;
  align-items: center;
  color: #5200f1;
  font-family: Open Sans SemiBold;
}
.ManagePopulation_addLabelContainer__2cLdY span {
  cursor: pointer;
}
.ManagePopulation_addLabelContainer__2cLdY .ManagePopulation_addIcon__36ExQ {
  height: 10px;
  margin-right: 10px;
}
.ManagePopulation_footer__31B_r {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.ManagePopulation_footer__31B_r .ManagePopulation_deletePopulation__39m30 {
  color: #fc035d;
  cursor: pointer;
}
.ManagePopulation_footer__31B_r .ManagePopulation_deletePopulation__39m30 .ManagePopulation_deletePopulationIcon__2QaHZ {
  height: 12px;
  margin-right: 7px;
}
.ManagePopulation_actions__2Sf1M .ManagePopulation_confirm__IsvRo {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.ManagePopulation_actions__2Sf1M .ManagePopulation_confirmDisabled__eaOu7 {
  -webkit-filter: grayscale(100);
          filter: grayscale(100);
  opacity: 0.5;
  cursor: inherit;
}
.ManagePopulation_actions__2Sf1M .ManagePopulation_close__381v9 {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.ManagePopulation_populationSizeInformation__UBEaj {
  font-size: 11px;
  display: flex;
  height: 50px;
  align-items: center;
  left: -25px;
  width: 650px;
  padding: 0 25px;
}
.ManagePopulation_populationSizeInformationRed__2WPoj {
  background-color: #fff2f7;
  color: #fc035d;
}
.ManagePopulation_populationSize__38QX8 {
  text-transform: uppercase;
  width: 25%;
}
.ManagePopulation_populationSmallAlert__1x_FY {
  color: #fc035d;
  font-size: 12px;
  font-family: Open Sans SemiBold;
  display: flex;
  width: 75%;
}
.ManagePopulation_populationSmallAlert__1x_FY img {
  height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  position: relative;
  margin-top: 5px;
}
.ManagePopulation_noLabelsAdded__2Hq0- {
  text-align: center;
  font-size: 11px;
  margin-bottom: 15px;
}
.ManagePopulation_line__3y8hG {
  border-bottom: 1px solid #e6e6e6;
  left: -25px;
  width: 700px;
}
.ManagePopulation_textInput__dA2ft {
  border: 1px solid #d6d9db;
  border-radius: 4px;
  width: 300px;
  height: 24px;
  padding: 0 10px;
  outline: none;
}
.ManagePopulation_textInput__dA2ft:focus {
  border-color: #bcbdbe;
}

.Sidebar_container__1HevI {
  width: 360px;
  position: fixed;
}
.Sidebar_container__1HevI.Sidebar_popupVisible__F4PZh {
  z-index: 99;
}
.Sidebar_createAPopulationButtonContainer__kgpDJ {
  margin-bottom: 30px;
}
.Sidebar_createAPopulationButton__2aDCJ {
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-bottom: 15px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 13px;
  font-family: Open Sans SemiBold;
  color: #5200f1;
  cursor: pointer;
}
.Sidebar_createAPopulationButton__2aDCJ img {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 13px;
}
.Sidebar_populationContainer__27Zt3 {
  min-height: 300px;
  max-height: calc(100vh - 390px);
  overflow: auto;
}
.Sidebar_createPopulationHeadry__3uUxn {
  width: 80%;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.Sidebar_populationsInfoContainer__3Phy7 {
  background-color: #ede5fe;
  padding: 15px;
  box-sizing: border-box;
}
.Sidebar_infoTitleWrapper__2Gek6 {
  display: flex;
  margin-bottom: 5px;
}
.Sidebar_infoIcon__2aK7O {
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
}
.Sidebar_infoTitle__sdh1b {
  font-family: 'Roboto Medium';
}
.Sidebar_infoText__23TtL {
  font-style: italic;
  font-size: 13px;
  line-height: 16px;
  /*font-family: Open Sans SemiBold;*/
}

.Slider_container__3WEMD {
  margin-top: 30px;
  margin-left: 20px;
  margin-bottom: 40px;
  margin-right: 40px;
}
.Slider_additionalDetailsContainer__UviwJ {
  padding: 20px 30px;
  font-size: 13px;
}
.Slider_averageRatingCount__9KDZk {
  font-family: 'Roboto Bold';
}
.Slider_switchContainer__3H0D7 {
  text-align: right;
  margin-bottom: 10px;
  margin-left: 20px;
  display: inline-block;
}
.Slider_ratingSwitch__3wrgs {
  position: relative;
  display: inline-block;
  width: 74px;
  height: 33px;
}
.Slider_ratingSlider__2XXRC {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d4d4d4;
  transition: all 0.5s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
input:checked + .Slider_ratingSlider__2XXRC:before {
  -webkit-transform: translateX(0px) translateY(-50%);
          transform: translateX(0px) translateY(-50%);
}
.Slider_ratingSlider__2XXRC:before {
  position: absolute;
  content: '';
  height: 27px;
  width: 38px;
  left: 3px;
  top: 50%;
  background-color: white;
  transition: 0.3s;
  border-radius: 15px;
  -webkit-transform: translateX(30px) translateY(-50%);
          transform: translateX(30px) translateY(-50%);
}
.Slider_ratingPercentageOn__1r4I0,
.Slider_ratingPercentageOff__IX4sg,
.Slider_ratingNumberOn__3Wnj7,
.Slider_ratingNumberOff__2xh4_ {
  position: absolute;
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  font-weight: bold;
}
.Slider_ratingPercentageOn__1r4I0,
.Slider_ratingPercentageOff__IX4sg {
  left: 15px;
}
.Slider_ratingNumberOn__3Wnj7,
.Slider_ratingNumberOff__2xh4_ {
  right: 17px;
}
.Slider_ratingPercentageOff__IX4sg,
.Slider_ratingNumberOff__2xh4_ {
  color: #fff;
}
.Slider_ratingPercentageOn__1r4I0,
.Slider_ratingNumberOn__3Wnj7 {
  color: rgb(82, 0, 241);
  opacity: 0.7;
}
.Slider_input__3fWsE {
  display: none;
}
.Slider_sliderSettingsContainer__mKybn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.Slider_dropDownContainer__3JF86 {
  height: 30px;
  min-width: 125px;
  line-height: 30px;
  padding: 0 23px 0 8px;
  border: 1px solid #d4d4d4;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-repeat: no-repeat;
  background-position: 95% 12px;
  background-size: 10px 5px;
  outline: none;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
}
.Slider_dropDownActive__3QGi_ {
  border-radius: 3px 3px 0 0;
}
.Slider_dropDownArrow__2PS2B {
  width: 10px;
  height: 5px;
  position: absolute;
  top: 12px;
  right: 8px;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.Slider_dropDownArrowActive__df_0L {
  -webkit-transform: rotate(180deg) scaleX(-1);
          transform: rotate(180deg) scaleX(-1);
}
.Slider_dropDown__uzSzh {
  width: 100%;
  position: absolute;
  top: 29px;
  left: -1px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 0 0 3px 3px;
  text-align: left;
  z-index: 100;
  background-color: #fff;
}
.Slider_dropDownOption__3qHeY {
  cursor: pointer;
  width: 100%;
  padding-left: 8px;
  box-sizing: border-box;
}
.Slider_dropDownOption__3qHeY:hover {
  background-color: #edeef1;
}
.Slider_horizontal__1eZ2t {
  min-width: 165px;
}

.Question_container__2ldoA {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 15px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
.Question_blockStructure__f4lGr {
  display: inline-flex;
  margin-top: 30px;
  width: calc(100% - 80px);
}
.Question_content__2QT2M {
  max-height: 315px;
  overflow-y: auto;
  padding: 20px 30px 20px 95px;
}
.Question_number__agZ4c {
  margin-left: 15px;
  width: 35px;
  color: #221f1f;
  font-size: 18px;
}
.Question_iconContainer__3BUSY {
  width: 45px;
  color: #8500f0;
  top: 4px;
}
.Question_iconContainer__3BUSY .Question_icon__1ZDy3 {
  font-size: 16px;
}
.Question_blockDetails__1BjND {
  width: 100%;
}
.Question_blockDetails__1BjND .Question_blockText__3vrxA {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  width: calc(100% - 80px);
}
.Question_otherResultsContainer__2_KEx {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #8b8b8d;
}
.Question_otherResultsHeader__3kqk2 {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}
.Question_otherResultsTitle__1wHhV {
  text-transform: uppercase;
}
.Question_otherResultsList__1PCYE {
  max-height: 200px;
  overflow-y: scroll;
}
.Question_otherResultsList__1PCYE.Question_otherResultsListExpanded__2s2ew {
  max-height: none;
}
.Question_otherResult__2wiJC {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.Question_otherResultColor__2h-t8 {
  min-width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
}
.Question_otherResultString__2efxO {
  margin-left: 50px;
}
.Question_toggleButtonWrapper__LErEs {
  display: flex;
}
.Question_toggleButton__3FQMR {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 35px;
  height: 35px;
  padding: 0px 30px;
  border-radius: 20px;
  font-size: 14px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.09);
  color: #505050;
  background-color: #edeef1;
  box-sizing: border-box;
}
.Question_toggleButton__3FQMR.Question_statisticalRelevance__3_Qx5 {
  margin-right: 40px;
}
.Question_toggleButtonActive__yNaYZ {
  color: #ffffff;
  background-color: #5200f1;
}
.Question_toggleButtonDisabled__3G3_t {
  cursor: auto;
  color: #8b8b8d;
}
.Question_resultsContainer__6w6WT {
  height: 45px;
  line-height: 45px;
  display: flex;
  padding-left: 95px;
  border-bottom: 1px solid #efefef;
  justify-content: space-between;
}
.Question_resultsContainerLeft__CPglW {
  display: flex;
}
.Question_resultsContainerRight__jGNnZ {
  display: flex;
}
.Question_resultViewTypeItem__3cHW- {
  font-family: 'Roboto Bold';
  margin-right: 20px;
  color: #000000;
  cursor: pointer;
}
.Question_warning__YEWcY {
  color: #fc035d;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-family: Open Sans SemiBold;
}
.Question_warning__YEWcY img {
  width: 22px;
  height: 22px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
}
.Question_sortAnswersTableContainer__3C8_a {
  margin: 40px 80px 40px 40px;
}
.Question_leftTitle__30cQs {
  width: 30%;
  background-color: #f1eff1;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: bold;
}
.Question_topTitle__1D_f6 {
  background-color: blue;
  flex-grow: 1;
  flex-basis: 0;
  background-color: #f1eff1;
  padding: 10px;
  font-weight: bold;
  margin-right: 2px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.Question_leftTitle__30cQs.Question_topTitle__1D_f6 {
  flex-grow: initial !important;
  flex-basis: initial !important;
  background-color: #f1eff1;
}
.Question_tableRow__1OHjW {
  display: flex;
  border-bottom: 2px solid #d9d8da;
}
.Question_tableValue__9B5cV {
  background-color: #ffffff;
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px;
  margin-right: 2px;
  overflow: hidden !important;
}
.Question_percentage__25ibY {
  font-size: 12px;
}

.OverallStatistics_statistics__32ppO {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  height: 50px;
  justify-content: center;
  color: #221f1f;
}
.OverallStatistics_statistics__32ppO.OverallStatistics_disabled__Kdl73 {
  opacity: 0.5;
}
.OverallStatistics_statisticsItem__3J5TY {
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.OverallStatistics_count__3rw83 {
  font-family: Open Sans SemiBold;
  color: #5200f1;
  font-size: 28px;
}
.OverallStatistics_statistics__32ppO.OverallStatistics_disabled__Kdl73 .OverallStatistics_count__3rw83 {
  color: #95989a;
}
.OverallStatistics_countPercentage__2S_SC {
  font-size: 14px;
  margin-left: 4px;
  font-family: Open Sans Bold;
}
.OverallStatistics_label__2CRPr {
  font-size: 12px;
  margin-left: 8px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .OverallStatistics_statistics__32ppO.OverallStatistics_disabled__Kdl73 {
    width: calc(100% - 20px);
  }
  .OverallStatistics_statistics__32ppO {
    flex-wrap: wrap;
    height: auto;
  }
}

.MainContent_container__xdH1b {
  width: 1012px;
  margin-bottom: 100px;
  margin-left: 375px;
}
.MainContent_container__xdH1b.MainContent_disabled__3HRTf {
  position: fixed;
}

@media only screen and (max-width: 1395px) {
  .MainContent_container__xdH1b {
    width: calc(100% - 390px);
  }
}

@media only screen and (max-width: 768px) {
  .MainContent_container__xdH1b.MainContent_disabled__3HRTf {
    position: relative;
    width: calc(100vw - 20px);
    margin-left: 10px;
  }
}

.Workspaces_container__2Se5y {
  width: 1390px;
  margin-left: auto;
  margin-right: auto;
}
.Workspaces_content__1iBYI {
  position: relative;
  top: 102px;
}
.Workspaces_sideContainer__2dPWn {
  width: 360px;
  position: fixed;
}
.Workspaces_createAPopulationButton__A0B78 {
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 13px;
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  margin-bottom: 30px;
}
.Workspaces_createAPopulationButton__A0B78.Workspaces_disabled__3q48F {
  color: #95989a;
  opacity: 0.5;
}
.Workspaces_createAPopulationButton__A0B78 img {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 13px;
}
.Workspaces_createAPopulationButton__A0B78.Workspaces_disabled__3q48F img {
  -webkit-filter: grayscale(100%) opacity(0.4);
          filter: grayscale(100%) opacity(0.4);
}
.Workspaces_populationContainer__3Rp9W {
  min-height: 300px;
  max-height: calc(100vh - 390px);
  overflow: auto;
}

.Workspaces_defaultSample__v5X3c {
  border: 1px solid #efefef;
  border-radius: 10px;
  page-break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
  box-shadow: 0px 10px 5px -8px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  margin-bottom: 35px;
  font-size: 11px;
  display: flex;
  height: 50px;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.Workspaces_defaultSample__v5X3c .Workspaces_titleTextContainer__3JrZE {
  display: flex;
  align-items: center;
}
.Workspaces_defaultSample__v5X3c .Workspaces_colorBullet__zygjx {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 15px;
}
.Workspaces_defaultSample__v5X3c .Workspaces_populationLabel__2OAhE {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  color: #707070;
}
.Workspaces_defaultSample__v5X3c .Workspaces_filteredResultsCount__3sswS {
  text-transform: lowercase;
}

.Workspaces_populationsInfoContainer__1RXIX {
  background-color: #ede5fe;
  padding: 15px;
  box-sizing: border-box;
}
.Workspaces_infoTitleWrapper__OeVxO {
  display: flex;
  margin-bottom: 5px;
}
.Workspaces_infoIcon__3bvn1 {
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
}
.Workspaces_infoTitle__3B32v {
  font-family: 'Roboto Medium';
}
.Workspaces_infoText__3WnbA {
  font-style: italic;
  font-size: 13px;
  line-height: 16px;
}

@media only screen and (max-width: 1395px) {
  .Workspaces_container__2Se5y {
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .Workspaces_content__1iBYI {
    top: 160px;
  }
  .Workspaces_createAPopulationButton__A0B78 {
    position: relative;
    margin-left: 10px;
    margin-bottom: 20px;
  }
}

.main-results-container {
  width: 1390px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 65px;
}

.main-results-container .loader-container {
  background-color: #e3e3e3;
}
.main-results-container .filters-container {
  height: 475px;
  min-width: 360px;
  box-shadow: none;
  border: none;
  margin-top: 0;
  border-radius: 7px;
}
.main-results-container .question-container {
  height: 400px;
  margin-top: 25px;
  border-radius: 7px;
}
.main-results-container .statistics-loading {
  margin-top: 2px;
  height: 50px;
  border-radius: 7px;
}
.main-results-container .header-container {
  height: 50px;
  width: 100%;
  margin-top: 0;
  border-radius: 7px;
}
.results-content-loading {
  display: inline-flex;
  align-items: flex-start;
}
.toolbar-container {
  padding-top: 30px;
  padding-bottom: 7px;
  position: absolute;
  width: 1390px;
  z-index: 100;
  display: flex;
  background-color: #fff;
}
.toolbar-container.toolbar-container-fixed {
  position: fixed;
}
.toolbar-container.toolbar-container-relative {
  position: relative;
}
.toolbar-container.toolbar-container-no-padding-bottom {
  padding-bottom: 0;
}
.toolbar-container .navigation {
  padding: 5px;
  display: flex;
  height: 40px;
  line-height: 40px;
  flex-grow: 1;
  width: auto;
  width: initial;
  margin-right: 8px;
}
.toolbar-container .navigation .navigation-item {
  flex-grow: 1;
  text-align: left;
  font-size: 12px;
  color: #000000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.toolbar-container .navigation .navigation-item.navigation-item-active {
  font-family: Open Sans Bold;
  color: #5200f1;
}
.toolbar-container .navigation .navigation-item:nth-child(2) {
  border-left: 1px solid #d3d3d3;
}
.toolbar-container .toolbar-button:nth-child(2) {
  margin-left: auto;
}
.toolbar-container .toolbar-button {
  border-radius: 7px;
  padding: 5px 20px;
  font-family: Open Sans SemiBold;
  margin-left: 8px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center;
}
.toolbar-container .toolbar-button.logic-jump-button {
  color: #5200f1;
  border: 1px solid #d3d3d3;
  display: inline-flex;
  align-items: center;
}
.toolbar-container .toolbar-button.logic-jump-button.logic-jump-button-active {
  color: #5200f1;
}
.toolbar-container
  .toolbar-button.logic-jump-button.logic-jump-button-disabled {
  opacity: 0.5;
}
.toolbar-container .toolbar-button.logic-jump-button .logic-jump-icon {
  width: 14px;
  height: 12px;
  margin-right: 15px;
}
.toolbar-container .toolbar-button.edit-button {
  color: #5200f1;
  border: 1px solid #c3c3c3;
}
.toolbar-container .toolbar-button.edit-button.edit-button-active {
  color: #5200f1;
  border-radius: 7px 7px 0 0;
  border-bottom-color: #e6e6e6;
}
.toolbar-container .toolbar-button.edit-button .edit-icon {
  width: 12px;
  height: 12px;
  margin-left: 10px;
  -webkit-filter: grayscale(100%) opacity(0.4);
          filter: grayscale(100%) opacity(0.4);
}
.toolbar-container .toolbar-button.edit-button.edit-button-active .edit-icon {
  -webkit-filter: none;
          filter: none;
}
.toolbar-container .toolbar-button.edit-button .edit-drop-down {
  width: 100%;
  position: absolute;
  top: 51px;
  left: -1px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #d4d4d4;
  border-radius: 0 0 7px 7px;
  text-align: left;
  background-color: #fff;
}
.toolbar-container .toolbar-button.edit-button .edit-drop-down-option {
  cursor: pointer;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  display: block;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
}
.toolbar-container .toolbar-button.background-color-button {
  background-color: #5200f1;
  color: #fff;
  border: 1px solid #5200f1;
}
.toolbar-container .toolbar-button.edit-workspace-button {
  color: #5200f1;
  border: 1px solid #c3c3c3;
}
.toolbar-container
  .toolbar-button.edit-workspace-button.edit-workspace-button-active {
  color: #5200f1;
}
.toolbar-container
  .toolbar-button.edit-workspace-button.edit-workspace-button-disabled {
  opacity: 0.5;
  cursor: text;
  -webkit-filter: grayscale(100%) opacity(0.4);
          filter: grayscale(100%) opacity(0.4);
}
.toolbar-container
  .toolbar-button.edit-workspace-button
  .edit-workspace-button-icon {
  height: 12px;
  margin-right: 10px;
}
.toolbar-container
  .toolbar-button.edit-workspace-button.edit-workspace-button-active
  .edit-workspace-button-icon {
  -webkit-filter: none;
          filter: none;
}
.toolbar-container .toolbar-button.export-button {
  color: #5200f1;
  border: 1px solid #c3c3c3;
  display: inline-flex;
  align-items: center;
}
.toolbar-container .toolbar-button.export-button.export-button-disabled {
  cursor: text;
  opacity: 0.5;
  -webkit-filter: grayscale(100%) opacity(0.4);
          filter: grayscale(100%) opacity(0.4);
}
.toolbar-container .toolbar-button.export-button .export-icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}
.toolbar-container .share-results-button .toolbar-temporary-tooltip {
  position: absolute;
  border: 1px solid #c3c3c3;
  background-color: #fff;
  color: #221f1f;
  bottom: -22px;
  right: 1px;
  font-size: 12px;
  line-height: 12px;
  padding: 7px 14px;
  font-family: 'Open Sans Regular';
  border-radius: 2px;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.toolbar-container .share-results-button .toolbar-temporary-tooltip-arrow {
  width: 0;
  height: 0;
  position: absolute;
  left: 38px;
  top: 33px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid #c3c3c3;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}
.toolbar-container
  .share-results-button
  .toolbar-temporary-tooltip-arrow.toolbar-temporary-tooltip-arrow-white {
  top: 34px;
  border-bottom-color: #fff;
  opacity: 1;
}
.toolbar-container
  .share-results-button.share-results-button-tooltip-visible
  .toolbar-temporary-tooltip,
.toolbar-container
  .share-results-button.share-results-button-tooltip-visible
  .toolbar-temporary-tooltip-arrow {
  visibility: visible;
  opacity: 1;
  transition: none;
}
.blocks-results-header {
  position: relative;
  background-color: #fff;
  top: 0;
  padding-top: 12px;
  z-index: 99;
  width: calc(100vw - 375px - (100vw - 1390px) / 2);
  overflow-x: hidden;
}
.blocks-results-header-shadow {
  box-shadow: -2px 6px 5px -5px #cacaca;
}
.blocks-results-header.blocks-results-header-sticky {
  position: -webkit-sticky;
  position: sticky;
}
.box-shadow {
  height: 15px;
  width: calc(100vw - 362px - (100vw - 1390px) / 2);
  box-shadow: 0 6px 5px -5px #cacaca;
}
.content-results-header-loading {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  height: 55px;
  line-height: 55px;
  padding-top: 30px;
  z-index: 99;
}
.content-results-header {
  display: flex;
  background-color: #ffffff;
  z-index: 100;
  border: 1px solid #d3d3d3;
  border-radius: 7px;
  margin-bottom: 10px;
  height: 50px;
  justify-content: center;
  width: 1013px;
  margin-left: 13px;
}
.left-results-header-content {
}
.left-results-header-content .header1 {
  font-family: 'Roboto Bold';
  font-size: 23px;
  color: #221f1f;
  height: 30px;
  line-height: 30px;
  max-width: 800px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.left-results-header-content .header2 {
  font-size: 14px;
  color: #8b8b8d;
  height: 30px;
  line-height: 30px;
}
.right-results-header-content {
  display: inline-flex;
}
.right-results-header-content .right-header-content-container {
  width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.right-results-header-content
  .right-header-content-container
  .right-header-value {
  font-family: Open Sans SemiBold;
  color: #5200f1;
  font-size: 28px;
}
.right-results-header-content
  .right-header-content-container
  .right-header-value
  .right-header-value-percentage {
  font-size: 14px;
  margin-left: 4px;
  font-family: Open Sans Bold;
}
.right-results-header-content
  .right-header-content-container
  .right-header-title {
  font-size: 12px;
  margin-left: 8px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  max-width: 50px;
  color: #221f1f;
}
.results-content {
  display: inline-flex;
  align-items: flex-start;
  top: 82px;
}
.results-content-top {
  top: 100px;
}
.results-container {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 7px;
  margin-bottom: 15px;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}
.results-container .container-title {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: none;
}
.results-container .answers-container .camera-question-image-item {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.results-container .answers-container .camera-question-image-item img {
  max-width: 100px;
  max-height: 130px;
  cursor: pointer;
}
.results-container .answers-container .camera-question-image-item img:hover {
  opacity: 0.7;
}
.results-container .answers-container .open-question-answer-container {
}
.results-container
  .answers-container
  .open-question-answer-container
  .answer-text {
}
.results-container
  .answers-container
  .open-question-answer-container
  .answer-time-taken {
}
.results-container .answers-container .open-question-answer-container {
  border-bottom: 1px solid #dfe0e4;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #8b8b8d;
  font-size: 13px;
}
.results-container
  .answers-container
  .open-question-answer-container:last-child {
  border-bottom: 0;
}
.results-container
  .answers-container
  .open-question-answer-container
  .answer-text {
  width: 80%;
}
.results-container
  .answers-container
  .open-question-answer-container
  .answer-time-taken {
  width: 20%;
}

.results-container .answers-container .results-answers-distribution-container {
  overflow-y: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-left: 95px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution {
  font-size: 14px;
  color: #221f1f;
  margin-bottom: 30px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer {
  width: 90%;
  padding-right: 20px;
  margin-bottom: 3px;
  z-index: 9;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-other-answer {
  width: 90%;
  padding-right: 20px;
  margin-bottom: 3px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-other-answer-button {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #5200f5;
  margin: 12px 0;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .other-answers-list
  .other-answer {
  display: block;
  padding: 10px 0;
  color: #8b8b8d;
  font-size: 13px;
  border-bottom: 1px solid #dfe0e4;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .other-answers-list
  .other-answer:last-child {
  border-bottom: 0;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .other-answers-list {
  margin-left: 2px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer
  .allow-other-label {
  margin-left: 10px;
  border: 1px solid #5200f5;
  color: #5200f5;
  font-size: 11px;
  padding: 2px 5px;
  border-radius: 2px;
  opacity: 0.7;
  position: absolute;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer.cover-image-padding {
  padding-left: 35px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .answer-cover-image-container {
  top: -3px;
  z-index: 10;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .answer-cover-image-container
  .left-container {
  border: 1px solid #8500f0;
  border-radius: 2px;
  height: 22px;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  cursor: default;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .left-container {
  width: 22px;
  height: 22px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .left-container
  .cover-image-preview {
  width: 22px;
  height: 22px;
  background-size: cover;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #ffffff;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer-details {
  display: flex;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer-details
  .unfiltered {
  font-size: 10px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container {
  width: 75%;
  height: 11px;
  background-color: #ebecf0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 3px;
  cursor: pointer;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container.unfiltered {
  height: 15px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container
  .results-answers-bar {
  height: 11px;
  background-color: #5200f1;
  cursor: pointer;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution.drop-out
  .results-answers-bar-container
  .results-answers-bar {
  background-color: #fc035d;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container
  .results-answers-bar.unfiltered {
  height: 5px;
  background-color: #8b8b8d;
  cursor: default;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-bar-container
  .results-answers-bar:hover {
  opacity: 0.8;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-percentage {
  font-family: 'Roboto Bold';
  width: 10%;
  padding-left: 20px;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution.drop-out
  .results-answers-percentage {
  color: #fc035d;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution
  .results-answers-answer-count {
  font-family: 'Roboto Bold';
  width: 15%;
}
.results-container
  .answers-container
  .results-answers-distribution-container
  .results-answers-distribution.drop-out
  .results-answers-answer-count {
  color: #fc035d;
}

.results-container .filter-results-container {
  padding-right: 19px;
  margin-left: 19px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
}
.results-container .filter-results-container .filter-results-title {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.filter-results-options-container {
  display: flex;
  justify-content: space-between;
}
.filter-results-options-button {
  font-size: 30px;
  font-weight: bold;
  color: #5300f2;
  cursor: pointer;
  letter-spacing: 1px;
  line-height: 0px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.demographics-dropdown {
  position: absolute;
  z-index: 100;
  top: -190px;
  right: 30px;
  box-sizing: border-box;
  font-size: 11px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.demographics-dropdown-action {
  height: 30px;
  text-transform: none;
  color: #8b8b8d;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}
.demographics-dropdown-action-icon {
  width: 20px;
  text-align: center;
}
.demographics-dropdown-action-text:hover {
  opacity: 0.7;
}
.side-results-container-title-item {
  display: inline-flex;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #8b8b8d;
  cursor: pointer;
}
.side-results-container-title-item .count {
  background-color: #5200f1;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 12px;
  height: 11px;
  line-height: 10px;
}
.side-results-container-title-item.active {
  color: #000000;
}
.side-results-container-content {
}
.action-results-column-container {
  width: 360px;
  position: relative;
  z-index: 99;
  top: 12px;
  margin-top: 12px;
  border: 1px solid #d3d3d3;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}
.action-results-column-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.action-results-column-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.action-results-column-container.action-results-column-container-sticky {
  position: -webkit-sticky;
  position: sticky;
}
.blocks-results-column-container {
  width: 1012px;
  margin-bottom: 100px;
  margin-left: 13px;
  margin-top: 15px;
}
.blocks-results-loading {
  margin-top: 10px;
}
.results-container .block-structure {
  display: inline-flex;
  margin-top: 30px;
  width: calc(100% - 80px);
}
.results-container .block-structure .block-number {
  margin-left: 15px;
  width: 35px;
  color: #221f1f;
  font-size: 18px;
  flex-shrink: 0;
}
.results-container .block-structure .block-icon {
  width: 35px;
  color: #8500f0;
  top: 4px;
  flex-shrink: 0;
}
.results-container .block-structure .block-icon .icon {
  font-size: 16px;
}
.results-container .block-content {
  width: 100%;
}
.results-container .cover-image-preview {
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 20px;
  cursor: pointer;
}
.results-container .media-upload {
  position: absolute;
  top: 30px;
  right: 20px;
}
.results-container .media-upload .video-file-preview {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  top: 0px;
  left: 0px;
  background-color: #8500f0;
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
}
.results-container .media-upload .video-file-preview span {
  position: relative;
  display: inline-block;
  top: 6px;
  left: 9px;
}
.block-content .block-text {
  margin-bottom: 30px;
  font-size: 18px;
  color: #221f1f;
  width: calc(100% - 80px);
}
.block-content .block-text .block-text-matrix-axis {
  margin-bottom: 20px;
}
.block-content
  .block-text
  .block-text-matrix-axis
  .block-text-matrix-axis-bold {
  font-family: 'Open Sans SemiBold';
  text-transform: capitalize;
}
.block-content .block-text .block-respondents {
  margin-top: 5px;
  font-size: 12px;
  color: #8b8b8d;
}
.block-content .block-text .block {
  font-family: 'Open Sans Regular';
  font-size: 18px;
  border: 0;
  outline: none;
  width: 600px;
  background-color: #ffffff;
  color: #000000;
  margin-bottom: 10px;
}
.results-container .result-view-type-container {
  height: 45px;
  line-height: 45px;
  display: flex;
  padding-left: 95px;
  border-bottom: 1px solid #efefef;
  justify-content: space-between;
}
.results-container .results-container-left {
  display: flex;
}
.results-container .result-view-type-container .view-type-item {
  font-family: 'Open Sans Bold';
  margin-right: 45px;
  color: #8b8b8d;
  cursor: pointer;
  font-size: 13px;
}
.results-container .result-view-type-container .view-type-item.active {
  color: #000000;
}
.results-container .result-view-type-container .expand-open-question-button {
  display: flex;
  cursor: pointer;
  margin-right: 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 35px;
  line-height: 35px;
  height: 35px;
  padding: 0 30px;
  border-radius: 20px;
  font-size: 14px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.09);
  color: #505050;
  background-color: #edeef1;
}
.results-container
  .result-view-type-container
  .expand-open-question-button.expand-open-question-button-active {
  color: #ffffff;
  background-color: #5200f1;
}
.results-container .answers-container .open-question-answers-container {
  max-height: 315px;
}
.results-container
  .answers-container
  .open-question-answers-container.open-question-answers-expanded {
  max-height: none;
}

.gauge-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.gauge-legend-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  flex-wrap: wrap;
  width: 100%;
}
.gauge-legend-container .gauge-legend-item-container {
  margin-right: 15px;
}
.gauge-legend-container .gauge-legend-item-container .gauge-legent-item-bullet {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 5px;
}
.gauge-legend-container .gauge-legend-item-container .gauge-legent-item-label {
  font-family: 'Open Sans SemiBold';
  font-size: 11px;
  text-transform: uppercase;
  color: #8b8b8d;
}
.gauge-legend-container .gauge-legend-item-container .gauge-legent-item-value {
  display: block;
  font-family: 'Roboto Bold';
  font-size: 14px;
  color: #221f1f;
  text-align: center;
  padding-left: 10px;
}

.results-bar-container {
}
.results-bar-container .results-bar-bottom-axis {
}
.results-bar-container .results-bar-bottom-axis text {
  font-size: 11px;
  color: #8b8b8d;
}
.results-bar-container .results-bar-bottom-axis line,
.results-bar-container .results-bar-bottom-axis path {
  stroke: #8b8b8d;
}
.results-bar-container .results-bar-left-axis {
}
.results-bar-container .results-bar-left-axis text {
  font-size: 11px;
  color: #8b8b8d;
}
.results-bar-container .results-bar-left-axis line,
.results-bar-container .results-bar-left-axis path {
  stroke: #8b8b8d;
}
.results-bar-container .results-bar-left-axis .tick line {
  display: none;
}
.results-bar-single-bar:hover {
  opacity: 0.5;
}

.results-page-export-overlay-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.export-popup-container {
  background-color: #fff;
  padding: 40px 0 20px 0;
  border-radius: 10px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: hidden;
}
.results-page-export-container {
  background-color: #fff;
  padding: 30px 0 15px 0;
  border: 1px solid #707070;
  border-radius: 8px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
}
.results-page-export-container .results-page-export-introduction {
  padding: 0 35px;
  font-size: 14px;
}
.results-page-export-container
  .results-page-export-introduction
  .results-page-export-introduction-title {
  font-family: 'Open Sans Bold';
  color: #5200f5;
  padding-bottom: 20px;
}
.results-page-export-container
  .results-page-export-introduction
  .results-page-export-introduction-subtitle {
  font-style: italic;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
}
.results-page-export-container .results-page-export-options {
  display: flex;
  padding: 30px 35px;
}
.results-page-export-container .results-page-export-option {
  box-shadow: 0px 3px 6px #00000029;
  border: 0.25px solid #95989a;
  border-radius: 6px;
  width: 120px;
  height: 120px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
  box-sizing: border-box;
}
.results-page-export-container .results-page-export-option-active {
  border: 2px solid #5300f2;
  box-shadow: none;
}
.results-page-export-container
  .results-page-export-option
  .results-page-export-option-icon {
  -webkit-filter: grayscale(100%) opacity(0.4);
          filter: grayscale(100%) opacity(0.4);
  width: 58px;
  height: 80px;
}
.results-page-export-container
  .results-page-export-option.results-page-export-option-active
  .results-page-export-option-icon {
  -webkit-filter: none;
          filter: none;
}
.results-page-export-container .results-page-export-options-information {
  max-width: 150px;
  margin-left: 40px;
}
.results-page-export-container
  .results-page-export-options-information
  .results-page-export-options-information-title {
  font-family: 'Open Sans Bold';
  color: #5200f5;
  padding-bottom: 10px;
}
.results-page-export-container
  .results-page-export-options-information
  .results-page-export-options-information-subtitle {
  font-style: italic;
}
.results-page-export-container .results-page-export-additional-details {
  display: flex;
  align-items: center;
  padding: 20px 0;
  margin: 0 35px;
  box-sizing: border-box;
  width: 610px;
  max-width: 100%;
  min-height: 77px;
}
.results-page-export-container
  .results-page-export-additional-details.results-page-export-additional-details-visible {
  border-top: 1px solid #e6e6e6;
}
.results-page-export-container
  .results-page-export-additional-details
  .results-page-export-warning {
  display: flex;
  align-items: center;
  color: #8b8b8d;
  font-family: Open Sans SemiBold;
}
.results-page-export-container
  .results-page-export-additional-details
  .results-page-export-warning
  .results-page-export-warning-icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}
.results-page-export-container
  .results-page-export-additional-details
  .checkbox {
  margin-right: 8px;
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid #5300f2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.results-page-export-container
  .results-page-export-additional-details
  .checkbox.selected-checkbox {
  background-color: #5300f2;
}
.results-page-export-container
  .results-page-export-additional-details
  .checkbox
  img {
  width: 10px;
}
.results-page-export-container .results-page-export-actions {
  border-top: 1px solid #e6e6e6;
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
}
.results-page-export-container
  .results-page-export-actions
  .results-page-export-confirm-icon {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.results-page-export-container
  .results-page-export-actions
  .results-page-export-close-icon {
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
  top: 3px;
  position: relative;
}

.result-view-data.area-chart-container {
  padding-left: 75px;
  display: flex;
}
.result-view-data.area-chart {
  width: 80%;
}
.result-view-data.stats-data {
  width: 20%;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  font-size: 12px;
}
.result-view-data.stats-data .results-view-stats-big-container {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.result-view-data.stats-data
  .results-view-stats-big-container
  .results-view-stats-big-number {
  font-family: 'Roboto Bold';
  font-size: 25px;
}
.result-view-data.stats-data
  .results-view-stats-big-container
  .results-view-stats-big-label {
}
.result-view-data.stats-data .results-view-stats-small-container {
  width: 100%;
}
.result-view-data.stats-data .results-view-stats-small-subcontainer {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.result-view-data.stats-data
  .results-view-stats-small-container
  .results-view-stats-small-number {
  font-family: 'Roboto Bold';
  font-size: 17px;
}
.result-view-data.stats-data
  .results-view-stats-small-container
  .results-view-stats-small-label {
}

.result-view-data.area-chart .line {
  fill: none;
  stroke: #8500f0;
  stroke-width: 3px;
}

.result-view-data.area-chart .area {
  fill: #ebeaef;
}
.result-view-data.area-chart .axis {
}
.result-view-data.area-chart .axis line,
.result-view-data.area-chart .axis path {
  stroke: #9a9a9a;
}
.result-view-data.area-chart text,
.result-view-data.area-chart .axis-title {
  fill: #9a9a9a;
  font-size: 13px;
}
.result-view-data.area-chart .overlay {
  fill: none;
  pointer-events: all;
}
.result-view-data.area-chart .focus circle {
  fill: #ebeaef;
  stroke: #8500f0;
  stroke-width: 3px;
}
.result-view-data.area-chart .hover-line {
  stroke: #6f257f;
  stroke-width: 2px;
  stroke-dasharray: 3, 3;
}

.result-chart-cursor-pointer {
  cursor: pointer;
}
.result-chart-hover-opacity {
  opacity: 1;
}
.result-chart-on-hover-opacity:hover {
  opacity: 0.7;
}
.result-chart-half-opacity {
  opacity: 0.5;
}
.result-chart-grey-bar {
  opacity: 0;
}
.result-chart-grey-bar-active {
  opacity: 0.7;
}
.workspace-result-bar-0 {
  margin-left: 10px;
  margin-right: 10px;
}

.results-container .answers-container .sort-answers-table-container {
  margin-top: 40px;
}
.results-container .answers-container .table-row {
  display: flex;
  border-bottom: 2px solid #d9d8da;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .left-title {
  width: 30%;
  background-color: #f1eff1;
  padding: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: bold;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .top-title {
  background-color: blue;
  flex-grow: 1;
  flex-basis: 0;
  background-color: #f1eff1;
  padding: 10px;
  font-weight: bold;
  margin-right: 2px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .top-title.left-title {
  flex-grow: initial !important;
  flex-basis: initial !important;
  background-color: #f1eff1;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .table-value {
  background-color: #ffffff;
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px;
  margin-right: 2px;
  overflow: hidden !important;
}
.results-container
  .answers-container
  .sort-answers-table-container
  .table-row
  .table-value
  .percentage {
  font-size: 12px;
}

.logic-map-container-results-page .edgePath path {
  fill: none;
}
.logic-map-container-results-page .flow-chart-question-type {
  cursor: pointer;
}
.logic-map-container-results-page .flow-chart-zoom-control-container {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 9;
  display: flex;
}
.logic-map-container-results-page .flow-chart-zoom-control {
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  font-size: 14px;
  border-radius: 2px;
}

@media only screen and (max-width: 1395px) {
  .main-results-container {
    width: calc(100% - 30px);
  }
  .toolbar-container {
    width: calc(100vw - 40px);
  }
  .blocks-results-header {
    width: 100%;
  }
  .content-results-header-loading,
  .results-content-loading {
    width: calc(100% - 20px);
    margin-left: 10px;
  }
  .content-results-header {
    width: calc(100vw - 417px);
  }
  .right-results-header-content {
    width: 100%;
    justify-content: center;
  }
  .right-results-header-content .right-header-content-container:last-child {
    margin-right: 30px;
  }
  .blocks-results-column-container {
    width: calc(100vw - 417px);
  }
  .blocks-results-column-container.blocks-results-loading {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .blocks-results-wrapper {
    padding-top: 15px;
  }
  .blocks-results-header {
    overflow-x: visible;
  }
  .content-results-header-loading,
  .content-results-header {
    position: relative;
    display: block;
    height: auto;
    padding-left: 0px;
  }
  .content-results-header-loading {
    padding-bottom: 0;
  }
  .results-content-loading {
    flex-flow: column-reverse;
  }
  .content-results-header {
    width: 100%;
    margin-left: 0;
    padding-top: 0;
  }
  .action-results-column-container,
  .action-results-column-container.action-results-column-container-sticky {
    width: auto;
    position: relative;
  }
  .my-campaigns-action {
    display: none;
  }
  .results-content {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    top: 0px;
  }
  .toolbar-container,
  .toolbar-container-fixed {
    flex-wrap: wrap;
    position: relative;
  }
  .toolbar-container .navigation {
    margin-right: 5px;
  }
  .toolbar-container .navigation-item {
    line-height: normal;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .toolbar-container .toolbar-button {
    line-height: normal;
    font-size: 13px;
    margin: 0 5px 5px 0;
    padding: 5px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .toolbar-container .toolbar-button:nth-child(2) {
    margin-left: 0;
  }
  .toolbar-container .toolbar-button .edit-drop-down .edit-drop-down-option {
    height: 25px;
    line-height: 25px;
    padding-left: 10px;
  }
  .blocks-results-column-container {
    width: 100%;
    margin-left: 0px;
  }
  .results-container .answers-container {
    max-height: 1000px;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution {
    display: block;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .results-answers-bar-container {
    width: 70%;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .results-answers-percentage {
    width: auto;
    padding-left: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .results-answers-answer-count {
    width: auto;
  }
  .action-results-column-container .demographic-results-container,
  .action-results-column-container {
    max-height: none;
    max-height: initial;
  }
  .left-results-header-content {
    padding-left: 10px;
  }
  .right-results-header-content .right-header-content-container {
    justify-content: flex-start;
    padding-left: 15px;
  }
  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .results-answers-answer-details {
    display: block;
  }
  .footer-container {
    display: none;
  }
}

.campaign-results-drag-rect {
  fill: #000000;
  opacity: 0.3;
  cursor: pointer;
}

.results-container-response-times-container {
  margin-top: 13px;
  padding-top: 20px;
}
.results-container-response-times-container .area {
  fill: rgb(82, 0, 241);
  -webkit-clip-path: url(#clip);
          clip-path: url(#clip);
}

.results-container-response-times-container .zoom {
  cursor: move;
  fill: none;
  pointer-events: all;
}

.results-container-response-times-container line,
.results-container-response-times-container path {
  stroke: #9a9a9a;
}
.results-container-response-times-container text {
  fill: #9a9a9a;
}
.results-container-response-times-container .selection {
  opacity: 0.7;
}

.print-popup-alert-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.print-popup-alert {
  width: 425px;
  background-color: #ffffff;
  border: 1px solid #efefef;
  padding: 20px;
  border-radius: 5px;
}
.print-popup-alert .print-text {
  display: block;
  color: #221f1f;
  margin-bottom: 20px;
}
.print-popup-alert .print-button {
  display: block;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: 0 auto;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #ffffff;
  background-color: #5200f1;
  text-align: center;
  font-size: 14px;
  padding: 0 30px;
  cursor: pointer;
  border-radius: 20px;
}

.content-result-not-found {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #8b8b8d;
  margin-top: 56px;
}

.data-title {
  color: #000000;
  font-family: 'Open Sans SemiBold';
  font-size: 14px !important;
}

/* PRINT STYLESHEET */
@media print {
  .print-popup-alert-background {
    display: none !important;
  }
  .main-results-container {
    max-width: 95%;
    width: auto;
  }
  .main-results-container .results-content {
    width: 100%;
    display: block;
  }
  .toolbar-container {
    width: calc(100vw - 40px);
  }
  .blocks-results-wrapper {
    padding-top: 15px;
  }

  .action-results-column-container {
    width: 100%;
    background-color: #ffffff;
    position: relative;
  }
  .action-results-column-container .container-title {
    flex-wrap: wrap;
  }
  .blocks-results-header {
    width: 100%;
    position: relative;
    overflow-x: visible;
  }
  .blocks-results-header.blocks-results-header-sticky {
    position: relative;
  }
  .blocks-results-header .content-results-header {
    width: 100%;
    margin-left: 0px;
  }

  .action-results-column-container
    .container-title
    .side-results-container-title-item.active {
    display: inline-block;
  }
  .action-results-column-container
    .container-title
    .side-results-container-title-item {
    display: none;
  }

  .action-results-column-container .demographic-results-container {
    max-height: none;
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }

  .action-results-column-container
    .demographic-results-container
    .filter-results-container {
    width: 95%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .action-results-column-container
    .demographic-results-container
    .filter-results-container
    .filter-results-charts-bar {
    overflow: visible;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center;
            transform-origin: center;
  }

  .action-results-column-container
    .demographic-results-container
    .filter-type-reset {
    display: none;
  }

  .blocks-results-column-container {
    width: 100%;
    margin-left: auto;
    position: relative;
  }
  .blocks-results-column-container .results-container {
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
    border: none;
    display: block;
  }

  .blocks-results-column-container
    .results-container
    .result-view-type-container {
    padding-left: 45px;
  }

  .blocks-results-column-container
    .results-container
    .answers-container
    .results-answers-distribution-container {
    max-height: none !important;
    padding-left: 45px;
  }
  .blocks-results-column-container
    .results-container
    .answers-container
    .filter-results-charts-bar-wrapper {
    padding-left: 20px;
  }
  .blocks-results-column-container
    .results-container
    .answers-container
    .filter-results-charts-bar {
    overflow: hidden;
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    -webkit-transform-origin: left;
            transform-origin: left;
  }

  .blocks-results-column-container
    .results-container
    .block-structure
    .block-content
    .block-text {
    width: 100%;
    margin-right: 0;
  }

  .blocks-results-column-container
    .answers-container
    .results-answers-distribution-container
    .sort-answers-table-container {
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .blocks-results-column-container
    .answers-container
    .results-answers-distribution-container
    .sort-answers-table-container
    .table-row
    .left-title {
    width: 20%;
  }

  .footer-container {
    display: none;
  }

  .results-container
    .answers-container
    .results-answers-distribution-container
    .results-answers-distribution
    .answer-cover-image-container
    .left-container {
    display: none;
  }
  .results-container .cover-image-preview,
  .results-container .media-upload {
    display: none;
  }
}

.Insights_container__31fsy {
  border: 1px solid #d3d3d3;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Insights_insightContainer__3dJl- {
  cursor: pointer;
  background-color: #ffffff;
  border-bottom: 1px solid #d3d3d3;
  padding: 25px;
  position: relative;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.Insights_insightContainer__3dJl-:last-child {
  border-bottom: 0;
  border-radius: 5px;
}
.Insights_draggingInsightContainer__3n0TE {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
}
.Insights_showActionDropdownButton__2aONF {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: right;
  height: 50px;
  position: absolute;
  top: -12px;
  right: 10px;
}
.Insights_actionDropdownContainer__2P2_h {
  display: none;
  position: relative;
  top: 2px;
  right: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  padding: 8px 15px;
  z-index: 999;
  color: #9d9d9d;
}
.Insights_actionDropdownContainerOption__3X5X1 {
  text-align: left;
  font-size: 13px;
  text-transform: uppercase;
}
.Insights_showActionDropdownButton__2aONF:hover .Insights_actionDropdownContainer__2P2_h {
  display: block;
}
.Insights_name__acBdy {
  font-family: 'Open Sans SemiBold';
  cursor: pointer;
}
.Insights_description__26dF5 {
  font-family: 'Open Sans Regular';
  cursor: pointer;
  display: inline-block;
}
.Insights_baseQuestionContainer__3_Rtu {
  font-size: 12px;
  padding: 5px 0 0 0;
  margin-bottom: 15px;
  margin-top: 10px;
}
.Insights_baseQuestionContainer__3_Rtu img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
.Insights_baseQuestionContainerNumber__1sfn- {
  padding-right: 2px;
}
.Insights_questionContainerPreview__4g2Sc {
  display: inline-block;
  padding: 3px 5px 3px 0px;
  background-color: #f9f9f9;
  margin-right: 6px;
}
.Insights_basedOnDataText__3q5Jg {
  top: 2px;
  font-size: 13px;
  margin-bottom: 10px;
}
.Insights_questionWrapContainerPreview__1X6_U {
  margin-bottom: 10px;
  outline: none;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.Insights_actiosButtonContainer__3Syb9 {
  display: inline-block;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  padding: 0 10px;
  white-space: nowrap;
}
.Insights_actiosButtonContainer__3Syb9 .Insights_actionButton__32tpt .Insights_rightArrowIcon__3STHL {
  position: relative;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
.Insights_actiosButtonContainer__3Syb9 .Insights_actionButton__32tpt .Insights_leftArrowIcon__3HMLm {
  position: relative;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.InsightsHeader_container__wllZk {
  padding-top: 5px;
}
.InsightsHeader_title__1PSyn {
  display: inline-flex;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  margin-top: 10px;
  background-color: #ebe6fc;
  padding: 5px 10px 5px 10px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.InsightsHeader_collapsedTitle__3V3GV {
  border-radius: 5px;
}
.InsightsHeader_insightsIcon__fAItu {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 20px;
  margin-right: 7px;
}
.InsightsHeader_arrowIcon__2kHBE {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 20px;
  margin-left: 7px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.InsightsHeader_rotatedArrow__3B-N6 {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.InsightsPopup_addInsightButton__3O37w {
  display: inline-block;
  line-height: 30px;
  padding: 0 10px 0 8px;
  border: 1px solid #d4d4d4;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: left;
  color: #221f1f;
  background-color: #fff;
}
.InsightsPopup_backgroundContainer__2wMvy {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
}
.InsightsPopup_popupContainer__1xcFH {
  position: fixed;
  width: 660px;
  left: calc(50% - 330px);
  top: 150px;
  background-color: #fff;
  padding: 30px 0 15px 0;
  border: 1px solid #707070;
  border-radius: 8px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: visible;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
}
.InsightsPopup_popupContent__1jkuk {
  width: 600px;
  max-height: 600px;
  overflow: visible;
  padding-left: 30px;
  padding-right: 30px;
}

.InsightsPopup_title__3YJk4 {
  font-family: 'Open Sans Bold';
  color: #5200f5;
}
.InsightsPopup_filtersContainer__v5Fq9 .filters-bar-container {
  padding: 0;
}
.InsightsPopup_filtersContainer__v5Fq9 .single-active-filter {
  padding-right: 10px !important;
}
.InsightsPopup_filtersContainer__v5Fq9 .single-active-filter-filters-relation {
  position: relative;
  top: 10px;
  font-family: 'Roboto Bold';
  font-size: 10px;
  padding-left: 3px;
  padding-right: 3px;
}
.InsightsPopup_filtersContainer__v5Fq9 .insight-nested-active-filter-filters-relation {
  font-family: 'Roboto Bold';
  font-size: 10px;
  margin-left: 3px;
  margin-right: 3px;
  color: #707070;
}

.InsightsPopup_subtitle__2ux_X {
  margin-top: 10px;
  margin-bottom: 5px;
}
.InsightsPopup_filtersBarWrapper__3qmsi {
  display: flex;
}
.InsightsPopup_footer__4xNI1 {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
  margin-top: 20px;
}
.InsightsPopup_footer__4xNI1 .InsightsPopup_details__AyyxB {
  color: #5200f1;
}
.InsightsPopup_footer__4xNI1 .InsightsPopup_footerErrorBackground__1gYFj {
  position: absolute;
  width: calc(100% + 50px);
  height: calc(100% + 25px);
  left: -25px;
  top: 0;
  background-color: #fff2f7;
}
.InsightsPopup_footer__4xNI1 .InsightsPopup_error__1x-9h {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.InsightsPopup_footer__4xNI1 .InsightsPopup_error__1x-9h .InsightsPopup_warningIcon__FQX7_ {
  height: 22px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.InsightsPopup_actions__1EI-d .InsightsPopup_confirm__1e03U {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.InsightsPopup_actions__1EI-d .InsightsPopup_confirm__1e03U.InsightsPopup_disabledConfirm__3C6Fi {
  opacity: 0.2;
}
.InsightsPopup_actions__1EI-d .InsightsPopup_close__2TPiO {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.InsightsPopup_inputElement__4F8Nv {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  font-size: 13px;
  width: calc(100% - 12px);
}
.InsightsPopup_inputElement__4F8Nv:focus {
  outline: none;
}
.InsightsPopup_fieldsContainer__1Aefo input::-webkit-input-placeholder {
  color: #8f8e8e;
  opacity: 1; /* Firefox */
}
.InsightsPopup_fieldsContainer__1Aefo input::-ms-input-placeholder {
  color: #8f8e8e;
  opacity: 1; /* Firefox */
}
.InsightsPopup_fieldsContainer__1Aefo input::placeholder {
  color: #8f8e8e;
  opacity: 1; /* Firefox */
}
.InsightsPopup_fieldsContainer__1Aefo input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #8f8e8e;
}
.InsightsPopup_baseQuestionContainer__Egorm {
  font-size: 12px;
  padding: 5px 0 0 0;
  margin-bottom: 5px;
}
.InsightsPopup_baseQuestionContainer__Egorm img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
.InsightsPopup_baseQuestionContainerNumber__9pTxa {
  padding-right: 2px;
}
.InsightsPopup_loaderContainer__3U8zL {
  display: inline-block;
  bottom: 13px;
  right: -11px;
}
.InsightsPopup_scopingDescription__1mmEp {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  font-size: 13px;
  width: calc(100% - 12px);
  background-color: #f9f8f8;
  color: #8b8b8d;
}
.InsightsPopup_dataPointsContainer__3Ndtz {
  height: 135px;
  left: -30px;
  width: calc(100% + 60px);
  overflow-y: scroll;
}
.InsightsPopup_dataPointsContainer__3Ndtz::-webkit-scrollbar {
  width: 7px;
}
.InsightsPopup_dataPointsContainer__3Ndtz::-webkit-scrollbar-track {
  background: #a5a5a5;
  border-radius: 10px;
}
.InsightsPopup_dataPointsContainer__3Ndtz::-webkit-scrollbar-thumb {
  background: rgba(4, 4, 4, 0.5);
  border-radius: 10px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.InsightsPopup_singleDataPointsContainer__3pQKG {
  background-color: #ebe5fc;
  padding-left: 30px;
  padding-right: 45px;
}
.InsightsPopup_addFeatureButton__cLSKO {
  display: inline-flex;
  margin-top: 15px;
  align-items: center;
  color: #5200f1;
  font-family: Open Sans SemiBold;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.InsightsPopup_addIcon__135C_ {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.InsightsPopup_draggingIcon__17Y5i {
  position: absolute;
  width: 18px;
  height: 10px;
  left: 50%;
  margin-left: -9px;
  top: 10px;

  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;

  background-repeat: no-repeat;
  background-position: center center;
  width: 30px;
  height: 20px;
}

.DataPoint_container__CAAJg {
}
.DataPoint_dataPointContainer__28Sh_ {
  padding-top: 10px;
  padding-bottom: 10px;
}
.DataPoint_background__1p9Ah {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #ebe5fc;
}
.DataPoint_fieldsContainer__1OwEx {
  margin-top: 5px;
}
.DataPoint_close__1W54K {
  position: absolute;
  top: 18px;
  right: -32px;
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.InsightsTextarea_container__1yMVp {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  background-color: #ffffff;
}
.InsightsTextarea_textareaElement__3OhCG textarea {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  resize: none;
  width: calc(100% - 12px);
  border: 0;
}
.InsightsTextarea_draftContainer__2ZCom {
  min-height: 50px;
}
.InsightsTextarea_textareaElement__3OhCG:focus {
  outline: none;
}
.InsightsTextarea_container__1yMVp .emoji-picker {
  z-index: 999;
}

.emoji-picker {
  padding: 0 5px 10px 5px;
  position: absolute;
  top: 40px;
  right: -43px;
  width: 208px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
  z-index: 25;
}
.emoji-picker::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.emoji-picker::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.emoji-picker .emoji-category {
  width: 100%;
  margin-left: 5px;
  margin-bottom: 10px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.emoji-picker .emoji-category:first-child {
  margin-top: 10px;
}
.emoji-picker .emoji {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.emoji-picker .emoji:hover {
  opacity: 0.6;
}

.EmojiPopupContainer_container__dehlN {
  position: fixed;
  width: 208px;
  margin-left: 285px;
  background-color: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 3%);
  box-sizing: border-box;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 20;
}
.EmojiPopupContainer_dropdownItem__3kMxF {
  font-size: 12px;
  cursor: pointer;
  padding: 5px 10px 0px 10px;
  margin-bottom: 5px;
}
.EmojiPopupContainer_dropdownItem__3kMxF img {
  width: 13px;
  height: 9px;
  margin-left: 4px;
  margin-right: 4px;
}
.EmojiPopupContainer_dropdownItemLabel__25qg7 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
}
.EmojiPopupContainer_noAvailableOptions__2RX40 {
  text-align: center;
  min-height: 30px;
  font-size: 13px;
  color: #8b8b8d;
  white-space: pre-wrap;
}
.EmojiPopupContainer_dropdownPopupItemsContainer__2eH_Z {
  padding: 5px 0;
  overflow-y: auto;
  max-height: 100px;
}
.EmojiPopupContainer_dropdownItemNumber__2ITS3 {
  padding-right: 2px;
}

.EmojiPopupContainer_emojiPickerContainer__2FVfs {
  padding: 0 5px 10px 5px;
  width: 208px;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border: 1px solid #edeef1;
  border-radius: 3px;
  box-shadow: 0px 8px 16px -3px rgba(210, 210, 210, 0.8);
  max-height: 220px;
  overflow-y: scroll;
  justify-content: flex-start;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
  z-index: 25;
}
.EmojiPopupContainer_emojiPickerContainer__2FVfs::-webkit-scrollbar {
  width: 5px;
  height: 100%;
  border-radius: 5px;
}
.EmojiPopupContainer_emojiPickerContainer__2FVfs::-webkit-scrollbar-thumb {
  background: #b4b2b6;
}
.EmojiPopupContainer_emojiPickerContainer__2FVfs .EmojiPopupContainer_emojiCategory__QOqJJ {
  width: 100%;
  margin-left: 5px;
  margin-bottom: 10px;
  line-height: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  color: #8b8b8d;
}
.EmojiPopupContainer_emojiPickerContainer__2FVfs .EmojiPopupContainer_emojiCategory__QOqJJ:first-child {
  margin-top: 10px;
}
.EmojiPopupContainer_emojiPickerContainer__2FVfs .EmojiPopupContainer_emoji__3b1fW {
  width: 33px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.EmojiPopupContainer_emojiPickerContainer__2FVfs .EmojiPopupContainer_emoji__3b1fW:hover {
  opacity: 0.6;
}

.NextStepsPopup_backgroundContainer__2nFxh {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NextStepsPopup_popupContainer__yPbUv {
  background-color: #fff;
  padding: 30px 0 15px 0;
  border: 1px solid #707070;
  border-radius: 8px;
  z-index: 99999999;
  max-height: 90vh;
  overflow: visible;
  box-shadow: 0px 3px 6px #00000029;
  font-size: 13px;
}

.NextStepsPopup_popupContent__3gX56 {
  width: 600px;
  max-height: 600px;
  overflow: visible;
  padding-left: 30px;
  padding-right: 30px;
}
.NextStepsPopup_title__3bVkp {
  font-family: 'Open Sans Bold';
  color: #5200f5;
}
.NextStepsPopup_fieldsContainer__3CGIl {
  margin-top: 15px;
}

.NextStepsPopup_footer__2wtGM {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e6e6e6;
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
  margin-top: 20px;
}
.NextStepsPopup_actions__1RoCA .NextStepsPopup_confirm__1Z_yi {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.NextStepsPopup_actions__1RoCA .NextStepsPopup_confirm__1Z_yi.NextStepsPopup_disabledConfirm__2SkPg {
  opacity: 0.2;
}
.NextStepsPopup_actions__1RoCA .NextStepsPopup_close__1hytk {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.NextStepsPopup_loaderContainer__2D6Wt {
  display: inline-block;
  bottom: 13px;
  right: -11px;
}

.Scoping_container__1CNkC {
  padding-top: 5px;
}
.Scoping_title__OUcaG {
  display: inline-flex;
  align-items: center;
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  color: #000000;
  margin-top: 10px;
  padding: 5px 10px 5px 10px;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.Scoping_nextStepsTitle__2RkG0 {
  cursor: pointer;
}
.Scoping_collapsedTitle__bB9Rh {
  border-radius: 5px;
}
.Scoping_titleBackgroundDark__39uSQ {
  background-color: #c9bcff;
}
.Scoping_titleBackgroundLight__1nNvI {
  background-color: #ebe6fc;
}
.Scoping_titleIcon__3Rpih {
  display: inline-block;
  position: relative;
  max-height: 16px;
  max-width: 13px;
  margin-right: 7px;
  margin-top: 1px;
}
.Scoping_arrowIcon__2BXnJ {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 20px;
  margin-left: 7px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.Scoping_rotatedArrow__35EfQ {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.Scoping_nextStepsContainer__tkXvb {
  border: 1px solid #d3d3d3;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Scoping_textContainer__1mzbY {
  padding: 15px 20px;
  border: 1px solid #d3d3d3;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.Scoping_textContainer__1mzbY .Scoping_textField__3VycC {
  margin-bottom: 10px;
}
.Scoping_textContainer__1mzbY .Scoping_textField__3VycC:last-child {
  margin-bottom: 0;
}
.Scoping_textContainer__1mzbY .Scoping_textField__3VycC .Scoping_textFieldTitle__2Rdrr {
  font-family: 'Open Sans SemiBold';
  margin-bottom: 4px;
}
.Scoping_nextStepContainer__1zwlZ {
  border-bottom: 1px solid #d3d3d3;
}
.Scoping_nextStepsContainer__tkXvb .Scoping_textContainer__1mzbY {
  border: none;
  border-bottom: 1px solid #d3d3d3;
  border-radius: 0;
}
.Scoping_nextStepsContainer__tkXvb .Scoping_addNextStep__34EUq {
  border-bottom: none;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.Scoping_nextStepsContainer__tkXvb .Scoping_addNextStep__34EUq:hover {
  opacity: 0.7;
}
.Scoping_nextStepsContainer__tkXvb .Scoping_addNextStep__34EUq .Scoping_plusIcon__WdYAX {
  color: #5300f2;
  margin-right: 5px;
}

.Scoping_showActionDropdownButton__3kAAx {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: right;
  height: 50px;
  position: absolute;
  top: -12px;
  right: 10px;
}
.Scoping_actionDropdownContainer__1IqA1 {
  display: none;
  position: relative;
  top: 2px;
  right: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  padding: 8px 15px;
  z-index: 999;
  color: #9d9d9d;
}
.Scoping_actionDropdownContainerOption__3u5xF {
  text-align: left;
  font-size: 13px;
  text-transform: uppercase;
}
.Scoping_showActionDropdownButton__3kAAx:hover .Scoping_actionDropdownContainer__1IqA1 {
  display: block;
}

.GraphFeatures_featuresContainer__SVnij {
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 5px;
}
.GraphFeatures_featuresContainer__SVnij::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.GraphFeatures_featuresContainer__SVnij::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.GraphFeatures_noFeatures__3VJGP {
  color: #8b8b8d;
  position: absolute;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.GraphFeatures_addFeatureButton__2JN2M {
  display: inline-flex;
  align-items: center;
  color: #5200f1;
  font-family: Open Sans SemiBold;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-left: 15px;
}
.GraphFeatures_addFeatureButtonDisabled__2sPRH {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
  cursor: default;
}
.GraphFeatures_addIcon__1GVCO {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.EditGraphsPopup_container__2VMAR {
  width: 650px;
  font-size: 13px;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 1000px;
}
.EditGraphsPopup_container__2VMAR::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.EditGraphsPopup_container__2VMAR::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.EditGraphsPopup_titleContainer__1flX0 {
  display: flex;
}
.EditGraphsPopup_titleContainer__1flX0 .EditGraphsPopup_titleItem__AFxzC {
  font-family: Open Sans SemiBold;
  color: #8b8b8d;
  cursor: pointer;
  padding: 15px 20px 15px 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-bottom: 1px solid #e6e6e6;
}
.EditGraphsPopup_titleContainer__1flX0 .EditGraphsPopup_titleItem__AFxzC.EditGraphsPopup_activeTitle__2aTak {
  font-family: Open Sans SemiBold;
  color: #404040;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 0;
}
.EditGraphsPopup_titleItemPlaceholder__2u25U {
  flex: 1 1;
  border-bottom: 1px solid #e6e6e6;
}
.EditGraphsPopup_title__2uTJT {
  padding: 10px 15px 15px 0px;
  font-size: 14px;
  font-family: Open Sans SemiBold;
}
.EditGraphsPopup_templateTitle__1nKr7 {
  padding: 10px 15px 15px 15px;
  font-size: 14px;
  font-family: Open Sans SemiBold;
}
.EditGraphsPopup_description__1-X2m {
  padding: 10px 15px 15px 15px;
  font-style: italic;
}
.EditGraphsPopup_templateDescription__wLPzG {
  border-top: 1px solid #e6e6e6;
  padding-top: 15px;
}
.EditGraphsPopup_questionsContainer__2QSsn {
  padding: 25px 15px 5px 15px;
  max-height: 200px;
  overflow: auto;
  border-bottom: 1px solid #e6e6e6;
}
.EditGraphsPopup_question__3O28n {
  display: flex;
  margin-bottom: 15px;
}
.EditGraphsPopup_question__3O28n.EditGraphsPopup_questionNotMergeable__3qKWt {
  opacity: 0.4;
}
.EditGraphsPopup_question__3O28n .EditGraphsPopup_selectorContainer__uU79Y {
  width: 5%;
  text-align: center;
  color: #5200f1;
  font-size: 12px;
  position: relative;
  top: 2px;
}
.EditGraphsPopup_question__3O28n .EditGraphsPopup_selectorContainer__uU79Y .EditGraphsPopup_selectorIcon__DCNno {
  cursor: pointer;
}
.EditGraphsPopup_question__3O28n .EditGraphsPopup_number__2RMKo {
  width: 5%;
  text-align: center;
}
.EditGraphsPopup_question__3O28n .EditGraphsPopup_iconContainer__1Egjl {
  width: 5%;
  text-align: center;
}
.EditGraphsPopup_question__3O28n .EditGraphsPopup_iconContainer__1Egjl img {
  height: 12px;
}
.EditGraphsPopup_question__3O28n .EditGraphsPopup_questionTextContainer__sEAD9 {
  width: 85%;
}
.EditGraphsPopup_footer__2Xugp {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.EditGraphsPopup_footer__2Xugp .EditGraphsPopup_details__3gs3b {
  color: #5200f1;
}
.EditGraphsPopup_footer__2Xugp .EditGraphsPopup_footerErrorBackground__30dNe {
  position: absolute;
  width: calc(100% + 50px);
  height: calc(100% + 25px);
  left: -25px;
  top: 0;
  background-color: #fff2f7;
}
.EditGraphsPopup_footer__2Xugp .EditGraphsPopup_error__27HQR {
  font-family: Open Sans SemiBold;
  color: #fc035d;
  display: inline-flex;
  align-items: center;
}
.EditGraphsPopup_footer__2Xugp .EditGraphsPopup_error__27HQR .EditGraphsPopup_warningIcon__3vyMr {
  height: 22px;
  margin-right: 10px;
  float: left;
  position: relative;
}
.EditGraphsPopup_actions__ImfrU .EditGraphsPopup_confirm__1VZk6 {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.EditGraphsPopup_actions__ImfrU .EditGraphsPopup_close__2Reoq {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.EditGraphsPopup_selectAllContainer__2txhe {
  border-bottom: 1px solid #e6e6e6;
  padding: 0 15px 15px 15px;
  display: flex;
  justify-content: space-between;
}
.EditGraphsPopup_selectAllContainer__2txhe .EditGraphsPopup_selectorContainer__uU79Y {
  width: 5%;
  text-align: center;
  color: #5200f1;
  font-size: 12px;
  position: relative;
  top: 2px;
}
.EditGraphsPopup_selectAllContainer__2txhe .EditGraphsPopup_selectorContainer__uU79Y span {
  cursor: pointer;
}
.EditGraphsPopup_selectAllContainer__2txhe .EditGraphsPopup_selectedBlocks__2QgDt {
  font-family: Open Sans SemiBold;
  color: #5200f1;
}
.EditGraphsPopup_deletePopulation__mUwf0 {
  color: #fc035d;
  cursor: pointer;
}
.EditGraphsPopup_deletePopulation__mUwf0 .EditGraphsPopup_deletePopulationIcon__1g9x2 {
  height: 12px;
  margin-right: 7px;
}
.EditGraphsPopup_graphNameContainer__2YPR8 {
  padding: 0 15px;
  margin-bottom: 20px;
}
.EditGraphsPopup_graphNameContainer__2YPR8 .EditGraphsPopup_mergedGraphName__3V1ka {
  padding: 6px;
  border: 1px solid #dfe0e4;
  border-radius: 5px;
  width: 50%;
}
.EditGraphsPopup_loaderContainer__3t7MF {
  top: 10px;
}
.EditGraphsPopup_shift__2NiHw {
  margin-left: 33.5px;
}
.EditGraphsPopup_templateContainer__3de3S {
  min-height: 400px;
}
.EditGraphsPopup_templateContainer__3de3S .EditGraphsPopup_templateTabWrapper__bai6Q {
  padding: 0 15px 5px 15px;
}
.EditGraphsPopup_templateContainer__3de3S .EditGraphsPopup_templateTabWrapper__bai6Q strong {
  font-family: Open Sans SemiBold;
}
.EditGraphsPopup_templateContainer__3de3S .EditGraphsPopup_templateTabWrapper__bai6Q .EditGraphsPopup_templateTab__397ul {
  background-color: rgb(82 34 242 / 7%);
  border-radius: 6px;
  font-style: italic;
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 12px;
}
.EditGraphsPopup_templateContainer__3de3S .EditGraphsPopup_templateTabWrapper__bai6Q .EditGraphsPopup_hrLine__2Tjj- {
  height: 1px;
  width: 100%;
  border-top: 1px solid #eae9e9;
  margin-bottom: 10px;
}
.EditGraphsPopup_templateTab__397ul:hover {
  opacity: 0.7;
}
.EditGraphsPopup_templateContainer__3de3S .EditGraphsPopup_templateTab__397ul .EditGraphsPopup_bold__31NZB {
  font-family: Open Sans SemiBold;
}
.EditGraphsPopup_bottomLine__n3ujO {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #e6e6e6;
}
.EditGraphsPopup_horizontalLine__3lHHk {
  height: 1px;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #e6e6e6;
}

.EditGraphsPopup_customGraphSubtitles__24kvK {
  padding-top: 5px;
  padding-bottom: 10px;
}
.EditGraphsPopup_inputContainer__194T1 {
  padding: 0px 15px 15px 15px;
  font-style: italic;
}
.EditGraphsPopup_inputContainer__194T1 input {
  width: 45%;
  position: relative;
  background-color: #ffffff;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dfe0e4;
  border-radius: 3px;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 20px;
  box-sizing: border-box;
}
.EditGraphsPopup_inputContainer__194T1 input:first-child {
  margin-right: 14px;
}

.CommunicationCenter_pageContainer__1f6so {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
}
.CommunicationCenter_pageHeader__RYhrf {
}
.CommunicationCenter_pageHeader__RYhrf .CommunicationCenter_header1__2u_9w {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.CommunicationCenter_pageContent__1O6su {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
}
.CommunicationCenter_whiteContainer__19uwb {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}

.CommunicationCenter_pageContent__1O6su .CommunicationCenter_notificationDetailsContainer__2ns7p {
  width: calc(100% - 316px);
  margin-bottom: 30px;
}
.CommunicationCenter_pageContentListPlaceholder__1DZ9J {
  width: 300px;
  height: calc(100vh - 300px);
  margin-right: 20px;
  background-color: #ebeaef;
}
.CommunicationCenter_pageContentMainPlaceholder__3TbMN {
  width: calc(100% - 316px);
  height: calc(100vh - 300px);
  background-color: #ebeaef;
}
.CommunicationCenter_sections__2sp3r {
  height: 50px;
  line-height: 50px;
  margin-top: 0px;
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  margin-bottom: 32px;
  padding: 0;
}
.CommunicationCenter_sections__2sp3r .CommunicationCenter_sectionName__1B7PQ {
  font-family: 'Open Sans Bold';
  width: 50%;
  color: #95989a;
  text-align: center;
  cursor: pointer;
}
.CommunicationCenter_sections__2sp3r .CommunicationCenter_sectionName__1B7PQ.CommunicationCenter_active__238_6 {
  color: #000000;
}

.NotificationsList_notificationsListContainter__1ax1t {
  margin-bottom: 15px;
  width: 300px;
  margin-right: 20px;
}
.NotificationsList_notificationsListContainter__1ax1t .NotificationsList_title__2br0P {
  font-family: 'Roboto Medium';
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  border-bottom: 1px solid #d5d5d5;
  background-color: #fbfbfb;
}
.NotificationsList_notificationsListContainter__1ax1t .NotificationsList_notificationsContainer__3ICy- {
  max-height: calc(100vh - 320px);
  overflow: scroll;
}
.NotificationsList_notificationsListContainter__1ax1t .NotificationsList_notificationsContainer__3ICy- .NotificationsList_notificationItem__31Dih {
  padding: 20px;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
  cursor: pointer;
}
.NotificationsList_notificationsListContainter__1ax1t
  .NotificationsList_notificationsContainer__3ICy-
  .NotificationsList_notificationItem__31Dih.NotificationsList_notificationItemSelected__1wkHx {
  background-color: #f6f7fb;
}

.NotificationsList_notificationsListContainter__1ax1t
  .NotificationsList_notificationsContainer__3ICy-
  .NotificationsList_notificationItem__31Dih.NotificationsList_notificationItemPlaceholder__3XEVJ {
  height: 40px;
  background-color: #f6f7fb;
}

.NotificationsList_notificationsListContainter__1ax1t .NotificationsList_notificationsContainer__3ICy- .NotificationsList_notificationItem__31Dih:hover {
  opacity: 0.8;
}
.NotificationsList_notificationsListContainter__1ax1t .NotificationsList_notificationsContainer__3ICy- .NotificationsList_notificationItem__31Dih .NotificationsList_label__hywL8 {
  display: flex;
  margin-bottom: 3px;
  justify-content: space-between;
}
.NotificationsList_notificationsListContainter__1ax1t
  .NotificationsList_notificationsContainer__3ICy-
  .NotificationsList_notificationItem__31Dih
  .NotificationsList_label__hywL8
  .NotificationsList_name__3TrK- {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.NotificationsList_notificationsListContainter__1ax1t
  .NotificationsList_notificationsContainer__3ICy-
  .NotificationsList_notificationItem__31Dih
  .NotificationsList_label__hywL8
  .NotificationsList_date__1v374 {
  width: 90px;
  text-align: right;
  font-size: 12px;
  color: #73859f;
  white-space: nowrap;
  margin-left: 10px;
}
.NotificationsList_notificationsListContainter__1ax1t
  .NotificationsList_notificationsContainer__3ICy-
  .NotificationsList_notificationItem__31Dih
  .NotificationsList_filterDetails__3CDeD {
  display: flex;
  justify-content: space-between;
}
.NotificationsList_notificationsListContainter__1ax1t
  .NotificationsList_notificationsContainer__3ICy-
  .NotificationsList_notificationItem__31Dih
  .NotificationsList_filterDetails__3CDeD
  .NotificationsList_filterType__1hmSX {
  display: inline-block;
  padding: 2px 4px 2px 4px;
  font-size: 9px;
  color: #ffffff;
  background-color: #5200f1;
  border-radius: 4px;
}
.NotificationsList_notificationsListContainter__1ax1t .NotificationsList_notificationsContainer__3ICy- .NotificationsList_notificationItem__31Dih .NotificationsList_count__3uzrH {
  width: 120px;
  text-align: right;
  font-size: 12px;
  color: #73859f;
}
.NotificationsList_whiteContainer__3EVCv {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}
.NotificationsList_pageContentListPlaceholder__1Fu2p {
  height: calc(100vh - 300px);
  background-color: #ebeaef;
}
.NotificationsList_loadingPlaceholder__2yW2t {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: grey;
  font-size: 12px;
}

.NotificationsList_listTypeContainer__CtwBU {
  color: #b5b5b8;
  display: flex;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
  padding-left: 20px;
}
.NotificationsList_listTypeContainer__CtwBU div {
  cursor: pointer;
  margin-right: 15px;
}
.NotificationsList_listTypeContainer__CtwBU div.NotificationsList_active___IwzB {
  color: #000000;
}

.SendPushNotifications_sendPushContainer__2ejSw {
  padding: 20px;
}
.SendPushNotifications_title__1-6Hc {
  font-family: 'Roboto Medium';
  font-size: 18px;
  margin-bottom: 20px;
}
.SendPushNotifications_title__1-6Hc .SendPushNotifications_templatesIcon__1l6Tm {
  font-size: 14px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
.SendPushNotifications_inputContainer__2sHK0 input {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  display: block;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  resize: none;
}
.SendPushNotifications_inputContainer__2sHK0 .SendPushNotifications_inputCharacterCount__3DiaV {
  font-size: 14px;
  color: #b5b5b8;
  position: absolute;
  right: 0;
  top: 5px;
}
.SendPushNotifications_footerContainer__1n5I1 {
  margin-top: 20px;
  display: flex;
}
.SendPushNotifications_sendPushButton__1oe0t {
  border-radius: 5px;
  background-color: #5200f1;
  color: #ffffff;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  margin-right: 10px;
}
.SendPushNotifications_schedulePushButton__P7UsJ {
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border: 1px solid #eaeaea;
}
.SendPushNotifications_sendPushButtonDisabled__2mcvL {
  background-color: #b5b5b8;
  cursor: not-allowed;
}
.SendPushNotifications_sendPushButton__1oe0t:hover,
.SendPushNotifications_schedulePushButton__P7UsJ:hover {
  opacity: 0.7;
}
.SendPushNotifications_filterDropdownStyle__1WNRR {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  width: 150px;
  height: 15px;
}
.SendPushNotifications_filterDropdownStyle__1WNRR .SendPushNotifications_selectedOptionsLabel__1KISr {
  width: 150px;
}

.SendNotificationFilter_filterTopContainer__fCU7r {
}
.SendNotificationFilter_filterTopContainer__fCU7r {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.SendNotificationFilter_filterTopContainer__fCU7r .SendNotificationFilter_counter__10-y0 {
}
.SendNotificationFilter_filterTopContainer__fCU7r .SendNotificationFilter_addFilterButton__3tZet {
  border: 1px solid #ba97ff;
  color: #5200f1;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.SendNotificationFilter_filterTopContainer__fCU7r .SendNotificationFilter_addFilterButton__3tZet .SendNotificationFilter_addFilterButtonIcon__DudoB {
  font-size: 10px;
  margin-left: 5px;
}
.SendNotificationFilter_filterItemContainer__3tuJB {
  display: flex;
  margin-bottom: 10px;
}
.SendNotificationFilter_filterItemContainer__3tuJB .SendNotificationFilter_filterType__3BZI3 {
  display: flex;
  width: 30%;
}
.SendNotificationFilter_filterItemContainer__3tuJB .SendNotificationFilter_filterType__3BZI3 .SendNotificationFilter_closeIcon__VtJXm {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.SendNotificationFilter_filterItemContainer__3tuJB .SendNotificationFilter_filterDropdown__1LGPj {
  position: relative;
  width: calc(100% - 30px);
  height: 25px;
  line-height: 25px;
  margin: 0 4px;
}
.SendNotificationFilter_rangeFieldContainer__2ocLR {
  display: flex;
}
.SendNotificationFilter_rangeFieldContainer__2ocLR .SendNotificationFilter_rangeFieldContainerStartContainer__DzYHp {
}
.SendNotificationFilter_rangeFieldContainer__2ocLR .SendNotificationFilter_rangeFieldContainerEndContainer__XB6AO {
}
.SendNotificationFilter_rangeFilterInput__3YIX4 {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
}
.SendNotificationFilter_andContainer__1EQLd {
  position: relative;
  border-top: 1px solid #d5d5d5;
  height: 20px;
  margin-top: 20px;
}
.SendNotificationFilter_andContainer__1EQLd .SendNotificationFilter_and__1_vc9 {
  font-size: 11px;
  color: #ffffff;
  background-color: #5200f1;
  padding: 5px;
  position: absolute;
  top: -13px;
  border-radius: 5px;
  left: 85px;
}
.SendNotificationFilter_countLoader__BeA6j {
  position: relative;
  bottom: 10px;
}
.SendNotificationFilter_surveysFilterDescrption__lmr2y {
  color: #9e9e9e;
  font-size: 11px;
}

.DropdownSelector_filterDropdownSelectorContainer__2Y9vj {
  position: relative;
  width: calc(100% - 30px);
  height: 25px;
  line-height: 25px;
}
.DropdownSelector_selectedOptionsLabel__3HK8B {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 30px;
}
.DropdownSelector_filterDropdownPopup__32ix8 {
  font-size: 11px;
  background-color: #ffffff;
  position: absolute;
  top: 26px;
  width: 250px;
  border: 1px solid #dfe0e4;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.DropdownSelector_filterDropdownItem__OcDBI {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.DropdownSelector_filterDropdownItemCheckboxSelected__2F2NN {
  height: 21px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownSelector_filterDropdownItemCheckbox__iDE42 {
  height: 21px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
}
.DropdownSelector_filterDropdownItemLabel__Lbqh3 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.DropdownSelector_noAvailableClientAccounts___Beny {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.DropdownSelector_filterDropdownPopupSearchInputContainer__331Kn input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 20px);
  outline: none;
}
.DropdownSelector_filterDropdownPopupItemsContainer__2JCn4 {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.DropdownSelector_filterDropdownPopupItemsContainer__2JCn4::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.DropdownSelector_filterDropdownPopupItemsContainer__2JCn4::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.DropdownSelector_filterDropdownPopupFooterContainer__FMgDG {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.DropdownSelector_filterDropdownPopupConfirm__3Jm05 {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}
.DropdownSelector_debouceInputLoader__3rarc {
  position: absolute;
  bottom: 11px;
  right: 0px;
}

.SchedulePopup_container__136LC {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.SchedulePopup_scheduleButton__31z2b {
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45px;
}
.SchedulePopup_scheduleButtonAdditionalText__12kSB {
  color: #5200f1;
  font-size: 10px;
}
.SchedulePopup_schedulerHeader__4ZdAM {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
}

.SchedulePopup_schedulerHeaderTitle__2L3d7 {
  font-size: 16px;
  font-weight: bold;
}

.SchedulePopup_schedulerHeaderExtraTitle__2QoX6 {
  font-size: 12px;
  font-weight: bold;
  color: #5200f1;
}

.SchedulePopup_schedulerActionsContainer__2tQhL {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.SchedulePopup_checkboxLabel__1pB39 {
  font-style: italic;
  font-size: 13px;
  margin-left: 10px;
}

.SchedulePopup_checkboxLabel__1pB39 .SchedulePopup_actionLabel__905Li {
  color: #5200f1;
  text-transform: capitalize;
  font-weight: bold;
}

.SchedulePopup_actionOption__3VsfV {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 15px;
}
.SchedulePopup_middle__3VSnp {
  width: 1px;
  background-color: #e6e6e6;
}

.SchedulePopup_schedulerFooter__1be2U {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SchedulePopup_schedulerFooter__1be2U .SchedulePopup_scheduleButton__31z2b {
  font-size: 16px;
  border-radius: 8px;
  font-family: 'Open Sans Regular';
  border: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding: 5px 30px 5px 30px;
  line-height: 45px;
  color: #ffffff;
  background-color: #5200f1;
  width: 140px;
}

.SchedulePopup_switcherContainer__2ZuYN {
  display: flex;
  flex-direction: row;
}

.SchedulePopup_dateTimeContainer__36sxb {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin-top: 25px;
  margin-bottom: 30px;
}

.SchedulePopup_dateContainer__22-IB {
  display: flex;
  width: 40%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.SchedulePopup_disabled__1wQmC * {
  color: #f2f2f2 !important;
  pointer-events: none;
}

.SchedulePopup_disabled__1wQmC * > .SchedulePopup_timeInput__6jHx5 {
  border: 2px solid #f2f2f2;
}

.SchedulePopup_disabled__1wQmC * > .SchedulePopup_dateInput__cteWL input {
  border: 2px solid #f2f2f2;
}

.SchedulePopup_disabled__1wQmC * ::-webkit-input-placeholder {
  color: #f2f2f2;
}

.SchedulePopup_disabled__1wQmC * ::-ms-input-placeholder {
  color: #f2f2f2;
}

.SchedulePopup_disabled__1wQmC * ::placeholder {
  color: #f2f2f2;
}

.SchedulePopup_dateTimeInputContainer__2ScLw {
  display: flex;
  width: 35%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.SchedulePopup_timeInput__6jHx5 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  padding: 10px 0px 10px 0px;
  width: 100%;
  font-size: 15px;
  color: #000000;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

.SchedulePopup_dateInput__cteWL input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
  padding: 10px 0px 10px 0px;
  width: 100%;
  font-size: 15px;
  color: #000000;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
}

.SchedulePopup_dateInput__cteWL::-webkit-input-placeholder {
  color: #000000;
}

.SchedulePopup_dateInput__cteWL::-ms-input-placeholder {
  color: #000000;
}

.SchedulePopup_dateInput__cteWL::placeholder {
  color: #000000;
}

.SchedulePopup_datePickerContainer__2CFgl {
  position: absolute;
}

.SchedulePopup_datePicker__3LFuc {
  display: block;
}

.SchedulePopup_errorMessage__Gt4TV {
  color: red;
  font-size: 12px;
  font-family: 'Open Sans Bold';
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0px;
}

.SchedulePopup_extendedErrorMessage__3xsWB {
  width: 40vh;
}

.SchedulePopup_errorMessageBorder__2kq5s input {
  border: 2px solid red;
}

.SchedulePopup_scheduleButtonDisabled__3ez5B {
  background: #cccccc !important;
  pointer-events: none;
}

.SchedulePopup_orPublishNowButton__1Eiu5 {
  font-family: 'Open Sans Bold';
  font-size: 12px;
  color: #5200f1;
  margin-top: 15px;
  cursor: pointer;
}
.SchedulePopup_footer__3SG13 {
  display: flex;
  padding-top: 20px;
  justify-content: right;
  border-top: 1px solid #d4d4d4;
  width: 100%;
  left: 0px;
  width: calc(100% + 20px);
}
.SchedulePopup_sendPushButton__3MSeE {
  border-radius: 5px;
  background-color: #5200f1;
  color: #ffffff;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  margin-right: 20px;
}
.SchedulePopup_sendPushButtonDisabled__3ZXhg {
  background-color: #b5b5b8;
  cursor: not-allowed;
}
.SchedulePopup_sendPushButton__3MSeE:hover {
  opacity: 0.7;
}

.SendEmailNotifications_sendPushContainer__mq_yK {
  padding: 20px;
}
.SendEmailNotifications_title__3ShUn {
  font-family: 'Roboto Medium';
  font-size: 18px;
  margin-bottom: 20px;
}
.SendEmailNotifications_title__3ShUn .SendEmailNotifications_templatesIcon__7T7p8 {
  font-size: 14px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
.SendEmailNotifications_inputContainer__3s7CX input {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  border: 0;
  outline: none;
  display: block;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-bottom: 1px solid #eeeeee;
  height: 25px;
  line-height: 25px;
  margin-bottom: 25px;
  overflow: hidden;
  resize: none;
}
.SendEmailNotifications_footerContainer__1hJuj {
  margin-top: 20px;
  display: flex;
}
.SendEmailNotifications_sendPushButton__3IPO4 {
  border-radius: 5px;
  background-color: #5200f1;
  color: #ffffff;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  margin-right: 10px;
}
.SendEmailNotifications_schedulePushButton__2tgbE {
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  box-shadow: 1px 1px 9px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border: 1px solid #eaeaea;
}
.SendEmailNotifications_sendPushButtonDisabled__2SWyZ {
  background-color: #b5b5b8;
  cursor: not-allowed;
}
.SendEmailNotifications_sendPushButton__3IPO4:hover,
.SendEmailNotifications_schedulePushButton__2tgbE:hover {
  opacity: 0.7;
}
.SendEmailNotifications_filterDropdownStyle__KpwDg {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  width: 150px;
  height: 15px;
}
.SendEmailNotifications_filterDropdownStyle__KpwDg .SendEmailNotifications_selectedOptionsLabel__1Lraa {
  width: 150px;
}
.SendEmailNotifications_inputTitle__2_S__ {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.SendEmailNotifications_requiredAsterisk__2IuSe {
  margin-left: 0;
  color: #be0000;
}
.SendEmailNotifications_dropdownFilterStyle__juvXv {
}
.SendEmailNotifications_dropdownFilterStyle__juvXv > div:first-child {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 5px;
  color: #cbcbcb;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 30px;
  height: 40px;
  background-position-x: 815px;
}
.SendEmailNotifications_dropdownFilterStyle__juvXv.SendEmailNotifications_selectedValue__1rNeb > div:first-child {
  color: #000000;
}
.SendEmailNotifications_dropdownFilterStyle__juvXv input {
  width: 91%;
  margin-bottom: 0;
}
.SendEmailNotifications_dropdownWiderFilterStyle__Zl81N {
  margin-bottom: 25px;
}
.SendEmailNotifications_dropdownWiderFilterStyle__Zl81N > div:first-child {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 5px;
  color: #cbcbcb;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 30px;
  height: 40px;
  background-position-x: 815px;
}
.SendEmailNotifications_dropdownWiderFilterStyle__Zl81N.SendEmailNotifications_selectedValue__1rNeb > div:first-child {
  color: #000000;
}
.SendEmailNotifications_dropdownWiderFilterStyle__Zl81N input {
  width: 91%;
  margin-bottom: 0;
}
.SendEmailNotifications_sendNotificationFilterContainer__1bLbT {
  margin-top: 20px;
}
.SendEmailNotifications_sectionDetailsContainer__33-jV {
  margin-top: 10px;
}
.SendEmailNotifications_filterDropdownPopup__3kve6 {
  font-size: 11px;
  background-color: #ffffff;
  position: absolute;
  top: 26px;
  width: 500px;
  border: 1px solid #dfe0e4;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}

.InputField_container__2W6rW {
}
.InputField_container__2W6rW input {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 30px;
  height: 40px;
}
.InputField_title__tE7rm {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.InputField_requiredAsterisk__3OqDh {
  margin-left: 0;
  color: #be0000;
}

.SurveyFiltersDetails_sectionDetailsTitle__RKQiG {
  font-size: 13px;
  color: #6d6d6d;
}
.SurveyFiltersDetails_sectionDetails__vgZ2D {
  font-size: 13px;
  background-color: #f6f7fb;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
.SurveyFiltersDetails_sectionItemTitle__3Ml8B {
  color: #8c8c8c;
}
.SurveyFiltersDetails_sectionItemValues__1dxp8 {
  margin-bottom: 15px;
}
.SurveyFiltersDetails_sectionItemValues__1dxp8:last-child {
  margin-bottom: 0;
}

.NotificationDetails_detailsContainer__3fuin {
  padding: 20px;
  border-bottom: 1px solid #d5d5d5;
}
.NotificationDetails_closeIconContainer__2SL5v {
  font-size: 17px;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #8f8e8e;
  cursor: pointer;
  z-index: 9;
}
.NotificationDetails_sectionTitle__38QHm {
  font-size: 12px;
  color: #2b333f;
  margin-top: 30px;
}
.NotificationDetails_sectionDetails__2HALW {
  font-size: 14px;
}
.NotificationDetails_footerContainer__5oRGX {
  margin-top: 20px;
  display: flex;
}
.NotificationDetails_sendPushButton__3kxOG {
  border-radius: 5px;
  background-color: #5200f1;
  color: #ffffff;
  font-family: 'Roboto Medium';
  font-size: 13px;
  padding: 10px 25px;
  box-shadow: 0px 10px 1px 0px rgba(0, 0, 0, 0.01);
  cursor: pointer;
  margin-right: 20px;
}
.NotificationDetails_sendPushButton__3kxOG:hover {
  opacity: 0.7;
}
.NotificationDetails_sendThisPushAgain__1bb4h {
  background-color: #efefef;
  color: #8f8e8e;
}
.NotificationDetails_cancelScheduledPN__Gcgus {
  background-color: #e73560;
  color: #ffffff;
}
.NotificationDetails_pushNotificationDetailsListContainer__1qmlE {
  margin-top: 10px;
}
.NotificationDetails_pushNotificationDetailsListItem__NsGoU {
  background-color: #f6f7fb;
  margin-bottom: 10px;
  font-size: 12px;
  padding: 10px;
}

.TargetFilters_targetFilterContainer__1eoye {
  background-color: #f6f7fb;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
.TargetFilters_targetFilterItem__OVNYm {
}
.TargetFilters_targetFilterLabel__2aPzL {
  font-size: 13px;
  margin-top: 10px;
}
.TargetFilters_targetFilterSingleLabel__dYaSG {
  color: #8b8b8d;
  font-size: 12px;
}
.TargetFilters_targetFilterMultiLabel__mYRbn {
  color: #8b8b8d;
  font-size: 12px;
}
.TargetFilters_targetFilterRangeLabel__1a7_8 {
  color: #8b8b8d;
  font-size: 12px;
}

.CouponManagement_pageContainer__3YtWd {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 96px;
}

.CouponManagement_pageHeader__sVQvB .CouponManagement_header1__1ZGWD {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
  margin-bottom: 32px;
}
.CouponManagement_pageContent__710uh {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
}
.CouponManagement_whiteContainer__16WPs {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
}
.CouponManagement_sections__1oaxV {
  height: 50px;
  line-height: 50px;
  margin-top: 0px;
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  margin-bottom: 32px;
  padding: 0;
}
.CouponManagement_sections__1oaxV .CouponManagement_sectionName__1dkml {
  font-family: 'Open Sans Bold';
  width: 50%;
  color: #95989a;
  text-align: center;
  cursor: pointer;
}
.CouponManagement_sections__1oaxV .CouponManagement_sectionName__1dkml.CouponManagement_active__9b1DN {
  color: #000000;
}

.CouponManagement_info__MLaw7 {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 32px;
  padding: 16px;
  padding-bottom: 0;
}

.CouponManagement_fieldName__W9VVX {
  font-size: 14px;
  color: #8b8b8d;
}

.CouponManagement_dropText__3qEAV {
  font-size: 30px;
  color: #aaa;
}

.CouponManagement_or__1twtf {
  font-size: 20px;
  text-align: center;
}

.CouponManagement_keyInput__S5ZvT {
  height: 200px;
  width: calc(100% - 16px);
  padding: 8px;
}

.CouponManagement_buttonWrapper__1ouBK {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.CouponManagement_button__2E5xi {
  padding: 8px;
  min-width: 200px;
  margin-bottom: 32px;
}

.CouponManagement_container__3nmbg {
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
}
.CouponManagement_titleContainer__2ehd5 {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
}
.CouponManagement_titleContainer__2ehd5 .CouponManagement_title__ObMux {
  margin-left: 20px;
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
}
.CouponManagement_detailsContainer__woV4g {
  padding: 10px 0 10px;
}
.CouponManagement_detailContainer__2pQn_ {
  font-size: 18px;
  color: #221f1f;
  padding: 10px 20px 0 20px;
}
.CouponManagement_detailTitleContainer__3ZjFB {
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.CouponManagement_detailTitleContainer__3ZjFB .CouponManagement_title__ObMux {
  font-size: 14px;
}
.CouponManagement_field__2HNfP {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}
.CouponManagement_description__3qlm6 {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  display: block;
  width: calc(100% - 20px);
  background-color: #ffffff;
  color: #000000;
  height: 25px;
  line-height: 25px;
  margin-bottom: 5px;
  overflow: hidden;
  resize: none;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
}
.CouponManagement_notesField__yosaP {
}
.CouponManagement_notesTitleContainer__e-Hdq {
  position: absolute;
  top: -20px;
  display: flex;
  width: 100%;
  opacity: 0.6;
  justify-content: space-between;
}
.CouponManagement_notesTitleContainer__e-Hdq .CouponManagement_title__ObMux {
  font-size: 14px;
}
.CouponManagement_arrowExpanded__1Txvc {
  width: 13px;
  height: 7px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.CouponManagement_arrowCollapsed__DOtIG {
  width: 13px;
  height: 7px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}
.CouponManagement_imageContainer__1Rxw- {
  display: inline-block;
  margin: 10px;
}
.CouponManagement_imageContainer__1Rxw- .CouponManagement_removeImageContainer__Zebbk {
  position: absolute;
  top: 2px;
  right: 10px;
  width: 15px;
  height: 15px;
  background-color: #5200f2;
  border-radius: 50%;
  cursor: pointer;
}
.CouponManagement_imageContainer__1Rxw- .CouponManagement_removeImageContainer__Zebbk img {
  width: 26px;
  height: 15px;
  position: absolute;
  left: -5px;
  top: 0px;
}
.CouponManagement_imageContainer__1Rxw- .CouponManagement_coverImage__4HuKz {
  max-height: 100px;
  max-width: 300px;
}
.CouponManagement_errorText__3_6wU {
  font-size: 12px;
  margin: 2px 12px 1px;
  color: #d9534f;
}
.CouponManagement_dropContainer__3rAaL {
  height: 115px;
  border: 1px dashed #e8e8e8;
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  flex-direction: column;
  margin-bottom: 20px;
}
.CouponManagement_dropContainer__3rAaL .CouponManagement_button__2E5xi {
  width: 180px;
  text-align: center;
  border: 1px solid #e8e8e8;
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.CouponManagement_dropContainer__3rAaL .CouponManagement_dropDescription__1_I7R {
  text-align: center;
}
.CouponManagement_previewFilename__3bMTC {
  font-family: 'Open Sans Regular';
  color: #8b8b8d;
  display: flex;
  font-size: 13px;
  width: 100px;
  text-align: center;
}

.CouponManagement_bigButton___SLbS {
  display: inline-block;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  padding-left: 80px;
  padding-right: 80px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.ProfileQuestionsTable_listContainer__3COVA {
  margin-top: 30px;
}
.ProfileQuestionsTable_loadingItem__3yJXC {
  height: 40px !important;
}
.ProfileQuestionsTable_tableContainer__k6Ten {
  margin-top: 30px;
  font-family: 'Open Sans Regular';
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}
.ProfileQuestionsTable_headerRow__2FrNw th {
  text-align: left;
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #ebeaef;
}
.ProfileQuestionsTable_tableRow__3mvmI td {
  border-bottom: 1px solid #ebeaef;
  height: 30px;
  line-height: 30px;
}
.ProfileQuestionsTable_tableRow__3mvmI td span:hover {
  text-decoration: underline;
  color: #5200f1;
  cursor: pointer;
}
.ProfileQuestionsTable_noResults__3n_3V {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  text-align: center;
  margin-top: 40px;
}
.ProfileQuestionsTable_visibleForSearchCheckbox__XrF-_ {
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.ProfileQuestionDetails_container__mss96 {
  width: 800px;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
}
.ProfileQuestionDetails_question__1G5UL {
}
.ProfileQuestionDetails_tableContainer__1y3Tx {
  margin-top: 30px;
  font-family: 'Open Sans Regular';
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}
.ProfileQuestionDetails_headerRow__3Lwnl th {
  text-align: left;
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #ebeaef;
}
.ProfileQuestionDetails_tableRow__eQ2Ag td {
  border-bottom: 1px solid #ebeaef;
  height: 30px;
  line-height: 30px;
}
.ProfileQuestionDetails_tableRow__eQ2Ag td span:hover {
  text-decoration: underline;
  color: #5200f1;
  cursor: pointer;
}
.ProfileQuestionDetails_closeContainer__q0jzx {
  text-align: right;
}
.ProfileQuestionDetails_close__EOiEp {
  margin-top: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.SearchDropdown_filterItemContainer__3kvZM {
  display: flex;
}
.SearchDropdown_filterItemContainer__3kvZM .SearchDropdown_filterType__1dmLc {
  display: flex;
}
.SearchDropdown_filterItemContainer__3kvZM .SearchDropdown_filterType__1dmLc .SearchDropdown_closeIcon__3Ejnt {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.SearchDropdown_filterItemContainer__3kvZM .SearchDropdown_filterType__1dmLc {
  display: flex;
}
.SearchDropdown_filterItemContainer__3kvZM .SearchDropdown_filterType__1dmLc .SearchDropdown_closeIcon__3Ejnt {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}

.ProfileTagFilters_dropdownContainers__2UoMs {
  display: flex;
  margin-top: 30px;
}
.ProfileTagFilters_searchInputContainer__3II20 {
  display: flex;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 40%;
  flex-grow: 1;
  position: absolute;
  right: 0px;
  top: -70px;
}
.ProfileTagFilters_searchInputContainer__3II20 .ProfileTagFilters_questionContaining__2a8jI {
  display: flex;
  background-color: #5200f1;
  color: #ffffff;
  font-size: 11px;
  height: 25px;
  line-height: 25px;
  min-width: 120px;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 8px;
  position: relative;
  text-transform: uppercase;
}
.ProfileTagFilters_searchInputContainer__3II20 .ProfileTagFilters_questionContainingInput__WIQLI {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  border: 0;
  font-size: 13px;
  width: 190px;
  padding-right: 30px;
  outline: none;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  flex-grow: 1;
}
.ProfileTagFilters_resetFilters__1zXQs {
  margin-top: 10px;
  margin-left: 5px;
}
.ProfileTagFilters_resetFilters__1zXQs span {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  font-size: 13px;
  cursor: pointer;
}

.ProfileTagsManagement_container__2i8Um {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.ProfileTagsManagement_title__8uaqk {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}

.AccountManagementHeader_container__3cElo {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 70px;
  display: flex;
  justify-content: space-between;
}
.AccountManagementHeader_title__2evAI {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.AccountManagementHeader_rightContainer__2h_TE {
  display: flex;
}
.AccountManagementHeader_searchContainer__1M9ei {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.AccountManagementHeader_searchInputContainer__3K73e {
  display: flex;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 40%;
  flex-grow: 1;
}
.AccountManagementHeader_searchInputContainer__3K73e .AccountManagementHeader_questionContainingInput__BAToj {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  border: 0;
  font-size: 13px;
  width: 260px;
  padding-right: 30px;
  outline: none;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  flex-grow: 1;
}
.AccountManagementHeader_searchInputContainer__3K73e .AccountManagementHeader_close__9dnbF {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #777777;
}
.AccountManagementHeader_searchIcon__HV-GH {
  cursor: pointer;
  height: 20px;
}
.AccountManagementHeader_buttonMarginWrapper__3ZJyv {
  display: inline-block;
  margin: 0 15px;
}
.AccountManagementHeader_buttonMarginWrapper__3ZJyv ~ .AccountManagementHeader_buttonMarginWrapper__3ZJyv {
  margin-left: 0;
}

.RecordAction_container__3fuSv {
  display: flex;
  font-size: 12px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  padding: 0 10px;
  white-space: nowrap;
}
.RecordAction_container__3fuSv:last-child {
  margin-right: 0;
}
.RecordAction_container__3fuSv img {
  margin-right: 5px;
}

.AccountManagementRecord_container__wm1Sn {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.AccountManagementRecord_container__wm1Sn.AccountManagementRecord_noClick__1lCpB {
  cursor: default;
}
.AccountManagementRecord_container__wm1Sn:first-child {
  border-top: 1px solid #dddddd;
}
.AccountManagementRecord_detailsContainer__dOYmS {
  padding-left: 10px;
}
.AccountManagementRecord_actionsContainer__2g31n {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.AccountManagementRecord_actionsContainer__2g31n .AccountManagementRecord_actionsButton__3Nll4 {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
.AccountManagementRecord_starredUser__1xSIU {
  display: inline-block;
  height: 14px;
  margin-right: 7px;
}
.AccountManagementRecord_record__FJGYA.AccountManagementRecord_isRecordHovered__2JVWo {
  cursor: pointer;
}
.AccountManagementRecord_rowContainer__1v9ZK {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
}
.AccountManagementRecord_record__FJGYA.AccountManagementRecord_isRecordHovered__2JVWo .AccountManagementRecord_nameContainer__1jbRX {
  opacity: 0.7;
}
.AccountManagementRecord_nameContainer__1jbRX {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 300px;
  line-height: 18px;
  position: relative;
  top: 3px;
}
.AccountManagementRecord_touchpointsContainer__UNnyd {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.AccountManagementRecord_actionButtonsContainer__hICiI {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}
.AccountManagementRecord_touchpointNumber__3PY-z {
  width: 100px;
}
.AccountManagementRecord_barContainer__358Jt {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #e7e7e8;
}
.AccountManagementRecord_bar__13bKO {
  height: 5px;
}
.AccountManagementRecord_warningTooltipContainer__1Yh5U {
  display: inline-block;
}
.AccountManagementRecord_warningTooltipIcon__2sfb_ {
  display: inline-block;
  cursor: pointer;
  height: 15px;
  position: relative;
  top: 3px;
  margin-left: 10px;
}
.AccountManagementRecord_warningTooltipPopup__2Dg45 {
  position: absolute;
  left: 50px;
  bottom: 4px;
  padding: 0px 20px;
  background-color: #f61553;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  text-align: center;
  color: #ffffff;
  text-transform: none;
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
}
.AccountManagementRecord_warningTooltipPopupTriangle__2vyQV {
  position: absolute;
  left: -8px;
  top: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #f61555 transparent transparent;
}
.AccountManagementRecord_warningTooltipIcon__2sfb_:hover + .AccountManagementRecord_warningTooltipPopup__2Dg45 {
  display: block;
}

.AccountManagementAddEditPopup_container__Nu-6v {
  width: 600px;
  font-size: 14px;
  color: #1b1b1b;
}
.AccountManagementAddEditPopup_content__18Mbp {
  padding: 50px;
  padding-bottom: 20px;
}
.AccountManagementAddEditPopup_footer__GuTV3 {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.AccountManagementAddEditPopup_footer__GuTV3 .AccountManagementAddEditPopup_details__2qEnR {
  color: #5200f1;
}
.AccountManagementAddEditPopup_actions__1DnST .AccountManagementAddEditPopup_confirm__1Z8Sl {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.AccountManagementAddEditPopup_actions__1DnST .AccountManagementAddEditPopup_confirm__1Z8Sl.AccountManagementAddEditPopup_invalid__OhR3b {
  opacity: 0.12;
}
.AccountManagementAddEditPopup_actions__1DnST .AccountManagementAddEditPopup_close__1goXQ {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.AccountManagementAddEditPopup_title__2Vdj4 {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cbcbcb;
  display: flex;
  box-shadow: -15px 10px 15px #fff;
  z-index: 1;
}
.AccountManagementAddEditPopup_subtitle__1qml1 {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.AccountManagementAddEditPopup_input__3TBRG {
  font-family: 'Open Sans Regular';
  width: calc(100% - 40px);
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.AccountManagementAddEditPopup_input__3TBRG::-webkit-input-placeholder {
  color: #cbcbcb;
}
.AccountManagementAddEditPopup_input__3TBRG::-ms-input-placeholder {
  color: #cbcbcb;
}
.AccountManagementAddEditPopup_input__3TBRG::placeholder {
  color: #cbcbcb;
}
.AccountManagementAddEditPopup_input__3TBRG:focus {
  outline: none;
}
.AccountManagementAddEditPopup_copyLinkButton__1UCyZ {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border: 1px solid #cbcbcb;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.AccountManagementAddEditPopup_copyLinkButton__1UCyZ .AccountManagementAddEditPopup_linkIcon__3HKVo {
  width: 17px;
  margin-right: 3px;
  position: relative;
  left: -4px;
}
.AccountManagementAddEditPopup_dropdownContainer__2hQhs {
  margin-bottom: 10px;
}
.AccountManagementAddEditPopup_invitationLinkContainer__1tnJB {
  display: flex;
  border: 1px solid #cbcbcb;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.AccountManagementAddEditPopup_invitationLinkContainer__1tnJB input {
  border: 0;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(80% - 20px);
  color: #969696;
}
.AccountManagementAddEditPopup_invitationLinkContainer__1tnJB .AccountManagementAddEditPopup_copyInputLink__1A0Qi {
  width: 20%;
  text-align: center;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
}
.AccountManagementAddEditPopup_invitationLinkContainer__1tnJB .AccountManagementAddEditPopup_copiedInputLink__3r398 {
  width: 20%;
  text-align: center;
  background-color: #8f8f8f;
  color: #ffffff;
  font-size: 13px;
}
.AccountManagementAddEditPopup_loaderContainer__3kAl5 {
  position: relative;
  top: -5px;
  left: -5px;
  display: inline-block;
  height: 27px;
}
.AccountManagementAddEditPopup_dropdownLicenceTopContainer__1TP5g {
  display: flex;
}
.AccountManagementAddEditPopup_dropdownLicenceContainer__1rju_ {
  width: 245px;
  margin-right: 10px;
}
.AccountManagementAddEditPopup_dropdownLicenceContainer__1rju_:last-child {
  margin-right: 0;
}
.AccountManagementAddEditPopup_additionalOptionCheck__2Be23 {
  font-size: 12px;
  margin-bottom: 20px;
}
.AccountManagementAddEditPopup_additionalOptionCheck__2Be23 input {
  width: 11px;
  height: 11px;
  margin-right: 7px;
  cursor: pointer;
}
.AccountManagementAddEditPopup_creditsLeftTopContainer__3a_HO {
  display: flex;
  margin-bottom: 10px;
}
.AccountManagementAddEditPopup_creditsLeftContainer__2k58n {
  margin-right: 30px;
}
.AccountManagementAddEditPopup_creditsLeftContainer__2k58n:last-child {
  margin-right: 0;
}
.AccountManagementAddEditPopup_creditsInput__26uJD {
  font-family: 'Open Sans Regular';
  width: 50px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.AccountManagementAddEditPopup_errorContainer__ovR1i {
  background-color: #fdf2f7;
  height: 40px;
  line-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 50px;
}
.AccountManagementAddEditPopup_warningErrorIcon__2Wo3i {
  width: 15px;
  margin-right: 10px;
}
.AccountManagementAddEditPopup_contentContainer__2TQAh {
  max-height: 400px;
  overflow-x: visible;
  overflow-y: auto;
}
.AccountManagementAddEditPopup_contentContainer__2TQAh::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.AccountManagementAddEditPopup_contentContainer__2TQAh::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.BasicSettings_container__2T_eg {
  width: 600px;
  font-size: 14px;
  color: #1b1b1b;
}
.BasicSettings_content__3pgHL {
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.BasicSettings_content__3pgHL::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.BasicSettings_content__3pgHL::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.BasicSettings_footer__1TdUe {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.BasicSettings_footer__1TdUe .BasicSettings_details__3Fq4m {
  color: #5200f1;
}
.BasicSettings_actions__vlHF2 .BasicSettings_confirm__3i0Aa {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.BasicSettings_actions__vlHF2 .BasicSettings_confirm__3i0Aa.BasicSettings_invalid__O4w1S {
  opacity: 0.12;
}
.BasicSettings_actions__vlHF2 .BasicSettings_close__2AglO {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.BasicSettings_title__3jjDI {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 10px;
}
.BasicSettings_subtitle__3XfTw {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.BasicSettings_requiredAsterisk__2snBI {
  margin-left: 0;
  color: #be0000;
}
.BasicSettings_input__q71KI {
  font-family: 'Open Sans Regular';
  width: calc(100% - 40px);
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.BasicSettings_input__q71KI::-webkit-input-placeholder {
  color: #cbcbcb;
}
.BasicSettings_input__q71KI::-ms-input-placeholder {
  color: #cbcbcb;
}
.BasicSettings_input__q71KI::placeholder {
  color: #cbcbcb;
}
.BasicSettings_input__q71KI:focus {
  outline: none;
}
.BasicSettings_copyLinkButton__26-DN {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border: 1px solid #cbcbcb;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.BasicSettings_copyLinkButton__26-DN .BasicSettings_linkIcon__2C34w {
  width: 17px;
  margin-right: 3px;
  position: relative;
  left: -4px;
}
.BasicSettings_dropdownContainer__3ymUZ {
  margin-bottom: 0px;
}
.BasicSettings_invitationLinkContainer__WigKo {
  display: flex;
  border: 1px solid #cbcbcb;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.BasicSettings_invitationLinkContainer__WigKo input {
  border: 0;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(80% - 20px);
  color: #969696;
}
.BasicSettings_invitationLinkContainer__WigKo .BasicSettings_copyInputLink__3Ey84 {
  width: 20%;
  text-align: center;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
}
.BasicSettings_invitationLinkContainer__WigKo .BasicSettings_copiedInputLink__3WLfy {
  width: 20%;
  text-align: center;
  background-color: #8f8f8f;
  color: #ffffff;
  font-size: 13px;
}
.BasicSettings_loaderContainer__28Z29 {
  position: relative;
  top: -5px;
  left: -5px;
  display: inline-block;
  height: 27px;
}
.BasicSettings_dropdownLicenceTopContainer__2WLiO {
  display: flex;
}
.BasicSettings_dropdownLicenceContainer__1Ccb3 {
  width: 245px;
  margin-right: 10px;
}
.BasicSettings_dropdownLicenceContainer__1Ccb3:last-child {
  margin-right: 0;
}
.BasicSettings_additionalOptionCheck__3LEo3 {
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.BasicSettings_additionalOptionCheck__3LEo3 input {
  width: 13px;
  height: 13px;
  margin-right: 7px;
  cursor: pointer;
}
.BasicSettings_numberInputRows__1FvVv {
  margin-top: 10px;
  margin-bottom: 10px;
}
.BasicSettings_errorContainer__YuGP1 {
  background-color: #fdf2f7;
  height: 40px;
  line-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 50px;
}
.BasicSettings_warningErrorIcon__2D0mU {
  width: 15px;
  margin-right: 10px;
}
.BasicSettings_licenceSelectorRow__3o5FF {
  margin-bottom: 10px;
}
.BasicSettings_licenceCheckboxContainer__36K3Q {
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
}
.BasicSettings_licenceCheckboxContainer__36K3Q input {
  margin-right: 5px;
  margin-top: 1px;
  accent-color: #5200f1;
  outline: none;
  cursor: pointer;
}
.BasicSettings_licenceDatesContainer__q4Ocr {
  display: flex;
}
.BasicSettings_licenceSingleDateInputContainer__r1dJM {
}
.BasicSettings_licenceSingleDateInputContainer__r1dJM .BasicSettings_dateInputWrapper__3RTz4:first-child {
  margin-right: 12px;
}
.BasicSettings_licenceSingleDateInputContainer__r1dJM input {
  font-family: 'Open Sans Regular';
  width: 200px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
}
.BasicSettings_licenceSingleDateInputContainer__r1dJM input:first-child {
  margin-right: 10px;
}
.BasicSettings_licenceSingleDateInputContainer__r1dJM input::-webkit-input-placeholder {
  color: #cbcbcb;
}
.BasicSettings_licenceSingleDateInputContainer__r1dJM input::-ms-input-placeholder {
  color: #cbcbcb;
}
.BasicSettings_licenceSingleDateInputContainer__r1dJM input::placeholder {
  color: #cbcbcb;
}
.BasicSettings_licenceSingleDateInputContainer__r1dJM input:focus {
  outline: none;
}
.BasicSettings_datePickerOuterContainer__bCGEn {
  height: 37px;
  position: static;
}
.BasicSettings_datePickerOuterContainer__bCGEn .BasicSettings_datePickerOuterAbsoluteContainerStartDate__2Pix6 {
  position: absolute;
  top: -10px;
  left: 0px;
  z-index: 99999;
}
.BasicSettings_datePickerOuterContainer__bCGEn .BasicSettings_datePickerOuterAbsoluteContainerEndDate__3RWpx {
  position: absolute;
  top: -10px;
  left: 137px;
  z-index: 99999;
}

.BasicSettings_datePickerOuterContainer__bCGEn .BasicSettings_datePickerOuterFixedContainer__2p7dj {
  position: fixed;
}
.BasicSettings_licenceSingleDateTitleStartDate__3hVUO {
  margin-bottom: 10px;
}
.BasicSettings_licenceSingleDateTitleEndDate__3QUCl {
  margin-bottom: 10px;
  left: 137px;
}

.NumberInputTooltip_tooltipContainer__-0z5z {
  display: inline-block;
}
.NumberInputTooltip_tooltipIcon__1QRBP {
  display: inline-block;
  height: 13px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.NumberInputTooltip_tooltipPopup__1DbpS {
  position: fixed;
  padding: 10px 20px;
  width: 260px;
  background-color: #ffffff;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  color: #333333;
  text-transform: none;
  border-radius: 5px;
  font-size: 13px;
}
.NumberInputTooltip_tooltipIcon__1QRBP:hover + .NumberInputTooltip_tooltipPopup__1DbpS {
  display: block;
}

.NumberInputRow_row__NXaDx {
  display: flex;
  justify-content: space-between;
}
.NumberInputRow_textContainer__1_qLH {
  display: flex;
  justify-content: center;
  flex-direction: column;
  bottom: 4px;
}
.NumberInputRow_description__1Cf_X {
  color: #acacac;
  font-size: 12px;
}
.NumberInputRow_valueInput__1RKPj {
  font-family: 'Open Sans Regular';
  width: 50px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  text-align: right;
}
.NumberInputRow_valueInput__1RKPj:disabled {
  color: #acacac;
  background-color: #f5f6f6;
}

.AccessToCommunity_content__3b43Z {
  padding: 50px 0;
  padding-top: 0px;
  padding-bottom: 20px;
}
.AccessToCommunity_subtitle__3xaKi {
  border-top: 1px solid #cbcbcb;
  font-family: 'Open Sans SemiBold';
  padding-top: 15px;
  padding-bottom: 15px;
}
.AccessToCommunity_bottomBorderDiv__Iuawm {
  border-bottom: 1px solid #cbcbcb;
  margin-top: 10px;
}

.ManagerSelectInvite_container__1h585 {
  width: 600px;
  font-size: 14px;
  color: #1b1b1b;
}
.ManagerSelectInvite_content__19l-H {
  padding: 50px;
  padding-top: 0px;
  padding-bottom: 20px;
  max-height: 350px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #b4b2b6;
}
.ManagerSelectInvite_content__19l-H::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.ManagerSelectInvite_content__19l-H::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.ManagerSelectInvite_footer__G5TDo {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.ManagerSelectInvite_footer__G5TDo .ManagerSelectInvite_details__2o1_4 {
  color: #5200f1;
}
.ManagerSelectInvite_actions__OBcAk .ManagerSelectInvite_confirm__1WxDM {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.ManagerSelectInvite_actions__OBcAk .ManagerSelectInvite_confirm__1WxDM.ManagerSelectInvite_invalid__134zD {
  opacity: 0.12;
}
.ManagerSelectInvite_actions__OBcAk .ManagerSelectInvite_close__2-sjZ {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.ManagerSelectInvite_title__IRUUs {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 10px;
}
.ManagerSelectInvite_subtitle__2YPp- {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.ManagerSelectInvite_input__31CbZ {
  font-family: 'Open Sans Regular';
  width: calc(100% - 40px);
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.ManagerSelectInvite_input__31CbZ::-webkit-input-placeholder {
  color: #cbcbcb;
}
.ManagerSelectInvite_input__31CbZ::-ms-input-placeholder {
  color: #cbcbcb;
}
.ManagerSelectInvite_input__31CbZ::placeholder {
  color: #cbcbcb;
}
.ManagerSelectInvite_input__31CbZ:focus {
  outline: none;
}
.ManagerSelectInvite_copyLinkButton__2sCAf {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border: 1px solid #cbcbcb;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.ManagerSelectInvite_copyLinkButton__2sCAf .ManagerSelectInvite_linkIcon__DV33z {
  width: 17px;
  margin-right: 3px;
  position: relative;
  left: -4px;
}
.ManagerSelectInvite_dropdownContainer__10NHG {
  margin-bottom: 10px;
}
.ManagerSelectInvite_invitationLinkContainer__vUusH {
  display: flex;
  border: 1px solid #cbcbcb;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.ManagerSelectInvite_invitationLinkContainer__vUusH input {
  border: 0;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(80% - 20px);
  color: #969696;
}
.ManagerSelectInvite_invitationLinkContainer__vUusH .ManagerSelectInvite_copyInputLink__2Pcbt {
  width: 20%;
  text-align: center;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
}
.ManagerSelectInvite_invitationLinkContainer__vUusH .ManagerSelectInvite_copiedInputLink__3xz_F {
  width: 20%;
  text-align: center;
  background-color: #8f8f8f;
  color: #ffffff;
  font-size: 13px;
}
.ManagerSelectInvite_loaderContainer__319gc {
  position: relative;
  top: -5px;
  left: -5px;
  display: inline-block;
  height: 27px;
}
.ManagerSelectInvite_dropdownLicenceTopContainer__3Km5D {
  display: flex;
}
.ManagerSelectInvite_dropdownLicenceContainer__3ZMyP {
  width: 245px;
  margin-right: 10px;
}
.ManagerSelectInvite_dropdownLicenceContainer__3ZMyP:last-child {
  margin-right: 0;
}
.ManagerSelectInvite_additionalOptionCheck__3tCWF {
  font-size: 12px;
  margin-bottom: 10px;
}
.ManagerSelectInvite_additionalOptionCheck__3tCWF input {
  width: 11px;
  height: 11px;
  margin-right: 7px;
  cursor: pointer;
}
.ManagerSelectInvite_numberInputRows__1Suiu {
  margin-top: 10px;
  margin-bottom: 10px;
}
.ManagerSelectInvite_errorContainer__25bPS {
  background-color: #fdf2f7;
  height: 40px;
  line-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 50px;
}
.ManagerSelectInvite_warningErrorIcon__2nToj {
  width: 15px;
  margin-right: 10px;
}
.ManagerSelectInvite_borderTopContainer___rmMW {
  border-top: 1px solid #cbcbcb;
  padding-top: 15px;
  padding-bottom: 15px;
}

.Dropdown_container__RDvSC {
  position: relative;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  color: #000;
}
.Dropdown_container__RDvSC .Dropdown_selectedValue__3f2VB {
  font-family: 'Open Sans Regular';
  border-radius: 5px;
  border: 1px solid #cbcbcb;
  background-color: #ffffff;
  height: 35px;
  line-height: 32px;
  padding-left: 20px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 14px 6px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box;
  color: #1b1b1b;
}
.Dropdown_container__RDvSC .Dropdown_selectedValue__3f2VB.Dropdown_disabled__1Qhrr {
  background-color: #ebebeb;
  border-color: #dcdcdc;
  opacity: 0.7;
  cursor: default;
}
.Dropdown_container__RDvSC .Dropdown_selectedValue__3f2VB .Dropdown_placeholder__1Vc8s {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #bebebe;
}
.Dropdown_container__RDvSC .Dropdown_popupContainer__3O1Rn {
  font-size: 12px;
  background-color: #ffffff;
  position: absolute;
  top: 38px;
  width: 100%;
  border: 1px solid #efefef;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
  box-sizing: border-box;
}
.Dropdown_container__RDvSC .Dropdown_popupContainer__3O1Rn .Dropdown_searchInput__2e1Ad {
  border-bottom: 1px solid #efefef;
  display: flex;
}
.Dropdown_container__RDvSC .Dropdown_popupContainer__3O1Rn .Dropdown_searchInput__2e1Ad input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 20px);
  outline: none;
  color: #000;
  border-radius: 5px;
}
.Dropdown_container__RDvSC .Dropdown_popupContainer__3O1Rn .Dropdown_searchInput__2e1Ad input::-webkit-input-placeholder {
  color: #bebebe;
}
.Dropdown_container__RDvSC .Dropdown_popupContainer__3O1Rn .Dropdown_searchInput__2e1Ad input::-ms-input-placeholder {
  color: #bebebe;
}
.Dropdown_container__RDvSC .Dropdown_popupContainer__3O1Rn .Dropdown_searchInput__2e1Ad input::placeholder {
  color: #bebebe;
}
.Dropdown_container__RDvSC .Dropdown_popupContainer__3O1Rn .Dropdown_searchInput__2e1Ad .Dropdown_inputLoaderContainer__1RcZi {
  position: relative;
  top: -10px;
}
.Dropdown_container__RDvSC .Dropdown_popupContainer__3O1Rn .Dropdown_searchInput__2e1Ad .Dropdown_addItemIcon__2ig4A {
  position: relative;
  top: 0px;
  width: 11px;
  right: 10px;
  cursor: pointer;
}
.Dropdown_container__RDvSC .Dropdown_popupContainer__3O1Rn .Dropdown_popupItemsContainer__3cW9P {
  max-height: 100px;
  overflow-y: auto;
  padding: 5px 0;
  box-sizing: border-box;
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_popupItem__HNxbP {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding-left: 10px;
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_popupItem__HNxbP.Dropdown_parentPopupItem__3C7kz:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_popupItem__HNxbP.Dropdown_parentPopupItem__3C7kz {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid #efefef;
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_popupItem__HNxbP.Dropdown_childPopupItem__2Wnm1 {
  padding-left: 30px;
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_popupItem__HNxbP .Dropdown_itemLabel__2Ybj3 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_popupItem__HNxbP .Dropdown_checkboxSelected__1TRb7,
.Dropdown_popupItemsContainer__3cW9P .Dropdown_popupItem__HNxbP .Dropdown_checkbox__3YK26 {
  width: 13px;
  height: 21px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_popupItem__HNxbP .Dropdown_checkboxSelected__1TRb7 {
  color: #5200f1;
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_popupItem__HNxbP .Dropdown_checkbox__3YK26 {
  color: #b5b5b8;
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_noItems__1crwn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  height: 34px;
  line-height: 34px;
  font-size: 12px;
  color: #8b8b8d;
}
.Dropdown_popupItemsContainer__3cW9P::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.Dropdown_popupItemsContainer__3cW9P::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.Dropdown_popupItemsContainer__3cW9P .Dropdown_loaderContainer__339YW {
  position: relative;
  top: -5px;
  left: 10px;
  display: inline-block;
}

.BusinessSettings_container__2Brxd {
  width: 600px;
  font-size: 14px;
  color: #1b1b1b;
}
.BusinessSettings_content__38fQ1 {
  padding: 50px;
  padding-top: 0px;
  padding-bottom: 20px;
}
.BusinessSettings_content__38fQ1::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.BusinessSettings_content__38fQ1::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgb(255 255 255 / 50%);
}
.BusinessSettings_title__3nHSo {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 10px;
}
.BusinessSettings_subtitle__2kdav {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.BusinessSettings_requiredAsterisk__2aje0 {
  margin-left: 0;
  color: #be0000;
}
.BusinessSettings_input__2bOiI {
  font-family: 'Open Sans Regular';
  width: calc(100% - 40px);
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.BusinessSettings_input__2bOiI::-webkit-input-placeholder {
  color: #cbcbcb;
}
.BusinessSettings_input__2bOiI::-ms-input-placeholder {
  color: #cbcbcb;
}
.BusinessSettings_input__2bOiI::placeholder {
  color: #cbcbcb;
}
.BusinessSettings_input__2bOiI:focus {
  outline: none;
}
.BusinessSettings_textareaField__2j0J- {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100px;
  height: 100px;
  margin-bottom: 10px;
}
.BusinessSettings_textareaField__2j0J-::-webkit-input-placeholder {
  font-family: 'Open Sans Regular';
  color: #cbcbcb;
}
.BusinessSettings_textareaField__2j0J-::-ms-input-placeholder {
  font-family: 'Open Sans Regular';
  color: #cbcbcb;
}
.BusinessSettings_textareaField__2j0J-::placeholder {
  font-family: 'Open Sans Regular';
  color: #cbcbcb;
}
.BusinessSettings_dropdownContainer__33GJu {
  margin-bottom: 10px;
}
.BusinessSettings_tooltipContainer__21qki {
  display: inline-block;
}
.BusinessSettings_tooltipIcon__15Tq0 {
  display: inline-block;
  height: 13px;
  position: relative;
  top: 2px;
  margin-left: 5px;
}
.BusinessSettings_tooltipPopup__377o2 {
  position: fixed;
  left: 30px;
  bottom: 30px;
  padding: 8px 12px;
  width: 355px;
  height: 30px;
  background-color: #ffffff;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  color: #494949;
  text-transform: none;
  border-radius: 5px;
  font-size: 12px;
}

.AccountManagementRemovePopup_container__2YuZG {
  width: 600px;
}
.AccountManagementRemovePopup_content__3MpoB {
  padding: 50px;
  padding-bottom: 20px;
}
.AccountManagementRemovePopup_footer__2Kir4 {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.AccountManagementRemovePopup_footer__2Kir4 .AccountManagementRemovePopup_details__1u6fN {
  color: #5200f1;
}
.AccountManagementRemovePopup_actions__jMBNh .AccountManagementRemovePopup_confirm__2PjOH {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.AccountManagementRemovePopup_actions__jMBNh .AccountManagementRemovePopup_confirm__2PjOH.AccountManagementRemovePopup_invalid__3d7yb {
  opacity: 0.12;
}
.AccountManagementRemovePopup_actions__jMBNh .AccountManagementRemovePopup_close__1hZzE {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.AccountManagementRemovePopup_title__1WB27 {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 10px;
}
.AccountManagementRemovePopup_subtitle__wie6S {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.AccountManagementRemovePopup_input__3rZkO {
  font-family: 'Open Sans Regular';
  width: calc(100% - 40px);
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.AccountManagementRemovePopup_input__3rZkO::-webkit-input-placeholder {
  color: #cbcbcb;
}
.AccountManagementRemovePopup_input__3rZkO::-ms-input-placeholder {
  color: #cbcbcb;
}
.AccountManagementRemovePopup_input__3rZkO::placeholder {
  color: #cbcbcb;
}
.AccountManagementRemovePopup_input__3rZkO:focus {
  outline: none;
}
.AccountManagementRemovePopup_copyLinkButton__1FlEH {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border: 1px solid #cbcbcb;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.AccountManagementRemovePopup_copyLinkButton__1FlEH .AccountManagementRemovePopup_linkIcon__30tUv {
  width: 17px;
  margin-right: 3px;
  position: relative;
  left: -4px;
}
.AccountManagementRemovePopup_dropdownContainer__2goYA {
  margin-bottom: 10px;
}
.AccountManagementRemovePopup_invitationLinkContainer__1mbG0 {
  display: flex;
  border: 1px solid #cbcbcb;
  height: 30px;
  line-height: 30px;
  border-radius: 5px;
  overflow: hidden;
}
.AccountManagementRemovePopup_invitationLinkContainer__1mbG0 input {
  border: 0;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(80% - 20px);
  color: #969696;
}
.AccountManagementRemovePopup_invitationLinkContainer__1mbG0 .AccountManagementRemovePopup_copyInputLink__3O7Ky {
  width: 20%;
  text-align: center;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  font-size: 13px;
}
.AccountManagementRemovePopup_invitationLinkContainer__1mbG0 .AccountManagementRemovePopup_copiedInputLink__3Gksk {
  width: 20%;
  text-align: center;
  background-color: #8f8f8f;
  color: #ffffff;
  font-size: 13px;
}
.AccountManagementRemovePopup_loaderContainer__1CaR2 {
  position: relative;
  top: -5px;
  left: -5px;
  display: inline-block;
  height: 27px;
}

.AccountManagementExportPopup_container__3xJeC {
  width: 600px;
  overflow: visible;
}
.AccountManagementExportPopup_content__302Dd {
  padding: 50px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #1b1b1b;
}
.AccountManagementExportPopup_footer__2TSgU {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.AccountManagementExportPopup_footer__2TSgU .AccountManagementExportPopup_details__27HBD {
  color: #5200f1;
}
.AccountManagementExportPopup_actions__jC3zV .AccountManagementExportPopup_confirm__2k8wO {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.AccountManagementExportPopup_actions__jC3zV .AccountManagementExportPopup_confirm__2k8wO.AccountManagementExportPopup_invalid__2isO- {
  opacity: 0.12;
}
.AccountManagementExportPopup_actions__jC3zV .AccountManagementExportPopup_close__33mKm {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.AccountManagementExportPopup_title__20UHI {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 20px;
}
.AccountManagementExportPopup_subtitle__3NiAP {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 15px;
}
.AccountManagementExportPopup_dateInputsContainer__3LOeO {
  display: flex;
}
.AccountManagementExportPopup_dateInputsContainer__3LOeO .AccountManagementExportPopup_dateInputWrapper__2Y8aq:first-child {
  margin-right: 12px;
}
.AccountManagementExportPopup_dateInputsContainer__3LOeO input {
  font-family: 'Open Sans Regular';
  width: inherit;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
}
.AccountManagementExportPopup_dateInputsContainer__3LOeO input::-webkit-input-placeholder {
  color: #cbcbcb;
}
.AccountManagementExportPopup_dateInputsContainer__3LOeO input::-ms-input-placeholder {
  color: #cbcbcb;
}
.AccountManagementExportPopup_dateInputsContainer__3LOeO input::placeholder {
  color: #cbcbcb;
}
.AccountManagementExportPopup_dateInputsContainer__3LOeO input:focus {
  outline: none;
}
.AccountManagementExportPopup_note__2XcyX {
  color: #8b8b8d;
  margin: 20px 0;
}
.AccountManagementExportPopup_loaderContainer__3Qvxv {
  position: relative;
  top: -5px;
  left: -5px;
  display: inline-block;
  height: 27px;
}
.AccountManagementExportPopup_errorContainer__1QePn {
  background-color: #fdf2f7;
  height: 40px;
  line-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 50px;
}
.AccountManagementExportPopup_warningErrorIcon__1S_eI {
  width: 15px;
  margin-right: 10px;
}

.AccountManagementRecordLoader_placeholder__25KQ6 {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #efebeb;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
}
.AccountManagementRecordLoader_placeholder__25KQ6:first-child {
  border-top: 1px solid #efebeb;
}

.SortingArrow_arrow__1O4c0 {
  width: 12px;
  height: 12px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.SortingArrow_arrowPointingUp__2Hh3B {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.AccountManagementRecordsPage_container__2MJnK {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.AccountManagementRecordsPage_title__1oa7_ {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.AccountManagementRecordsPage_noResultsPlaceholder__y22s0 {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.AccountManagementRecordsPage_appBarPath__3gHhP {
  cursor: pointer;
}
.AccountManagementRecordsPage_appBarSpliter__KQmu3 {
  font-size: 11px;
  margin-left: 10px;
  margin-right: 10px;
}
.AccountManagementRecordsPage_mainTable__2F3mV {
  width: 100%;
}
.AccountManagementRecordsPage_headerRow__2vCob {
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.AccountManagementRecordsPage_headerRow__2vCob.AccountManagementRecordsPage_clickable__28MWC {
  cursor: pointer;
}
.AccountManagementRecordsPage_headerRow__2vCob.AccountManagementRecordsPage_activeSorting__jOh6k {
  color: #4a25e8;
}
.AccountManagementRecordsPage_touchpointsContainer__23U7_ {
  width: 250px;
}
.AccountManagementRecordsPage_licenceStartContainer__2pYMU {
  width: 200px;
}
.AccountManagementRecordsPage_licenceEndContainer__sE9UY {
  width: 200px;
}
.AccountManagementRecordsPage_touchpointsTooltipContainer__Rjtgy {
  display: inline-block;
}
.AccountManagementRecordsPage_touchpointsTooltipIcon__3gi6T {
  display: inline-block;
  cursor: pointer;
  height: 13px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.AccountManagementRecordsPage_touchpointsTooltipPopup__1pnCM {
  position: absolute;
  left: 30px;
  bottom: 30px;
  padding: 0px 30px;
  width: 350px;
  background-color: #ffffff;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  text-align: center;
  color: #333333;
  text-transform: none;
  border-radius: 5px;
  font-size: 13px;
}
.AccountManagementRecordsPage_touchpointsTooltipIcon__3gi6T:hover + .AccountManagementRecordsPage_touchpointsTooltipPopup__1pnCM {
  display: block;
}
.AccountManagementRecordsPage_topTooltipPopupText__3rVbR {
  border-bottom: 1px solid #dddddd;
}

.AccountManagementMember_container__2_q2w {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.AccountManagementMember_container__2_q2w.AccountManagementMember_noClick__1zsS- {
  cursor: default;
}
.AccountManagementMember_container__2_q2w:first-child {
  border-top: 1px solid #dddddd;
}
.AccountManagementMember_detailsContainer__2EhIt {
  padding-left: 10px;
}
.AccountManagementMember_actionsContainer__2UlVn {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.AccountManagementMember_actionsContainer__2UlVn .AccountManagementMember_actionsButton__1jp-M {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
.AccountManagementMember_starredUser__3QBSE {
  display: inline-block;
  height: 14px;
  margin-right: 7px;
}
.AccountManagementMember_rowContainer__16ihZ {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
}
.AccountManagementMember_nameContainer__1voN5 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 200px;
  line-height: 18px;
  position: relative;
  top: 3px;
}
.AccountManagementMember_actionButtonsContainer__2Vxii {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.AccountManagementMembersPage_container__-NIaC {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.AccountManagementMembersPage_title__3AKMx {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.AccountManagementMembersPage_noResultsPlaceholder__2-slH {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.AccountManagementMembersPage_appBarPath__1oa_q {
  cursor: pointer;
}
.AccountManagementMembersPage_appBarSpliter__1Cqfh {
  font-size: 11px;
  margin-left: 10px;
  margin-right: 10px;
}
.AccountManagementMembersPage_mainTable__CAZnC {
  width: 100%;
}
.AccountManagementMembersPage_headerRow__9mmWB {
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}

.ClientsInvitation_container__2PTWI {
  min-height: calc(100vh - 20px);
  width: calc(100vw - 20px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.ClientsInvitation_mainContainer__3GnsH {
  display: flex;
  min-height: calc(100vh - 20px);
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}
.ClientsInvitation_logo__24K4S {
  width: 110px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.ClientsInvitation_invitationContent__26bml {
  display: flex;
  flex-direction: column;
}
.ClientsInvitation_invitationContent__26bml div {
  margin-left: auto;
  margin-right: auto;
}
.ClientsInvitation_title__1wDp8 {
  font-family: 'Open Sans Light';
  font-size: 35px;
}
.ClientsInvitation_title__1wDp8 span {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
}
.ClientsInvitation_invitationExplanation__K3mn- {
  font-family: 'Open Sans Regular';
  margin-bottom: 20px;
}
.ClientsInvitation_text__44gYb {
  font-size: 17px;
  color: #666666;
}
.ClientsInvitation_text__44gYb a:link,
.ClientsInvitation_text__44gYb a:visited {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  text-decoration: none;
}
.ClientsInvitation_text__44gYb a:hover {
  cursor: pointer;
}
.ClientsInvitation_button__jadMC {
  display: block;
  position: relative;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 15px;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
}
.ClientsInvitation_mainLoadingContainer__3WpsJ {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media only screen and (max-width: 1024px) {
  .ClientsInvitation_container__2PTWI {
    width: 100%;
  }
  .ClientsInvitation_mainContainer__3GnsH {
    width: 90%;
  }
}

.OAuthLoginPage_container__1ZOjC {
  min-height: calc(100vh - 20px);
  width: calc(100vw - 20px);
  overflow-x: hidden;
  overflow-y: hidden;
}
.OAuthLoginPage_mainContainer__gIwyE {
  display: flex;
  min-height: calc(100vh - 20px);
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
}
.OAuthLoginPage_logo__1-0Ar {
  width: 110px;
  position: absolute;
  left: 0px;
  top: 50px;
}
.OAuthLoginPage_invitationContent__2M4cG {
  display: flex;
  flex-direction: column;
}
.OAuthLoginPage_invitationContent__2M4cG div {
  margin-left: auto;
  margin-right: auto;
}
.OAuthLoginPage_title__2AgJ7 {
  font-family: 'Open Sans Light';
  font-size: 35px;
}
.OAuthLoginPage_title__2AgJ7 span {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
}
.OAuthLoginPage_invitationExplanation__U6zxx {
  font-family: 'Open Sans Regular';
  margin-bottom: 20px;
}
.OAuthLoginPage_text__HWaL0 {
  font-size: 17px;
  color: #666666;
}
.OAuthLoginPage_text__HWaL0 a:link,
.OAuthLoginPage_text__HWaL0 a:visited {
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  text-decoration: none;
}
.OAuthLoginPage_text__HWaL0 a:hover {
  cursor: pointer;
}
.OAuthLoginPage_button__LCBoT {
  display: block;
  position: relative;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 15px;
  text-decoration: none;
  margin-left: auto;
  margin-right: auto;
}
.OAuthLoginPage_mainLoadingContainer__lOYqU {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media only screen and (max-width: 1024px) {
  .OAuthLoginPage_container__1ZOjC {
    width: 100%;
  }
  .OAuthLoginPage_mainContainer__gIwyE {
    width: 90%;
  }
}

.ActiveFilters_activeFiltersContainer__3FGUW {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.ActiveFilters_activeFilterItem__3P4Tw {
  display: flex;
  background-color: #5200f1;
  color: #ffffff;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  min-width: 100px;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 10px;
  border-radius: 5px;
  margin-right: 6px;
  margin-bottom: 6px;
}
.ActiveFilters_activeFilterItem__3P4Tw .ActiveFilters_activeFilterItemLabel__2OdLU {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ActiveFilters_activeFilterItem__3P4Tw .ActiveFilters_removeIcon__3r_RQ {
  font-size: 10px;
  cursor: pointer;
  margin-left: 10px;
}
.ActiveFilters_clearAllFilters__1sTs6 {
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  z-index: 1;
}

.SearchDropdown_filterItemContainer__2aVPg {
  display: flex;
}
.SearchDropdown_filterItemContainer__2aVPg .SearchDropdown_filterType__3O-I9 {
  display: flex;
}
.SearchDropdown_filterItemContainer__2aVPg .SearchDropdown_filterType__3O-I9 .SearchDropdown_closeIcon__1z-aU {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.SearchDropdown_filterItemContainer__2aVPg .SearchDropdown_filterType__3O-I9 {
  display: flex;
}
.SearchDropdown_filterItemContainer__2aVPg .SearchDropdown_filterType__3O-I9 .SearchDropdown_closeIcon__1z-aU {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.SearchDropdown_filterDropdownSelectorContainer__SFh8F {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 200px;
  margin-right: 10px;
}

.ProfilingTagsFilter_container__2ksYQ {
  display: flex;
  padding-bottom: 30px;
}
.ProfilingTagsFilter_quotaInputContainer__3AWXW {
  font-size: 11px;
  font-family: 'Open Sans Regular';
  height: 30px;
  line-height: 30px;
}
.ProfilingTagsFilter_quotaInput__2uubB {
  height: 30px;
  line-height: 30px;
  width: 70px;
  padding-left: 25px;
  padding-right: 10px;
  border: 1px solid #bcbbbb;
  outline: none;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
  font-size: 11px;
}

.ProfilingTagsFilter_quotaIcon__3yYnd {
  position: absolute;
  left: 10px;
  font-size: 13px;
}
.ProfilingTagsFilter_deleteIcon__2FM1w {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 5px;
  top: 0px;
  width: 25px;
  height: 25px;
}

.MultipleSearchDropdown_filterDropdownSelectorContainer__Utw4a {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 290px;
  margin-right: 10px;
}
.MultipleSearchDropdown_selectedOptionsLabel__3mHbW {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 7px 3px;
  cursor: pointer;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  padding-right: 30px;
  color: #9e9e9e;
}
.MultipleSearchDropdown_selectedOptionsLabel__3mHbW.MultipleSearchDropdown_valueSelected__1cxIv {
  color: #000000;
}
.MultipleSearchDropdown_filterDropdownPopup__2TvSG {
  font-size: 11px;
  background-color: #ffffff;
  position: fixed;
  top: 31px;
  width: 300px;
  border: 1px solid #dfe0e4;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.MultipleSearchDropdown_filterDropdownItem__1GJYx {
  display: flex;
  justify-content: space-between;
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.MultipleSearchDropdown_filterDropdownItem__1GJYx.MultipleSearchDropdown_active__1nJ5g {
  background-color: #fafafa;
}
.MultipleSearchDropdown_filterDropdownItemCheckboxSelected__2Fgcj {
  height: 21px;
  margin-right: 10px;
  position: relative;
  color: #5200f1;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
  cursor: pointer;
}
.MultipleSearchDropdown_filterDropdownItemCheckbox__3d01N {
  height: 21px;
  margin-right: 10px;
  color: #b5b5b8;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
  position: relative;
  cursor: pointer;
}
.MultipleSearchDropdown_filterDropdownItemLabel__qmOnt {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 85%;
}
.MultipleSearchDropdown_noAvailableClientAccounts__1ddzy {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.MultipleSearchDropdown_filterDropdownPopupSearchInputContainer__1Y2yY {
  display: flex;
}
.MultipleSearchDropdown_filterDropdownPopupSearchInputContainer__1Y2yY input {
  display: block;
  border: 0;
  line-height: 25px;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  width: calc(100% - 40px);
  outline: none;
}
.MultipleSearchDropdown_filterDropdownPopupSearchInputContainer__1Y2yY .MultipleSearchDropdown_inputLoaderContainer__r351K > div {
  position: relative;
  top: -10px;
}
.MultipleSearchDropdown_filterDropdownPopupItemsContainer__1YF7e {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.MultipleSearchDropdown_filterDropdownPopupItemsContainer__1YF7e::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.MultipleSearchDropdown_filterDropdownPopupItemsContainer__1YF7e::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.MultipleSearchDropdown_filterDropdownPopupFooterContainer__2iAT9 {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.MultipleSearchDropdown_filterDropdownPopupConfirm__2pLyD {
  font-family: 'Open Sans SemiBold';
  font-size: 13px;
  color: #8500f0;
  cursor: pointer;
}
.MultipleSearchDropdown_loaderContainer__19SX3 {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 20px;
}
.MultipleSearchDropdown_addItemIcon__1rGXD {
  position: relative;
  top: 0px;
  width: 11px;
  left: 0px;
  cursor: pointer;
}
.MultipleSearchDropdown_additionalInfoContainer__1idkZ {
  padding: 7px 10px;
  border-top: 1px solid #efefef;
  line-height: 20px;
}
.MultipleSearchDropdown_additionalInfoContainer__1idkZ .MultipleSearchDropdown_title__1H4t1 {
  color: #868686;
}
.MultipleSearchDropdown_additionalInfoContainer__1idkZ .MultipleSearchDropdown_checkboxContainer__1UFMq {
}
.MultipleSearchDropdown_additionalInfoContainer__1idkZ .MultipleSearchDropdown_checkboxContainer__1UFMq .MultipleSearchDropdown_checkboxSelectAllContainer__WzqO1 {
  position: relative;
  top: 7px;
}
.MultipleSearchDropdown_quotaInputContainer__2BJDu {
  font-size: 11px;
  font-family: 'Open Sans Regular';
  height: 25px;
  line-height: 25px;
  display: inline-block;
}
.MultipleSearchDropdown_quotaInput__249fw {
  height: 25px;
  line-height: 25px;
  width: 58px;
  padding-left: 20px;
  padding-right: 10px;
  border: 1px solid #bcbbbb;
  outline: none;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
  font-size: 11px;
  text-align: right;
}
.MultipleSearchDropdown_quotaIcon__3a-Qu {
  position: absolute;
  left: 8px;
  font-size: 13px;
}
.MultipleSearchDropdown_dropdownDisabled__lQqmP {
  background-color: #f9f9f9 !important;
  color: #8b8b8d !important;
}

.UserPropertiesFilter_container__2k4U7 {
  display: flex;
  padding-bottom: 30px;
}
.UserPropertiesFilter_quotaInputContainer__1tGJd {
  font-size: 11px;
  font-family: 'Open Sans Regular';
  height: 30px;
  line-height: 30px;
}
.UserPropertiesFilter_quotaInput__2c5Wl {
  height: 30px;
  line-height: 30px;
  width: 70px;
  padding-left: 25px;
  padding-right: 10px;
  border: 1px solid #bcbbbb;
  outline: none;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
  font-size: 11px;
}

.UserPropertiesFilter_quotaIcon__2V9eL {
  position: absolute;
  left: 10px;
  font-size: 13px;
}
.UserPropertiesFilter_deleteIcon__2gQiD {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 5px;
  top: 0px;
  width: 25px;
  height: 25px;
}

.CommunityTagsFilter_container__zUy-X {
  display: flex;
  padding-bottom: 30px;
}
.CommunityTagsFilter_deleteIcon__3zY3U {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 5px;
  top: 0px;
  width: 25px;
  height: 25px;
}

.FiltersRelation_container__27mA7 {
  position: absolute;
  bottom: -11px;
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  -moz-user-select: none;
       user-select: none; /* Standard syntax */
}
.FiltersRelation_container__27mA7.FiltersRelation_disabled__m07u8 {
  cursor: default;
  background-color: #cecece;
}
.FiltersRelation_container__27mA7 .FiltersRelation_tooltip__3vqpc {
  display: none;
  position: absolute;
  top: 0;
  left: 40px;
  z-index: 100;
  font-size: 12px;
  width: 320px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.FiltersRelation_container__27mA7:hover .FiltersRelation_tooltip__3vqpc {
  display: block;
}

.SingleFilter_singleFilter__3WFd1 {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 30px;
}
.SingleFilter_singleFilter__3WFd1:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
}

.AcquisitionFilter_container__1JRN4 {
  padding-bottom: 30px;
}
.AcquisitionFilter_deleteIcon__2Xkcj {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 5px;
  top: 0px;
  width: 25px;
  height: 25px;
}
.AcquisitionFilter_campaignLinkContainer__sSJjj {
  width: 75%;
}
.AcquisitionFilter_linkInput__1TrDj {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 28px;
  line-height: 28px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: calc(100% - 20px);
  outline: none;
}
.AcquisitionFilter_filtersButtonContainer__1Ys0j {
  display: flex;
}
.AcquisitionFilter_filtersButtonContainer__1Ys0j .AcquisitionFilter_filtersButton__1WBnM {
  color: #4a25e8;
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  top: -4px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}
.AcquisitionFilter_filtersButtonContainer__1Ys0j .AcquisitionFilter_filtersButton__1WBnM img {
  width: 9px;
  margin-right: 5px;
}
.AcquisitionFilter_filtersButtonContainer__1Ys0j .AcquisitionFilter_filtersButton__1WBnM.AcquisitionFilter_filtersButtonActive__3y3SB {
  padding-bottom: 10px;
  border-radius: 0px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.AcquisitionFilter_filtersButtonContainer__1Ys0j .AcquisitionFilter_minimumQuotaContainer__2adav {
  display: inline-flex;
  font-size: 11px;
  line-height: 25px;
  height: 25px;
  margin-left: 13px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.AcquisitionFilter_filtersButtonContainer__1Ys0j .AcquisitionFilter_minimumQuotaContainer__2adav .AcquisitionFilter_minQuotaLabel__CuJsm {
  display: inline-block;
}
.AcquisitionFilter_filtersButtonContainer__1Ys0j .AcquisitionFilter_minimumQuotaContainer__2adav .AcquisitionFilter_checkbox__38w8m.AcquisitionFilter_checkboxSelected__2GsP1 {
  color: #5200f1;
}
.AcquisitionFilter_filtersButtonContainer__1Ys0j .AcquisitionFilter_minimumQuotaContainer__2adav .AcquisitionFilter_checkbox__38w8m {
  color: #b5b5b8;
  height: 21px;
  margin-right: 5px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
}
.AcquisitionFilter_filtersContainer__2MWYK {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  top: -10px;
  padding: 15px 0;
  border-top: 1px solid #f7f7f7;
  min-height: 36px;
}
.AcquisitionFilter_firstRowContainer__1G6Tp {
  display: flex;
}
.AcquisitionFilter_dropdownContainers__1zYR2 {
  display: flex;
  margin-top: 20px;
}
.AcquisitionFilter_searchDropdownTitle__1kLPI,
.AcquisitionFilter_campaignLinkTitle__1c8bQ {
  font-size: 12px;
  margin-bottom: 10px;
}

.Loader_loaderFixedContainer__1b1fY {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
.Loader_loaderFillContainer__2fqQ3 {
  position: absolute;
  top: -20px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.Loader_loadingAnimation__2e0Nm {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 50px;
  opacity: 0.5;
}
.Loader_loadingAnimation__2e0Nm div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 6px;
  border: 4px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation___R4PN 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation___R4PN 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__2e0Nm div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.Loader_loadingAnimation__2e0Nm div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.Loader_loadingAnimation__2e0Nm div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes Loader_loading-animation___R4PN {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader_loading-animation___R4PN {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Filters_container__1Sc8u {
  margin-bottom: 20px;
}
.Filters_container__1Sc8u.Filters_transparent__1ru-R {
  opacity: 0.5;
}
.Filters_filtersButtonContainer__1Yezy {
  display: flex;
}
.Filters_filtersButtonContainer__1Yezy .Filters_filtersButton__1be0r {
  color: #4a25e8;
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  top: -4px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}
.Filters_filtersButtonContainer__1Yezy .Filters_filtersButton__1be0r img {
  width: 9px;
  margin-right: 5px;
}
.Filters_filtersButtonContainer__1Yezy .Filters_filtersButton__1be0r.Filters_filtersButtonActive__2bEIG {
  padding-bottom: 10px;
  border-radius: 0px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.Filters_filtersButtonContainer__1Yezy .Filters_minimumQuotaContainer__2AmLs {
  display: inline-flex;
  font-size: 11px;
  line-height: 25px;
  height: 25px;
  margin-left: 13px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Filters_filtersButtonContainer__1Yezy .Filters_minimumQuotaContainer__2AmLs .Filters_minQuotaLabel__jj-qy {
  display: inline-block;
}
.Filters_filtersButtonContainer__1Yezy .Filters_minimumQuotaContainer__2AmLs .Filters_checkbox__1IeYx.Filters_checkboxSelected__1uACw {
  color: #5200f1;
}
.Filters_filtersButtonContainer__1Yezy .Filters_minimumQuotaContainer__2AmLs .Filters_checkbox__1IeYx {
  color: #b5b5b8;
  height: 21px;
  margin-right: 5px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
}
.Filters_filtersContainer__VenzQ {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  top: -10px;
  padding: 15px 0;
  border-top: 1px solid #f7f7f7;
  min-height: 36px;
}
.Filters_filters__YJyWh {
  padding: 0 40px 0 30px;
}
.Filters_footer__2l2Xa {
  display: flex;
  justify-content: space-between;
  padding: 0 40px 0 30px;
}
.Filters_footer__2l2Xa.Filters_innerFilters__3rCQ7 {
  padding-top: 15px;
  border-top: 1px solid #e2e2e2;
}
.Filters_addFilterButton__iWLc7 {
  display: inline-block;
  font-family: 'Open Sans SemiBold';
  color: #5200f1;
  font-size: 12px;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
}
.Filters_addFilterButton__iWLc7 img {
  width: 15px;
  height: 15px;
  margin-right: 7px;
  position: relative;
  top: 3px;
}
.Filters_moreInfoContainer__2lawT {
  display: inline-block;
  margin-left: 10px;
}
.Filters_moreInfoContainer__2lawT .Filters_questionMark__3Wbcz {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.Filters_moreInfoContainer__2lawT .Filters_tooltip__3OUnE {
  display: none;
  position: absolute;
  top: -5px;
  left: 30px;
  z-index: 100;
  font-size: 12px;
  width: 450px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.Filters_moreInfoContainer__2lawT:hover .Filters_tooltip__3OUnE {
  display: block;
}
.Filters_filterControlsContainer__1g7t- {
  display: flex;
}
.Filters_whiteButton__2dTFz {
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  color: #5200f1;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
.Filters_purpleButton__3TsPq {
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  margin-left: 10px;
}
.Filters_buttonDisabled__3ciNV {
  border: 0;
  background-color: #cecece;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  margin-left: 10px;
}

.TimelineChart_gridLines__1Blmr line {
  stroke: #e5e5e5;
}
.TimelineChart_gridLines__1Blmr path {
  display: none;
}

.TimelineChart_xAxis__3zlS- line {
  display: none;
}

.TimelineChart_yAxis__3KBe3 path,
.TimelineChart_yAxis__3KBe3 line {
  display: none;
}

.TimelineChart_mainLine__p8PlT {
  fill: none;
  stroke: #a57bfa;
  stroke-width: 1;
}

.RangeSelector_container__353cI {
  display: flex;
}
.RangeSelector_range__uxNZi {
  border: 0;
  background-color: #cecece;
  color: #ffffff;
  cursor: pointer;
  height: 25px;
  line-height: 25px;
  font-size: 10px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  margin-left: 5px;
}
.RangeSelector_range__uxNZi.RangeSelector_active__2sHiq {
  background-color: #5200f1;
}

.ChartMetric_container__3E1ta {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
}
.ChartMetric_container__3E1ta .ChartMetric_loadingContainer__2iXWY {
  height: 282px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.ChartMetric_header__2PiHG {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ChartMetric_headerContainer__2kO9s {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ChartMetric_rangesContainer__2SaOy {
  margin-right: 30px;
}
.ChartMetric_headerContainer__2kO9s .ChartMetric_title__3xi2k {
  color: #4a25e8;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 20px;
}
.ChartMetric_headerContainer__2kO9s .ChartMetric_valueContainer__3u87f {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ChartMetric_headerContainer__2kO9s .ChartMetric_valueContainer__3u87f .ChartMetric_value__3LZ_M {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 26px;
  margin-right: 10px;
}
.ChartMetric_headerContainer__2kO9s .ChartMetric_description__1uxt4 {
  width: 80px;
  font-size: 13px;
  line-height: 14px;
}
.ChartMetric_chartContainer__1G09s {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.ChartMetric_tooltipContainer__4hq-g {
  position: fixed;
  z-index: 9999999999999999;
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 15px;
}

.ValueMetric_container__2L4Os {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ValueMetric_container__2L4Os .ValueMetric_loadingContainer__2mKri {
  height: 91px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.ValueMetric_title__1_aaB {
  color: #4a25e8;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
}
.ValueMetric_valueContainer__29qLY {
  display: flex;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
}
.ValueMetric_valueContainer__29qLY .ValueMetric_value__DwZL9 {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 36px;
  margin-right: 10px;
}
.ValueMetric_valueContainer__29qLY .ValueMetric_description__3dHut {
  width: 140px;
  font-size: 16px;
  line-height: 18px;
}

.MainPageCharts_container__1Cp8n {
  display: flex;
  flex-wrap: wrap;
}
.MainPageCharts_row__1pDPc {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
}

.BarChart_gridLines__1vPkK line {
  stroke: #e5e5e5;
}
.BarChart_gridLines__1vPkK path {
  display: none;
}

.BarChart_xAxis__2Qxb5 line {
  display: none;
}

.BarChart_yAxis__25nNG path,
.BarChart_yAxis__25nNG line {
  display: none;
}

.BarChart_mainLine__1OWUZ {
  fill: none;
  stroke: #a57bfa;
  stroke-width: 1;
}

.BarMetric_container__16BI2 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
}
.BarMetric_container__16BI2 .BarMetric_loadingContainer__3lKon {
  height: 282px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.BarMetric_header__2PMV2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.BarMetric_headerContainer__30BHt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.BarMetric_rangesContainer__1yghO {
  margin-right: 30px;
}
.BarMetric_headerContainer__30BHt .BarMetric_title__m4rcu {
  color: #4a25e8;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 20px;
}
.BarMetric_headerContainer__30BHt .BarMetric_valueContainer__1JD-m {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.BarMetric_headerContainer__30BHt .BarMetric_valueContainer__1JD-m .BarMetric_value__2VG7M {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 26px;
  margin-right: 10px;
}
.BarMetric_headerContainer__30BHt .BarMetric_description__cLFa- {
  width: 80px;
  font-size: 13px;
  line-height: 14px;
}
.BarMetric_chartContainer__1tHFL {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.BarMetric_tooltipContainer__10w-7 {
  position: fixed;
  z-index: 9999999999999999;
  color: #000000;
  font-family: 'Open Sans Regular';
  font-size: 12px;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #000000;
}
.BarMetric_legend__1Ac3I {
  text-align: center;
}
.BarMetric_legend__1Ac3I .BarMetric_legendItem__3Gabj {
  display: inline-block;
  margin: 0 10px;
  font-size: 11px;
}
.BarMetric_legend__1Ac3I .BarMetric_legendItem__3Gabj .BarMetric_bullet__1B8AD {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 5px;
}
.BarMetric_legend__1Ac3I .BarMetric_legendItem__3Gabj .BarMetric_bullet__1B8AD.BarMetric_blue__ll7sG {
  background-color: #5200f1;
}
.BarMetric_legend__1Ac3I .BarMetric_legendItem__3Gabj .BarMetric_bullet__1B8AD.BarMetric_lightblue__1zBUZ {
  background-color: #9f94ed;
}
.BarMetric_moreInfoContainer__2ZXNz {
  display: inline-block;
  margin-left: -10px;
  position: relative;
  top: 1px;
}
.BarMetric_moreInfoContainer__2ZXNz .BarMetric_questionMark__34Nfi {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.BarMetric_moreInfoContainer__2ZXNz .BarMetric_tooltip__1OdWN {
  display: none;
  position: absolute;
  top: -5px;
  left: 30px;
  z-index: 100;
  font-size: 12px;
  width: 300px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.BarMetric_moreInfoContainer__2ZXNz:hover .BarMetric_tooltip__1OdWN {
  display: block;
}

.PlaceholderChart_container__2ifTg {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
}
.PlaceholderChart_container__2ifTg .PlaceholderChart_loadingContainer__2MvNe {
  height: 282px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.PlaceholderChart_header__3EXKB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PlaceholderChart_headerContainer__YNHjY {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PlaceholderChart_rangesContainer__2qar0 {
  margin-right: 30px;
}
.PlaceholderChart_headerContainer__YNHjY .PlaceholderChart_title__1rBBC {
  color: #4a25e8;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 20px;
}
.PlaceholderChart_headerContainer__YNHjY .PlaceholderChart_valueContainer__3d6Ts {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.PlaceholderChart_headerContainer__YNHjY .PlaceholderChart_valueContainer__3d6Ts .PlaceholderChart_value__kOYDv {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 26px;
  margin-right: 10px;
}
.PlaceholderChart_headerContainer__YNHjY .PlaceholderChart_description__2fDxd {
  width: 80px;
  font-size: 13px;
  line-height: 14px;
}
.PlaceholderChart_chartContainer__bKOBb {
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  color: #7d7c7c;
  height: 162px;
}
.PlaceholderChart_tooltipContainer__29gvK {
  position: fixed;
  z-index: 9999999999999999;
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 15px;
}

.CommunityDashboardPage_container__dKGJJ {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.CommunityDashboardPage_header__tmiMT {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
}
.CommunityDashboardPage_title__z5E3x {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}

.TotalSampleSize_container__2G4m_ {
  margin-bottom: 30px;
  font-size: 24px;
}
.TotalSampleSize_input__1oLm9 {
  height: 40px;
  width: 85px;
  padding: 0 8px;
  margin-left: 25px;
  border: 1px solid #c3c3c3;
  outline: none;
  font-size: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
  text-align: right;
}

.DeliveryPotential_title__3f0tJ {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 20px;
  margin-bottom: 15px;
}
.DeliveryPotential_container__3ca4E {
  margin-bottom: 20px;
  display: flex;
}
.DeliveryPotential_deliveryItemsContainer__j5um9 {
  display: flex;
  flex-grow: 1;
}
.DeliveryPotential_deliveryContainer__KJ2ZL {
  flex-basis: 100%;
  flex-grow: 1;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  margin-left: 10px;
  border-radius: 10px;
  padding-left: 30px;
  padding-right: 40px;
}
.DeliveryPotential_deliveryContainer__KJ2ZL .DeliveryPotential_loadingContainer__2HQk4 {
  flex: 1 1;
  margin-top: 10px;
}
.DeliveryPotential_deliveryContainer__KJ2ZL .DeliveryPotential_deliveryTitleContainer__D88Gf {
  display: flex;
  align-items: center;
}
.DeliveryPotential_deliveryContainer__KJ2ZL .DeliveryPotential_deliveryTitleContainer__D88Gf .DeliveryPotential_deliveryTitle__33dx- {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
}
.DeliveryPotential_deliveryContainer__KJ2ZL .DeliveryPotential_mainContainer__1BKvM {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.DeliveryPotential_deliveryContainer__KJ2ZL .DeliveryPotential_mainContainer__1BKvM .DeliveryPotential_maxDeliveryPotentialCount__1PZuY {
  color: #5200f1;
  font-family: 'Open Sans Bold';
  font-size: 32px;
  margin-right: 10px;
}
.DeliveryPotential_trafficLightContainer__3D4ED {
  width: 50px;
  padding-top: 12px;
  padding-bottom: 12px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}
.DeliveryPotential_light__1YTEt {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  margin-bottom: 6px;
}
.DeliveryPotential_light__1YTEt:last-of-type {
  margin-bottom: 0;
}
.DeliveryPotential_redLight__2bwTN,
.DeliveryPotential_orangeLight__28Cpi,
.DeliveryPotential_greenLight__2o3KN {
  background-color: #e8e8e8;
  transition: all 0.45s ease-in-out;
}
.DeliveryPotential_redLight__2bwTN.DeliveryPotential_active__24p3V {
  background-color: #ff005f;
}
.DeliveryPotential_orangeLight__28Cpi.DeliveryPotential_active__24p3V {
  background-color: #e8bd2b;
}
.DeliveryPotential_greenLight__2o3KN.DeliveryPotential_active__24p3V {
  background-color: #a0d035;
}
.DeliveryPotential_moreInfoContainer__2FiQ8 {
  display: inline-block;
  margin-left: 15px;
}
.DeliveryPotential_moreInfoContainer__2FiQ8 .DeliveryPotential_questionMark__3n9k3 {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.DeliveryPotential_moreInfoContainer__2FiQ8 .DeliveryPotential_tooltip__S9W0V {
  display: none;
  position: absolute;
  top: -5px;
  left: 30px;
  z-index: 100;
  font-size: 12px;
  width: 300px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.DeliveryPotential_moreInfoContainer__2FiQ8:hover .DeliveryPotential_tooltip__S9W0V {
  display: block;
}
.DeliveryPotential_calculateButton__YWixP {
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  height: 48px;
  line-height: 45px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
.DeliveryPotential_calculateButtonDisabled__36AYp {
  border: 0;
  background-color: #c4c4c4;
  color: #ffffff;
  cursor: not-allowed;
  height: 48px;
  line-height: 45px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}

.CalculatingIncidenceRate_container__3z6oD {
  padding: 50px 100px;
}
.CalculatingIncidenceRate_title__3aOx3 {
  color: #4a25e8;
  text-align: center;
  font-family: 'Open Sans SemiBold';
  font-size: 16px;
  margin-bottom: 5px;
}
.CalculatingIncidenceRate_description__3zsTM {
  color: #a8a8a9;
  text-align: center;
  font-family: 'Open Sans Regular';
  font-size: 13px;
  margin-bottom: 40px;
}

.Chart_gridLines__3zh0B path {
  display: none;
}
.Chart_gridLines__3zh0B .Chart_gridLineTick__3G5AR line {
  stroke: #e9e9e9;
  stroke-width: 1px;
  stroke-opacity: 0.9;
}
.Chart_gridLines__3zh0B .Chart_gridLineTick__3G5AR:first-of-type {
  display: none;
}

.Chart_xAxis__p6Z7B path,
.Chart_yAxis__2mrsZ path {
  stroke: #939393;
}
.Chart_xAxisTick__yfdh_,
.Chart_yAxisTick__2jlEh {
  font-style: italic;
  font-family: 'Open Sans Regular';
}
.Chart_xAxisIRTick__3oMlq {
  font-style: italic;
  font-family: 'Open Sans SemiBold';
}
.Chart_xAxisTick__yfdh_ line,
.Chart_yAxisTick__2jlEh line {
  display: none;
}
.Chart_yAxisLegend__2-HcO {
  font-style: italic;
  font-size: 11px;
}

.ChartMetric_container__2zWRt {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.ChartMetric_container__2zWRt .ChartMetric_loadingContainer__1fq4S {
  min-height: 391px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  margin-top: 20px;
}
.ChartMetric_title__2SukD {
  color: #4a25e8;
  font-family: 'Open Sans SemiBold';
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 30px;
  text-transform: capitalize;
}
.ChartMetric_chartContainer__3IK5v {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}
.ChartMetric_tooltipContainer__3FPcU {
  position: fixed;
  z-index: 9999999999999999;
  font-family: 'Open Sans SemiBold';
  font-size: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.05);
  border: 1px solid #efefef;
  padding: 8px 10px;
  border-radius: 5px;
}
.ChartMetric_legend__37NiA {
  text-align: center;
}
.ChartMetric_legend__37NiA .ChartMetric_legendItem__rzhGx {
  display: inline-block;
  margin: 0 10px;
  font-size: 11px;
}
.ChartMetric_legend__37NiA .ChartMetric_legendItem__rzhGx .ChartMetric_bullet__13GHM {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-right: 5px;
}
.ChartMetric_legend__37NiA .ChartMetric_legendItem__rzhGx .ChartMetric_bullet__13GHM.ChartMetric_green__3c17Q {
  background-color: #a0d035;
}
.ChartMetric_legend__37NiA .ChartMetric_legendItem__rzhGx .ChartMetric_bullet__13GHM.ChartMetric_orange__oS3Yd {
  background-color: #e8bd2b;
}
.ChartMetric_legend__37NiA .ChartMetric_legendItem__rzhGx .ChartMetric_bullet__13GHM.ChartMetric_red__2YkBA {
  background-color: #ff005f;
}
.ChartMetric_legend__37NiA .ChartMetric_legendItem__rzhGx .ChartMetric_bullet__13GHM.ChartMetric_lightGrey__1-zgb {
  background-color: #f2f2f2;
}
.ChartMetric_legend__37NiA .ChartMetric_legendItem__rzhGx .ChartMetric_bullet__13GHM.ChartMetric_darkGrey__3-FnH {
  background-color: #d9d9d9;
}
.ChartMetric_legend__37NiA .ChartMetric_legendItem__rzhGx .ChartMetric_bullet__13GHM.ChartMetric_blue__2f33u {
  background-color: #5200f1;
}
.ChartMetric_legend__37NiA .ChartMetric_legendItem__rzhGx .ChartMetric_stripe__24Xy1 {
  display: inline-block;
  width: 8px;
  height: 1px;
  background-color: #000;
  margin-right: 5px;
  vertical-align: middle;
}

.RepresentativeSample_title__3iFJC {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 20px;
  margin-bottom: 15px;
}
.RepresentativeSample_container__2uhEE {
  margin-bottom: 15px;
  display: flex;
}
.RepresentativeSample_optionContainer__KcPb2 {
  margin-right: 15px;
  font-size: 11px;
  display: inline-flex;
  align-items: center;
}
.RepresentativeSample_optionContainer__KcPb2 input {
  margin-right: 5px;
  margin-top: 1px;
  accent-color: #5200f1;
  outline: none;
}
.RepresentativeSample_moreInfoContainer__26ZTw .RepresentativeSample_questionMark__17cuI {
  background-color: #d9d9d9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #ffffff;
  cursor: default;
}
.RepresentativeSample_moreInfoContainer__26ZTw .RepresentativeSample_tooltip__2EYUf {
  display: none;
  position: absolute;
  top: -5px;
  left: 30px;
  z-index: 100;
  font-size: 12px;
  width: 300px;
  font-family: 'Open Sans Light';
  background-color: #fff;
  padding: 15px;
  color: #000000;
  font-style: italic;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.08);
  line-height: 18px;
  white-space: pre-wrap;
}
.RepresentativeSample_moreInfoContainer__26ZTw:hover .RepresentativeSample_tooltip__2EYUf {
  display: block;
}

.DeltaWarnings_container__308I0 {
  margin-bottom: 30px;
}
.DeltaWarnings_warning__24dnt {
  color: #fc035d;
  background-color: rgba(255, 0, 95, 0.12);
  font-family: 'Open Sans SemiBold';
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  font-size: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.DeltaWarnings_warningIcon__2xyIK {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.ProfileAttributesMetrics_chartContainerBackground__1F5ek {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.07);
  border: 1px solid #f7f7f7;
  width: 100%;
}
.ProfileAttributesMetrics_chartContainerBackground__1F5ek .ProfileAttributesMetrics_loadingContainer__2xV_1 {
  min-height: 391px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  margin-top: 20px;
}

.MainPageCharts_container__jB0x_ {
}
.MainPageCharts_row__TbGhR {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
}
.MainPageCharts_title__pOqpy {
  color: #5200f1;
  font-family: 'Open Sans SemiBold';
  font-size: 20px;
  padding-top: 15px;
  margin-bottom: 15px;
}

.FeasibilityCheckerPage_container__3qm4u {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.FeasibilityCheckerPage_title__CW9Cx {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
  margin-bottom: 10px;
}

.CommunityAutomationsHeader_container__21LHO {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 70px;
  display: flex;
  justify-content: space-between;
}
.CommunityAutomationsHeader_title__3YiB3 {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.CommunityAutomationsHeader_rightContainer__2pvnb {
  display: flex;
}
.CommunityAutomationsHeader_searchContainer__x7Jq9 {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.CommunityAutomationsHeader_searchInputContainer__1Spph {
  display: flex;
  background-color: #ffffff;
  height: 25px;
  line-height: 25px;
  padding: 5px;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 40%;
  flex-grow: 1;
}
.CommunityAutomationsHeader_searchInputContainer__1Spph .CommunityAutomationsHeader_questionContainingInput__3HG8S {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  border: 0;
  font-size: 13px;
  width: 260px;
  padding-right: 30px;
  outline: none;
  height: 25px;
  line-height: 25px;
  padding-left: 5px;
  flex-grow: 1;
}
.CommunityAutomationsHeader_searchInputContainer__1Spph .CommunityAutomationsHeader_close__3fu8n {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 12px;
  cursor: pointer;
  color: #777777;
}
.CommunityAutomationsHeader_searchIcon__ruGrt {
  cursor: pointer;
  height: 20px;
}
.CommunityAutomationsHeader_buttonMarginWrapper__uBhD7 {
  display: inline-block;
  margin: 0 15px;
}
.CommunityAutomationsHeader_buttonMarginWrapper__uBhD7 ~ .CommunityAutomationsHeader_buttonMarginWrapper__uBhD7 {
  margin-left: 0;
}

.CommunityAutomationsListPage_container__2vFwn {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.CommunityAutomationsListPage_title__2gpVs {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.CommunityAutomationsListPage_noResultsPlaceholder__2vMv- {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.CommunityAutomationsListPage_appBarPath__12HyT {
  cursor: pointer;
}
.CommunityAutomationsListPage_appBarSpliter__1yLB4 {
  font-size: 11px;
  margin-left: 10px;
  margin-right: 10px;
}
.CommunityAutomationsListPage_mainTable__FTp4C {
  width: 100%;
}
.CommunityAutomationsListPage_headerRow__bgHjx {
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}
.CommunityAutomationsListPage_nameContainer__2sZaR {
  padding-left: 20px;
}

.CommunityAutomationsRecordLoader_placeholder__1FFdx {
  border-bottom: 1px solid #efebeb;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
}
.CommunityAutomationsRecordLoader_placeholder__1FFdx:first-child {
  border-top: 1px solid #efebeb;
}

.ActionDropdown_popup__1N0hi {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  z-index: 9999;
  color: #8b8b8d;
  font-size: 11px;
  text-transform: uppercase;
  text-align: left;
  scrollbar-width: thin;
}
.ActionDropdown_actionDropdownPopup__L1nYm {
  z-index: 99999;
}
.ActionDropdown_popup__1N0hi::-webkit-scrollbar {
  -webkit-appearance: none;
}
.ActionDropdown_popup__1N0hi::-webkit-scrollbar:vertical {
  width: 11px;
}
.ActionDropdown_popup__1N0hi::-webkit-scrollbar:horizontal {
  height: 11px;
}
.ActionDropdown_popup__1N0hi::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.ActionDropdown_option__L5re5,
.ActionDropdown_option__L5re5:link,
a.ActionDropdown_option__L5re5:visited {
  display: block;
  height: 40px;
  line-height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  color: #8b8b8d;
  text-decoration: none;
  cursor: pointer;
}
.ActionDropdown_option__L5re5:hover {
  opacity: 0.7;
}
.ActionDropdown_separator__20QF0 {
  background-color: #ebeaef;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ActionDropdown_icon__1ybP5 {
  margin-right: 10px;
  color: #5200f1;
  font-size: 12px;
}
.ActionDropdown_option__L5re5.ActionDropdown_action-dropdown-withdraw-request-option__25dgJ {
  line-height: normal;
  height: auto;
  display: flex;
}
.ActionDropdown_deleteIcon__1ISRn {
  width: 12px;
  height: 12px;
}

.Loader_loaderFixedContainer__1OAxo {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
.Loader_loaderFillContainer__3OSl1 {
  position: absolute;
  top: -20px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.Loader_loadingAnimation__2CabI {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.Loader_loadingAnimation__2CabI div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation__2_kHX 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation__2_kHX 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__2CabI div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.Loader_loadingAnimation__2CabI div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.Loader_loadingAnimation__2CabI div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes Loader_loading-animation__2_kHX {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader_loading-animation__2_kHX {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.CommunityAutomationsRecord_container__2dzhS {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #dddddd;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.CommunityAutomationsRecord_container__2dzhS.CommunityAutomationsRecord_noClick__2kzLa {
  cursor: default;
}
.CommunityAutomationsRecord_container__2dzhS:first-child {
  border-top: 1px solid #dddddd;
}
.CommunityAutomationsRecord_detailsContainer__1Z3Cf {
  padding-left: 10px;
}
.CommunityAutomationsRecord_actionsContainer__2PgQ6 {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.CommunityAutomationsRecord_actionsContainer__2PgQ6 .CommunityAutomationsRecord_actionsButton__1snng {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
.CommunityAutomationsRecord_starredUser__2towH {
  display: inline-block;
  height: 14px;
  margin-right: 7px;
}
.CommunityAutomationsRecord_record__4PUR9.CommunityAutomationsRecord_isRecordHovered__HfpNo {
  cursor: pointer;
}
.CommunityAutomationsRecord_rowContainer__21p12 {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
}
.CommunityAutomationsRecord_rowContainer__21p12.CommunityAutomationsRecord_statusContainer__3s85o {
  text-transform: lowercase;
}
.CommunityAutomationsRecord_rowContainer__21p12.CommunityAutomationsRecord_statusContainer__3s85o::first-letter {
  text-transform: uppercase;
}
.CommunityAutomationsRecord_record__4PUR9.CommunityAutomationsRecord_isRecordHovered__HfpNo .CommunityAutomationsRecord_nameContainer__1Y1H4 {
  opacity: 0.7;
}
.CommunityAutomationsRecord_nameContainer__1Y1H4 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: 350px;
  line-height: 18px;
  position: relative;
  top: 3px;
  padding-left: 20px;
}
.CommunityAutomationsRecord_touchpointsContainer__2t5XA {
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.CommunityAutomationsRecord_actionButtonsContainer__CaiHh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}
.CommunityAutomationsRecord_touchpointNumber__rOwy3 {
  width: 100px;
}
.CommunityAutomationsRecord_barContainer__Nqzen {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #e7e7e8;
}
.CommunityAutomationsRecord_bar__2d8HD {
  height: 5px;
}
.CommunityAutomationsRecord_warningTooltipContainer__1E1xR {
  display: inline-block;
}
.CommunityAutomationsRecord_warningTooltipIcon__1PwBD {
  display: inline-block;
  cursor: pointer;
  height: 15px;
  position: relative;
  top: 3px;
  margin-left: 10px;
}
.CommunityAutomationsRecord_warningTooltipPopup__3WcXK {
  position: absolute;
  left: 50px;
  bottom: 4px;
  padding: 0px 20px;
  background-color: #f61553;
  display: none;
  z-index: 25;
  border: 1px solid #dddddd;
  text-align: center;
  color: #ffffff;
  text-transform: none;
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
}
.CommunityAutomationsRecord_warningTooltipPopupTriangle__175zT {
  position: absolute;
  left: -8px;
  top: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent #f61555 transparent transparent;
}
.CommunityAutomationsRecord_warningTooltipIcon__1PwBD:hover + .CommunityAutomationsRecord_warningTooltipPopup__3WcXK {
  display: block;
}
.CommunityAutomationsRecord_actionsContainer__2PgQ6 {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.CommunityAutomationsRecord_actionsContainer__2PgQ6 .CommunityAutomationsRecord_actionsButton__1snng {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
.CommunityAutomationsRecord_languageFlag__Cqv-Y {
  position: absolute;
  left: 0px;
  top: 22px;
  width: 10px;
  height: 8px;
}

.CommunityAutomationPage_container__3z4wd {
  margin-left: auto;
  margin-right: auto;
  margin-top: 66px;
}
.CommunityAutomationPage_headerContainer__1LbI4 {
  height: 80px;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.CommunityAutomationPage_leftHeader__17iml {
  display: flex;
}

.CommunityAutomationPage_leftHeader__17iml .CommunityAutomationPage_leftHeaderArrow__XMNF4 {
  position: relative;
  top: 5px;
  display: block;
  height: 13px;
  margin-right: 10px;
  cursor: pointer;
}

.CommunityAutomationPage_rightHeader__3NM1u {
}
.CommunityAutomationPage_rightHeader__3NM1u .CommunityAutomationPage_rightHeaderButton__1YSaI {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
.CommunityAutomationPage_rightHeader__3NM1u .CommunityAutomationPage_rightHeaderButton__1YSaI.CommunityAutomationPage_disabled__w_y_H {
  background-color: #d0d0d0;
}
.CommunityAutomationPage_filtersContainer__2_YW6 {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  border-bottom: 1px solid #d3d3d3;
}
.CommunityAutomationPage_filtersContainer__2_YW6 .community-dashboard-filters-container {
  margin-bottom: 0;
}

.CommunityAutomationMainDiagram_container__1Vsxy {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}
.CommunityAutomationMainDiagram_arrowDown__1O3E- {
  display: block;
  height: 60px;
}

.CommunityAutomationStep_container__MCe-Z {
  width: 340px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;

  color: #8e8e8e;
  border: 1px #8e8e8e dashed;
  background-color: #f5f3f3;
  margin-bottom: 10px;
}
.CommunityAutomationStep_active__3pWAF {
  background-color: #e6e0f6;
  color: #4a25e8;
  border: 1px #4a25e8 solid;
}
.CommunityAutomationStep_stepNameAssigned__3GpE1 {
  background-color: #ffffff;
  color: #414141;
  border: 1px #a2a2a2 solid;
}
.CommunityAutomationStep_lastEventStep__1RNXM {
  margin-bottom: 0;
}
.CommunityAutomationStep_binIcon__2_xAw {
  display: none;
  position: absolute;
  height: 15px;
  top: 22px;
  right: 23px;
  cursor: pointer;
}
.CommunityAutomationStep_container__MCe-Z:hover .CommunityAutomationStep_binIcon__2_xAw {
  display: block;
}
.CommunityAutomationStep_addNewStepIcon__1USAR {
  position: absolute;
  display: inline-block;
  bottom: -30px;
  left: 50%;
  margin-left: -15px;
}

.CommunityAutomationPropertesColumn_container__28hsc {
  font-family: 'Open Sans Regular';
  font-size: 12px;
}
.CommunityAutomationPropertesColumn_header__3W4tw {
  padding: 20px;
  border-bottom: 1px solid #d3d3d3;
}
.CommunityAutomationPropertesColumn_header__3W4tw .CommunityAutomationPropertesColumn_title__IoeKO {
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  margin-bottom: 5px;
}
.CommunityAutomationPropertesColumn_header__3W4tw .CommunityAutomationPropertesColumn_description__9kRXU {
  margin-bottom: 15px;
}
.CommunityAutomationPropertesColumn_header__3W4tw .CommunityAutomationPropertesColumn_searchContainer__1FRJQ {
}
.CommunityAutomationPropertesColumn_header__3W4tw .CommunityAutomationPropertesColumn_searchContainer__1FRJQ .CommunityAutomationPropertesColumn_searchInput__1k-i9 {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 31px;
  height: 40px;
}

.CommunityAutomationPropertesColumn_listContainer__2GO06 {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 345px);
}
.CommunityAutomationPropertesColumn_listContainer__2GO06 .CommunityAutomationPropertesColumn_option__3gYL_ {
  padding: 20px;
  border-bottom: 1px solid #d3d3d3;
  cursor: pointer;
}
.CommunityAutomationPropertesColumn_listContainer__2GO06 .CommunityAutomationPropertesColumn_option__3gYL_:hover {
  opacity: 0.7;
}

.CommunityAutomationPropertesSingleStep_container__Sssx6 {
}
.CommunityAutomationPropertesSingleStep_header__E4Uiw {
  padding: 20px;
}
.CommunityAutomationPropertesSingleStep_header__E4Uiw .CommunityAutomationPropertesSingleStep_title__20Kcb {
  font-family: 'Open Sans SemiBold';
  font-size: 14px;
  margin-bottom: 5px;
}
.CommunityAutomationPropertesSingleStep_header__E4Uiw .CommunityAutomationPropertesSingleStep_subDescription__30izu {
  font-style: italic;
}
.CommunityAutomationPropertesSingleStep_fieldTitle__RwSna {
  margin-top: 15px;
  margin-bottom: 5px;
}
.CommunityAutomationPropertesSingleStep_inputField__2oRiR {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 31px;
  height: 40px;
}
.CommunityAutomationPropertesSingleStep_inputField__2oRiR.CommunityAutomationPropertesSingleStep_inputFieldWithLabel__2NRoH {
  display: inline-block;
}
.CommunityAutomationPropertesSingleStep_inputFieldLabel__3yn_x {
  margin-left: 5px;
}
.CommunityAutomationPropertesSingleStep_inputCharacterCount__2SWm3 {
  color: #b5b5b8;
  position: absolute;
  right: 10px;
  bottom: 12px;
}
.CommunityAutomationPropertesSingleStep_textareaField__31Ou_ {
  font-family: 'Open Sans Regular';
  font-size: 12px;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100px;
  height: 100px;
}
.CommunityAutomationPropertesSingleStep_amount1NarrowField__1XUL- {
  width: 80px;
}
.CommunityAutomationPropertesSingleStep_dropdownWiderFilterStyle__1gbdk {
  margin-bottom: 25px;
}
.CommunityAutomationPropertesSingleStep_dropdownWiderFilterStyle__1gbdk > div:first-child {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  color: #cbcbcb;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 30px;
  height: 40px;
  background-position-x: 355px;
}
.CommunityAutomationPropertesSingleStep_dropdownWiderFilterStyle__1gbdk.CommunityAutomationPropertesSingleStep_selectedValue__mIW8I > div:first-child {
  color: #000000;
}
.CommunityAutomationPropertesSingleStep_dropdownWiderFilterStyle__1gbdk input {
  width: 91%;
  margin-bottom: 0;
}
.CommunityAutomationPropertesSingleStep_filterDropdownPopup__1vGnA {
  font-size: 11px;
  background-color: #ffffff;
  position: absolute;
  top: 37px;
  width: 350px;
  border: 1px solid #dfe0e4;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}

.CommunityAutomationMainContainer_container__m8zxH {
  display: flex;
  background-color: #f9f8f8;
  min-height: calc(110vh - 196px);
}
.CommunityAutomationMainContainer_container__m8zxH .CommunityAutomationMainContainer_mainDiagramContainer__3CF2h {
  width: calc(100vw - 420px);
  display: flex;
  justify-content: center;
}
.CommunityAutomationMainContainer_container__m8zxH .CommunityAutomationMainContainer_rightColumnContainer__3CXiG {
  width: 420px;
  background-color: #ffffff;
  border-left: 1px solid #d3d3d3;
}

.ActionNameEditor_container__2ZyrZ {
  display: flex;
}
.ActionNameEditor_editIcon__3EPUJ {
  display: block;
  position: relative;
  top: 5px;
  cursor: pointer;
  margin-left: 5px;
  height: 13px;
}
.ActionNameEditor_inputFieldContainer__1NL6O {
}
.ActionNameEditor_inputFieldContainer__1NL6O.ActionNameEditor_placeholder__3AP20 {
  color: #adadad;
}
.ActionNameEditor_inputFieldContainer__1NL6O .ActionNameEditor_inputField__1K6wM {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  padding: 0;
  border: 0;
  margin: 0;
  outline: none;
  width: 50vw;
}
.ActionNameEditor_inputFieldContainer__1NL6O span {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  max-width: 50vw;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: 4px;
}

.CommunityManagementHeader_container__3xtCk {
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.CommunityManagementHeader_title__1tNFc {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.CommunityManagementHeader_rightContainer__282vp {
  display: flex;
}

.ActionDropdown_popup__OC0p3 {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  z-index: 9999;
  color: #8b8b8d;
  font-size: 11px;
  text-transform: uppercase;
  text-align: left;
  scrollbar-width: thin;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.ActionDropdown_popup__OC0p3::-webkit-scrollbar {
  -webkit-appearance: none;
}
.ActionDropdown_popup__OC0p3::-webkit-scrollbar:vertical {
  width: 11px;
}
.ActionDropdown_popup__OC0p3::-webkit-scrollbar:horizontal {
  height: 11px;
}
.ActionDropdown_popup__OC0p3::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.ActionDropdown_option__yhZwE,
.ActionDropdown_option__yhZwE:link,
a.ActionDropdown_option__yhZwE:visited {
  display: block;
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  color: #8b8b8d;
  text-decoration: none;
  cursor: pointer;
}
.ActionDropdown_option__yhZwE:hover {
  opacity: 0.7;
}

.CommunityManagementRecord_rowContainer__3K-gx {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
  max-width: 300px;
}
.CommunityManagementRecord_firstColumn__hXFwL {
  padding-left: 20px;
  box-sizing: border-box;
}
.CommunityManagementRecord_typeColumn__zoQrz {
  width: 200px;
}
.CommunityManagementRecord_nameColumn__2Qyus {
  width: 290px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.CommunityManagementRecord_createdAtColumn__2HeXQ {
  width: 150px;
}
.CommunityManagementRecord_actionsContainer__qe1uF {
  padding-right: 10px;
  text-align: right;
}
.CommunityManagementRecord_actionsContainer__qe1uF .CommunityManagementRecord_actionsButton__2c1L8 {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}

.RouteTitle_myCampaignsAction__3i6IN {
  font-family: 'Open Sans Regular';
  border-left: 1px solid #3b3838;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  background: none;
  border: none;
  display: inline-block;
  min-width: 130px;
}
.RouteTitle_actionLabel__Sl00H {
  margin: 0 16px;
}

.CommunityManagementListPage_container__2QFHC {
  width: 1190px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.CommunityManagementListPage_noResultsPlaceholder__1Tjww {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.CommunityManagementListPage_mainTable__17qNF {
  width: 100%;
  border-collapse: collapse;
}
.CommunityManagementListPage_headerRow__22H0Q {
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}
.CommunityManagementListPage_firstColumn__3-oUo {
  padding-left: 20px;
  box-sizing: border-box;
}
.CommunityManagementListPage_typeColumn__298pL {
  width: 200px;
}
.CommunityManagementListPage_nameColumn__29W1e {
  width: 300px;
}
.CommunityManagementListPage_createdAtColumn__3RXk_ {
  width: 150px;
}
.CommunityManagementListPage_errorsContainer__O2_ul {
  display: inline-flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}
.CommunityManagementListPage_error__14GSy {
  background-color: #fdf2f7;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
}

.InputField_container__3ekvb {
  margin-bottom: 15px;
}
.InputField_container__3ekvb input {
  font-family: 'Open Sans Regular';
  font-size: 13px;
  border: 0;
  outline: none;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  resize: none;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  min-height: 30px;
  height: 40px;
}
.InputField_title__2Q-oE {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.InputField_requiredAsterisk__1E8Er {
  margin-left: 0;
  color: #be0000;
}

.DropdownSelector_container__3Yg6E {
  margin-bottom: 15px;
}
.DropdownSelector_title__2t-io {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.DropdownSelector_requiredAsterisk__TfrhD {
  margin-left: 0;
  color: #be0000;
}
.DropdownSelector_filterDropdownSelectorContainer__2yW8o {
}
.DropdownSelector_selectedOptionsLabel__24nG1 {
  display: block;
  width: 100%;
  height: 40px;
  min-height: 30px;
  line-height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  font-family: 'Open Sans Regular';
  font-size: 13px;
  color: #000000;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
  border: 1px solid #efefef;
  border-radius: 5px;
  background-position: right;
  background-origin: content-box;
  background-size: 15px;
  background-repeat: no-repeat;
}
.DropdownSelector_selectedOptionsLabel__24nG1.DropdownSelector_placeholder__19Ved {
  color: #cbcbcb;
}
.DropdownSelector_selectedOptionsLabel__24nG1.DropdownSelector_disabled__1ha1K {
  background-color: #ebebeb;
  border-color: #dcdcdc;
  opacity: 0.7;
  cursor: default;
}
.DropdownSelector_filterDropdownPopup__3P746 {
  font-size: 11px;
  background-color: #ffffff;
  position: absolute;
  top: 42px;
  width: 100%;
  border: 1px solid #dfe0e4;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  z-index: 9999999;
}
.DropdownSelector_noAvailableClientAccounts__2QAqj {
  text-align: center;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  color: #8b8b8d;
}
.DropdownSelector_filterDropdownPopupItemsContainer__2R0jx {
  max-height: 200px;
  overflow-y: auto;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.DropdownSelector_filterDropdownPopupItemsContainer__2R0jx::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.DropdownSelector_filterDropdownPopupItemsContainer__2R0jx::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.DropdownSelector_filterDropdownItem__3isKq {
  line-height: 21px;
  height: 21px;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}
.DropdownSelector_filterDropdownItemLabel__cD1g4 {
  display: inline-block;
}

.TypeExplanation_explanation__9zdyn {
  margin-bottom: 20px;
  font-size: 13px;
  color: #8b8b8d;
  white-space: pre-line;
}

.RouteSubtitle_routeSubtitle__8x1yL .RouteSubtitle_appBarPath__sukea:first-child {
  cursor: pointer;
}
.RouteSubtitle_appBarSpliter__3AKwR {
  font-size: 11px;
  margin-left: 10px;
  margin-right: 10px;
}

.CommunityUserRecord_rowContainer__3DUTp {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
  max-width: 300px;
}
.CommunityUserRecord_firstColumn__1cN17 {
  padding-left: 20px;
  box-sizing: border-box;
}
.CommunityUserRecord_actionsContainer__1FDvR {
  padding-right: 10px;
  text-align: right;
}
.CommunityUserRecord_actionsContainer__1FDvR .CommunityUserRecord_removeIcon__3GTkD {
  position: relative;
  height: 25px;
  width: 25px;
  margin-left: 15px;
  cursor: pointer;
  vertical-align: middle;
  opacity: 0.7;
}

.CommunityUsersList_container__24HEe {
  width: 1190px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.CommunityUsersList_noResultsPlaceholder__IucWS {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.CommunityUsersList_mainTable__asF3r {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.CommunityUsersList_headerRow__35keu {
  font-size: 11px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}
.CommunityUsersList_firstColumn__19sjZ {
  padding-left: 20px;
  box-sizing: border-box;
}
.CommunityUsersList_errorsContainer__z6az7 {
  display: inline-flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}
.CommunityUsersList_error__2SnSV {
  background-color: #fdf2f7;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
}

.Onboarding_sectionContainer__3eufW {
  width: 500px;
  max-width: 100%;
  margin-bottom: 15px;
}
.Onboarding_sectionTitle__2ulHu {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.Onboarding_sectionDetails__1ZFlC {
  font-size: 13px;
  background-color: #f6f7fb;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
}
.Onboarding_sectionDetails__1ZFlC .Onboarding_item__Um4Am {
  color: #8c8c8c;
  margin-top: 15px;
}
.Onboarding_sectionDetails__1ZFlC .Onboarding_item__Um4Am:first-child {
  margin-top: 0;
}
.Onboarding_explanation__3-fQK {
  margin-bottom: 20px;
  font-size: 13px;
  color: #8b8b8d;
  white-space: pre-line;
}

.CommunityManagementPage_container__3KIuW {
  width: 1190px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.CommunityManagementPage_title__379g4 {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
  padding-bottom: 50px;
}
.CommunityManagementPage_inputContainer__1mKrN {
  width: 500px;
  max-width: 100%;
}
.CommunityManagementPage_createUpdatedButtonContainer__2ZHOe {
  display: flex;
}
.CommunityManagementPage_errorsContainer__3R499 {
  display: inline-flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}
.CommunityManagementPage_error__zExRI {
  background-color: #fdf2f7;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
}
.CommunityManagementPage_sectionTitle__XeWsA {
  margin-bottom: 10px;
  font-size: 13px;
  color: #282828;
}
.CommunityManagementPage_requiredAsterisk__3idVs {
  margin-left: 0;
  color: #be0000;
}

.TrackingParamFilters_container__2UiLY {
  margin-bottom: 20px;
}
.TrackingParamFilters_container__2UiLY.TrackingParamFilters_transparent__24B2j {
  opacity: 0.5;
}
.TrackingParamFilters_dropdownContainers__j5V3c {
  display: flex;
  margin-top: 20px;
  padding: 0 20px;
}
.TrackingParamFilters_searchDropdownTitle__3LZB-,
.TrackingParamFilters_campaignLinkTitle__yLghG {
  font-size: 12px;
  margin-bottom: 10px;
}
.TrackingParamFilters_campaignLinkContainer__2Fwr0 {
  padding: 0 20px;
}
.TrackingParamFilters_linkInput__2W56w {
  border-radius: 5px;
  border: 1px solid #bcbbbb;
  background-color: #ffffff;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: calc(100% - 20px);
  outline: none;
}
.TrackingParamFilters_filtersButtonContainer__Qva2X {
  display: flex;
}
.TrackingParamFilters_filtersButtonContainer__Qva2X .TrackingParamFilters_filtersButton__3J5y3 {
  color: #4a25e8;
  background-color: #ffffff;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  top: -4px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}
.TrackingParamFilters_filtersButtonContainer__Qva2X .TrackingParamFilters_filtersButton__3J5y3 img {
  width: 9px;
  margin-right: 5px;
}
.TrackingParamFilters_filtersButtonContainer__Qva2X .TrackingParamFilters_filtersButton__3J5y3.TrackingParamFilters_filtersButtonActive__3_GZt {
  padding-bottom: 10px;
  border-radius: 0px;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.TrackingParamFilters_filtersButtonContainer__Qva2X .TrackingParamFilters_minimumQuotaContainer__1Iey9 {
  display: inline-flex;
  font-size: 11px;
  line-height: 25px;
  height: 25px;
  margin-left: 13px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.TrackingParamFilters_filtersButtonContainer__Qva2X .TrackingParamFilters_minimumQuotaContainer__1Iey9 .TrackingParamFilters_minQuotaLabel__3SJKp {
  display: inline-block;
}
.TrackingParamFilters_filtersButtonContainer__Qva2X .TrackingParamFilters_minimumQuotaContainer__1Iey9 .TrackingParamFilters_checkbox__2vWDX.TrackingParamFilters_checkboxSelected__1VrjH {
  color: #5200f1;
}
.TrackingParamFilters_filtersButtonContainer__Qva2X .TrackingParamFilters_minimumQuotaContainer__1Iey9 .TrackingParamFilters_checkbox__2vWDX {
  color: #b5b5b8;
  height: 21px;
  margin-right: 5px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  top: 1px;
}
.TrackingParamFilters_filtersContainer__1KTVQ {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
  top: -10px;
  padding: 15px 0;
  border-top: 1px solid #f7f7f7;
  min-height: 36px;
}
.TrackingParamFilters_footer__1eJNp {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 0 30px;
  margin: 20px 20px 0 20px;
}
.TrackingParamFilters_footer__1eJNp.TrackingParamFilters_innerFilters__3WKLa {
  padding-top: 15px;
  border-top: 1px solid #e2e2e2;
}
.TrackingParamFilters_addFilterButton__3Md8B img {
  width: 15px;
  height: 15px;
  margin-right: 7px;
  position: relative;
  top: 3px;
}
.TrackingParamFilters_filterControlsContainer__2eZ4N {
  display: flex;
}
.TrackingParamFilters_whiteButton__3yPR_ {
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  color: #5200f1;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
}
.TrackingParamFilters_purpleButton__3UHXT {
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  margin-left: 10px;
}
.TrackingParamFilters_buttonDisabled__3-pmJ {
  border: 0;
  background-color: #cecece;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-family: 'Open Sans SemiBold';
  margin-left: 10px;
}
.TrackingParamFilters_selectTimeframeContainer__x3vPy {
  font-size: 16px;
  font-family: 'Open Sans Light';
  margin-bottom: 20px;
}
.TrackingParamFilters_selectTimeframeContainer__x3vPy .TrackingParamFilters_dateRange__1C8vp {
  font-size: 18px;
}
.TrackingParamFilters_selectTimeframeContainer__x3vPy .TrackingParamFilters_editIcon__10dVI {
  margin-right: 10px;
  color: #5200f1;
  font-size: 14px;
  cursor: pointer;
}
.TrackingParamFilters_editIconHidden__3HA4B {
  opacity: 0.2;
}

.SelectTimeframePopup_container__2R1AU {
  width: 600px;
  overflow: visible;
}
.SelectTimeframePopup_content__QUHPt {
  padding: 50px;
  padding-bottom: 20px;
  font-size: 14px;
  color: #1b1b1b;
}
.SelectTimeframePopup_footer__d91kL {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.SelectTimeframePopup_footer__d91kL .SelectTimeframePopup_details__2-On9 {
  color: #5200f1;
}
.SelectTimeframePopup_actions__2W3Ti .SelectTimeframePopup_confirm__2I2Ov {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.SelectTimeframePopup_actions__2W3Ti .SelectTimeframePopup_confirm__2I2Ov.SelectTimeframePopup_invalid__2CiB1 {
  opacity: 0.12;
}
.SelectTimeframePopup_actions__2W3Ti .SelectTimeframePopup_close__3z8CC {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.SelectTimeframePopup_title__1rjfF {
  font-family: 'Roboto Bold';
  font-size: 25px;
  margin-bottom: 20px;
}
.SelectTimeframePopup_subtitle__1ZFe8 {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 15px;
}
.SelectTimeframePopup_dateInputsContainer__2__OS {
  display: flex;
}
.SelectTimeframePopup_dateInputsContainer__2__OS .SelectTimeframePopup_dateInputWrapper__3z7sS:first-child {
  margin-right: 12px;
}
.SelectTimeframePopup_dateInputsContainer__2__OS input {
  font-family: 'Open Sans Regular';
  width: inherit;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-top: 10px;
}
.SelectTimeframePopup_dateInputsContainer__2__OS input::-webkit-input-placeholder {
  color: #cbcbcb;
}
.SelectTimeframePopup_dateInputsContainer__2__OS input::-ms-input-placeholder {
  color: #cbcbcb;
}
.SelectTimeframePopup_dateInputsContainer__2__OS input::placeholder {
  color: #cbcbcb;
}
.SelectTimeframePopup_dateInputsContainer__2__OS input:focus {
  outline: none;
}
.SelectTimeframePopup_note__dLZpe {
  color: #8b8b8d;
  margin: 20px 0;
}
.SelectTimeframePopup_loaderContainer__2-yQm {
  position: relative;
  top: -5px;
  left: -5px;
  display: inline-block;
  height: 27px;
}
.SelectTimeframePopup_errorContainer__Y_kOA {
  background-color: #fdf2f7;
  height: 40px;
  line-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 50px;
}
.SelectTimeframePopup_warningErrorIcon__2Jxop {
  width: 15px;
  margin-right: 10px;
}

.ActiveFilters_activeFiltersContainer__1ZNHa {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.ActiveFilters_activeFilterItem__1zJXI {
  display: flex;
  background-color: #5200f1;
  color: #ffffff;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  min-width: 100px;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 10px;
  border-radius: 5px;
  margin-right: 6px;
  margin-bottom: 6px;
}
.ActiveFilters_activeFilterItem__1zJXI .ActiveFilters_activeFilterItemLabel__19Ch7 {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ActiveFilters_activeFilterItem__1zJXI .ActiveFilters_removeIcon__1w1Uv {
  font-size: 10px;
  cursor: pointer;
  margin-left: 10px;
}
.ActiveFilters_clearAllFilters__2mZsV {
  color: #5200f1;
  font-size: 11px;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  z-index: 1;
}

.AcquisitionMetricsList_listContainer__1Jgta {
  margin-top: 30px;
}
.AcquisitionMetricsList_loadingItem__nB8GU {
  height: 40px !important;
}
.AcquisitionMetricsList_tableContainer__ES3_H {
  margin-top: 30px;
  font-family: 'Open Sans Regular';
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}
.AcquisitionMetricsList_headerRow__1HcDX th {
  text-align: left;
  font-size: 13px;
  color: #afaeae;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #ebeaef;
  padding: 0 15px;
}
.AcquisitionMetricsList_rightAlignValues__3a-HM {
  text-align: right !important;
}
.AcquisitionMetricsList_tableRow__ZNBta td {
  border-bottom: 1px solid #ebeaef;
  height: 35px;
  line-height: 35px;
  color: #717171;
  padding: 0 15px;
}
.AcquisitionMetricsList_tableRow__ZNBta td.AcquisitionMetricsList_values__AF-zi {
  text-align: right;
}
.AcquisitionMetricsList_tableRow__ZNBta td span:hover {
  text-decoration: underline;
  color: #5200f1;
  cursor: pointer;
}
.AcquisitionMetricsList_noResults__snRR2 {
  font-size: 11px;
  color: #8b8b8d;
  text-transform: uppercase;
  text-align: center;
  margin-top: 40px;
}
.AcquisitionMetricsList_visibleForSearchCheckbox__2KRDc {
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.AcquisitionMetricsPage_container__Z4TtZ {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.AcquisitionMetricsPage_header__Q0c2g {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
}
.AcquisitionMetricsPage_title__3Vl6d {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.AcquisitionMetricsPage_rightContainer__3j7M_ {
  display: flex;
}
.AcquisitionMetricsPage_buttonContainer__2F-Ab {
  margin-left: 15px;
}

.GenerateAcquisitionCampaignLinkPage_container__a6HCC {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.GenerateAcquisitionCampaignLinkPage_header__1DXUZ {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
}
.GenerateAcquisitionCampaignLinkPage_title__1XUOi {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-right: 50px;
}
.GenerateAcquisitionCampaignLinkPage_appBarPath__1q72p {
  cursor: pointer;
}
.GenerateAcquisitionCampaignLinkPage_subtitle__2DnsY {
  font-size: 14px;
  color: #1b1b1b;
  margin-top: 10px;
  margin-bottom: 10px;
}
.GenerateAcquisitionCampaignLinkPage_subtitle__2DnsY span.GenerateAcquisitionCampaignLinkPage_description__2RrGK {
  margin-left: 2px;
  color: #807e7e;
  font-size: 13px;
}
.GenerateAcquisitionCampaignLinkPage_input__2wlGn,
.GenerateAcquisitionCampaignLinkPage_datePickerContainer__BMoy_ input {
  font-family: 'Open Sans Regular';
  width: 257px;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
}
.GenerateAcquisitionCampaignLinkPage_input__2wlGn::-webkit-input-placeholder {
  color: #cbcbcb;
}
.GenerateAcquisitionCampaignLinkPage_input__2wlGn::-ms-input-placeholder {
  color: #cbcbcb;
}
.GenerateAcquisitionCampaignLinkPage_input__2wlGn::placeholder {
  color: #cbcbcb;
}
.GenerateAcquisitionCampaignLinkPage_input__2wlGn:focus {
  outline: none;
}
.GenerateAcquisitionCampaignLinkPage_dropdownContainer__2DYXJ {
  margin-bottom: 20px;
}
.GenerateAcquisitionCampaignLinkPage_dropdownLicenceContainer__9N3n4 {
  width: 300px;
  margin-right: 10px;
}
.GenerateAcquisitionCampaignLinkPage_dropdownLicenceContainer__9N3n4:last-child {
  margin-right: 0;
}
.GenerateAcquisitionCampaignLinkPage_buttonContainer__1Khs1 {
  width: 150px;
  margin-top: 30px;
  text-align: center;
}
.GenerateAcquisitionCampaignLinkPage_linkSuccessfullyGeneratedContainer__3fhYS {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #d4d4d4;
  font-size: 12px;
}
.GenerateAcquisitionCampaignLinkPage_linkGeneratedTitle__2Yioi {
  margin-bottom: 10px;
}
.GenerateAcquisitionCampaignLinkPage_generatedLink__3xJf- {
  font-size: 13px;
  text-decoration: underline;
}
.GenerateAcquisitionCampaignLinkPage_buttonsContainer__33zY- {
  display: flex;
}
.GenerateAcquisitionCampaignLinkPage_buttonsContainer__33zY- .GenerateAcquisitionCampaignLinkPage_buttonContainer__1Khs1 {
  margin-right: 15px;
}
.GenerateAcquisitionCampaignLinkPage_mainLoadingContainer__3FH3e {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.GenerateAcquisitionCampaignLinkPage_requiredAsterisk__lMMxD {
  margin-left: 0;
  color: #be0000;
}
.GenerateAcquisitionCampaignLinkPage_communitiesDropdown__1PKYj,
.GenerateAcquisitionCampaignLinkPage_communitiesDropdownSelected__3rjmk {
  width: 298px;
}
.GenerateAcquisitionCampaignLinkPage_communitiesDropdown__1PKYj > div:first-of-type {
  font-family: 'Open Sans Regular';
  border-radius: 5px;
  border: 1px solid #686767;
  background-color: #ffffff;
  height: 35px;
  line-height: 32px;
  padding-left: 20px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 14px 6px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box;
  color: #555555;
  opacity: 0.4;
}
.GenerateAcquisitionCampaignLinkPage_communitiesDropdownSelected__3rjmk > div:first-of-type {
  font-family: 'Open Sans Regular';
  border-radius: 5px;
  border: 1px solid #cbcbcb;
  background-color: #ffffff;
  height: 35px;
  line-height: 32px;
  padding-left: 20px;
  padding-right: 5px;
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 14px 6px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box;
  color: #000000;
}

.SearchDropdown_filterItemContainer__3soVv {
  display: flex;
}
.SearchDropdown_filterItemContainer__3soVv .SearchDropdown_filterType__3gnbd {
  display: flex;
}
.SearchDropdown_filterItemContainer__3soVv .SearchDropdown_filterType__3gnbd .SearchDropdown_closeIcon__1kzZA {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.SearchDropdown_filterItemContainer__3soVv .SearchDropdown_filterType__3gnbd {
  display: flex;
}
.SearchDropdown_filterItemContainer__3soVv .SearchDropdown_filterType__3gnbd .SearchDropdown_closeIcon__1kzZA {
  color: #787878;
  font-size: 12px;
  line-height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
.SearchDropdown_filterDropdownStyle__2tRZ8 {
  position: relative;
  height: 30px;
  line-height: 30px;
  width: 222px;
  margin-right: 10px;
}

.ConfigurationActions_actionButtons__3UgI3 {
  text-align: right;
  font-size: 13px;
}
.ConfigurationActions_actionButton__3j0YT {
  min-height: 30px;
  margin-right: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 4px 15px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  line-height: 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ConfigurationActions_actionButton__3j0YT:hover * {
  opacity: 0.7;
}
.ConfigurationActions_actionButton__3j0YT.ConfigurationActions_disabledButton__JoLMo {
  cursor: not-allowed;
}
.ConfigurationActions_actionButton__3j0YT.ConfigurationActions_disabledButton__JoLMo *,
.ConfigurationActions_actionButton__3j0YT.ConfigurationActions_disabledButton__JoLMo:hover * {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  opacity: 0.5;
}
.ConfigurationActions_actionButton__3j0YT .ConfigurationActions_addIcon__DSGxd {
  height: 8px;
  width: 8px;
}
.ConfigurationActions_actionButton__3j0YT .ConfigurationActions_buttonText__1fhZw {
  display: inline-block;
  padding-left: 8px;
}

.ActionDropdown_popup__1PNgm {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.02);
  z-index: 9999;
  color: #8b8b8d;
  font-size: 11px;
  text-transform: uppercase;
  text-align: left;
  scrollbar-width: thin;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.ActionDropdown_popup__1PNgm::-webkit-scrollbar {
  -webkit-appearance: none;
}
.ActionDropdown_popup__1PNgm::-webkit-scrollbar:vertical {
  width: 11px;
}
.ActionDropdown_popup__1PNgm::-webkit-scrollbar:horizontal {
  height: 11px;
}
.ActionDropdown_popup__1PNgm::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}
.ActionDropdown_option__FHzJ4,
.ActionDropdown_option__FHzJ4:link,
a.ActionDropdown_option__FHzJ4:visited {
  display: block;
  height: 30px;
  line-height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  color: #8b8b8d;
  text-decoration: none;
  cursor: pointer;
}
.ActionDropdown_option__FHzJ4:hover {
  opacity: 0.7;
}

.ConfigurationRecord_rowContainer__2ilmJ {
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #dddddd;
}
.ConfigurationRecord_subRecord__2-C6h {
  padding-left: 30px;
}
.ConfigurationRecord_arrow__1oLPo {
  cursor: pointer;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  top: 1px;
}
.ConfigurationRecord_arrowPointingUp__1P4QM {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.ConfigurationRecord_actionsContainer__2-GJp {
  padding-right: 10px;
  text-align: right;
}
.ConfigurationRecord_actionsContainer__2-GJp .ConfigurationRecord_actionsButton__HwOTJ {
  cursor: pointer;
  color: #bfbfbf;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 1px;
  height: 35px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}

.ConfigurationTable_container__1gtUS {
  margin-bottom: 20px;
}
.ConfigurationTable_noResultsPlaceholder__3Xudu {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #666666;
}
.ConfigurationTable_mainTable__2BaBW {
  width: 100%;
  border-collapse: collapse;
}
.ConfigurationTable_headerRow__1ZYqE {
  font-size: 13px;
  color: #8b8b8d;
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}
.ConfigurationTable_headerRow__1ZYqE th {
  font-weight: normal;
}

.ConfigurationTableType_container__3gMtz {
  margin-bottom: 20px;
}

.ConfigurationPopup_container__ll_4- {
  width: 500px;
  font-size: 14px;
  color: #1b1b1b;
  box-shadow: -15px 10px 15px #fff;
}
.ConfigurationPopup_content__21m2B {
  padding: 50px;
  padding-bottom: 20px;
}
.ConfigurationPopup_contentContainer__3Amwg {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
}
.ConfigurationPopup_footer__q19sR {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #cbcbcb;
}
.ConfigurationPopup_footer__q19sR .ConfigurationPopup_details__3J_zm {
  color: #5200f1;
}
.ConfigurationPopup_actions__93tti .ConfigurationPopup_confirm__33cF3 {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.ConfigurationPopup_actions__93tti .ConfigurationPopup_confirm__33cF3.ConfigurationPopup_invalid__1xUCk {
  opacity: 0.12;
}
.ConfigurationPopup_actions__93tti .ConfigurationPopup_close__1Cc7W {
  position: relative;
  top: 2px;
  height: 20px;
  width: 20px;
  margin-left: 15px;
  cursor: pointer;
}
.ConfigurationPopup_title__SiCcK {
  font-family: 'Roboto Bold';
  color: #5300f2;
  margin-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
}
.ConfigurationPopup_description__3uzZ_ {
  margin-bottom: 10px;
}
.ConfigurationPopup_subtitle__2xgEo {
  font-size: 14px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.ConfigurationPopup_label__3ZmTM {
  margin-bottom: 5px;
}
.ConfigurationPopup_input__13ViC {
  font-family: 'Open Sans Regular';
  width: 100%;
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  color: #1b1b1b;
  border: 1px solid #cbcbcb;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.ConfigurationPopup_input__13ViC::-webkit-input-placeholder {
  color: #cbcbcb;
}
.ConfigurationPopup_input__13ViC::-ms-input-placeholder {
  color: #cbcbcb;
}
.ConfigurationPopup_input__13ViC::placeholder {
  color: #cbcbcb;
}
.ConfigurationPopup_input__13ViC:focus {
  outline: none;
}
.ConfigurationPopup_dropdown__1oxKT {
  padding-left: 10px;
}
.ConfigurationPopup_loaderContainer__CMk1c {
  position: relative;
  top: -8px;
  left: 2px;
  display: inline-block;
  height: 25px;
}
.ConfigurationPopup_errorContainer__3fuJx {
  background-color: #fdf2f7;
  min-height: 40px;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}
.ConfigurationPopup_errorContainer__3fuJx .ConfigurationPopup_errorText__2R0tS {
  margin-left: 5px;
}
.ConfigurationPopup_warningErrorIcon__1QMQc {
  width: 15px;
  margin-right: 10px;
}

.ConfigurationListPage_container__2TaYH {
  width: 1190px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 145px;
  padding-bottom: 100px;
}
.ConfigurationListPage_title__3_zZK {
  font-family: 'Roboto Bold';
  font-size: 38px;
  height: 50px;
  line-height: 50px;
  color: #221f1f;
  margin-bottom: 20px;
}
.ConfigurationListPage_errorsContainer__3u_8K {
  display: inline-flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ConfigurationListPage_error__32mB- {
  background-color: #fdf2f7;
  display: flex;
  color: #e95083;
  font-size: 14px;
  padding: 10px;
  border-radius: 2px;
}

.CookiesPopup_container__1lGM3 {
  background-color: #f3f4f7;
  padding: 30px;
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 9999999;
  font-family: 'Open Sans Regular';
  width: 600px;
  font-size: 13px;
  border-radius: 5px;
}
.CookiesPopup_title__1bWyk {
  font-family: 'Open Sans Bold', Arial;
  font-size: 14px;
  margin-bottom: 15px;
}
.CookiesPopup_description__1BJaA {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 18px;
}
.CookiesPopup_description__1BJaA a:link,
.CookiesPopup_description__1BJaA a:visited {
  font-family: 'Open Sans Bold', Arial;
  color: #000000;
  text-decoration: underline;
}
.CookiesPopup_buttonsContainer__161qC {
  display: flex;
  justify-content: right;
}
.CookiesPopup_buttonsContainer__161qC .CookiesPopup_rejectButton__1Wo4j {
  display: inline-block;
  font-family: 'Open Sans Bold', Arial;
  color: #000000;
  line-height: 35px;
  text-decoration: underline;
  cursor: pointer;
}
.CookiesPopup_buttonsContainer__161qC .CookiesPopup_acceptButton__3b7d0 {
  display: inline-block;
  padding: 10px 20px;
  background-color: #000000;
  color: #ffffff;
  font-family: 'Open Sans Bold', Arial;
  margin-left: 15px;
  border-radius: 5px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .CookiesPopup_container__1lGM3 {
    background-color: #f3f4f7;
    padding: 30px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    font-family: 'Open Sans Regular';
    width: calc(100vw - 60px);
    font-size: 13px;
    border-radius: 5px;
  }
}

.AuthRoute_container__2NpNt {
  /*
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  */
}
.AuthRoute_mainContainer__159i5 {
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 1024px) {
  .AuthRoute_mainContainer__159i5 {
    width: 100%;
  }
}

.CookiesPopup_topMarginContainer__2JQtw {
  position: relative;
  height: 55px;
}
.CookiesPopup_container__3axZ0 {
  background-color: #f3f4f7;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  font-family: 'Open Sans Regular';
  width: 100vw;
  font-size: 11px;
  border-radius: 5px;
}
.CookiesPopup_testingContainer__2yOlp {
  top: 35px;
  padding: 5px 10px;
}
.CookiesPopup_contentContainer__11l_T {
  display: flex;
  width: 1100px;
  max-width: 90%;
  margin: 0 auto;
}
.CookiesPopup_description__fKfI4 {
  font-size: 12px;
  line-height: 18px;
}
.CookiesPopup_description__fKfI4 a:link,
.CookiesPopup_description__fKfI4 a:visited {
  font-family: 'Open Sans Bold', Arial;
  color: #000000;
  text-decoration: underline;
}
.CookiesPopup_acceptButtonsContainer__B0Zoi {
  width: 155px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CookiesPopup_acceptButtonsContainer__B0Zoi .CookiesPopup_acceptButton__2CyLl {
  display: inline-block;
  padding: 10px 20px;
  background-color: #000000;
  color: #ffffff;
  font-family: 'Open Sans Bold', Arial;
  border-radius: 15px;
  cursor: pointer;
  width: 100px;
  height: 20px;
  line-height: 20px;
}
.CookiesPopup_rejectButtonsContainer__25InH {
  width: 180px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CookiesPopup_rejectButtonsContainer__25InH .CookiesPopup_rejectButton__1F-c5 {
  display: inline-block;
  font-family: 'Open Sans Bold', Arial;
  color: #000000;
  text-decoration: underline;
  cursor: pointer;
  height: 20px;
  line-height: 20px;
}

@media only screen and (max-width: 768px) {
  .CookiesPopup_container__3axZ0 {
    background-color: #f3f4f7;
    padding: 30px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    font-family: 'Open Sans Regular';
    width: calc(100vw - 60px);
    font-size: 13px;
    border-radius: 5px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .CookiesPopup_topMarginContainer__2JQtw {
    height: 300px;
  }
  .CookiesPopup_description__fKfI4 {
    margin-bottom: 20px;
  }
  .CookiesPopup_contentContainer__11l_T {
    display: block;
    width: auto;
    max-width: auto;
    margin: 0 auto;
  }
  .CookiesPopup_acceptButtonsContainer__B0Zoi {
    width: auto;
    margin-bottom: 20px;
  }
  .CookiesPopup_acceptButtonsContainer__B0Zoi .CookiesPopup_acceptButton__2CyLl {
    width: 120px;
  }
  .CookiesPopup_rejectButtonsContainer__25InH {
    width: auto;
    margin-bottom: 20px;
  }
}

.AppBar_appBarContainer__DKfPT {
  height: 150px;
  background-color: #fff;
  z-index: 999;
}
.AppBar_webSurveyLogoContainer__OrcPw {
  width: 1100px;
  max-width: 90%;
  margin: 0 auto;
  line-height: 150px;
}
.AppBar_webSurveyLogo__2yksu {
  width: 115px;
  height: 23px;
  font-family: 'Roboto Light Light' !important;
}
.AppBar_webSurveyCustomLogo__3o6o_ {
  max-width: 185px;
  max-height: 85px;
  height: auto;
  font-family: 'Roboto Light Light' !important;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  .AppBar_appBarContainer__DKfPT {
    height: 70px;
  }
  .AppBar_webSurveyLogoContainer__OrcPw {
    line-height: 70px;
  }
  .AppBar_webSurveyLogo__2yksu {
    width: 75px;
    height: 15px;
  }
  .AppBar_webSurveyCustomLogo__3o6o_ {
    margin-top: 10px;
    max-width: 120px;
    max-height: 50px;
  }
}

.TestingBanner_testingBanner__2mreP {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 40px;
  font-size: 14px;
  font-family: 'Roboto Light';
}

.SavingTranslationError_container__2R-Qd {
  position: fixed;
  bottom: 10px;
  width: 400px;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #ffbfd7;
  border-radius: 2px;
  padding: 2px 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 13px;
  font-family: 'Roboto Light';
  background-color: #fff;
  color: #fc035d;
  background-color: #ffd6e5;
  text-align: center;
}

.CampaignsRoute_container__2npVa {
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
}
.CampaignsRoute_mainContainer__1O_2H {
  min-height: calc(100vh - 150px); /* Appbar is 150px in height */
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1024px) {
  .CampaignsRoute_container__2npVa {
    width: 100%;
  }
  .CampaignsRoute_mainContainer__1O_2H {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .CampaignsRoute_mainContainer__1O_2H {
    min-height: calc(100vh - 70px); /* Appbar is 70px in height */
  }
}

.AppBar_appBarWrapper__QpCAs {
  border-bottom: 1px solid #e4e7ec;
  box-shadow: 0 2px 100px #00000005;
  margin-bottom: 50px;
}
.AppBar_appBar__FODVa {
  height: 100px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
}
.AppBar_logo__25D3C {
  width: 89px;
}
.AppBar_languageActive__3Owjp {
  font-family: 'Open Sans SemiBold';
}
.AppBar_languageOption__29ZGz {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 14px;
}
.AppBar_languageOption__29ZGz:last-child {
  margin-left: 10px;
}

.SignUpExplanation_container__1TkNN {
  font-family: 'Roboto Regular';
  color: #6b7589;
  padding-right: 30px;
}
.SignUpExplanation_containerHiddenForMobile__358lZ {
  font-family: 'Roboto Regular';
  color: #6b7589;
  padding-right: 30px;
}
.SignUpExplanation_desktopContainer__1Wqat {
  display: block;
}
.SignUpExplanation_mobileContainer__2BFiV {
  display: none;
}
.SignUpExplanation_titleLabel__3Q6yv {
  display: inline-block;
  background-color: #f4ecfb;
  color: #6f2a7f;
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 4px;
  letter-spacing: 0.5px;
  font-size: 12px;
  font-family: 'Roboto Medium';
}
.SignUpExplanation_title__1gdiA {
  font-family: 'Montserrat SemiBold';
  font-size: 32px;
  color: #000;
}
.SignUpExplanation_subTitle__3kO9Z {
  font-family: 'Roboto Medium';
  margin-bottom: 4px;
}
.SignUpExplanation_text__1C22t {
  line-height: 26px;
}
.SignUpExplanation_textItalic__5eYXp {
  font-style: italic;
}
p {
  -webkit-margin-before: 1.5em;
          margin-block-start: 1.5em;
  -webkit-margin-after: 1.5em;
          margin-block-end: 1.5em;
}
p:last-child {
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

@media only screen and (max-width: 992px) {
  .SignUpExplanation_container__1TkNN {
    padding-right: 0;
  }
  .SignUpExplanation_containerHiddenForMobile__358lZ {
    display: none;
  }
  .SignUpExplanation_desktopContainer__1Wqat {
    display: none;
  }
  .SignUpExplanation_mobileContainer__2BFiV.SignUpExplanation_showMobileContainer__1R79e {
    display: block;
  }
  .SignUpExplanation_mobileContainer__2BFiV .SignUpExplanation_title__1gdiA {
    margin-top: 80px;
  }
}

.SocialSignUp_container__2keoI {
  text-align: center;
  width: 100%;
}
.SocialSignUp_title__15o5z {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  margin-bottom: 20px;
}
.SocialSignUp_buttonsContainer__5HnVg {
  margin-bottom: 20px;
}
.SocialSignUp_socialButton__TOVUv {
  border: 2px solid #e4e7ec;
  border-radius: 4px;
  max-width: 80%;
  margin: 4px auto;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  font-size: 14px;
}
.SocialSignUp_socialButton__TOVUv:hover * {
  opacity: 0.7;
}
.SocialSignUp_socialButton__TOVUv.SocialSignUp_loading__2bdCj {
  -webkit-animation: SocialSignUp_loading-animation__9ZbAq 1s ease infinite;
          animation: SocialSignUp_loading-animation__9ZbAq 1s ease infinite;
}
.SocialSignUp_socialButton__TOVUv .SocialSignUp_facebookIcon__3H92d {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  top: 4px;
  margin-right: 8px;
}
.SocialSignUp_socialButton__TOVUv .SocialSignUp_googleIcon__1CgJ7 {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  top: 4px;
  margin-right: 8px;
}
.SocialSignUp_socialButton__TOVUv .SocialSignUp_appleIcon__3UFNm {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 20px;
  top: 4px;
  margin-right: 8px;
}
.SocialSignUp_proceedWithEmail__vNyug {
  font-family: 'Roboto Regular';
  color: #6b7589;
  cursor: pointer;
}
.SocialSignUp_proceedWithEmail__vNyug:hover {
  opacity: 0.7;
}
.SocialSignUp_proceedWithEmail__vNyug .SocialSignUp_proceedWithEmailBold__24A1Z {
  font-family: 'Roboto Medium';
}
.SocialSignUp_error__3Puht {
  font-size: 13px;
  color: #ec008b;
  margin-bottom: 4px;
}

.SocialSignUp_backgroundBlur__2X3Yo {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: #fff;
  opacity: 0.5;
}
.SocialSignUp_popup__1Z513 {
  z-index: 99;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 40px;
  background-color: #fff;
  width: 520px;
  max-width: 90%;
  box-sizing: border-box;
  border: 1px solid #e4e7ec;
  border-radius: 10px;
  box-shadow: 0 15px 34px #d4d4d433, 0 61px 61px #d4d4d433,
    0 137px 82px #d4d4d433, 0 244px 98px #d4d4d403, 0 381px 107px #d4d4d400;
  font-size: 14px;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_closeIcon__3YAwT {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  padding: 5px;
  cursor: pointer;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_closeIcon__3YAwT:hover {
  opacity: 0.7;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_popupDescription__29waT {
  text-align: left;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_acceptInputContainer__3fkdr {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_acceptInputContainer__3fkdr .SocialSignUp_checkmark__jiiuA {
  width: 17px;
  min-width: 17px;
  height: 17px;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-width: 2px;
  border-style: solid;
  border-color: #7b7b7b;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_acceptInputContainer__3fkdr .SocialSignUp_checkmark__jiiuA.SocialSignUp_checkmarkActive__3NKJ5 {
  background-color: #6f2a7f;
  border-color: #6f2a7f;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_acceptInputContainer__3fkdr .SocialSignUp_tick__3Vkw9 {
  position: absolute;
  top: 2px;
  left: 1px;
  width: 13px;
  height: 11px;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_acceptInputContainer__3fkdr .SocialSignUp_termsText__2KAYH {
  text-align: left;
  font-size: 14px;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_acceptInputContainer__3fkdr .SocialSignUp_termsText__2KAYH .SocialSignUp_termsLink__MRufQ {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_confirmButton__2Sz6R {
  display: inline-block;
  background-color: #6f2a7f;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Montserrat SemiBold';
  margin-top: 40px;
  padding: 15px 30px;
}
.SocialSignUp_popup__1Z513 .SocialSignUp_confirmButton__2Sz6R.SocialSignUp_confirmButtonDisabled__w-y4P {
  background-color: #7b7b7b;
  cursor: auto;
}

@-webkit-keyframes SocialSignUp_loading-animation__9ZbAq {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes SocialSignUp_loading-animation__9ZbAq {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 992px) {
  .SocialSignUp_title__15o5z {
    display: none;
  }
  .SocialSignUp_buttonsContainer__5HnVg {
    margin-top: 24px;
  }
  .SocialSignUp_socialButton__TOVUv {
    border: 2px solid #7c7c7c;
    font-family: 'Roboto Medium';
  }
  .SocialSignUp_popup__1Z513 {
    padding: 20px;
  }
  .SocialSignUp_popup__1Z513 .SocialSignUp_closeIcon__3YAwT {
    top: 5px;
    right: 5px;
  }
}

.SelectDropdown_userDetailTitle__1sClw {
  font-family: 'Roboto Bold';
  font-size: 15px;
  padding-left: 10px;
  margin-bottom: 15px;
}
.SelectDropdown_error__2zKNF {
  margin-top: 4px;
  font-size: 13px;
  color: #fc035d;
  /*background-color: #fc035d0d;*/
  padding-left: 5px;
  border-radius: 2px;
  display: inline-block;
}
.SelectDropdown_dropdown__Ybn2c {
  background-repeat: no-repeat;
  background-position: right 15px top 13px;
  background-size: 12px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.EmailSignUp_form__3W75M {
  width: 100%;
  font-family: 'Roboto Regular';
  text-align: left;
}
.EmailSignUp_input__1k6Ep {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e4e7ec;
  height: 35px;
  padding: 0px 15px;
  outline: none;
  font-family: 'Roboto Regular';
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.EmailSignUp_dateInput__3xKN3 {
  display: block;
}
.EmailSignUp_label__3gXdV:first-child {
  margin-top: 0;
}
.EmailSignUp_label__3gXdV {
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
  font-size: 14px;
  font-family: 'Roboto Medium';
}
.EmailSignUp_button__f6H1H {
  display: inline-block;
  background-color: #6f2a7f;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Montserrat SemiBold';
  margin-top: 50px;
  padding: 15px 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.EmailSignUp_button__f6H1H.EmailSignUp_buttonDisabled__31JN3 {
  background-color: #7b7b7b;
  cursor: auto;
}
.EmailSignUp_button__f6H1H:hover {
  opacity: 0.7;
}
.EmailSignUp_button__f6H1H.EmailSignUp_buttonDisabled__31JN3:hover {
  opacity: 1;
}
.EmailSignUp_acceptInputContainer__1denQ {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.EmailSignUp_acceptInputContainer__1denQ .EmailSignUp_checkmark__a7_TR {
  width: 17px;
  height: 17px;
  min-width: 17px;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-width: 2px;
  border-style: solid;
  border-color: #7b7b7b;
}
.EmailSignUp_acceptInputContainer__1denQ .EmailSignUp_checkmark__a7_TR.EmailSignUp_checkmarkActive__2OwCR {
  background-color: #6f2a7f;
  border-color: #6f2a7f;
}
.EmailSignUp_acceptInputContainer__1denQ .EmailSignUp_tick__1f6g_ {
  position: absolute;
  top: 2px;
  left: 1px;
  width: 13px;
  height: 11px;
}
.EmailSignUp_acceptInputContainer__1denQ .EmailSignUp_termsText__2s2ft {
  text-align: left;
  font-size: 14px;
}
.EmailSignUp_acceptInputContainer__1denQ .EmailSignUp_termsText__2s2ft .EmailSignUp_termsLink__22bFQ {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
}
.EmailSignUp_error__1TRzu {
  margin-top: 4px;
  font-size: 13px;
  color: #ec008b;
  padding-left: 5px;
  border-radius: 2px;
  display: inline-block;
}
.EmailSignUp_componentError__1Itt1 {
  margin: 5px 0;
  font-size: 13px;
  color: #ec008b;
  background-color: rgba(236, 0, 139, 0.05);
  border-radius: 2px;
  display: inline-block;
  padding: 1px 5px;
}

.Completed_container__3rpEr {
  text-align: center;
  width: 100%;
}
.Completed_title__1Krio {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  margin-bottom: 20px;
}
.Completed_text__20jtP {
  font-family: 'Roboto Regular';
  color: #6b7589;
}
.Completed_textMobile__33l3r {
  display: none;
}
.Completed_qrCode__2p64s {
  width: 130px;
  margin: 20px auto 0 auto;
  padding-bottom: 30px;
}
.Completed_mobileAppsContainer__3_Wed {
  margin: 20px 0;
  z-index: 2;
  display: none;
}
.Completed_mobileAppsContainer__3_Wed a {
  display: inline-block;
  margin-bottom: 4px;
}
.Completed_mobileAppsContainer__3_Wed img {
  height: 50px;
  border-radius: 6px;
  position: relative;
}
.Completed_mobileAppsContainer__3_Wed img:hover {
  z-index: 99;
}

@media only screen and (max-width: 992px) {
  .Completed_title__1Krio {
    margin-top: 80px;
  }
  .Completed_textDesktop__3C9QY {
    display: none;
  }
  .Completed_textMobile__33l3r {
    display: block;
  }
  .Completed_qrCode__2p64s {
    display: none;
  }
  .Completed_mobileAppsContainer__3_Wed {
    display: block;
  }
}

.SignUpForm_wrapper__16FVy {
  margin-left: auto;
  width: 100%;
  max-width: 525px;
  box-sizing: border-box;
  border: 1px solid #e4e7ec;
  border-radius: 10px;
  box-shadow: 0 15px 34px #d4d4d433, 0 61px 61px #d4d4d433,
    0 137px 82px #d4d4d433, 0 244px 98px #d4d4d403, 0 381px 107px #d4d4d400;
}
.SignUpForm_contentContainer__1Ui4s {
  padding: 70px;
  background-color: #fff;
  min-height: 700px;
  box-sizing: border-box;
  border-radius: 10px;
}
.SignUpForm_centralizeContent__2GA8w {
  display: flex;
  align-items: center;
}
.SignUpForm_gridLeft__1B8cX {
  z-index: 0;
  position: absolute;
  top: 0;
  left: -19%;
}
.SignUpForm_gridRight__1lKfL {
  position: absolute;
  bottom: 0;
  right: -10%;
}
.SignUpForm_contact-us-grid-left__325Z5 {
  z-index: 0;
  position: absolute;
  top: 0;
  left: -15%;
}
.SignUpForm_w-embed__31Oop:before,
.SignUpForm_w-embed__31Oop:after {
  content: ' ';
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}
svg:not(:root) {
  overflow: hidden;
}
.SignUpForm_w-embed__31Oop:after {
  clear: both;
}
.SignUpForm_contact-us-grid-right__3sJkM {
  position: absolute;
  bottom: 0%;
  right: -8%;
}

.SignUpForm_backButton__1Di6q {
  font-size: 30px;
  color: #cdcdcd;
  position: absolute;
  top: 8px;
  left: 12px;
  cursor: pointer;
  padding: 0 10px;
}
.SignUpForm_backButton__1Di6q:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 1200px) {
  .SignUpForm_gridLeft__1B8cX {
    z-index: 0;
    position: absolute;
    top: 0;
    left: -15%;
  }
  .SignUpForm_gridRight__1lKfL {
    position: absolute;
    bottom: 0;
    right: -8%;
  }
}

@media only screen and (max-width: 992px) {
  .SignUpForm_wrapper__16FVy {
    margin-left: 0;
    border: none;
    box-shadow: none;
    padding: 0;
    max-width: 100%;
  }
  .SignUpForm_contentContainer__1Ui4s {
    padding: 0;
    min-height: auto;
    border: none;
  }
  .SignUpForm_gridLeft__1B8cX,
  .SignUpForm_gridRight__1lKfL {
    display: none;
  }
  .SignUpForm_backButton__1Di6q {
    position: relative;
    display: block;
    text-align: left;
    padding: 0;
    margin-bottom: 10px;
    left: 0;
    top: 0;
  }
}

.Footer_wrapper__12XHY {
  background-color: #161b26;
}
.Footer_container__2ugEC {
  margin: 0 auto;
  width: 100%;
  max-width: 1190px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 60px;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.Footer_link__1alL8 {
  font-family: 'Roboto Regular';
  color: #98a2b3;
  text-decoration: none;
  margin-right: 60px;
  transition: all 0.1s ease-in-out;
}
.Footer_link__1alL8:hover {
  color: #fff;
}

@media only screen and (max-width: 992px) {
  .Footer_link__1alL8 {
    display: block;
    margin-right: 0;
    margin-bottom: 12px;
  }
}

.RegistrationPage_registrationWrapper__1adx- {
  width: 100%;
  background-color: #fff;
  color: #000;
  min-height: 100vh;
  font-family: 'Roboto Light';
}

.RegistrationPage_contentContainer__2ap1W {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1190px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 200px;
  box-sizing: border-box;
}
.RegistrationPage_left__LVfDS {
  width: 43%;
}
.RegistrationPage_right__gsb7f {
  width: 57%;
}

@media only screen and (max-width: 992px) {
  .RegistrationPage_contentContainer__2ap1W {
    display: block;
    text-align: center;
    padding-bottom: 160px;
  }
  .RegistrationPage_left__LVfDS,
  .RegistrationPage_right__gsb7f {
    width: 100%;
  }
}

.right-container {
  width: 100%;
  height: 100vh;
  display: flex;
}
.right-container .welcome-container {
  width: 50%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-container .welcome-container .welcome-container-content {
  width: 80%;
  max-height: 700px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right-container
  .welcome-container
  .welcome-container-content
  .welcome-message {
  font-family: 'Roboto Medium';
  font-size: 60px;
  margin-bottom: 30px;
  color: #ffffff;
}
.right-container
  .welcome-container
  .welcome-container-content
  .welcome-message-description {
  margin-bottom: 20px;
  color: #ffffff;
}
.right-container
  .welcome-container
  .welcome-container-content
  .mobile-apps-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 450px;
  margin: auto;
}
.right-container
  .welcome-container
  .welcome-container-content
  .mobile-apps-container
  a {
}
.right-container
  .welcome-container
  .welcome-container-content
  .mobile-apps-container
  img {
  width: 200px;
  height: 60px;
}
.right-container .form-container {
  width: 50%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-container .form-container .form-container-title {
  font-family: 'Roboto Bold';
  font-size: 30px;
  margin-bottom: 10px;
  text-align: center;
}
.right-container .form-container .buffl-logo {
  position: relative;
  width: 200px;
  left: 50%;
  margin-bottom: 40px;
  margin-left: -100px;
  display: block;
}
.right-container .form-container form {
  position: relative;
  width: 560px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.right-container .form-container input,
.right-container .form-container textarea,
.right-container .form-container select {
  font-family: 'Open Sans Regular';
  background-color: #ffffff;
  display: block;
  width: 500px;
  height: 90px;
  line-height: 90px;
  color: #221f1f;
  font-size: 18px;
  padding-left: 30px;
  padding-right: 30px;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: content-box;
}
.right-container .form-container textarea {
  line-height: 1.5em;
  padding-top: 30px;
  padding-bottom: 30px;
  height: 32px;
}
.right-container .form-container input:focus,
.right-container .form-container textarea:focus {
  box-shadow: 0 0 5px #5200f1;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  outline: none;
}

.right-container .form-container .error-message {
  background: #f5a0a1;
  color: #b03223;
  border: 1px solid #b03223;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.right-container .form-container .success-message {
  background: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.right-container .form-container .button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  border: 0;
  background-color: #5200f1;
  color: #ffffff;
  cursor: pointer;
}
.right-container .form-container .button-loading {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
}
.right-container .form-container .button-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: button-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: button-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.right-container .form-container .button-loading div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.button-loading div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.button-loading div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes button-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes button-loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.right-container .form-container .create-account a:link,
.right-container .form-container .create-account a:visited {
  text-align: center;
  color: #5200f1;
  text-decoration: none;
}
.right-container .form-container .create-account a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1200px) {
  .right-container .form-container input,
  .right-container .form-container textarea,
  .right-container .form-container select {
    width: 30vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 768px) {
  .right-container .welcome-container {
    display: none;
  }
  .right-container .form-container {
    width: 100%;
  }
  .right-container .form-container form {
    width: 100%;
  }
  .right-container .form-container input,
  .right-container .form-container textarea,
  .right-container .form-container select {
    width: 60vw;
  }
}

/* Hide the browser's default checkbox */
.right-container input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.right-container .checkbox-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 26px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Create a custom checkbox */
.right-container .checkbox-custom {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.right-container .checkbox-label:hover input ~ .checkbox-custom {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.right-container .checkbox-label input:checked ~ .checkbox-custom {
  background-color: #5200f1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.right-container .checkbox-custom:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.right-container .checkbox-label input:checked ~ .checkbox-custom:after {
  display: block;
}

/* Style the checkmark/indicator */
.right-container .checkbox-label .checkbox-custom:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.CampaignsPage_parentContainer__gqXDZ {
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  overflow: hidden;
}
.CampaignsPage_mainContainer__2COnK {
  flex: 1 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  font-family: 'Roboto Light';
}
.CampaignsPage_title__1-7V5 {
  font-family: 'Montserrat Bold';
  font-size: 30px;
  color: #000;
  padding-top: 40px;
  padding-bottom: 20px;
}
.CampaignsPage_campaignName__2_Kqn {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
  white-space: pre-line;
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  text-align: center;
  display: block;
}
.CampaignsPage_campaignImage__35QAv {
  display: block;
  margin: 0 auto;
  max-width: 400px;
  max-height: 340px;
}
.CampaignsPage_campaignDescriptionContainer__1TSt8 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  font-family: 'Roboto Light';
  text-align: center;
}
.CampaignsPage_campaignDescriptionFlex__1B1Do {
  flex: 3 1;
  text-align: center;
  font-family: 'Roboto Light';
  text-align: center;
}
.CampaignsPage_campaignDescriptionStatic__1sUfq {
  width: auto;
  max-width: 470px;
  margin: 0 auto;
  font-family: 'Roboto Light';
  text-align: center;
}
.CampaignsPage_campaignDescriptionTranslation__2yYCo {
  font-size: inherit;
  width: 470px;
  max-width: 100%;
  text-align: center;
  display: inline-block;
  font-family: 'Roboto Light';
  text-align: center;
}
.CampaignsPage_languagesSelectorContainer__4g-Iw {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.CampaignsPage_languageSelector__2VKk5 {
  width: 130px;
  opacity: 0.3;
  cursor: pointer;
}
.CampaignsPage_languageSelector__2VKk5.CampaignsPage_selectedLanguage__3e_Ms {
  opacity: 1;
}
.CampaignsPage_languageSelector__2VKk5,
.CampaignsPage_startSelector__14zCw {
  margin-left: 20px;
  margin-right: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  width: 220px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
}
.CampaignsPage_languageSelector__2VKk5 .CampaignsPage_languageSelectorMobileContent__s_Svz {
  display: none;
}
.CampaignsPage_startSelector__14zCw {
  padding: 0 45px;
  box-sizing: border-box;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
}
.CampaignsPage_readyLaunchTitle__bUXGO {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  padding-top: 40px;
  padding-bottom: 20px;
  white-space: pre-line;
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.CampaignsPage_readyLaunchDescription__3fFts {
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  font-size: inherit;
  text-align: center;
  display: block;
  font-family: 'Roboto Light';
  text-align: center;
}
.CampaignsPage_textAreaTranslation__QnzDG {
  white-space: pre-line;
}
.CampaignsPage_acceptInputContainer__IESWB {
  margin-top: 40px;
}
.CampaignsPage_notAvailableContentContainer__2kckE {
  margin-right: auto;
  margin-left: auto;
  width: 1100px;
  max-width: 90%;
  text-align: center;
}
.CampaignsPage_notAvailableTitle__1i79P {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  width: 600px;
  max-width: 100%;
  margin-bottom: 40px;
  text-align: center;
}
.CampaignsPage_notAvailableDescription__GM3OO {
  width: 600px;
  max-width: 100%;
  font-size: inherit;
  display: block;
  margin: 20px auto 40px auto;
  font-family: 'Roboto Light';
  text-align: center;
}
.CampaignsPage_downloadAppMessage__2b9WW {
  font-size: 18px;
  margin-bottom: 10px;
}
.CampaignsPage_qrCode__2TIJS {
  width: 130px;
}
.CampaignsPage_appStoreButtons__2_sx9 {
  display: none;
}
.CampaignsPage_appStoreButtons__2_sx9 a {
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  height: 45px;
  padding: 2px;
}
.CampaignsPage_appStoreButtons__2_sx9 img {
  height: 45px;
  border-radius: 4px;
}

@media only screen and (max-width: 900px) {
  .CampaignsPage_campaignName__2_Kqn {
    width: 90%;
  }
  .CampaignsPage_campaignDescriptionContainer__1TSt8 {
    width: 90%;
    display: block;
  }
  .CampaignsPage_readyLaunchTitle__bUXGO,
  .CampaignsPage_readyLaunchDescription__3fFts {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .CampaignsPage_notAvailableContentContainer__2kckE {
    margin-top: 60px;
  }
  .CampaignsPage_notAvailableTitle__1i79P {
    font-size: 28px;
  }
  .CampaignsPage_qrCode__2TIJS {
    display: none;
  }
  .CampaignsPage_appStoreButtons__2_sx9 {
    display: block;
  }
  .CampaignsPage_appStoreButtons__2_sx9 a {
    height: 35px;
  }
  .CampaignsPage_appStoreButtons__2_sx9 img {
    height: 35px;
  }
}
@media only screen and (max-width: 768px) {
  .CampaignsPage_parentContainer__gqXDZ {
    min-height: calc(100vh - 70px);
  }
  .CampaignsPage_campaignDescriptionFlex__1B1Do {
    padding: 0 20px;
  }
  .CampaignsPage_campaignName__2_Kqn {
    font-size: 28px;
  }
  .CampaignsPage_campaignImage__35QAv {
    max-width: 100%;
  }
  .CampaignsPage_languagesSelectorContainer__4g-Iw {
    display: block;
  }
  .CampaignsPage_languageSelector__2VKk5,
  .CampaignsPage_startSelector__14zCw {
    height: 54px;
    line-height: 54px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  .CampaignsPage_languageSelector__2VKk5 .CampaignsPage_languageSelectorDesktopContent__DUR_e {
    display: none;
  }
  .CampaignsPage_languageSelector__2VKk5 .CampaignsPage_languageSelectorMobileContent__s_Svz {
    display: block;
  }
  .CampaignsPage_startSelector__14zCw {
    padding: 0;
  }
  .CampaignsPage_acceptInputContainer__IESWB {
    margin-bottom: 0;
  }
}

.Footer_container__3UN14 {
  overflow: hidden;
}
.Footer_bottomButtonWrapper__3n5ui {
  margin-top: 60px;
}
.Footer_bottomButtonContainer__yMgXc {
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
  clear: both;
  width: 800px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.Footer_bottomButtonContainer__yMgXc .Footer_leftButtonContainer__1mZA1 {
  min-width: 160px;
}
.Footer_bottomButtonContainer__yMgXc .Footer_rightButtonContainer__aSEDD {
  min-width: 160px;
}
.Footer_button__361eg {
  font-family: 'Montserrat Bold';
  cursor: pointer;
  text-transform: uppercase;
  width: 220px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
}
.Footer_buttonDisabled__wxfUQ {
  cursor: auto;
  opacity: 0.3;
}
.Footer_backgroundGrid__lWxDn {
  display: block;
  width: 100%;
  position: relative;
  z-index: 2;
}
.Footer_bottomBarContainer__YUCtv {
  width: 100%;
  height: 25px;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 1024px) {
  .Footer_container__3UN14.Footer_fixedContainer__1ZnDW {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: visible;
  }
}

@media only screen and (max-width: 900px) {
  .Footer_container__3UN14.Footer_fixedContainer__1ZnDW {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: visible;
  }
}

@media only screen and (max-width: 768px) {
  .Footer_bottomButtonWrapper__3n5ui {
    padding-bottom: 25px;
  }
  .Footer_bottomButtonWrapper__3n5ui.Footer_noMobileTopMargin__2UZo4 {
    margin-top: 0;
  }
  .Footer_bottomButtonContainer__yMgXc {
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: 40px;
    justify-content: center;
    display: flex;
    width: auto;
  }
  .Footer_bottomButtonContainer__yMgXc .Footer_leftButtonContainer__1mZA1,
  .Footer_bottomButtonContainer__yMgXc .Footer_rightButtonContainer__aSEDD {
    box-sizing: border-box;
    margin-top: 35px;
  }
  .Footer_bottomButtonContainer__yMgXc .Footer_leftButtonContainer__1mZA1 {
    margin-right: 5px;
    padding-left: 10px;
  }
  .Footer_bottomButtonContainer__yMgXc .Footer_rightButtonContainer__aSEDD {
    margin-left: 5px;
    padding-right: 10px;
  }
  .Footer_button__361eg {
    width: 150px;
    height: 48px;
    line-height: 48px;
  }
}

.UserDetails_parentContainer__MgNds {
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
}
.UserDetails_mainContainer__3xiH8 {
  flex: 1 1;
  width: 100%;
  overflow: hidden;
}
.UserDetails_container__3e7dW {
  width: 900px;
  max-width: 90%;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  box-sizing: border-box;
}
.UserDetails_campaignName__25y13 {
  font-family: 'Montserrat Bold';
  font-size: 20px;
  color: #221f1f;
  padding-top: 40px;
  padding-bottom: 10px;
}
.UserDetails_description__13Ply {
  font-family: 'Roboto Light';
  margin-bottom: 40px;
  width: 540px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  font-size: inherit;
}
.UserDetails_textAreaTranslation__2Sg3- {
  white-space: pre-line;
}
.UserDetails_title__hcusy {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #221f1f;
  margin-bottom: 20px;
  white-space: pre-line;
  width: 540px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}
.UserDetails_title__hcusy.UserDetails_termsTitle__35sFi {
  width: 580px;
}
.UserDetails_userDetailTitle__1Un5D {
  font-family: 'Roboto Light';
  font-size: 15px;
  margin-bottom: 15px;
}
.UserDetails_text__2Yb5L {
  font-family: 'Roboto Light';
}
.UserDetails_text__2Yb5L:last-of-type {
  margin-bottom: 0;
}
.UserDetails_textDescription__2IDEo {
  margin-bottom: 10px;
  font-size: 14px;
}

.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 .UserDetails_formContainerSection__vtXt4 {
  display: flex;
  justify-content: center;
}
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 input,
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 select {
  font-family: 'Roboto Light';
  display: inline-block;
  width: 150px;
  color: #221f1f;
  font-size: 16px !important;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #707070;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  height: 50px;
  line-height: 50px;
  border-radius: 30px;

  background-repeat: no-repeat;
  background-size: 15px 9px;
  background-position: 86% 50%;
}
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 input.UserDetails_errorField__OGEJL,
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 select.UserDetails_errorField__OGEJL {
  border-color: #ec008b;
}
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 input::-webkit-input-placeholder {
  color: #8e8e8e;
}
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 input::-ms-input-placeholder {
  color: #8e8e8e;
}
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 input::placeholder {
  color: #8e8e8e;
}
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 select.UserDetails_placeholder__2u6ar {
  color: #8e8e8e;
}
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 input:focus,
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 select:focus {
  background-color: #ffffff;
  outline: none;
}
.UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 .UserDetails_createUserProfile__2Q0Dx {
  width: 100%;
  height: 50px;
  font-size: 13px;
  line-height: 50px;
  background-color: #efefef;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;
}
.UserDetails_dontWantProfile__IWBAy {
  font-family: 'Roboto Light';
  color: red;
  margin-bottom: 40px;
  font-size: 13px;
  padding-left: 10px;
  cursor: pointer;
}

.UserDetails_userDetailsError__5OF2v {
  background: #ec008b;
  color: #ec008b;
  border: 1px solid #ec008b;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.UserDetails_languagesSelectorContainer__3V_Di {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}
.UserDetails_languagesSelectorContainer__3V_Di .UserDetails_terms-language-selector__2LCLE {
  margin-right: 20px;
}

.UserDetails_readFullDocumentsContainer__29cRU div {
  color: blue;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 7px;
}
.UserDetails_readFullDocumentsContainer__29cRU div:hover {
  text-decoration: underline;
}

.UserDetails_termsContentImage__3cKLx {
  display: block;
  width: 400px;
  max-width: 90%;
  margin: 16px auto 32px;
}

.UserDetails_textAlignCenter__3D_dO {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

.UserDetails_restartSurveyButtonContainer__3LZho {
  margin-top: 50px;
  margin-bottom: 100px;
  text-align: right;
}

.UserDetails_genderSelectorContainer__1Rjdl {
  display: inline-block;
  flex-direction: column;
  text-align: left;
  margin-right: 10px;
  margin-bottom: 15px;
}
.UserDetails_ageSelectorContainer__1fOqg {
  display: inline-block;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 15px;
}
.UserDetails_postalSelectorContainer__2XqtN {
  margin-bottom: 0px;
}
.UserDetails_postalSelectorContainer__2XqtN .UserDetails_userDetailTitle__1Un5D {
  margin-bottom: 0;
}
.UserDetails_errorContainer__QOs4V {
  font-family: 'Roboto Light';
  font-size: 16px;
  color: #ec008b;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.UserDetails_errorContainer__QOs4V .UserDetails_errorIcon__2bl5h {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.UserDetails_termsContentContainer__1L0rM {
  display: flex;
}

.UserDetails_isThatYouTopContainer__ixU6k {
  display: flex;
  margin-top: 40px;
  margin-bottom: 120px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto Light';
}
.UserDetails_isThatYouTopContainerTitle__1ljX9 {
  font-weight: bold;
}
.UserDetails_isThatYouContainer__1v9lT {
  margin-top: 30px;
  width: 500px;
}
.UserDetails_isThatYouOptionSelector__2EMWs {
  height: 60px;
  line-height: 60px;
  border-radius: 38px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  text-align: center;
  font-family: 'Roboto Light';
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-bottom: 10px;
}

@media only screen and (max-width: 900px) {
  .UserDetails_container__3e7dW {
    margin-top: 60px;
  }
  .UserDetails_termsContentImage__3cKLx {
    width: 80%;
  }
  .UserDetails_termsContentContainer__1L0rM {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .UserDetails_parentContainer__MgNds {
    min-height: calc(100vh - 70px);
  }
  .UserDetails_title__hcusy {
    font-size: 28px;
  }
  .UserDetails_description__13Ply {
    font-size: 14px;
  }
  .UserDetails_userDetailTitle__1Un5D {
    font-size: 14px;
  }
  .UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 input,
  .UserDetails_userDetailsPage__3YG7H .UserDetails_formContainer__3-FL2 select {
    width: 130px;
    height: 44px;
    line-height: 44px;
    background-size: 13px 7px;
  }
  .UserDetails_errorContainer__QOs4V {
    font-size: 14px;
  }
  .UserDetails_isThatYouContainer__1v9lT {
    width: 93vw;
    margin-bottom: 30px;
    display: block;
  }
}

.TranslationInputField_translationInput__2rKPd {
  border: none;
  outline: none;
  white-space: pre-line;
  resize: none;
  overflow: hidden;
  color: #000;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 4px;
}
.TranslationInputField_translationInput__2rKPd:focus,
.TranslationInputField_translationInput__2rKPd:active {
  outline: none;
}
.TranslationInputField_translationInput__2rKPd:disabled {
  cursor: text;
  background-color: rgba(255, 255, 255, 0);
  border-color: rgba(255, 255, 255, 0);
}
.TranslationInputField_translationInput__2rKPd.TranslationInputField_enabled__2oOXV:hover {
  border-color: #dfe0e4;
}
.TranslationInputField_textAreaTranslation__1QikD {
  white-space: pre-line;
}

.loading-animation {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.loading-animation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loading-animation div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.loading-animation div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.loading-animation div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loader-fixed-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ExternalTermsInput_container__1jW4g {
  width: 520px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto Light';
}
.ExternalTermsInput_termsLink__2lIZ1 {
  text-decoration: underline;
  cursor: pointer;
}
.ExternalTermsInput_buttonWrapper__25jab {
  margin-top: 25px;
}
.ExternalTermsInput_button__3y9mm {
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
  padding: 0 45px;
  box-sizing: border-box;
  cursor: pointer;
  color: #ffffff;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .ExternalTermsInput_button__3y9mm {
    padding: 0 30px;
    font-size: 14px;
  }
}

.AcceptTermsInput_acceptInputContainer__-fDId {
  width: 520px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto Light';
  text-align: left;
  display: flex;
}
.AcceptTermsInput_acceptInputContainer__-fDId .AcceptTermsInput_checkmark__2FXPK {
  top: 4px;
  width: 17px;
  height: 17px;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 2px;
  cursor: pointer;
  flex-shrink: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-width: 2px;
  border-style: solid;
}
.AcceptTermsInput_tick__Os5PT {
  position: absolute;
  top: 2px;
  left: 1px;
  width: 13px;
  height: 11px;
}
.AcceptTermsInput_acceptInputContainerActive__1w_Ab span {
  width: 10px;
  height: 10px;
}
.AcceptTermsInput_acceptInputContainer__-fDId .AcceptTermsInput_termsText__2vAFe {
  text-align: left;
}
.AcceptTermsInput_acceptInputContainer__-fDId .AcceptTermsInput_termsText__2vAFe .AcceptTermsInput_termsLink__rmapT {
  text-decoration: underline;
  cursor: pointer;
}
.AcceptTermsInput_acceptInputContainer__-fDId .AcceptTermsInput_desktopInput__2WJmk {
  display: flex;
}

@media only screen and (max-width: 768px) {
  .AcceptTermsInput_acceptInputContainer__-fDId {
    flex-direction: column;
    text-align: center;
  }
  .AcceptTermsInput_acceptInputContainer__-fDId .AcceptTermsInput_termsText__2vAFe {
    margin-bottom: 20px;
  }
}

.CampaignQuestionsPage_parentContainer__1iw4o {
  display: flex;
  min-height: calc(100vh - 150px); /* Appbar is 150px in height */
  flex-direction: column;
  font-family: 'Roboto Light';
}
.CampaignQuestionsPage_mainContainer__2KKnQ {
  flex: 1 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.CampaignQuestionsPage_campaignName__10R7X {
  font-family: 'Montserrat Bold';
  font-size: 20px;
  color: #221f1f;
  padding-top: 40px;
  padding-bottom: 10px;
}
.CampaignQuestionsPage_campaignQuestion__3B3mR {
  font-family: 'Roboto Light';
  font-size: 16px;
  color: #221f1f;
  padding-top: 0px;
  padding-bottom: 40px;
  white-space: pre-line;
  width: 800px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
}
.CampaignQuestionsPage_campaignDescription__2H8Qh {
  padding-top: 0px;
  padding-bottom: 20px;
  white-space: pre-line;
}
.CampaignQuestionsPage_campaignImage__1H2ze {
  background-color: grey;
  height: 400px;
}
.CampaignQuestionsPage_questionImage__1K4G7 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}
.CampaignQuestionsPage_questionImage__1K4G7 img {
  max-width: 100%;
  max-height: 400px;
}

.CampaignQuestionsPage_progressBarContainer__306nb {
  height: 10px;
  border-radius: 10px;
  background-color: #dadada;
  margin-bottom: 30px;
}
.CampaignQuestionsPage_progressBarContainer__306nb .CampaignQuestionsPage_progressBar__26fIe {
  height: 10px;
  border-radius: 10px;
  background-color: #687df0;
}

.CampaignQuestionsPage_questionContainer__2IaJZ,
.CampaignQuestionsPage_inputFieldContainer__2opXi {
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.CampaignQuestionsPage_sliderContainer__2zDja {
  text-align: center;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_answer__oI4hB {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  background-color: #e9f7fc;
  background-color: var(--mc-question-answer-background-color, #e9f7fc);
  border-radius: 27px;
  padding: 17px 22px;
  cursor: pointer;
  display: inline-flex;
  white-space: pre-line;
  width: 480px;
  box-sizing: border-box;
  transition: background-color 0.1s ease-in-out;
  text-align: left;
}
.CampaignQuestionsPage_mcQuestionContainer__2Qe_l .CampaignQuestionsPage_answer__oI4hB {
  margin-bottom: 0;
}
.CampaignQuestionsPage_questionContainer__2IaJZ.CampaignQuestionsPage_sliderContainer__2zDja .CampaignQuestionsPage_answer__oI4hB.CampaignQuestionsPage_sliderAnswer__25Zi9 {
  display: inline-block;
  width: auto;
  padding: 17px 34px;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswer__pnxOI {
  width: 280px;
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  padding: 25px 27px;
  background-color: #e9f7fc;
  background-color: var(--mc-question-answer-background-color, #e9f7fc);
  transition: background-color 0.1s ease-in-out;
  margin-bottom: 20px;
  display: grid;
  grid-template-rows: 1fr auto;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.CampaignQuestionsPage_imageAnswerContent__3SUOv {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswerText__2-bGz {
  font-family: 'Roboto Light';
  font-size: 14px;
  padding-top: 25px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswer__pnxOI img {
  max-width: 100%;
  max-height: 300px;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_answer__oI4hB.CampaignQuestionsPage_active__2bv0t {
  background-color: #a7e0f5;
  background-color: var(--mc-question-answer-background-color-active, #a7e0f5);
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswer__pnxOI.CampaignQuestionsPage_active__2bv0t {
  background-color: #24b2e8
  ;
  background-color: var(
    --mc-question-image-answer-background-color-active,
    #24b2e8
  );
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswer__pnxOI.CampaignQuestionsPage_active__2bv0t:hover {
  background-color: #24b2e8
  ;
  background-color: var(
    --mc-question-image-answer-background-color-active,
    #24b2e8
  );
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswer__pnxOI .CampaignQuestionsPage_zoomInIconContainer__3TiRp,
.CampaignQuestionsPage_questionImage__1K4G7 .CampaignQuestionsPage_zoomInIconContainer__3TiRp {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 57px;
  height: 57px;
  background-color: #fff;
  border-radius: 100%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  display: none;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswer__pnxOI .CampaignQuestionsPage_zoomInIconContainer__3TiRp .CampaignQuestionsPage_zoomInIcon__2hWbb,
.CampaignQuestionsPage_questionImage__1K4G7 .CampaignQuestionsPage_zoomInIconContainer__3TiRp .CampaignQuestionsPage_zoomInIcon__2hWbb {
  position: relative;
  display: block;
  left: 14px;
  top: 14px;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswer__pnxOI .CampaignQuestionsPage_zoomInIconContainer__3TiRp.CampaignQuestionsPage_visible__ptgRB,
.CampaignQuestionsPage_questionImage__1K4G7 .CampaignQuestionsPage_zoomInIconContainer__3TiRp.CampaignQuestionsPage_visible__ptgRB {
  display: block;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_answer__oI4hB .CampaignQuestionsPage_questionCounter__2ebfD {
  left: -4px;
  top: 0;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageQuestionCounter___QgnU {
  left: -8px;
  top: -3px;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_answer__oI4hB .CampaignQuestionsPage_questionCounter__2ebfD,
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageQuestionCounter___QgnU {
  border-radius: 50%;
  text-align: center;
  position: absolute;
  width: 22px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  font-family: 'Roboto Light';
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_answer__oI4hB .CampaignQuestionsPage_questionText__1jxf5 {
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_inputField__1n-8x {
  display: block;
  font-size: 16px !important;
  height: 200px;
  font-family: 'Roboto Light';
  background: #fafafa;
  border-radius: 27px;
  padding: 17px 22px;
  color: #000000;
  border: none;
  outline: none;
  margin: 0 auto;
  box-sizing: border-box;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_inputField__1n-8x.CampaignQuestionsPage_openAnswerInputField__3YsOQ {
  width: 700px;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_inputField__1n-8x.CampaignQuestionsPage_otherAnswerInputField__35b26 {
  width: 480px;
}
.CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_inputFieldDescription__3tY8P {
  width: 700px;
  margin: 10px auto 0 auto;
  font-size: 14px;
  text-align: right;
  font-family: 'Roboto Light';
}
.CampaignQuestionsPage_iframeContainer__1pR5X {
  width: 95%;
  min-height: 600px;
  height: auto;
  border: none;
  display: block;
  margin: 0 auto;
  border: 1px solid #eeeeee;
}

.CampaignQuestionsPage_imageContainer__3dqud {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CampaignQuestionsPage_imageContainer__3dqud img {
  max-width: 100%;
  max-height: 400px;
}
.CampaignQuestionsPage_loadingQuestionImageFailed__2Bitj {
  width: 430px;
  max-width: 85%;
  height: 245px;
  font-family: 'Roboto Light';
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.CampaignQuestionsPage_loadingQuestionImageFailed__2Bitj .CampaignQuestionsPage_imageNotFoundIcon__HnofS {
  margin-bottom: 10px;
}

.CampaignQuestionsPage_sliderOrContainer__3RYMU {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Roboto Light';
  font-size: 20px;
  margin-bottom: 30px;
}
.CampaignQuestionsPage_sliderOrContainerDivider__3EuaJ {
  width: 125px;
  height: 1px;
  background-color: grey;
}
.CampaignQuestionsPage_sliderOrContainer__3RYMU span {
  margin: 0 8px;
}

.CampaignQuestionsPage_videoPlayerContainer__QRYyP {
  margin-top: 20px;
  padding-bottom: 40px;
  max-width: 85vw;
  max-height: 80vh;
}

.CampaignQuestionsPage_mcQuestionContainer__2Qe_l {
  text-align: center;
}
.CampaignQuestionsPage_mcQuestionContainer__2Qe_l .CampaignQuestionsPage_amountOfAnswerOptionsContainer__16zFK {
  font-family: 'Roboto Light';
  margin-bottom: 10px;
  color: #808080;
}
.CampaignQuestionsPage_mcQuestionAnswers__DhA7P {
  -webkit-column-count: 3;
          column-count: 3;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}
.CampaignQuestionsPage_mcQuestionContainer__2Qe_l .CampaignQuestionsPage_mcAnswerContainer__1Q1r9 {
  -webkit-column-span: all;
          column-span: all;
  display: block;
  margin: 10px 0;
}
.CampaignQuestionsPage_mcQuestionAnswers__DhA7P .CampaignQuestionsPage_inputField__1n-8x {
  -webkit-column-span: all;
          column-span: all;
  display: block;
  margin-top: 5px;
}

@media only screen and (min-width: 900px) {
  /* Hover states only apply on desktop devices */
  .CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_answer__oI4hB:hover {
    background-color: #a7e0f5
    ;
    background-color: var(
      --mc-question-answer-background-color-active,
      #a7e0f5
    );
  }
  .CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswer__pnxOI:hover {
    background-color: #a7e0f5
    ;
    background-color: var(
      --mc-question-image-answer-background-color-hover,
      #a7e0f5
    );
  }
}

@media only screen and (max-width: 900px) {
  .CampaignQuestionsPage_mainContainer__2KKnQ {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .CampaignQuestionsPage_questionRootContainer__2r9MQ {
    width: 90%;
  }
  .CampaignQuestionsPage_questionContainer__2IaJZ {
    width: 90%;
  }
  .CampaignQuestionsPage_campaignQuestion__3B3mR {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_imageAnswer__pnxOI {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
  }
  .CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_inputField__1n-8x.CampaignQuestionsPage_openAnswerInputField__3YsOQ,
  .CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_inputField__1n-8x.CampaignQuestionsPage_otherAnswerInputField__35b26 {
    width: 100%;
    box-sizing: border-box;
  }
  .CampaignQuestionsPage_questionContainer__2IaJZ .CampaignQuestionsPage_inputFieldDescription__3tY8P {
    width: 100%;
    font-size: 10px;
  }
  .CampaignQuestionsPage_mcQuestionContainer__2Qe_l .CampaignQuestionsPage_answer__oI4hB {
    width: 100%;
  }
  .CampaignQuestionsPage_mcQuestionContainer__2Qe_l .CampaignQuestionsPage_inputFieldContainer__2opXi {
    width: 100%;
  }
  .CampaignQuestionsPage_mcQuestionContainer__2Qe_l .CampaignQuestionsPage_inputFieldContainer__2opXi .CampaignQuestionsPage_inputField__1n-8x {
    width: 100%;
    box-sizing: border-box;
  }
  .CampaignQuestionsPage_mcQuestionAnswers__DhA7P {
    display: flex;
    flex-flow: column wrap;
    -webkit-column-count: auto;
            column-count: auto;
    grid-column-gap: normal;
    -webkit-column-gap: normal;
            column-gap: normal;
  }
}

@media only screen and (max-width: 768px) {
  .CampaignQuestionsPage_parentContainer__1iw4o {
    min-height: calc(100vh - 70px); /* Appbar is 70px in height */
  }
  .CampaignQuestionsPage_iframeContainer__1pR5X {
    width: 85%;
    min-height: calc(100vh - 260px);
  }
}

.ImagePopup_container__2v4-_ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ImagePopup_container__2v4-_ img {
  max-width: 95%;
  max-height: 95%;
}

.video-react {
  background-color: rgba(255, 255, 255, 0) !important;
}
.video-react,
.video-react-video {
  border-radius: 6px;
}
.video-react-big-play-button {
  background-color: rgba(255, 255, 255, 0) !important;
}

.user-campaign-questions-slider-container {
  margin-bottom: 60px;
}
.user-campaign-questions-slider-container .input-range__label--value {
  top: -33px !important;
}
.user-campaign-questions-slider-container .input-range__slider {
  background: #24b2e8;
  background: var(--slider-circle-color, #24b2e8);
  border: 1px solid #24b2e8;
  border: 1px solid var(--slider-circle-color, #24b2e8);
}
.user-campaign-questions-slider-container .input-range__track--active {
  background: inherit;
}
.user-campaign-questions-slider-container .input-range__label {
  font-family: inherit;
}
.user-campaign-questions-slider-container
  .input-range__label.input-range__label--value {
  color: #24b2e8;
  color: var(--slider-circle-color, #24b2e8);
}
.input-range__disabled {
  opacity: 0.3;
}
@media only screen and (max-width: 900px) {
  .user-campaign-questions-slider-container {
    margin-bottom: 60px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.Message_DraftEditor__25mEF {
  cursor: text;
  padding-bottom: 40px;
  font-family: 'Roboto Light';
  font-size: 16px;
  width: 800px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
}

.Matrix_container__1XYmD {
  font-family: 'Roboto Light';
}
.Matrix_container__1XYmD .Matrix_gridContainer__1Ezcs {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  margin: 0 auto 40px auto;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 13px;
}
.Matrix_container__1XYmD .Matrix_mobileContainer__aHW4X {
  display: none;
}
.Matrix_gridContainer__1Ezcs .Matrix_row__1Vdnn {
  display: flex;
}
.Matrix_questionChoice__1Ihq6 {
  width: 50%;
  min-width: 50%;
  text-align: left;
  box-sizing: border-box;
  padding: 10px 30px 10px 10px;
  display: flex;
  align-items: center;

  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #dbdbdb;
}
.Matrix_questionChoice__1Ihq6 .Matrix_imageContainer__1smwa {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Matrix_questionChoice__1Ihq6 .Matrix_imageContainer__1smwa .Matrix_answerImage__2wrII {
  width: 38px;
  height: 38px;
  border-radius: 100%;
}
.Matrix_imageContainer__1smwa .Matrix_zoomInIconContainer__1bL4z {
  position: absolute;
  bottom: -4px;
  right: -4px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 100%;
  transition: all 0.1s ease-in-out;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0);
  transition: box-shadow 0.1s ease-in-out;
}
.Matrix_imageContainer__1smwa:hover .Matrix_zoomInIconContainer__1bL4z {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.Matrix_imageContainer__1smwa .Matrix_zoomInIconContainer__1bL4z .Matrix_zoomInIcon__3ZVKz {
  position: relative;
  width: 10px;
  height: 10px;
}

.Matrix_columnTitle__3Jae9 {
  padding: 10px 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #dbdbdb;
}
.Matrix_columnTitle__3Jae9 span {
  display: inline-block;
  vertical-align: middle;
  max-width: 70px;
  text-align: center;
}

.Matrix_row__1Vdnn .Matrix_answerOption__b6qRx {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #dbdbdb;
}
.Matrix_row__1Vdnn .Matrix_answerOption__b6qRx .Matrix_selectorContainer__2-T6n {
  width: 25px;
  height: 25px;
  background-color: var(--mc-question-answer-background-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 100%;
}
.Matrix_row__1Vdnn .Matrix_answerOption__b6qRx .Matrix_selectorContainer__2-T6n .Matrix_selector__JWGfc {
  width: 17px;
  height: 17px;
  background-color: var(--mc-question-answer-background-color);
  border-radius: 100%;
  visibility: hidden;
}
.Matrix_row__1Vdnn .Matrix_answerOption__b6qRx .Matrix_selectorContainer__2-T6n:hover .Matrix_selector__JWGfc {
  background-color: #24b2e8
  ;
  background-color: var(
    --mc-question-image-answer-background-color-active,
    #24b2e8
  );
  visibility: visible;
  transition: all 0.1s ease-in;
}
.Matrix_row__1Vdnn .Matrix_answerOption__b6qRx .Matrix_selector__JWGfc.Matrix_selected__1KFQO {
  background-color: #24b2e8
  ;
  background-color: var(
    --mc-question-image-answer-background-color-active,
    #24b2e8
  );
  visibility: visible;
}

/* Border fixes */
.Matrix_row__1Vdnn:first-child .Matrix_questionChoice__1Ihq6 {
  border: none;
}
.Matrix_row__1Vdnn:nth-child(2) .Matrix_questionChoice__1Ihq6 {
  border-top-left-radius: 5px;
}
.Matrix_row__1Vdnn:last-child .Matrix_questionChoice__1Ihq6 {
  border-bottom-width: 1px;
  border-bottom-left-radius: 5px;
}
.Matrix_columnTitle__3Jae9:nth-child(2) {
  border-top-left-radius: 5px;
}
.Matrix_columnTitle__3Jae9:last-child {
  border-top-right-radius: 5px;
  border-right-width: 1px;
}
.Matrix_row__1Vdnn:last-child .Matrix_answerOption__b6qRx {
  border-bottom-width: 1px;
}
.Matrix_row__1Vdnn .Matrix_answerOption__b6qRx:last-child {
  border-right-width: 1px;
}
.Matrix_row__1Vdnn:last-child .Matrix_answerOption__b6qRx:last-child {
  border-bottom-right-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .Matrix_container__1XYmD .Matrix_gridContainer__1Ezcs {
    display: none;
  }
  .Matrix_container__1XYmD .Matrix_mobileContainer__aHW4X {
    text-align: center;
    display: block;
  }

  .Matrix_mobileQuestionChoice__3v9uT {
    margin-bottom: 40px;
  }

  .Matrix_mobileQuestionChoice__3v9uT .Matrix_imageContainer__1smwa {
    background-color: #fafafa;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_imageContainer__1smwa .Matrix_answerImage__2wrII {
    max-width: 100%;
    max-height: 240px;
    display: block;
    margin: 0 auto;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_imageContainer__1smwa .Matrix_zoomInIconContainer__1bL4z {
    top: 8px;
    right: 8px;
    width: 40px;
    height: 40px;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_imageContainer__1smwa:hover .Matrix_zoomInIconContainer__1bL4z {
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_imageContainer__1smwa .Matrix_zoomInIconContainer__1bL4z .Matrix_zoomInIcon__3ZVKz {
    width: 24px;
    height: 24px;
  }

  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerText__2v75z {
    margin: 20px 0;
    padding: 15px 30px;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOptionsContainer__3aaUo {
    margin: 20px 0;
    padding: 15px 30px;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOptionsContainer__3aaUo .Matrix_answerRange__1AH4g {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: italic;
    margin-bottom: 35px;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOptionsContainer__3aaUo .Matrix_answerRange__1AH4g span:first-child {
    text-align: left;
    width: 120px;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOptionsContainer__3aaUo .Matrix_answerRange__1AH4g .Matrix_line__3_Re6 {
    height: 40px;
    border-right: 1px solid black;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOptionsContainer__3aaUo .Matrix_answerRange__1AH4g span:last-child {
    text-align: right;
    width: 120px;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOptionsContainer__3aaUo .Matrix_answerOptions__3Yevw {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin: 0 auto;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOption__b6qRx .Matrix_selectorContainer__2-T6n {
    width: 40px;
    height: 40px;
    background-color: #e9f7fc;
    background-color: var(--mc-question-answer-background-color, #e9f7fc);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 100%;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOption__b6qRx .Matrix_selectorContainer__2-T6n .Matrix_selector__JWGfc {
    width: 28px;
    height: 28px;
    background-color: #e9f7fc;
    background-color: var(--mc-question-answer-background-color, #e9f7fc);
    border-radius: 100%;
    visibility: hidden;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOption__b6qRx .Matrix_selectorContainer__2-T6n:hover .Matrix_selector__JWGfc {
    background-color: #24b2e8
    ;
    background-color: var(
      --mc-question-image-answer-background-color-active,
      #24b2e8
    );
    transition: all 0.1s ease-in;
    visibility: visible;
  }
  .Matrix_mobileQuestionChoice__3v9uT .Matrix_answerOption__b6qRx .Matrix_selector__JWGfc.Matrix_selected__1KFQO {
    background-color: #24b2e8
    ;
    background-color: var(
      --mc-question-image-answer-background-color-active,
      #24b2e8
    );
    visibility: visible;
  }
}

.DraftEditor-root {
  cursor: text;
}

.UserConsents_parentContainer__2Rb-5 {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
}
.UserConsents_mainContainer__2aS7t {
  flex: 1 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.UserConsents_title__1Z7_y {
  font-family: 'Roboto Bold';
  font-size: 42px;
  color: #221f1f;
  padding-top: 40px;
  padding-bottom: 20px;
}
.UserConsents_userDetailTitle__3218P {
  font-size: 12px;
  padding-left: 10px;
  margin-bottom: 5px;
}
.UserConsents_acceptInputContainer__2PXtm {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #4926e9;
}
.UserConsents_confirmInputContainer__1e9eJ {
  margin: 50px 0;
}
.UserConsents_createProfileInputContainer__3qnog {
  margin-top: 50px;
  margin-bottom: 20px;
}

.UserConsents_textDescription__18LD8 {
  font-family: 'Roboto Light';
}

.UserConsents_userDetailsPage__1OnrY .UserConsents_input__38_eG {
  font-family: 'Roboto Light';
  display: block;
  width: 100%;
  color: #221f1f;
  font-size: 16px !important;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #707070;
  margin-bottom: 20px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 52px;
  line-height: 52px;
  border-radius: 30px;
  outline: none;
}
.UserConsents_userDetailsPage__1OnrY .UserConsents_input__38_eG.UserConsents_errorField__27n5t {
  border-color: #ec008b;
}
.UserConsents_userDetailsPage__1OnrY .UserConsents_labelText__1j44q {
  display: block;
  font-family: 'Roboto Light';
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}
.UserConsents_label__30UZh {
}
.UserConsents_userDetailsPage__1OnrY .UserConsents_formContainer__3EUNr .UserConsents_createUserProfile__24Z4_ {
  width: 100%;
  height: 50px;
  font-size: 13px;
  line-height: 50px;
  background-color: #efefef;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  cursor: pointer;
}
.UserConsents_dontWantProfile__1ttG7 {
  font-family: 'Roboto Light';
  color: #ec008b;
  margin-bottom: 40px;
  font-size: 13px;
  padding-left: 10px;
  cursor: pointer;
}

.UserConsents_languagesSelectorContainer__2sQlt {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}
.UserConsents_languagesSelectorContainer__2sQlt .UserConsents_terms-language-selector__3Lnop {
  margin-right: 20px;
}

.UserConsents_readFullDocumentsContainer__1NJOM div {
  color: blue;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 7px;
}
.UserConsents_readFullDocumentsContainer__1NJOM div:hover {
  text-decoration: underline;
}

.UserConsents_termsContentImage__GYBDe {
  display: block;
  width: 400px;
  margin: 16px auto 32px;
}

.UserConsents_textAlignCenter__17tYa {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
}

.UserConsents_restartSurveyButtonContainer__2o_kp {
  margin-top: 50px;
  margin-bottom: 100px;
  text-align: right;
}

.UserConsents_sectionSubTitle__2b1s_ {
  font-family: 'Roboto Bold';
  font-size: 16px;
  margin-bottom: 20px;
}

.UserConsents_accountCreateContainer__2kdps {
  width: 420px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 90%;
}

.UserConsents_errorContainer__3JdGu {
  font-family: 'Roboto Light';
  font-size: 16px;
  color: #ec008b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.UserConsents_errorContainer__3JdGu .UserConsents_errorIcon__hxbPG {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

@media only screen and (max-width: 900px) {
  .UserConsents_accountCreateContainer__2kdps {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .UserConsents_termsContentImage__GYBDe {
    width: 80%;
  }

  .UserConsents_userDetailsPage__1OnrY .UserConsents_formContainer__3EUNr input,
  .UserConsents_userDetailsPage__1OnrY .UserConsents_formContainer__3EUNr select {
    width: calc(100% - 50px);
  }

  .UserConsents_title__1Z7_y,
  .UserConsents_textDescription__18LD8 {
    width: 100%;
  }
  .UserConsents_title__1Z7_y {
    font-size: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .UserConsents_errorContainer__3JdGu {
    font-size: 14px;
  }
}

.ConfirmInput_confirmInputContainer__14mUx {
  display: flex;
  font-family: 'Open Sans Light';
  text-align: left;
}
.ConfirmInput_confirmInputContainer__14mUx .ConfirmInput_checkmark__10_aP {
  top: 4px;
  width: 15px;
  height: 15px;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid #000000;
  cursor: pointer;
  flex-shrink: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ConfirmInput_tick__1ExAk {
  position: absolute;
  top: 3px;
  left: 1px;
  width: 11px;
  height: 8px;
}
.ConfirmInput_confirmInputContainerActive__28cO3 .ConfirmInput_checkmark__10_aP {
  background-color: #5200f1;
  border-color: #5200f1;
}
.ConfirmInput_confirmInputContainer__14mUx .ConfirmInput_label__2QaSY {
  padding-left: 20px;
}

.CampaignCompletedPage_parentContainer__2oWPc {
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  overflow: hidden;
}
.CampaignCompletedPage_mainContainer__1ERwC {
  flex: 1 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  font-family: 'Roboto Light';
}
.CampaignCompletedPage_contentContainer__124sR {
  margin-top: 80px;
  margin-right: auto;
  margin-left: auto;
  width: 1100px;
  max-width: 90%;
  text-align: center;
}
.CampaignCompletedPage_title__1K7Ub {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  width: 600px;
  max-width: 100%;
  text-align: center;
}
.CampaignCompletedPage_description__16QXQ {
  width: 600px;
  max-width: 100%;
  font-size: inherit;
  display: block;
  margin: 20px auto 40px auto;
  font-family: 'Roboto Light';
  text-align: center;
}
.CampaignCompletedPage_textAreaTranslation__1z9A- {
  white-space: pre-line;
}
.CampaignCompletedPage_termsContentImage__35Pty {
  width: 60%;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: block;
}
.CampaignCompletedPage_downloadAppMessage__2x_df {
  font-size: 18px;
  margin-bottom: 10px;
}
.CampaignCompletedPage_qrCode__Xjbx3 {
  width: 130px;
}
.CampaignCompletedPage_appStoreButtons__1ZRz7 {
  display: none;
}
.CampaignCompletedPage_appStoreButtons__1ZRz7 a {
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  height: 45px;
  padding: 2px;
}
.CampaignCompletedPage_appStoreButtons__1ZRz7 img {
  height: 45px;
  border-radius: 4px;
}

.CampaignCompletedPage_loaderContainer__wD8I7 {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, calc(-50% - 60px));
          transform: translate(-50%, calc(-50% - 60px));
}
.CampaignCompletedPage_loaderContainer__wD8I7 .CampaignCompletedPage_loaderText__2tcJD {
  color: grey;
  opacity: 0.7;
  top: 60px;
}

@media only screen and (max-width: 900px) {
  .CampaignCompletedPage_contentContainer__124sR {
    margin-top: 100px;
  }
  .CampaignCompletedPage_title__1K7Ub {
    font-size: 28px;
  }
  .CampaignCompletedPage_qrCode__Xjbx3 {
    display: none;
  }
  .CampaignCompletedPage_appStoreButtons__1ZRz7 {
    display: block;
  }
  .CampaignCompletedPage_appStoreButtons__1ZRz7 a {
    height: 35px;
  }
  .CampaignCompletedPage_appStoreButtons__1ZRz7 img {
    height: 35px;
  }
}

@media only screen and (max-width: 768px) {
  .CampaignCompletedPage_parentContainer__2oWPc {
    min-height: calc(100vh - 70px);
  }
  .CampaignCompletedPage_loaderContainer__wD8I7 {
    -webkit-transform: translate(-50%, calc(-50% - 80px));
            transform: translate(-50%, calc(-50% - 80px));
  }
  .CampaignCompletedPage_loaderContainer__wD8I7 .CampaignCompletedPage_loaderText__2tcJD {
    top: 80px;
  }
}

.CampaignFailedPage_parentContainer__2NuGA {
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  overflow: hidden;
}
.CampaignFailedPage_mainContainer__18VPf {
  flex: 1 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  font-family: 'Roboto Light';
}
.CampaignFailedPage_contentContainer__6Zzto {
  margin-right: auto;
  margin-left: auto;
  width: 1100px;
  max-width: 90%;
  text-align: center;
}
.CampaignFailedPage_title___BZen {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  width: 600px;
  max-width: 100%;
  margin-bottom: 40px;
  text-align: center;
}
.CampaignFailedPage_description__3OkU9 {
  width: 600px;
  max-width: 100%;
  font-size: inherit;
  display: block;
  margin: 20px auto 40px auto;
  font-family: 'Roboto Light';
  text-align: center;
}
.CampaignFailedPage_textAreaTranslation__1mPGm {
  white-space: pre-line;
}
.CampaignFailedPage_downloadAppMessage__zKRJ5 {
  font-size: 18px;
  margin-bottom: 10px;
}
.CampaignFailedPage_qrCode__kr3mh {
  width: 130px;
}
.CampaignFailedPage_appStoreButtons__3Gyl3 {
  display: none;
}
.CampaignFailedPage_appStoreButtons__3Gyl3 a {
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  height: 45px;
  padding: 2px;
}
.CampaignFailedPage_appStoreButtons__3Gyl3 img {
  height: 45px;
  border-radius: 4px;
}

@media only screen and (max-width: 900px) {
  .CampaignFailedPage_contentContainer__6Zzto {
    margin-top: 60px;
  }
  .CampaignFailedPage_title___BZen {
    font-size: 28px;
  }
  .CampaignFailedPage_qrCode__kr3mh {
    display: none;
  }
  .CampaignFailedPage_appStoreButtons__3Gyl3 {
    display: block;
  }
  .CampaignFailedPage_appStoreButtons__3Gyl3 a {
    height: 35px;
  }
  .CampaignFailedPage_appStoreButtons__3Gyl3 img {
    height: 35px;
  }
}

@media only screen and (max-width: 768px) {
  .CampaignFailedPage_parentContainer__2NuGA {
    min-height: calc(100vh - 70px);
  }
}

.CampaignEmailConversion_parentContainer__2VL5i {
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  overflow: hidden;
}
.CampaignEmailConversion_mainContainer___XgEy {
  flex: 1 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  font-family: 'Roboto Light';
}
.CampaignEmailConversion_contentContainer__1ABOF {
  margin-right: auto;
  margin-left: auto;
  width: 1100px;
  max-width: 90%;
  text-align: center;
}
.CampaignEmailConversion_contentContainer__1ABOF.CampaignEmailConversion_contentContainerMargin__GUvfY {
  margin-top: 80px;
}
.CampaignEmailConversion_title__12gvj {
  font-family: 'Montserrat Bold';
  font-size: 36px;
  color: #000;
  max-width: 100%;
  text-align: center;
}
.CampaignEmailConversion_description__228-Q {
  width: 540px;
  max-width: 100%;
  font-size: inherit;
  display: block;
  margin: 20px auto 40px auto;
  font-family: 'Roboto Light';
  text-align: center;
}
.CampaignEmailConversion_textAreaTranslation__2gCs1 {
  white-space: pre-line;
}
.CampaignEmailConversion_acceptInputContainer__GaAMP {
  margin-top: 50px;
  margin-bottom: 40px;
}
.CampaignEmailConversion_declineTermsContainer__3cB8b {
  margin-top: 20px;
}
.CampaignEmailConversion_inputContainer__2W-3o {
  margin-top: 30px;
}
.CampaignEmailConversion_inputContainer__2W-3o .CampaignEmailConversion_input__1AZjI {
  font-family: 'Roboto Light';
  display: inline-block;
  width: 420px;
  max-width: 100%;
  color: #000;
  font-size: 16px !important;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #cecece;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  height: 50px;
  line-height: 50px;
  border-radius: 30px;
}
.CampaignEmailConversion_inputContainer__2W-3o .CampaignEmailConversion_input__1AZjI::-webkit-input-placeholder {
  color: #cecece;
}
.CampaignEmailConversion_inputContainer__2W-3o .CampaignEmailConversion_input__1AZjI::-ms-input-placeholder {
  color: #cecece;
}
.CampaignEmailConversion_inputContainer__2W-3o .CampaignEmailConversion_input__1AZjI::placeholder {
  color: #cecece;
}
.CampaignEmailConversion_inputContainer__2W-3o .CampaignEmailConversion_input__1AZjI:focus {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  outline: none;
}
.CampaignEmailConversion_inputContainer__2W-3o .CampaignEmailConversion_input__1AZjI:disabled {
  opacity: 0.6;
  background-color: rgba(0, 0, 0, 0.05);
}
.CampaignEmailConversion_inputContainer__2W-3o .CampaignEmailConversion_input__1AZjI.CampaignEmailConversion_errorField__35qO- {
  border-color: #ec008b;
}
.CampaignEmailConversion_inputContainer__2W-3o .CampaignEmailConversion_errorContainer__3cpDo {
  font-family: 'Roboto Light';
  font-size: 16px;
  color: #ec008b;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.CampaignEmailConversion_inputContainer__2W-3o .CampaignEmailConversion_errorContainer__3cpDo .CampaignEmailConversion_errorIcon__ng5N0 {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.CampaignEmailConversion_nextSelector__1JuYn {
  margin-left: auto;
  margin-right: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
  width: 220px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  border-radius: 27px;
  padding: 0 45px;
  box-sizing: border-box;
  font-family: 'Montserrat Bold';
  text-transform: uppercase;
}
.CampaignEmailConversion_downloadAppMessage__1yGP7 {
  font-size: 18px;
  margin-bottom: 10px;
}
.CampaignEmailConversion_qrCode__28EtU {
  width: 130px;
}
.CampaignEmailConversion_appStoreButtons__tYUAD {
  display: none;
}
.CampaignEmailConversion_appStoreButtons__tYUAD a {
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
  height: 45px;
  padding: 2px;
}
.CampaignEmailConversion_appStoreButtons__tYUAD img {
  height: 45px;
  border-radius: 4px;
}

@media only screen and (max-width: 900px) {
  .CampaignEmailConversion_contentContainer__1ABOF {
    margin-top: 60px;
  }
  .CampaignEmailConversion_contentContainer__1ABOF.CampaignEmailConversion_contentContainerMargin__GUvfY {
    margin-top: 80px;
  }
  .CampaignEmailConversion_title__12gvj {
    font-size: 28px;
  }
  .CampaignEmailConversion_qrCode__28EtU {
    display: none;
  }
  .CampaignEmailConversion_appStoreButtons__tYUAD {
    display: block;
  }
  .CampaignEmailConversion_appStoreButtons__tYUAD a {
    height: 35px;
  }
  .CampaignEmailConversion_appStoreButtons__tYUAD img {
    height: 35px;
  }
}

@media only screen and (max-width: 768px) {
  .CampaignEmailConversion_parentContainer__2VL5i {
    min-height: calc(100vh - 70px);
  }
  .CampaignEmailConversion_nextSelector__1JuYn {
    height: 54px;
    line-height: 54px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
  }
}

.CampaignProfilingConsentPage_parentContainer__1U6OK {
  display: flex;
  min-height: calc(100vh - 150px); /* Appbar is 150px in height */
  flex-direction: column;
}
.CampaignProfilingConsentPage_mainContainer__9n5yh {
  flex: 1 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.CampaignProfilingConsentPage_campaignName__303CW {
  font-family: 'Roboto Bold';
  font-size: 20px;
  color: #221f1f;
  padding-top: 40px;
  padding-bottom: 10px;
}
.CampaignProfilingConsentPage_campaignQuestion__2JHd3 {
  font-family: 'Open Sans Light';
  font-size: 16px;
  color: #221f1f;
  padding-top: 0px;
  padding-bottom: 40px;
  white-space: pre-line;
  width: 800px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
}
.CampaignProfilingConsentPage_campaignDescription__1otWp {
  padding-top: 0px;
  padding-bottom: 20px;
  white-space: pre-line;
}
.CampaignProfilingConsentPage_campaignImage__3eUVI {
  background-color: grey;
  height: 400px;
}
.CampaignProfilingConsentPage_questionImage__vIteX {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}
.CampaignProfilingConsentPage_questionImage__vIteX img {
  max-width: 100%;
  max-height: 400px;
}

.CampaignProfilingConsentPage_progressBarContainer__kwCWA {
  height: 10px;
  border-radius: 10px;
  background-color: #dadada;
  margin-bottom: 30px;
}
.CampaignProfilingConsentPage_progressBarContainer__kwCWA .CampaignProfilingConsentPage_progressBar__KRIM7 {
  height: 10px;
  border-radius: 10px;
  background-color: #687df0;
}

.CampaignProfilingConsentPage_questionContainer__SGaQv,
.CampaignProfilingConsentPage_inputFieldContainer__2aOML {
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.CampaignProfilingConsentPage_sliderContainer__Sc9T9 {
  text-align: center;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_answer__iaAXh {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
  background-color: #f9f7ff;
  background-color: var(--mc-question-answer-background-color, #f9f7ff);
  border-radius: 27px;
  padding: 17px 22px;
  cursor: pointer;
  display: inline-flex;
  white-space: pre-line;
  width: 480px;
  box-sizing: border-box;
  transition: background-color 0.1s ease-in-out;
  text-align: left;
}
.CampaignProfilingConsentPage_mcQuestionContainer__jxzxo .CampaignProfilingConsentPage_answer__iaAXh {
  margin-bottom: 0;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_answer__iaAXh:hover {
  background-color: #dccbfd;
  background-color: var(--mc-question-answer-background-color-active, #dccbfd);
}
.CampaignProfilingConsentPage_questionContainer__SGaQv.CampaignProfilingConsentPage_sliderContainer__Sc9T9 .CampaignProfilingConsentPage_answer__iaAXh.CampaignProfilingConsentPage_sliderAnswer__1dIXR {
  display: inline-block;
  width: auto;
  padding: 17px 34px;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageAnswer__3JD_Z {
  width: 280px;
  box-sizing: border-box;
  height: auto;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
  padding: 25px 27px;
  background-color: #dccbfd;
  background-color: var(--mc-question-answer-background-color, #dccbfd);
  transition: background-color 0.1s ease-in-out;
  margin-bottom: 20px;
  display: grid;
  grid-template-rows: 1fr auto;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}
.CampaignProfilingConsentPage_imageAnswerContent__Sq9ef {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageAnswer__3JD_Z:hover {
  background-color: #dccbfd;
  background-color: var(--mc-question-answer-background-color-active, #dccbfd);
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageAnswerText__3q_Ka {
  font-family: 'Open Sans Regular';
  font-size: 14px;
  padding-top: 25px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageAnswer__3JD_Z img {
  max-width: 100%;
  max-height: 300px;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_answer__iaAXh.CampaignProfilingConsentPage_active__2ksl-,
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageAnswer__3JD_Z.CampaignProfilingConsentPage_active__2ksl- {
  background-color: #dccbfd;
  background-color: var(--mc-question-answer-background-color-active, #dccbfd);
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageAnswer__3JD_Z .CampaignProfilingConsentPage_zoomInIconContainer__2Zhvm,
.CampaignProfilingConsentPage_questionImage__vIteX .CampaignProfilingConsentPage_zoomInIconContainer__2Zhvm {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 57px;
  height: 57px;
  background-color: #fff;
  border-radius: 100%;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  display: none;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageAnswer__3JD_Z .CampaignProfilingConsentPage_zoomInIconContainer__2Zhvm .CampaignProfilingConsentPage_zoomInIcon__RvQZY,
.CampaignProfilingConsentPage_questionImage__vIteX .CampaignProfilingConsentPage_zoomInIconContainer__2Zhvm .CampaignProfilingConsentPage_zoomInIcon__RvQZY {
  position: relative;
  display: block;
  left: 14px;
  top: 14px;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageAnswer__3JD_Z .CampaignProfilingConsentPage_zoomInIconContainer__2Zhvm.CampaignProfilingConsentPage_visible__2Scpw,
.CampaignProfilingConsentPage_questionImage__vIteX .CampaignProfilingConsentPage_zoomInIconContainer__2Zhvm.CampaignProfilingConsentPage_visible__2Scpw {
  display: block;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_answer__iaAXh .CampaignProfilingConsentPage_questionCounter__38GzK {
  left: -4px;
  top: 0;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageQuestionCounter__3wvoj {
  left: -8px;
  top: -3px;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_answer__iaAXh .CampaignProfilingConsentPage_questionCounter__38GzK,
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageQuestionCounter__3wvoj {
  border-radius: 50%;
  text-align: center;
  position: absolute;
  width: 22px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  font-family: 'Open Sans Regular';
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_answer__iaAXh .CampaignProfilingConsentPage_questionText__3HX5O {
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_inputField__9cGGb {
  display: block;
  font-size: 16px !important;
  height: 200px;
  font-family: 'Open Sans Light';
  background: #fafafa;
  border-radius: 27px;
  padding: 17px 22px;
  color: #000000;
  border: none;
  outline: none;
  margin: 0 auto;
  box-sizing: border-box;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_inputField__9cGGb.CampaignProfilingConsentPage_openAnswerInputField__1TuyR {
  width: 700px;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_inputField__9cGGb.CampaignProfilingConsentPage_otherAnswerInputField__6gB-C {
  width: 480px;
}
.CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_inputFieldDescription__JUlgc {
  width: 700px;
  margin: 10px auto 0 auto;
  font-size: 14px;
  text-align: right;
  font-family: 'Open Sans Light';
}
.CampaignProfilingConsentPage_iframeContainer__PTdQj {
  width: 95%;
  min-height: 600px;
  height: auto;
  border: none;
  display: block;
  margin: 20px auto 0 auto;
  border: 1px solid #eeeeee;
}

.CampaignProfilingConsentPage_imageContainer__2hiOf {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CampaignProfilingConsentPage_imageContainer__2hiOf img {
  max-width: 100%;
  max-height: 400px;
}
.CampaignProfilingConsentPage_loadingQuestionImageFailed__dtz0Y {
  width: 430px;
  max-width: 85%;
  height: 245px;
  background-color: #f5f2fe;
  font-family: 'Open Sans Light';
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.CampaignProfilingConsentPage_loadingQuestionImageFailed__dtz0Y .CampaignProfilingConsentPage_imageNotFoundIcon__1sCXV {
  margin-bottom: 10px;
}

.CampaignProfilingConsentPage_sliderOrContainer__eifzF {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Open Sans Light';
  font-size: 20px;
  margin-bottom: 30px;
}
.CampaignProfilingConsentPage_sliderOrContainerDivider__3Btmi {
  width: 125px;
  height: 1px;
  background-color: grey;
}
.CampaignProfilingConsentPage_sliderOrContainer__eifzF span {
  margin: 0 8px;
}

.CampaignProfilingConsentPage_videoPlayerContainer__367wP {
  margin-top: 20px;
  padding-bottom: 40px;
  max-width: 85vw;
  max-height: 80vh;
}

.CampaignProfilingConsentPage_mcQuestionContainer__jxzxo {
  text-align: center;
}
.CampaignProfilingConsentPage_mcQuestionAnswers__34kNR {
  -webkit-column-count: 3;
          column-count: 3;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}
.CampaignProfilingConsentPage_mcQuestionContainer__jxzxo .CampaignProfilingConsentPage_mcAnswerContainer__2wKli {
  -webkit-column-span: all;
          column-span: all;
  display: block;
  margin: 10px 0;
}
.CampaignProfilingConsentPage_mcQuestionAnswers__34kNR .CampaignProfilingConsentPage_inputField__9cGGb {
  -webkit-column-span: all;
          column-span: all;
  display: block;
  margin-top: 5px;
}

@media only screen and (max-width: 900px) {
  .CampaignProfilingConsentPage_mainContainer__9n5yh {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .CampaignProfilingConsentPage_questionRootContainer__u4w2H {
    width: 90%;
  }
  .CampaignProfilingConsentPage_questionContainer__SGaQv {
    width: 90%;
  }
  .CampaignProfilingConsentPage_campaignQuestion__2JHd3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_imageAnswer__3JD_Z {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
  }
  .CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_inputField__9cGGb.CampaignProfilingConsentPage_openAnswerInputField__1TuyR,
  .CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_inputField__9cGGb.CampaignProfilingConsentPage_otherAnswerInputField__6gB-C {
    width: 100%;
    box-sizing: border-box;
  }
  .CampaignProfilingConsentPage_questionContainer__SGaQv .CampaignProfilingConsentPage_inputFieldDescription__JUlgc {
    width: 100%;
    font-size: 10px;
  }
  .CampaignProfilingConsentPage_mcQuestionContainer__jxzxo .CampaignProfilingConsentPage_answer__iaAXh {
    width: 100%;
  }
  .CampaignProfilingConsentPage_mcQuestionContainer__jxzxo .CampaignProfilingConsentPage_inputFieldContainer__2aOML {
    width: 100%;
  }
  .CampaignProfilingConsentPage_mcQuestionContainer__jxzxo .CampaignProfilingConsentPage_inputFieldContainer__2aOML .CampaignProfilingConsentPage_inputField__9cGGb {
    width: 100%;
    box-sizing: border-box;
  }
  .CampaignProfilingConsentPage_mcQuestionAnswers__34kNR {
    display: flex;
    flex-flow: column wrap;
    -webkit-column-count: auto;
            column-count: auto;
    grid-column-gap: normal;
    -webkit-column-gap: normal;
            column-gap: normal;
  }
}

@media only screen and (max-width: 768px) {
  .CampaignProfilingConsentPage_parentContainer__1U6OK {
    min-height: calc(100vh - 70px); /* Appbar is 70px in height */
  }
  .CampaignProfilingConsentPage_iframeContainer__PTdQj {
    width: 85%;
    min-height: calc(100vh - 260px);
  }
}

.Loader_loaderFixedContainer__1-nXV {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Loader_loadingAnimation__GWusJ {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.Loader_loadingAnimation__GWusJ div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation__2yREi 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation__2yREi 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__GWusJ div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.Loader_loadingAnimation__GWusJ div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.Loader_loadingAnimation__GWusJ div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes Loader_loading-animation__2yREi {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader_loading-animation__2yREi {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.AppBar_container__N--Fh {
  position: relative;
  z-index: 100;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Roboto Light';
}
.AppBar_left__2Q-wg {
  display: flex;
}
.AppBar_logoClick__2avk9 {
  cursor: pointer;
}
.AppBar_logo__3ar0R {
  width: 89px;
}
.AppBar_title__1v6Ph {
  position: relative;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #000000;
  font-size: 19px;
  text-transform: uppercase;
}
.AppBar_menu__3qbZv {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.AppBar_menu__3qbZv li {
  display: inline-block;
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 10px;
  border-left: 1px solid #000000;
  padding-left: 10px;
}
.AppBar_menu__3qbZv li:first-child {
  margin-left: 0px;
  border-left: 0;
  padding-left: 0px;
}
.AppBar_languageOption__Gzb65 {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 14px;
  color: #792483;
}
.AppBar_languageOption__Gzb65:last-of-type {
  margin-left: 10px;
}
.AppBar_languageOption__Gzb65.AppBar_languageOptionActive__2lUhF {
  font-family: 'Roboto Medium';
}

@media only screen and (max-width: 1024px) {
  .AppBar_container__N--Fh {
    margin-top: 40px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .AppBar_authContainer__2d0VF {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .AppBar_right__1fjQc {
    margin-top: 15px;
    width: 100%;
    text-align: center;
  }
  .AppBar_authContainer__2d0VF .AppBar_right__1fjQc {
    margin-top: 0;
    width: auto;
    margin-left: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .AppBar_menu__3qbZv li {
    display: none;
  }
  .AppBar_logout__1CRXb {
    display: inline-block;
  }
}

.AuthRoute_container__1lRXs {
  /*
  height: 100vh;
  width: 100vw;
  */
  overflow-x: hidden;
  overflow-y: auto;
}
.AuthRoute_mainContainer__gzL3l {
  width: calc(100% - 50px);
  padding-left: 25px;
  padding-right: 25px;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
}
.AuthRoute_backgroundContainer__1inQk {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  width: calc(((100vw - 1200px) / 2) + 1200px - 550px);
  z-index: 0;
}
.AuthRoute_backgroundContainerGreen__zMVYI {
  background-color: #f4fefb;
}
.AuthRoute_backgroundContainerBlue__WNHK3 {
  background-color: #fcfaff;
}
@media only screen and (max-width: 1024px) {
  .AuthRoute_mainContainer__gzL3l {
    width: 100%;
    box-sizing: border-box;
    min-height: calc(100vh - 40px);
  }
  .AuthRoute_backgroundContainer__1inQk {
    display: none;
  }
}

.Footer_contactContainer__TlwSb {
  margin-top: 50px;
  padding-bottom: 100px;
  text-align: center;
}
.Footer_button__3eMA1 {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  background-color: #792483;
  border-radius: 20px;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 13px;
  font-family: 'Montserrat Bold';
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.OrganisationsRoute_container__eVJQq {
  overflow-x: auto;
  overflow-y: auto;
}
.OrganisationsRoute_mainContainer__35ZRr {
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.LoginPage_register__3uD5v {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
}
.LoginPage_greenLink__3yGOf {
  color: #29f5b2;
  cursor: pointer;
  font-family: 'Open Sans SemiBold';
}
.LoginPage_mainContainer__1BgTZ {
  display: flex;
  margin: 0 auto;
  width: 100%;
  font-family: 'Open Sans Light';
}
.LoginPage_form__2w3Yc {
  z-index: 1;
  position: relative;
  right: 0;
  width: 300px;
  margin-left: auto;
  margin-right: 70px;
  margin-top: 80px;
  margin-bottom: 50px;
}
.LoginPage_title__1Tbfn {
  font-family: 'Roboto Black';
  font-size: 32px;
  margin: 0;
}
.LoginPage_input__3PFA8 {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #7c7c7c;
  height: 35px;
  padding: 0px 15px;
  outline: none;
  font-family: 'Open Sans Light';
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.LoginPage_grey__1o7h2 {
  margin-top: 10px;
  font-size: 14px;
  color: #d3d3d3;
  cursor: pointer;
}
.LoginPage_label__3DDIY {
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  font-family: 'Open Sans Light';
}
.LoginPage_button__7argf {
  display: block;
  text-align: center;
  background-color: #29f5b2;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Open Sans SemiBold';
  margin-top: 10px;
  text-transform: uppercase;
  width: 100%;
}
.LoginPage_button__7argf:hover {
  opacity: 0.7;
}
.LoginPage_error__1IaCG {
  margin-top: 4px;
  font-size: 13px;
  color: #fc035d;
  border-radius: 2px;
  display: inline-block;
}
.LoginPage_bottomBlobWrapper__2vc8z {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: calc((100vw - 100%) / -2);
  overflow: hidden;
}
.LoginPage_bottomBlob__3akBw {
  position: absolute;
  bottom: -1000px;
  width: 1718px;
  height: 1373px;
  left: -705px;
}
.LoginPage_herdyWorking__f4HfX {
  position: absolute;
  width: 651px;
  height: 570px;
  width: 554px;
  height: 400px;
  bottom: -60px;
  left: 200px;
}

@media only screen and (max-width: 1024px) {
  .LoginPage_mainContainer__1BgTZ {
    flex-direction: column;
    box-sizing: border-box;
  }
  .LoginPage_form__2w3Yc {
    position: relative;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .LoginPage_herdyWorking__f4HfX {
    width: 359px;
    height: 259px;
    left: 25px;
  }
}

.loading-animation {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.loading-animation div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: loading-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.loading-animation div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.loading-animation div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.loading-animation div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loading-animation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loading-animation.loading-animation-small {
  width: 20px;
  height: 2px;
  margin-top: 0;
}
.loading-animation.loading-animation-small div {
  top: 2px;
  width: 2px;
  height: 2px;
}

.RegistrationPage_login__1Q-EO {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
}
.RegistrationPage_blueLink__1CejD {
  color: #4926e9;
  cursor: pointer;
  font-family: 'Open Sans SemiBold';
}
.RegistrationPage_register__2QT2c:hover {
  text-decoration: underline;
}
.RegistrationPage_file__3n7-R {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  outline: none;
  color: #ffffff;
}
.RegistrationPage_logoName__37VGK {
  position: absolute;
  left: 100px;
  top: 12px;
  color: #221f1f;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 400px;
}

.RegistrationPage_mainContainer__lR0nU {
  display: flex;
  margin: 0 auto;
  width: 100%;
  font-family: 'Open Sans Light';
}
.RegistrationPage_mainContentContainer__1KWQ4 {
  width: 100%;
  max-width: calc(100% - 550px);
  min-height: calc(100vh - 120px);
}
.RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  margin-bottom: 300px;
}
.RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepsProgressContainerLine__3fS1X {
  position: absolute;
  top: 21px;
  left: calc((100vw - 100%) / -2);
  width: calc((100vw - 100%) / 2 + 100%);
  height: 1px;
  border-top: 3px dotted #a184f1;
}
.RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepContainer__3LAjy {
  z-index: 10000;
  padding-left: 70px;
  padding-right: 70px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.RegistrationPage_mainContentContainer__1KWQ4
  .RegistrationPage_stepsProgressContainer__32ri9
  .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT {
  background-color: rgba(255, 255, 255, 0);
}
.RegistrationPage_mainContentContainer__1KWQ4
  .RegistrationPage_stepsProgressContainer__32ri9
  .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT {
  margin-left: 70px;
  padding-left: 0;
  padding-right: 0;
  margin-right: 70px;
}
.RegistrationPage_mainContentContainer__1KWQ4
  .RegistrationPage_stepsProgressContainer__32ri9
  .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
  + .RegistrationPage_stepContainer__3LAjy {
  margin-left: 70px;
  padding-left: 0;
  padding-right: 70px;
  margin-right: 0;
}
.RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepContainer__3LAjy:last-of-type,
.RegistrationPage_mainContentContainer__1KWQ4
  .RegistrationPage_stepsProgressContainer__32ri9
  .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
  + .RegistrationPage_stepContainer__3LAjy:last-of-type {
  padding-right: calc(100% - (44px * 3) - (70px * 5));
}
.RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepContainer__3LAjy .RegistrationPage_circle__1a_ia {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 12px solid #e2dafa;
  background-color: #c8bdf8;
}
.RegistrationPage_mainContentContainer__1KWQ4
  .RegistrationPage_stepsProgressContainer__32ri9
  .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
  .RegistrationPage_circle__1a_ia {
  border-color: #a184f1;
  background-color: #4926e9;
}
.RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepContainer__3LAjy .RegistrationPage_stepLabel__2PwJa {
  font-size: 12px;
  margin-top: 15px;
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  left: calc(50% - ((100% - 44px) / 2) + 70px);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0.3;
}
.RegistrationPage_mainContentContainer__1KWQ4
  .RegistrationPage_stepsProgressContainer__32ri9
  .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
  .RegistrationPage_stepLabel__2PwJa {
  left: 50%;
  opacity: 1;
}
.RegistrationPage_mainContentContainer__1KWQ4
  .RegistrationPage_stepsProgressContainer__32ri9
  .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
  + .RegistrationPage_stepContainer__3LAjy
  .RegistrationPage_stepLabel__2PwJa {
  left: calc(50% - 35px);
}
.RegistrationPage_mainContentContainer__1KWQ4
  .RegistrationPage_stepsProgressContainer__32ri9
  + .RegistrationPage_stepContainer__3LAjy:last-of-type
  .RegistrationPage_stepLabel__2PwJa,
.RegistrationPage_mainContentContainer__1KWQ4
  .RegistrationPage_stepsProgressContainer__32ri9
  .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
  + .RegistrationPage_stepContainer__3LAjy:last-of-type
  .RegistrationPage_stepLabel__2PwJa {
  left: calc(50% - ((100% - 44px) / 2));
}
.RegistrationPage_form__2S4P8 {
  z-index: 1;
  position: relative;
  right: 0;
  width: 300px;
  margin-left: auto;
  margin-right: 70px;
  margin-top: 80px;
  margin-bottom: 50px;
  min-height: 505px;
}
.RegistrationPage_title__2ex2I {
  font-family: 'Roboto Black';
  font-size: 32px;
  margin: 0;
}
.RegistrationPage_input__1w9OV {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #7c7c7c;
  height: 35px;
  padding: 0px 15px;
  outline: none;
  font-family: 'Open Sans Light';
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.RegistrationPage_inputSavingsTarget__1gU4w {
  padding-left: 30px;
}
.RegistrationPage_euroSign__14yDP {
  position: absolute;
  left: 15px;
  margin-top: 8px;
  font-size: 14px;
}
.RegistrationPage_textarea__1YTO3 {
  display: block;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #7c7c7c;
  padding: 10px 15px;
  outline: none;
  font-family: 'Open Sans Light';
  font-size: 14px;
  box-sizing: border-box;
  height: 100px;
}
.RegistrationPage_textarea__1YTO3.RegistrationPage_textareaCharacters__2kgGU {
  height: 120px;
}
.RegistrationPage_textareaCharactersLength__3a70D {
  position: absolute;
  font-family: 'Open Sans Light';
  font-size: 14px;
  top: 120px;
  right: 9px;
}
.RegistrationPage_logoTextarea__HB1Cy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #7c7c7c;
  font-size: 14px;
  color: #d3d3d3;
  background-color: #fff;
  cursor: pointer;
  font-size: 12px;
}
.RegistrationPage_logoTextarea__HB1Cy .RegistrationPage_uploadIcon__3KlWE {
  width: 20px;
  height: 18px;
  margin-bottom: 5px;
}
.RegistrationPage_logoTextarea__HB1Cy .RegistrationPage_fileName__3Q_VU {
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.RegistrationPage_label__SeUIF {
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
}
.RegistrationPage_button__8x5ec {
  display: block;
  text-align: center;
  background-color: #4926e9;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Open Sans SemiBold';
  margin-top: 15px;
  text-transform: uppercase;
  width: 100%;
}
.RegistrationPage_button__8x5ec:hover {
  opacity: 0.7;
}
.RegistrationPage_error__3vcM9 {
  margin-top: 4px;
  font-size: 13px;
  color: #fc035d;
  border-radius: 2px;
  display: inline-block;
}
.RegistrationPage_bottomBlobWrapper__asiDf {
  position: absolute;
  width: 100vw;
  height: 100%;
  bottom: 0px;
  left: calc((100vw - 100%) / -2);
  overflow: hidden;
}
.RegistrationPage_bottomBlob___7gkK {
  position: absolute;
  bottom: -900px;
  width: 1546px;
  height: 1236px;
  left: -540px;
}
.RegistrationPage_herdyPickingApples__2M2K1 {
  position: absolute;
  width: 1004px;
  height: 677px;
  width: 628px;
  height: 424px;
  bottom: -70px;
  left: 180px;
}
.RegistrationPage_herdyCollectingApples__3BAhl {
  position: absolute;
  width: 1014px;
  height: 677px;
  width: 634px;
  height: 424px;
  bottom: -70px;
  left: 180px;
}

@media only screen and (max-width: 1024px) {
  .RegistrationPage_mainContainer__lR0nU {
    flex-direction: column;
    box-sizing: border-box;
  }
  .RegistrationPage_mainContentContainer__1KWQ4 {
    min-height: auto;
    width: 100%;
    max-width: 100%;
  }
  .RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 {
    margin-bottom: 0;
    justify-content: space-around;
  }
  .RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepsProgressContainerLine__3fS1X {
    left: calc((100vw - 100%) / -2);
    width: 100vw;
    top: 15px;
  }
  .RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepContainer__3LAjy {
    padding-left: 0;
    padding-right: 0;
  }
  .RegistrationPage_mainContentContainer__1KWQ4
    .RegistrationPage_stepsProgressContainer__32ri9
    .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT {
    margin-left: 0;
    margin-right: 0;
  }
  .RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9:first-child .RegistrationPage_stepContainer__3LAjy {
    margin-left: 0;
  }
  .RegistrationPage_mainContentContainer__1KWQ4
    .RegistrationPage_stepsProgressContainer__32ri9
    .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
    + .RegistrationPage_stepContainer__3LAjy {
    margin-left: 0;
    padding-right: 0;
  }
  .RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepContainer__3LAjy:last-of-type,
  .RegistrationPage_mainContentContainer__1KWQ4
    .RegistrationPage_stepsProgressContainer__32ri9
    .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
    + .RegistrationPage_stepContainer__3LAjy:last-of-type {
    padding-right: calc(100% - (24px * 3) - (35px * 4));
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepContainer__3LAjy .RegistrationPage_circle__1a_ia {
    width: 16px;
    height: 16px;
    border: 8px solid #e2dafa;
  }
  .RegistrationPage_mainContentContainer__1KWQ4 .RegistrationPage_stepsProgressContainer__32ri9 .RegistrationPage_stepContainer__3LAjy .RegistrationPage_stepLabel__2PwJa {
    left: 50%;
  }
  .RegistrationPage_mainContentContainer__1KWQ4
    .RegistrationPage_stepsProgressContainer__32ri9
    .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
    + .RegistrationPage_stepContainer__3LAjy
    .RegistrationPage_stepLabel__2PwJa {
    left: 50%;
  }
  .RegistrationPage_mainContentContainer__1KWQ4
    .RegistrationPage_stepsProgressContainer__32ri9
    + .RegistrationPage_stepContainer__3LAjy:last-of-type
    .RegistrationPage_stepLabel__2PwJa,
  .RegistrationPage_mainContentContainer__1KWQ4
    .RegistrationPage_stepsProgressContainer__32ri9
    .RegistrationPage_stepContainer__3LAjy.RegistrationPage_stepContainerActive__3JMhT
    + .RegistrationPage_stepContainer__3LAjy:last-of-type
    .RegistrationPage_stepLabel__2PwJa {
    left: 50%;
  }
  .RegistrationPage_form__2S4P8 {
    position: relative;
    width: 100%;
    margin-right: 0;
    min-height: auto;
    margin-bottom: 0;
  }
  .RegistrationPage_bottomBlobWrapper__asiDf {
    position: relative;
    left: calc((100vw - 100%) / -2);
    width: 100vw;
    height: 242px;
  }
  .RegistrationPage_herdyPickingApples__2M2K1 {
    width: 359px;
    width: 100%;
    height: 260px;
    left: 0;
    bottom: -50px;
  }
  .RegistrationPage_herdyCollectingApples__3BAhl {
    width: 362px;
    width: 100%;
    height: 260px;
    left: 0;
    bottom: -50px;
  }
}

.loader-fixed-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ResetPasswordPage_mainContainer__29Jjp {
  display: flex;
  margin: 0 auto;
  width: 100%;
  font-family: 'Open Sans Light';
}
.ResetPasswordPage_mainContainer__29Jjp.ResetPasswordPage_completed__67p5S {
  margin-top: 50px;
}
.ResetPasswordPage_description__2uP8K {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 25px;
  margin-top: 20px;
}
.ResetPasswordPage_form__SStL3 {
  z-index: 1;
  position: relative;
  right: 0;
  width: 300px;
  margin-left: auto;
  margin-right: 70px;
  margin-top: 80px;
  margin-bottom: 50px;
}
.ResetPasswordPage_title__1myYn {
  font-family: 'Roboto Black';
  font-size: 32px;
  margin: 0;
}
.ResetPasswordPage_input__33MSU {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #7c7c7c;
  height: 35px;
  padding: 0px 15px;
  outline: none;
  font-family: 'Open Sans Light';
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.ResetPasswordPage_grey__25l8U {
  margin-top: 10px;
  font-size: 14px;
  color: #d3d3d3;
  cursor: pointer;
}
.ResetPasswordPage_label__2hV5F {
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  font-family: 'Open Sans Light';
}
.ResetPasswordPage_button__1yes6 {
  display: block;
  text-align: center;
  background-color: #29f5b2;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Open Sans SemiBold';
  margin-top: 10px;
  text-transform: uppercase;
  width: 100%;
}
.ResetPasswordPage_button__1yes6:hover {
  opacity: 0.7;
}
.ResetPasswordPage_error__1Kr2K {
  margin-top: 4px;
  font-size: 13px;
  color: #fc035d;
  border-radius: 2px;
  display: inline-block;
}
.ResetPasswordPage_register__1QajF {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
}
.ResetPasswordPage_login__1aJ0Y {
  margin-top: 20px;
  font-size: 14px;
}
.ResetPasswordPage_greenLink__QXZD1 {
  color: #29f5b2;
  cursor: pointer;
  font-family: 'Open Sans SemiBold';
}
.ResetPasswordPage_bottomBlobWrapper__3QTFR {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: calc((100vw - 100%) / -2);
  overflow: hidden;
}
.ResetPasswordPage_bottomBlob__vHW4N {
  position: absolute;
  bottom: -1000px;
  width: 1718px;
  height: 1373px;
  left: -705px;
}
.ResetPasswordPage_herdyWorking__1zgFw {
  position: absolute;
  width: 651px;
  height: 570px;
  width: 554px;
  height: 400px;
  bottom: -60px;
  left: 200px;
}

@media only screen and (max-width: 1024px) {
  .ResetPasswordPage_mainContainer__29Jjp {
    flex-direction: column;
    box-sizing: border-box;
  }
  .ResetPasswordPage_form__SStL3 {
    position: relative;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .ResetPasswordPage_herdyWorking__1zgFw {
    width: 359px;
    height: 259px;
    left: 25px;
  }
}

.RequestResetPasswordPage_mainContainer__2goje {
  display: flex;
  margin: 0 auto;
  width: 100%;
  font-family: 'Open Sans Light';
}
.RequestResetPasswordPage_mainContainer__2goje.RequestResetPasswordPage_completed__1cnVT {
  margin-top: 50px;
}
.RequestResetPasswordPage_form__27Fhx {
  z-index: 1;
  position: relative;
  right: 0;
  width: 300px;
  margin-left: auto;
  margin-right: 70px;
  margin-top: 80px;
  margin-bottom: 50px;
}
.RequestResetPasswordPage_title__1OCq0 {
  font-family: 'Roboto Black';
  font-size: 32px;
  margin: 0;
}
.RequestResetPasswordPage_input__1EUyp {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #7c7c7c;
  height: 35px;
  padding: 0px 15px;
  outline: none;
  font-family: 'Open Sans Light';
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.RequestResetPasswordPage_label__12fTn {
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
  font-size: 14px;
  font-family: 'Open Sans Light';
}
.RequestResetPasswordPage_button__2jBCq {
  display: block;
  text-align: center;
  background-color: #29f5b2;
  color: #ffffff;
  cursor: pointer;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  border-radius: 30px;
  font-family: 'Open Sans SemiBold';
  margin-top: 10px;
  text-transform: uppercase;
  width: 100%;
}
.RequestResetPasswordPage_button__2jBCq:hover {
  opacity: 0.7;
}
.RequestResetPasswordPage_error__3QXVd {
  margin-top: 4px;
  font-size: 13px;
  color: #fc035d;
  border-radius: 2px;
  display: inline-block;
}
.RequestResetPasswordPage_register__3Ruk- {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
}
.RequestResetPasswordPage_greenLink__14d4_ {
  color: #29f5b2;
  cursor: pointer;
  font-family: 'Open Sans SemiBold';
}
.RequestResetPasswordPage_bottomBlobWrapper__2MNN1 {
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  left: calc((100vw - 100%) / -2);
  overflow: hidden;
}
.RequestResetPasswordPage_bottomBlob__5poUY {
  position: absolute;
  bottom: -1000px;
  width: 1718px;
  height: 1373px;
  left: -705px;
}
.RequestResetPasswordPage_herdyWorking__27MKx {
  position: absolute;
  width: 651px;
  height: 570px;
  width: 554px;
  height: 400px;
  bottom: -60px;
  left: 200px;
}

@media only screen and (max-width: 1024px) {
  .RequestResetPasswordPage_mainContainer__2goje {
    flex-direction: column;
    box-sizing: border-box;
  }
  .RequestResetPasswordPage_form__27Fhx {
    position: relative;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .RequestResetPasswordPage_herdyWorking__27MKx {
    width: 359px;
    height: 259px;
    left: 25px;
  }
}

.GoalOverview_container__1jZb1 {
}
.GoalOverview_goalOverviewContainer__1P18D {
  display: flex;
}
.GoalOverview_logoContainer__3fLu1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #b5b5b5;
  min-width: 200px;
  height: 190px;
  margin-right: 25px;
}
.GoalOverview_logo__aGNrn {
  display: block;
  max-width: 90%;
  max-height: 90%;
}
.GoalOverview_detailsContainer__1H-vo {
  margin: auto 0;
}
.GoalOverview_subTitle__2wLU8 {
  font-family: 'Roboto Regular';
  color: #000;
  margin-bottom: 4px;
}
.GoalOverview_description__1Ld-j {
  font-size: 16px;
  line-height: 17.5px;
  margin-bottom: 15px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
}
.GoalOverview_valueContainer__27AFv {
  margin-bottom: 15px;
}
.GoalOverview_valueContainer__27AFv .GoalOverview_currentValue__FPjaO {
  color: #792483;
  font-size: 30px;
  font-family: 'Montserrat Bold';
  margin-top: 4px;
}
.GoalOverview_valueContainer__27AFv .GoalOverview_loaderContainer__tqfbh {
  width: 20px;
}
.GoalOverview_editGoalButton__1C7yR {
  width: 17px;
  height: 17px;
  z-index: 99;
  position: absolute;
  top: -30px;
  right: -30px;
  padding: 15px;
  cursor: pointer;
  display: none;
}

@media only screen and (max-width: 1024px) {
  .GoalOverview_logo__aGNrn {
    max-width: 100%;
    max-height: 100%;
  }
  .GoalOverview_goalOverviewContainer__1P18D {
    padding: 0px;
    display: block;
  }
  .GoalOverview_logoContainer__3fLu1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 240px;
    margin-right: 0;
    border-radius: 15px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .GoalOverview_title__38u5W {
    font-size: 20px;
  }
}

.InviteMembers_title__c4z1I {
  font-family: 'Montserrat Bold';
  font-size: 24px;
  color: #792483;
  margin-bottom: 20px;
}
.InviteMembers_subTitle__15g3h {
  font-family: 'Roboto Regular';
  color: #000;
  margin-bottom: 4px;
}
.InviteMembers_linkInputContainer__3bGkx {
  display: flex;
  border: 1px solid #dfe0e4;
  border-radius: 7px;
  width: 650px;
  height: 25px;
  top: -2px;
}
.InviteMembers_linkInputContainer__3bGkx .InviteMembers_linkInput__1tloT {
  padding-left: 10px;
  height: 25px;
  line-height: 25px;
  width: auto;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.InviteMembers_linkInputContainer__3bGkx .InviteMembers_copyIconContainer__34nVn {
  margin-left: auto;
  padding-left: 2px;
  background-color: #792483;
  height: 25px;
  width: 48px;
  border-radius: 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: 28%;
  background-position: center center;
}
.InviteMembers_linkInputContainer__3bGkx .InviteMembers_copyIconContainer__34nVn:active,
.InviteMembers_linkInputContainer__3bGkx .InviteMembers_copyIconContainer__34nVn:focus {
  opacity: 0.7;
}

@media only screen and (max-width: 1024px) {
  .InviteMembers_linkLabel__3Havj {
    margin-bottom: 15px;
  }
  .InviteMembers_linkInputContainer__3bGkx {
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .InviteMembers_title__c4z1I {
    font-size: 20px;
  }
}

.Ranking_title__Acc9Z {
  font-family: 'Montserrat Bold';
  font-size: 24px;
  color: #792483;
  margin-bottom: 0;
}
.Ranking_contentContainer__1VVE0 {
}
.Ranking_table__3Wv9h {
  flex-basis: 100%;
}
.Ranking_tableRow__2e4DT {
  display: flex;
  margin-top: 35px;
  line-height: 20px;
}
.Ranking_tableRow__2e4DT .Ranking_index__1gexy {
  margin-right: 15px;
  color: #792483;
  font-family: 'Montserrat Bold';
  font-size: 20px;
  width: 20px;
}
.Ranking_tableRow__2e4DT .Ranking_name__3yTKh {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.Ranking_tableRow__2e4DT .Ranking_tokens__3PeDU {
  margin-left: auto;
  text-align: right;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-left: 15px;
}
.Ranking_noDataAvailable__qZUeM {
  margin-top: 40px;
  color: #8c8c8c;
}

@media only screen and (max-width: 1024px) {
  .Ranking_contentContainer__1VVE0 {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .Ranking_title__Acc9Z {
    font-size: 20px;
  }
}

ul.pagination-organisation {
  font-family: 'Roboto Medium';
}
ul.pagination-organisation li.active a {
  background-color: #792483;
}
ul.pagination-organisation li.previous,
ul.pagination-organisation li.next {
  color: #792483;
}

.Data_title__2QWXr {
  font-family: 'Montserrat Bold';
  font-size: 24px;
  color: #792483;
  margin-bottom: 0;
}
.Data_table__3vc3b {
  width: 100%;
}
.Data_tableRow__24_pr {
  display: flex;
  margin-top: 35px;
  justify-content: space-between;
}
.Data_tableRow__24_pr .Data_value__24tF6 {
  text-align: right;
}
.Data_nestedTable__2vwpE {
  margin-top: 30px;
  margin-left: 30px;
  padding-left: 35px;
  border-left: 1px solid #792483;
}
.Data_nestedTableRow__W4Baf {
  margin-bottom: 15px;
  padding-bottom: 5px;
}
.Data_nestedTableDataRow__2jxPv {
  display: flex;
  margin-bottom: 5px;
}
.Data_nestedTableRowTitle__2keBE {
  color: #792483;
  font-family: 'Roboto Regular';
}
.Data_nestedTableRowValue__34VM2 {
  margin-left: auto;
  text-align: right;
}

@media only screen and (max-width: 768px) {
  .Data_title__2QWXr {
    font-size: 20px;
  }
  .Data_nestedTable__2vwpE {
    margin-top: 20px;
    margin-left: 20px;
    padding-left: 25px;
    border-left: 1px solid #792483;
  }
}

.line-chart-container .chart-group line,
.line-chart-container .chart-group path {
  stroke: rgba(111, 42, 127, 0.15);
  fill: none;
  stroke-width: 2px;
}
.line-chart-container .chart-group .data-circle {
  stroke: none;
  fill: #6f2a7f;
}
.line-chart-container .chart-group .data-circle .data-tooltip {
  stroke: none;
  color: #6f2a7f;
}
.line-chart-container .axis-group line,
.line-chart-container .axis-group path {
  stroke: none;
  fill: none;
}
.line-chart-container .axis-group text {
  font-size: 14px;
  font-family: 'Roboto Light';
  stroke: none;
}
.line-chart-container .grid-group line,
.line-chart-container .grid-group path {
  stroke: #a7a7a7;
  fill: none;
}
.line-chart-container .domain {
  display: none;
}

.MoneyGraph_container__2E3nP {
  color: #000000;
}
.MoneyGraph_title__3x66K {
  font-family: 'Montserrat Bold';
  font-size: 24px;
  color: #792483;
  margin-bottom: 20px;
}
.MoneyGraph_noDataAvailable__q-pdM {
  margin-top: 40px;
  color: #8c8c8c;
}

@media only screen and (max-width: 768px) {
  .MoneyGraph_title__3x66K {
    font-size: 20px;
  }
}

.DashboardPage_container__3YCG0 {
  padding-top: 40px;
  font-family: 'Roboto Light';
}
.DashboardPage_title__3kvQs {
  font-family: 'Montserrat Bold';
  font-size: 32px;
  color: #000;
  margin-top: 40px;
  margin-bottom: 30px;
}
.DashboardPage_section__9FG7I {
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  box-shadow: 1px 5px 5px 0px rgba(0, 0, 0, 0.07);
  padding: 50px;
  margin-bottom: 30px;
}
.DashboardPage_section__9FG7I.DashboardPage_sectionLoading__2zeRm {
  height: 300px;
  background-color: #f6f7fb;
  border: none;
}
@media only screen and (max-width: 1024px) {
  .DashboardPage_section__9FG7I {
    padding: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .DashboardPage_title__3kvQs {
    font-size: 24px;
  }
}

.EditGoalPage_title__Xsxac {
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
}
.EditGoalPage_description__2YhWk {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 25px;
  margin-top: 20px;
}
.EditGoalPage_panel__3TEFH {
  width: 620px;
  padding: 20px 40px 40px 40px;
  margin-top: 30px;
  margin-bottom: 80px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.01);
  background-color: #ffffff;
}
.EditGoalPage_button__35ZCt {
  background-color: #4926e9;
  height: 50px;
  line-height: 50px;
  width: 300px;
  color: #ffffff;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}
.EditGoalPage_login__2wPa6 {
  margin-top: 30px;
  text-align: center;
  color: #4926e9;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 30px;
}
.EditGoalPage_register__CFqcO:hover {
  text-decoration: underline;
}
.EditGoalPage_inputLabel__1DUPZ {
  margin-top: 20px;
  margin-bottom: 5px;
}
.EditGoalPage_input__EiwJ_ {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  outline: none;
  font-size: 14px;
}
.EditGoalPage_textarea__2_Nm- {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  outline: none;
  height: 120px;
}
.EditGoalPage_file__1Voqa {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  outline: none;
  color: #ffffff;
}
.EditGoalPage_logoName__1Wtf0 {
  position: absolute;
  left: 100px;
  top: 12px;
  color: #221f1f;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 400px;
}
.EditGoalPage_error__2uwQA {
  color: #ff3c00;
  font-size: 13px;
  float: right;
}
.EditGoalPage_fixErrors__2v0zW {
  color: #ff3c00;
  font-size: 13px;
  text-align: center;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1024px) {
  .EditGoalPage_panel__3TEFH {
    width: calc(90% - 80px);
    margin-left: auto;
    margin-right: auto;
  }
  .EditGoalPage_input__EiwJ_ {
    width: calc(100% - 40px);
  }
  .EditGoalPage_description__2YhWk {
    width: 90%;
  }
}

.Goal_title__xeX6O {
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
}
.Goal_description__3k5d4 {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  line-height: 25px;
  margin-top: 20px;
}
.Goal_panel__1w4CU {
  width: 620px;
  padding: 20px 40px 40px 40px;
  margin-top: 30px;
  margin-bottom: 80px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 1px 10px 5px 0px rgba(0, 0, 0, 0.01);
  background-color: #ffffff;
}
.Goal_button__3fU6M {
  background-color: #4926e9;
  height: 50px;
  line-height: 50px;
  width: 200px;
  color: #ffffff;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
}
.Goal_login__2Y7Tl {
  margin-top: 30px;
  text-align: center;
  color: #4926e9;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 30px;
}
.Goal_register__2VvJN:hover {
  text-decoration: underline;
}
.Goal_inputLabel__2UMjK {
  margin-top: 20px;
  margin-bottom: 5px;
}
.Goal_input__3RA1Y {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  outline: none;
  font-size: 14px;
}
.Goal_textarea__3jL_b {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 22px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding: 10px 20px;
  background-color: #ffffff;
  outline: none;
  height: 120px;
  font-size: 14px;
}
.Goal_file___bHqn {
  display: block;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  border: 1px solid #8f8f90;
  border-radius: 30px;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
  outline: none;
  color: #ffffff;
}
.Goal_logoName__fYfuB {
  position: absolute;
  right: 15px;
  top: 12px;
  color: #221f1f;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 430px;
}
.Goal_error__1zgGO {
  color: #ff3c00;
  font-size: 13px;
  float: right;
}
.Goal_imageContainer__2WPW9 {
  display: flex;
}
.Goal_imageThumbnail__2aM54 {
  width: 40px;
  height: 40px;
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #8f8f90;
  margin-right: 15px;
}
@media only screen and (max-width: 1024px) {
  .Goal_logoName__fYfuB {
    display: none;
  }
}

.Bubbles1_container__2FX1S {
  width: 100%;
}
.Bubbles1_container__2FX1S .Bubbles1_bubble1__3ZP2P {
  position: absolute;
  top: 0px;
  left: -700px;
}
.Bubbles1_container__2FX1S .Bubbles1_bubble2__32xWX {
  position: absolute;
  top: 0px;
  right: -500px;
}

.Loader_loaderFixedContainer__-E3QZ {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.7;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Loader_loadingAnimation__2gWuk {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80.73px;
  height: 50px;
  opacity: 0.5;
}
.Loader_loadingAnimation__2gWuk div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  -webkit-animation: Loader_loading-animation__246vm 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Loader_loading-animation__246vm 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}
.Loader_loadingAnimation__2gWuk div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.Loader_loadingAnimation__2gWuk div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.Loader_loadingAnimation__2gWuk div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes Loader_loading-animation__246vm {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes Loader_loading-animation__246vm {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

